import React, { useState, useEffect } from 'react';
import { ICylinder, IKey } from 'models/types';
import { filterAssignableKeys } from 'domain/key/key_helper';
import { KeyRadioList } from 'domain/key/components';

interface ISelectTaskKeyModalProps {
    onSelect: (key: IKey) => void;
    cylindersToAssign: Array<ICylinder>;
    keys: Array<IKey>;
}

const SelectTaskKeyModal = (props: ISelectTaskKeyModalProps) => {
    const { keys, cylindersToAssign, onSelect } = props;

    const [assignableKeys, setAssignableKeys] = useState<Array<IKey>>([]);

    useEffect(() => {
        setAssignableKeys(filterAssignableKeys(cylindersToAssign, keys));
    }, [cylindersToAssign, keys]);

    return <KeyRadioList items={assignableKeys} onSelect={onSelect} />;
};

export default SelectTaskKeyModal;
