import { SET_APP_LOCALE, SetAppLocaleActionType } from './action_types';
import { Dispatch } from 'redux';

export const setAppLocale = (locale: string) => {
    return (dispatch: Dispatch<SetAppLocaleActionType>) => {
        dispatch({
            type: SET_APP_LOCALE,
            locale
        });
    };
};
