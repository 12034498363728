import React from 'react';
import { Flex, Text } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import ScheduleModel from 'models/schedule';

interface IScheduleProps {
    value?: ScheduleModel;
}

const ScheduleDuration = (props: IScheduleProps) => {
    const { value } = props;
    const { t } = useTranslation();

    if (!value) {
        return null;
    }

    const generateScheduleString = (numberOfPeriods: number, noSchedule: boolean) => {
        if (noSchedule) {
            return t(TKey.keyScheduleOff);
        } else if (numberOfPeriods === 0) {
            return t(TKey.keyScheduleNoPeriods);
        } else {
            return t(TKey.keyScheduleNumPeriods, {
                postProcess: 'interpolate',
                count: numberOfPeriods
            });
        }
    };

    const renderNewSchedule = () => {
        if (value.isPending()) {
            const numberOfPeriods = value.numberOfPendingPeriods();

            return (
                <Text fontSize="1rem" color="warning.90">
                    {t(TKey.keyScheduleNewSchedule)}:
                    <Text pl="half">{generateScheduleString(numberOfPeriods, !value.usePending)}</Text>
                </Text>
            );
        }
        return null;
    };

    const renderCurrentSchedule = () => {
        const numberOfPeriods = value.numberOfProgrammedPeriods();
        return <Text fontSize="1rem">{generateScheduleString(numberOfPeriods, !value.useProgrammed)}</Text>;
    };

    return (
        <Flex pt="half" flexDirection="column">
            {renderCurrentSchedule()}
            {renderNewSchedule()}
        </Flex>
    );
};

export default ScheduleDuration;
