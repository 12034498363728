import React from 'react';
import { useTranslation } from 'locale';
import { KeyCollectionType } from 'domain/key/types';
import { getKeyState, getKeyName, getKeyStatus, getKeyIconColor, getKeyBackgroundColor } from 'domain/key/key_helper';
import { Flex, H5, Text, Box } from 'aa-react-ui';
import { getHardwareMarking } from 'domain/hardware/hardware_helper';
import { KEY_STATE_TRANSLATION_KEY_MAP, KEY_STATUS_TRANSLATION_KEY_MAP } from 'domain/key/constants';
import { IconWrapper, KeyBlockedIcon, KeyIcon } from 'components/icon';
import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';
import Card from 'components/card';
import LoukInfo from './louk_info';

export interface IkeyCollectionItemProps {
    data?: KeyModel;
    hideStatus?: boolean;
    collectionType?: KeyCollectionType;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    parentCylinder?: CylinderModel;
}

const KeyCollectionItem: React.FunctionComponent<IkeyCollectionItemProps> = props => {
    const { data, selected, children, disabled, onClick, hideStatus, parentCylinder } = props;
    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    const isBlocked = data.isBlocked();

    const buildHeaderText = () => {
        const state = KEY_STATE_TRANSLATION_KEY_MAP[getKeyState(data)];
        const name = getKeyName(data);

        if (name && isBlocked) {
            return `${t(state)} (${name})`;
        }

        if (name) {
            return name;
        }

        return t(state);
    };

    const headerText = buildHeaderText();
    const marking = getHardwareMarking(data);

    const status = getKeyStatus(data)
        .filter(i => i)
        .map(j => {
            return t(KEY_STATUS_TRANSLATION_KEY_MAP[j]);
        })
        .join(' / ');

    return (
        <Card p="half" mt="quarter" mb="quarter" bg={getKeyBackgroundColor(disabled, selected)} onClick={onClick}>
            <IconWrapper icon={isBlocked ? KeyBlockedIcon : KeyIcon} stroke={getKeyIconColor(data)} width="3rem" />
            <Flex flexDirection="column" width="100%">
                <H5 data-testid="key-collection-item-header">{headerText}</H5>
                <Text color="contrast.60">{marking}</Text>
                {!hideStatus && <Text color="warning.90">{status}</Text>}
                {parentCylinder && <LoukInfo cylinder={parentCylinder} keyId={data.uuid} />}
            </Flex>
            <Box>{children}</Box>
        </Card>
    );
};

export default KeyCollectionItem;
