import directoryService, { cliqUrlBuilder } from 'common/services/directory_service';
import httpClient, { HTTP_STATUS_CODE } from 'common/services/http_client';
import socketService from 'common/services/socket_service';

import { TKey } from 'locale';
import { Dispatch } from 'redux';
import { SET_LOGGED_IN } from './action_types';
import { ISystem } from 'common/services/directory_service/types';

const setLoggedIn = (isLoggedIn: boolean, system?: ISystem) => ({
    type: SET_LOGGED_IN,
    isLoggedIn,
    system
});

export const createUserSession = (systemId: string, password: string) => {
    return async (dispatch: Dispatch): Promise<string> => {
        const [aaCode, mksName] = systemId.split(':');
        const error = await directoryService.init(parseInt(aaCode, 10), mksName);
        if (error) {
            return error;
        }

        const defaultSystem = directoryService.getDefaultSystem();

        if (!defaultSystem) {
            return TKey.loginErrorSystemNotFound;
        }

        try {
            const loginResults = await httpClient.post(
                cliqUrlBuilder.defaultSystemLogin(),
                {
                    systemId: defaultSystem.id,
                    pinCode: password
                },
                { withCredentials: true }
            );

            if (loginResults.status === HTTP_STATUS_CODE.OK) {
                socketService.open(defaultSystem.aaCode, defaultSystem.mksName);

                dispatch(setLoggedIn(true, defaultSystem));
                return '';
            }
            return TKey.genericErrorUnexpected;
        } catch (e) {
            const { response } = e;

            if (response) {
                const { status } = response;

                if (status === HTTP_STATUS_CODE.UNAUTHORIZED) {
                    return TKey.loginErrorGeneral;
                }
            }

            return TKey.genericErrorUnexpected;
        }
    };
};

export const destroyAppSession = () => {
    return async (dispatch: Dispatch): Promise<string | undefined> => {
        try {
            httpClient.clearListeners();
            const response = await httpClient.get(cliqUrlBuilder.defaultSystemLogout(), {
                withCredentials: true
            });

            if (response.status === HTTP_STATUS_CODE.OK) {
                socketService.close();
                directoryService.tearDown();
                dispatch(setLoggedIn(false));
                return;
            }
            return TKey.genericErrorUnexpected;
        } catch (e) {
            dispatch(setLoggedIn(false));
            return TKey.genericErrorUnexpected;
        }
    };
};
