import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import ValidityOption from 'domain/validity/components/validity_option';
import DateRange from 'components/date_range';
import { IDateRange } from 'components/date_range/types';
import dateTimeUtils from 'common/utils/date_time';
import { FIRST_POSSIBLE_DATE, LAST_POSSIBLE_DATE } from 'common/configs';
import { ValidityState } from 'domain/validity/types';
import Card from 'components/card';
import ValidityModel from 'models/validity';
import CommonUtils from 'common/utils/common';

interface IValiditySelector {
    value?: ValidityModel;
    onSelect?: (newValidity: ValidityModel) => void;
}

const ValiditySelector = (props: IValiditySelector) => {
    const { onSelect, value } = props;

    const { t } = useTranslation();
    const [currentState, setCurrentState] = useState(ValidityState.ALWAYS);
    const [dateRange, setDateRange] = useState<IDateRange>({
        startDate: dateTimeUtils.formatDateString(FIRST_POSSIBLE_DATE, 'MM/DD/YYYY'),
        endDate: dateTimeUtils.formatDateString(LAST_POSSIBLE_DATE, 'MM/DD/YYYY')
    });

    useEffect(() => {
        if (value) {
            const validityClone = CommonUtils.cloneDeep(value);

            const validityPending = validityClone.isPending();
            if (validityPending) {
                setCurrentState(validityClone.newState);
                setDateRange({
                    startDate: validityClone.newRangeBeginDate,
                    endDate: validityClone.newRangeEndDate
                });
            } else {
                setCurrentState(validityClone.currentState);
                setDateRange({
                    startDate: validityClone.currentRangeBeginDate,
                    endDate: validityClone.currentRangeEndDate
                });
            }
        }
    }, [value]);

    if (!value) {
        return null;
    }

    const renderDateRangeError = () => {
        if (!dateTimeUtils.isDateRangeValid(dateRange.startDate, dateRange.endDate)) {
            return (
                <Box p="full">
                    <Text color="error.90">{t(TKey.genericErrorInput)}</Text>
                </Box>
            );
        }
        return null;
    };

    const pushSelection = (state: ValidityState, range: IDateRange) => {
        const newValidity = CommonUtils.cloneDeep(value);

        newValidity.newRangeBeginDate = range.startDate;
        newValidity.newRangeEndDate = range.endDate;
        newValidity.newState = state;
        onSelect && onSelect(newValidity);
    };

    return (
        <Flex flexDirection="column" pt="full" mb="6rem">
            <ValidityOption
                onClick={() => {
                    pushSelection(ValidityState.ALWAYS, dateRange);
                }}
                label={t(TKey.keyValidityAlways)}
                selected={currentState === ValidityState.ALWAYS}
            />
            <ValidityOption
                onClick={() => {
                    pushSelection(ValidityState.NEVER, dateRange);
                }}
                label={t(TKey.keyValidityNever)}
                selected={currentState === ValidityState.NEVER}
            />
            <ValidityOption
                onClick={() => {
                    pushSelection(ValidityState.RANGE, dateRange);
                }}
                label={t(TKey.keyValidityRange)}
                selected={currentState === ValidityState.RANGE}>
                {currentState === ValidityState.RANGE && (
                    <Card>
                        <Box mb="2.2rem">
                            <DateRange
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onChange={(range: IDateRange) => {
                                    pushSelection(currentState, range);
                                }}
                            />
                            {renderDateRangeError()}
                        </Box>
                    </Card>
                )}
            </ValidityOption>
        </Flex>
    );
};

export default ValiditySelector;
