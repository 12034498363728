import React from 'react';

import { HomeIcon, KeyIcon, AboutIcon, TaskListIcon, LogoutIcon, CylinderIcon } from 'components/icon';

import { Flex, Text } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { Routes } from 'router';

import Link from './link';
import Icon from './icon';

const SideNav = () => {
    const { t } = useTranslation();

    return (
        <Flex height="100%" bg="contrast.90" flexDirection="column" justifyContent="space-between">
            <Flex flexDirection="column">
                <Link exact to={Routes.DASHBOARD} activeClassName="active">
                    <Icon icon={HomeIcon} />
                    <Text>{t(TKey.genericDashboard)}</Text>
                </Link>

                <Link to={Routes.KEYS} activeClassName="active">
                    <Icon icon={KeyIcon} />
                    <Text>{t(TKey.genericKeys)}</Text>
                </Link>

                <Link to={Routes.CYLINDERS} activeClassName="active">
                    <Icon icon={CylinderIcon} />
                    <Text>{t(TKey.genericCylinders)}</Text>
                </Link>

                <Link to={Routes.TASK_LIST} activeClassName="active">
                    <Icon icon={TaskListIcon} />
                    <Text>{t(TKey.genericTaskList)}</Text>
                </Link>

                <Link to={Routes.ABOUT} activeClassName="active">
                    <Icon icon={AboutIcon} />
                    <Text>{t(TKey.genericAbout)}</Text>
                </Link>
            </Flex>

            <Flex flexDirection="column">
                <Link to={Routes.LOGOUT} activeClassName="active">
                    <Icon icon={LogoutIcon} />
                    <Text>{t(TKey.actionLogout)}</Text>
                </Link>
            </Flex>
        </Flex>
    );
};

export default SideNav;
