import React from 'react';
import { Form, FormProps } from 'react-final-form';

import { Form as UIForm } from 'aa-react-ui';

function FormComponent(props: FormProps) {
    const { children, ...rest } = props;
    return (
        <Form {...rest}>
            {({ handleSubmit, ...rest }) => (
                <UIForm onSubmit={handleSubmit} style={{ width: '100%', height: '100%' }}>
                    {children && typeof children === 'function' && children({ handleSubmit, ...rest })}
                </UIForm>
            )}
        </Form>
    );
}

export default FormComponent;
