import KeyAccessEditor from './key_access_editor';
import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getSelectedKey, getKeyAccessData } from 'domain/key/redux/selectors';

const mapStateToProps = (state: IAppStoreState) => {
    return {
        keyData: getSelectedKey(state),
        keyAccessData: getKeyAccessData(state)
    };
};

export default connect(mapStateToProps)(KeyAccessEditor);
