import ReactDOM from 'react-dom';

interface PortalInterface {
    children: object;
}

function Portal(props: PortalInterface) {
    const { children } = props;

    const containerType = 'portal-modals';

    let portalContainer = document.getElementById(containerType);

    if (!portalContainer) {
        portalContainer = document.createElement('div');
        portalContainer.setAttribute('id', containerType);
        document.body.appendChild(portalContainer);
    }

    return ReactDOM.createPortal(children, portalContainer);
}

export default Portal;
