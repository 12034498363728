import storageService from '../storage_service';
import { IDsUrls, ISystem, IDsCredentials } from './types';

export const DS_URLS = 'SESSION_KEY:DS_URLS';
export const DS_CREDENTIALS = 'SESSION_KEY:DS_CREDENTIALS';
export const DS_DEFAULT_SYSTEM = 'SESSION_KEY:DS_DEFAULT_SYSTEM';
export const DIRECTORY_SERVICE_URL = 'STORE_KEY:DIRECTORY_SERVICE_URL';

export default {
    getDirectoryServiceUrl: () => {
        const serviceUrl = storageService.getItem(DIRECTORY_SERVICE_URL);
        return serviceUrl || process.env.REACT_APP_DS_URL;
    },
    getCreds: (): IDsCredentials | undefined => storageService.getItem(DS_CREDENTIALS, true),
    getServiceUrls: (): IDsUrls | undefined => storageService.getItem(DS_URLS, true),
    getDefaultSystem: (): ISystem | undefined => storageService.getItem(DS_DEFAULT_SYSTEM, true),
    setDirectoryServiceUrl: (url: string) => {
        storageService.setItem(DIRECTORY_SERVICE_URL, url);
    },
    setCreds: (data?: IDsCredentials): void => {
        storageService.setItem(DS_CREDENTIALS, data, true);
    },
    setServiceUrls: (data?: IDsUrls): void => {
        storageService.setItem(DS_URLS, data, true);
    },
    setDefaultSystem: (system?: ISystem): void => {
        storageService.setItem(DS_DEFAULT_SYSTEM, system, true);
    }
};
