import moment from 'moment';
import CylinderModel from 'models/cylinder';
import KeyModel from 'models/key';
import LoalEntryModel from 'models/loal_entry';
import CommonUtils from 'common/utils/common';

import { KeyState, CylinderState, AuthorizationState, IKey, ICylinder, ICylinderAuthorization } from 'models/types';
import { sortOnNameAndMarking, sortOnAlphaNumeric } from 'domain/hardware/hardware_helper';
import { CUSTOM_THEME_COLOR } from 'common/constants';
import { getAssignedButNotStartedTasks, getStartedTasks } from 'domain/task/task_helper';
import { IAssignedTaskMap } from 'domain/task/types';
import { CylinderStateInt, CylinderStatus, IAuditTrail, AuditTrailEvent, ICylinderAccessInfo } from './types';
import {
    sortKeysOnStateAndNameAndMarking,
    hasKeyAccessInCylinder,
    isKeyBlockedInCylinder
} from 'domain/key/key_helper';

import { isKeyHarmful } from 'domain/key/key_harmful';

export const isCylinderAtRisk = (cylinder: CylinderModel) => {
    if (cylinder) {
        const loukKeys = cylinder.getLouks().map(item => new KeyModel(item));
        const harmfulKeys = loukKeys.filter(item => isKeyHarmful(item));

        return harmfulKeys.some(key =>
            key.authorizations.some(
                loal =>
                    (loal.isCurrent() || loal.isRemoved()) &&
                    loal.cylinderUuid === cylinder.uuid &&
                    !isKeyBlockedInCylinder(key, cylinder)
            )
        );
    }

    return false;
};

export const getCylinderState = (cylinderData: CylinderModel) => {
    if (cylinderData.isInstalled()) {
        return CylinderState.INSTALLED;
    }

    if (cylinderData.isInStock()) {
        return KeyState.IN_STOCK;
    }

    return CylinderState.UNKNOWN;
};

export const sortCylindersOnStateAndNameAndMarking = (rhs: CylinderModel, lhs: CylinderModel) => {
    const rhsStateInt = rhs.stateIntRepresentation();
    const lhsStateInt = lhs.stateIntRepresentation();
    const stateDiff = rhsStateInt - lhsStateInt;
    if (stateDiff !== 0) {
        return stateDiff;
    }

    if (rhsStateInt < CylinderStateInt.IN_STOCK) {
        return sortOnNameAndMarking(rhs, lhs);
    }

    return sortOnAlphaNumeric(rhs.marking, lhs.marking);
};

export const getCylinderStatus = (cylinderData: CylinderModel) => {
    const status = [];

    if (cylinderData.hasPendingAccess()) {
        status.push(CylinderStatus.PENDING_ACCESS);
    }

    if (cylinderData.hasRequestedAuditTrail()) {
        status.push(CylinderStatus.PENDING_ACCESS_LOG);
    }

    return status;
};

export const getCylinderName = (dataModel: CylinderModel): string => {
    const { name } = dataModel;

    if (name) {
        return name;
    }

    return '';
};

export const filterAccessLog = (logs: Array<IAuditTrail>, startDate: Date, endDate: Date) => {
    const inRange = (min: Date, max: Date, value: Date) => min <= value && value <= max;

    return logs.filter(
        item =>
            item.event === AuditTrailEvent.OPEN &&
            (item.date === null || inRange(startDate, endDate, moment(item.date).toDate()))
    );
};

export const getCylinderIconColor = (cylinder: CylinderModel) => {
    if (isCylinderAtRisk(cylinder)) {
        return CUSTOM_THEME_COLOR.red;
    }

    if (cylinder.hasPendingJob()) {
        return CUSTOM_THEME_COLOR.orange;
    }

    if (cylinder.isInstalled()) {
        return CUSTOM_THEME_COLOR.darkBlue;
    }

    return CUSTOM_THEME_COLOR.gray;
};

export const getCylinderBackgroundColor = (disabled = false, selected = false) => {
    if (disabled) {
        return CUSTOM_THEME_COLOR.lightGray;
    }
    if (selected) {
        return CUSTOM_THEME_COLOR.extraLightBlue;
    }

    return 'transparent';
};

export const getCylinderAccessInfoList = (cylinderId: string, keys: Array<IKey>): Array<ICylinderAccessInfo> => {
    if (cylinderId) {
        const loalInfo = keys.map(key => {
            const { authorizations } = key;
            const keyAuth = authorizations.find(auth => auth.cylinderUuid === cylinderId);
            if (keyAuth) {
                return {
                    state: keyAuth.state,
                    cylinderId,
                    key
                };
            }
            return {
                state: AuthorizationState.PLACEHOLDER,
                cylinderId,
                key
            };
        });

        return [...loalInfo].sort((lhs, rhs) =>
            sortKeysOnStateAndNameAndMarking(new KeyModel(lhs.key), new KeyModel(rhs.key))
        );
    }

    return [];
};

export const sanitizeCylinderAccessInfo = (newData: ICylinderAccessInfo, oldData: ICylinderAccessInfo) => {
    const data = CommonUtils.cloneDeep(newData);

    if (oldData.state === data.state) {
        return data;
    }

    const { key, cylinderId, state } = data;

    if (data.state === AuthorizationState.PLACEHOLDER) {
        data.key.authorizations = key.authorizations.filter(auth => auth.cylinderUuid !== cylinderId);

        return data;
    }

    if (oldData.state === AuthorizationState.PLACEHOLDER) {
        const loal = new LoalEntryModel({
            cylinderUuid: cylinderId,
            state
        });

        data.key.authorizations.push(loal);
        return data;
    }

    for (const auth of data.key.authorizations) {
        if (auth.cylinderUuid === data.cylinderId) {
            auth.state = state;
            break;
        }
    }

    return data;
};

export const filterAssignedCylinders = (cylinders: Array<ICylinder>) => {
    return cylinders.filter(item => item.tasks.length && item.assignedToKeyUuid);
};

export const getAssignedTaskList = (assignedCylinders: Array<ICylinder>, keys: Array<IKey>) => {
    const tasks: IAssignedTaskMap = assignedCylinders.reduce((result: IAssignedTaskMap, item) => {
        const { assignedToKeyUuid } = item;

        if (assignedToKeyUuid) {
            const taskGroup = result[assignedToKeyUuid];

            if (taskGroup) {
                taskGroup.cylinders.push(item);
            } else {
                const key = keys.find(keyItem => keyItem.uuid === assignedToKeyUuid);
                result[assignedToKeyUuid] = {
                    key,
                    cylinders: [item]
                };
            }
        }

        return result;
    }, {});

    return Object.values(tasks);
};

export const filterUnassignedCylinders = (cylinders: Array<ICylinder>) => {
    return cylinders.filter(item => item.tasks.length && !item.assignedToKeyUuid);
};

export const assignTaskKeyIdToCylinders = (keyId: string, cylinders: Array<ICylinder>) => {
    return cylinders.map(item => {
        item.assignedToKeyUuid = keyId;
        return item;
    });
};

export const unassignedTaskKeyFromCylinders = (cylinders: Array<ICylinder>) => {
    return cylinders.map(item => {
        item.assignedToKeyUuid = null;
        return item;
    });
};

export const isCylinderUnassignable = (cylinder: ICylinder) => {
    const assignedButNotStartedTasks = getAssignedButNotStartedTasks(cylinder.tasks);
    const startedTasks = getStartedTasks(cylinder.tasks);

    return !!(assignedButNotStartedTasks.length || startedTasks.length);
};

export const isAssignmentPossible = (loal: Array<ICylinderAuthorization>, cylinders: Array<ICylinder>) => {
    return !loal.some(auth => {
        const loalCylinder = cylinders.find(item => item.uuid === auth.cylinderUuid);
        if (loalCylinder) {
            return loalCylinder.assignedToKeyUuid;
        } else {
            return false;
        }
    });
};

export const isCylinderAtRiskForLoal = (loal: LoalEntryModel, cylinder: CylinderModel, key: KeyModel) =>
    (loal.isCurrent() || loal.isRemoved()) && key.isBlocked() && hasKeyAccessInCylinder(key, cylinder);

export const getAffectedCylindersBy = (key: IKey, cylinders: Array<ICylinder>) => {
    if (key && cylinders) {
        return cylinders.filter(cylinder =>
            cylinder.unauthorizedKeys.some(
                louk => louk.keyUuid === key.uuid && louk.state === AuthorizationState.CURRENT
            )
        );
    }

    return [];
};

export const getPendingCylinders = (allCylinders: Array<ICylinder>) => {
    return allCylinders.reduce((result: Array<ICylinder>, item) => {
        const cylinder = new CylinderModel(item);

        if (cylinder.hasPendingJob()) {
            result.push(item);
        }

        return result;
    }, []);
};

export const getCylindersWithAssignedTaskKey = (
    authorizations: Array<ICylinderAuthorization>,
    cylinders: Array<ICylinder>,
    taskKey: IKey
) => {
    return authorizations.reduce((result: Array<ICylinder>, auth) => {
        const loalCylinder = cylinders.find(item => item.uuid === auth.cylinderUuid);
        if (loalCylinder && !loalCylinder.assignedToKeyUuid) {
            loalCylinder.assignedToKeyUuid = taskKey.uuid;
            result.push(loalCylinder);
        }

        return result;
    }, []);
};
