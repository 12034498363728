import { CylinderFilter } from '../types';

export const UPDATE_CYLINDER = 'UPDATE_CYLINDER';
export const UPDATE_MULTIPLE_CYLINDERS = 'UPDATE_MULTIPLE_CYLINDERS';
export const LOAD_ACCESS_LOG = 'LOAD_ACCESS_LOG';
export const SET_CYLINDER_SEARCH_QUERY = 'SET_CYLINDER_SEARCH_QUERY';
export const SET_CYLINDER_FILTER = 'SET_CYLINDER_FILTER';
export const SET_SELECTED_CYLINDER = 'SET_SELECTED_CYLINDER';

export interface ISetCylinderFilterAction {
    type: typeof SET_CYLINDER_FILTER;
    filter: CylinderFilter;
}

export interface ISetCylinderSearchQueryAction {
    type: typeof SET_CYLINDER_SEARCH_QUERY;
    searchQuery: string;
}

export interface ISetCurrentSelectedCylinder {
    type: typeof SET_SELECTED_CYLINDER;
    cylinderId: string;
}
