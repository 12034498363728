import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ICopySettings } from './types';
import { useKey, useUpdateKeyRequest } from 'domain/key/redux/hooks';
import { useNotification } from 'domain/notification/redux/hooks';
import { TKey } from 'locale';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';

import useWizard from 'components/wizard/useWizard';
import SelectSettings from './select_settings';
import SelectTargetKey from './select_target_key';
import SelectionSummary from './selection_summary';

const CopyDetails = () => {
    const history = useHistory();

    const { updateMultipleKeys, loading } = useUpdateKeyRequest();
    const { showSuccess, showError } = useNotification();

    const params = useParams<{ keyId: string }>();
    const selectedKey = useKey(params.keyId);

    const onFinish = async (finalData: ICopySettings) => {
        const { sourceKey, targetKeys, settings } = finalData;

        if (sourceKey && targetKeys.length) {
            const keysToUpdate = targetKeys.map(item => {
                if (settings.schedule) {
                    item.schedule = sourceKey.schedule;
                }

                if (settings.validity) {
                    item.validity = sourceKey.validity;
                }

                if (settings.cylinderAccess) {
                    item.authorizations = sourceKey.authorizations;
                }

                return item;
            });

            const updateSuccess = await updateMultipleKeys(keysToUpdate);
            if (updateSuccess) {
                showSuccess(TKey.copyKeySuccess);
                history.push(buildRoute(Routes.KEYS_KEY, { keyId: sourceKey.uuid }));
                return;
            }
        }

        showError(TKey.copyKeyError);
    };

    const onBack = () => {
        if (selectedKey) {
            history.push(buildRoute(Routes.KEYS_KEY, { keyId: selectedKey.uuid }));
        }
    };

    const initialData = {
        sourceKey: selectedKey,
        targetKeys: [],
        settings: {
            validity: true,
            schedule: true,
            cylinderAccess: true
        }
    };

    const { Wizard, goBack, goNext, data, setData } = useWizard<ICopySettings>(initialData, onFinish, onBack);

    useEffect(() => {
        setData({
            sourceKey: selectedKey,
            targetKeys: [],
            settings: {
                validity: true,
                schedule: true,
                cylinderAccess: true
            }
        });
    }, [selectedKey, setData]);

    return (
        <Wizard>
            <SelectSettings onBack={goBack} onNext={goNext} data={data} setData={setData} />
            <SelectTargetKey onBack={goBack} onNext={goNext} data={data} setData={setData} />
            <SelectionSummary onBack={goBack} onNext={goNext} data={data} setData={setData} isLoading={loading} />
        </Wizard>
    );
};

export default CopyDetails;
