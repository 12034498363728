import { useDispatch, useSelector } from 'react-redux';
import { addNotification, removeNotification } from './actions';
import { INotificationOptions } from './types';
import { getAppNotifications } from './selectors';
import { useCallback } from 'react';

export const useNotification = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(getAppNotifications);

    const showNotification = useCallback(
        (notification: INotificationOptions) => addNotification(notification)(dispatch),
        [dispatch]
    );

    const showError = useCallback(
        (errorMessage: string) => {
            addNotification({
                type: 'error',
                message: errorMessage
            })(dispatch);
        },
        [dispatch]
    );

    const showSuccess = useCallback(
        (successMessage: string) => {
            addNotification({
                type: 'success',
                message: successMessage
            })(dispatch);
        },
        [dispatch]
    );

    const hideNotification = useCallback((id: number) => removeNotification(id)(dispatch), [dispatch]);

    return {
        showError,
        showSuccess,
        showNotification,
        hideNotification,
        notifications
    };
};
