import React from 'react';
import { Text, Box, Flex, Alert } from 'aa-react-ui';
import { IconWrapper, Logs, ArrowRightIcon } from 'components/icon';
import { Routes } from 'router';
import { useTranslation, TKey } from 'locale';
import { buildRoute } from 'router/helper';
import StyledLink from 'components/styled_link';
import Card from 'components/card';
import LinkButton from 'components/link_button';

interface IAccessLogInfoProps {
    disabled?: boolean;
    auditTrailRequested?: boolean;
    cylinderId: string;
}

const AccessLogInfo: React.FunctionComponent<IAccessLogInfoProps> = props => {
    const { disabled, auditTrailRequested, cylinderId } = props;
    const { t } = useTranslation();

    const renderAuditTrailRequested = () => {
        if (auditTrailRequested) {
            return (
                <Box m="quarter">
                    <Alert
                        variant="info"
                        text={
                            <Text>
                                {`${t(TKey.cylinderAuditTrailRequestPendingBody1)}`}
                                <LinkButton to={Routes.TASK_LIST} ml="half" size="sm" variant="primary">
                                    {t(TKey.genericTaskList)}
                                </LinkButton>
                            </Text>
                        }
                    />
                </Box>
            );
        }

        return null;
    };

    const accessLogRoute = buildRoute(Routes.CYLINDERS_CYLINDER_ACCESS_LOG, { cylinderId });

    return (
        <>
            <StyledLink to={accessLogRoute}>
                <Card>
                    <Flex flexDirection="row" width="100%">
                        <IconWrapper
                            icon={Logs}
                            height="2rem"
                            width="2.5rem"
                            stroke={auditTrailRequested ? 'warning.90' : 'primary.90'}
                        />

                        <Text pl="half" fontSize="1rem" fontWeight="bold" color="contrast.90">
                            {t(TKey.genericAuditTrail)}
                        </Text>
                    </Flex>
                    {!disabled && <ArrowRightIcon height="2rem" width="2rem" />}
                </Card>
            </StyledLink>
            {renderAuditTrailRequested()}
        </>
    );
};

export default AccessLogInfo;
