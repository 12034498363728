import React, { useEffect, useState } from 'react';
import { Card, Box, Button, Flex, Text } from 'aa-react-ui';

import { TKey, useTranslation } from 'locale';
import { useNotification } from 'domain/notification/redux/hooks';
import socketService, { EventName } from 'common/services/socket_service';
import SimpleModal from 'components/simpleModal';

const NewDataConfirmation = () => {
    const { t } = useTranslation();

    const [displayNotification, setDisplayNotification] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const { showNotification } = useNotification();

    const NOTIFICATION_TEXT = t(TKey.genericRefreshBody);
    const NOTIFICATION_ACTION = t(TKey.genericRefreshLink);
    const CONFIRMATION_TITLE = t(TKey.actionConfirm);
    const CONFIRMATION_TEXT = t(TKey.actionConfirmOnExit);

    useEffect(() => {
        const onKeyUpdate = () => {
            showNotification({
                message: TKey.genericKeyUpdated,
                type: 'info'
            });

            setDisplayNotification(true);
        };

        socketService.addListener(EventName.KEY_UPDATED, onKeyUpdate);

        return () => {
            socketService.removeListener(EventName.KEY_UPDATED, onKeyUpdate);
        };
    }, [displayNotification, showNotification]);

    const toggleConfirmation = () => setDisplayConfirmation(!displayConfirmation);

    const primaryAction = () => window.location.reload();

    if (!displayNotification) {
        return null;
    }

    return (
        <>
            <Box m="full" position="absolute" bottom={0} right={0}>
                <Card p="full">
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text>{NOTIFICATION_TEXT}</Text>
                        <Button data-testid="notification-action" ml="half" size="sm" onClick={toggleConfirmation}>
                            {NOTIFICATION_ACTION}
                        </Button>
                    </Flex>
                </Card>
            </Box>
            <SimpleModal
                display={displayConfirmation}
                primaryAction={primaryAction}
                secondaryAction={toggleConfirmation}
                title={CONFIRMATION_TITLE}
                text={CONFIRMATION_TEXT}
            />
        </>
    );
};

export default NewDataConfirmation;
