import React, { useState } from 'react';
import { Box, Alert } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { useNonBlockedKeys } from 'domain/key/redux/hooks';
import { KeyRadioList } from 'domain/key/components';
import { IKey } from 'models/types';
import { useHistory } from 'react-router-dom';
import { Routes } from 'router';
import { currentAuthorizationsForKey } from 'domain/key/key_helper';
import { buildRoute } from 'router/helper';
import StepView from 'components/wizard/step_view';

const SelectKey = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [selectedKey, setSelectedKey] = useState<IKey | undefined>();
    const nonBlockedKeys = useNonBlockedKeys();

    const onNext = () => {
        if (selectedKey) {
            if (selectedKey && currentAuthorizationsForKey(selectedKey).length === 0) {
                history.push(buildRoute(Routes.BLOCK_KEY_SUMMARY, { keyId: selectedKey.uuid }), {
                    from: history.location
                });
            } else {
                history.push(buildRoute(Routes.BLOCK_KEY_VERIFY, { keyId: selectedKey.uuid }), {
                    from: history.location
                });
            }
        }
    };

    const onBack = () => {
        history.push(Routes.DASHBOARD);
    };

    return (
        <StepView
            title={t(TKey.blockKeyHeader)}
            subTitle={t(TKey.blockKeySelectKeyHeader)}
            onBack={onBack}
            submitText={t(TKey.actionNext)}
            disableSubmit={!selectedKey}
            onSubmit={onNext}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.blockKeySelectKeyBody)} />
            </Box>

            <KeyRadioList
                items={nonBlockedKeys}
                onSelect={newKey => {
                    setSelectedKey(newKey);
                }}
            />
        </StepView>
    );
};

export default SelectKey;
