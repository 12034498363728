import { useRef, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useFocus(): Array<any> {
    const inputRef = useRef<HTMLInputElement>(null);

    const memoizedCallback = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    return [inputRef, memoizedCallback];
}

export default useFocus;
