import { INotificationOptions } from './types';
import { createNotificationActionCreators } from './factory';

const notificationActionCreators = createNotificationActionCreators();

export const addNotification = (options: INotificationOptions) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any) => {
        dispatch(notificationActionCreators.addNotification(options));
    };
};

export const removeNotification = (id: number) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any) => {
        dispatch(notificationActionCreators.removeNotification(id));
    };
};
