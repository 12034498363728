import React from 'react';

import { Box, Flex } from 'aa-react-ui';

interface CenterProps {
    children: object;
}

function Center(props: CenterProps) {
    const { children } = props;
    return (
        <Box position="fixed" top="0" left="0" right="0" bottom="0">
            <Flex width="100%" height="100%" alignItems="center" justifyContent="center" flexDirection="column">
                {children}
            </Flex>
        </Box>
    );
}

export default Center;
