import * as firebase from 'firebase/app';
import 'firebase/analytics';

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './app_redux/store';
import { Theme, themeObject } from 'aa-react-ui';
import { PromptServiceProvider } from './lib/aa-prompt-service';
import AppRoot from './app_root';

function App() {
    useEffect(() => {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        };

        const fApp = firebase.initializeApp(firebaseConfig);
        firebase.analytics(fApp);
    }, []);

    return (
        <Provider store={store}>
            <Theme theme={themeObject}>
                <PromptServiceProvider>
                    <AppRoot />
                </PromptServiceProvider>
            </Theme>
        </Provider>
    );
}

export default App;
