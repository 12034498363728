import AuthorizationEntryModel from './authorization_entry';
import { IKeyAuthorization } from './types';

export default class LoukEntryModel extends AuthorizationEntryModel {
    keyUuid: string;

    constructor(dataObject: IKeyAuthorization) {
        super(dataObject.state);
        this.keyUuid = dataObject.keyUuid;
    }

    toDataObject() {
        return {
            keyUuid: this.keyUuid,
            state: this.state
        };
    }
}
