import React, { ReactNode } from 'react';
import { Box } from 'aa-react-ui';

import BaseContainer from 'components/base_container';
import LocaleSelector from 'domain/locale/components/locale_selector';

export interface IAuthLayoutProps {
    children: ReactNode;
    appLocale: string;
    setAppLocale: (newLocale: string) => void;
}

function AuthLayout(props: IAuthLayoutProps): JSX.Element {
    const { children, appLocale, setAppLocale } = props;

    return (
        <BaseContainer>
            <Box position="absolute" top={0} right={0}>
                <LocaleSelector
                    value={appLocale}
                    onChange={newLocale => {
                        setAppLocale(newLocale);
                    }}
                />
            </Box>
            {children}
        </BaseContainer>
    );
}

AuthLayout.displayName = 'AuthLayout';

export default AuthLayout;
