import React from 'react';
import { useTranslation, TKey } from 'locale';
import { Routes } from 'router';
import { Text } from 'aa-react-ui';
import { currentAuthorizationsForKey } from 'domain/key/key_helper';
import { useHistory } from 'react-router-dom';
import KeyModel from 'models/key';
import LinkButton from 'components/link_button';

interface IProps {
    keyData?: KeyModel;
}

const BlockKey = (props: IProps) => {
    const { keyData } = props;
    const { t } = useTranslation();
    const history = useHistory();

    if (!keyData) {
        return null;
    }

    const getRouteUrl = () => {
        if (currentAuthorizationsForKey(keyData.toDataTransferObject()).length === 0) {
            return `${Routes.BLOCK_KEY}/${keyData.uuid}/summary`;
        } else {
            return `${Routes.BLOCK_KEY}/${keyData.uuid}/verify`;
        }
    };

    if (!keyData.isBlocked()) {
        return (
            <LinkButton
                mr="quarter"
                to={{
                    pathname: getRouteUrl(),
                    state: {
                        from: history.location
                    }
                }}>
                <Text>{t(TKey.actionKeyBlock)}</Text>
            </LinkButton>
        );
    }

    return null;
};

export default BlockKey;
