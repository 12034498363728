import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from 'router';
import SelectKey from './select_key';
import VerifySelection from './verify_selection';
import SelectTaskKey from './select_task_key';
import SelectionSummary from './selection_summary';
import SuccessSummary from './success_summary';

const UnblockKey = () => {
    return (
        <>
            <Route exact path={Routes.UNBLOCK_KEY} component={SelectKey} />
            <Route exact path={Routes.UNBLOCK_KEY_VERIFY} component={VerifySelection} />
            <Route exact path={Routes.UNBLOCK_KEY_TASK_KEY} component={SelectTaskKey} />
            <Route exact path={Routes.UNBLOCK_KEY_SUMMARY} component={SelectionSummary} />
            <Route exact path={Routes.UNBLOCK_KEY_SUCCESS} component={SuccessSummary} />
        </>
    );
};

export default UnblockKey;
