import React from 'react';
import { useTranslation, TKey } from 'locale';
import { Routes } from 'router';
import { Text } from 'aa-react-ui';
import { useHistory } from 'react-router-dom';
import KeyModel from 'models/key';
import LinkButton from 'components/link_button';

interface IProps {
    keyData?: KeyModel;
}

const HandIn = (props: IProps) => {
    const { keyData } = props;
    const { t } = useTranslation();
    const history = useHistory();

    if (!keyData) {
        return null;
    }

    if (!keyData.isBlocked() && keyData.isHandedOut()) {
        return (
            <LinkButton
                mr="quarter"
                to={{
                    pathname: `${Routes.HAND_IN}/${keyData.uuid}/settings`,
                    state: { from: history.location }
                }}>
                <Text>{t(TKey.actionKeyHandIn)}</Text>
            </LinkButton>
        );
    }

    return null;
};

export default HandIn;
