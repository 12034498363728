import React from 'react';
import { CylinderCollectionItem } from 'domain/cylinder/components';
import CylinderModel from 'models/cylinder';
import { CylinderCollectionType } from 'domain/cylinder/types';
import { ArrowRightIcon } from 'components/icon';
import StyledLink from 'components/styled_link';
import { Routes } from 'router';
import CylinderInfoText from './cylinder_info_text';
import { buildRoute } from 'router/helper';

interface ICylinderInfoProps {
    disabled?: boolean;
    cylinderData?: CylinderModel;
    collectionType?: CylinderCollectionType;
}
const CylinderInfo: React.FunctionComponent<ICylinderInfoProps> = props => {
    const { cylinderData, disabled, collectionType } = props;

    if (!cylinderData) {
        return null;
    }

    const { uuid } = cylinderData;
    const hasTasks = cylinderData.hasTasks();
    const hasStartedTasks = cylinderData.hasStartedTasks();
    const hasPendingTasks = cylinderData.hasPendingTasks();

    const nameEditRoute = buildRoute(Routes.CYLINDERS_CYLINDER_NAME, { cylinderId: uuid });

    return (
        <>
            <StyledLink data-testid={`cylinder-name-edit-link-${uuid}`} disabled={disabled} to={nameEditRoute}>
                <CylinderCollectionItem dataModel={cylinderData} collectionType={collectionType} disabled={disabled}>
                    {!disabled && <ArrowRightIcon height="2rem" width="2rem" />}
                </CylinderCollectionItem>
            </StyledLink>
            <CylinderInfoText
                collectionType={collectionType}
                hasPendingTasks={hasPendingTasks}
                hasStartedTasks={hasStartedTasks}
                hasTasks={hasTasks}
            />
        </>
    );
};

export default CylinderInfo;
