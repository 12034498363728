import { FormattedInterval } from 'domain/schedule/types';

/**
 * Return a array with specified amount of numbers.
 * Will pad the start of the numbers with a zero if single number.
 *
 * 5 > [00,01,02,03,04,05];
 *
 * @param value How many values to add to array
 * @param steps Optional param, only every nth number.
 */
const getTimeData = (value: number, steps?: number) => {
    const data = [];

    for (let i = 0; i <= value; i++) {
        if (steps && i % steps !== 0) {
            continue;
        }

        const value = i.toString().padStart(2, '0');

        data.push(value);
    }

    return data;
};

/**
 * Construct form data with default values.
 * Merge defaults with provided values.
 */
function getInitialValues(initialValues?: FormattedInterval) {
    const { day, ...data } = initialValues || {};

    const defaults = {
        days: day ? [day] : [],
        startHour: '00',
        startMinutes: '00',
        endHour: '00',
        endMinutes: '00',
        ...data
    };

    return defaults;
}

export { getTimeData, getInitialValues };
