import React from 'react';
import { SectionHeader, Section, SectionBody, Text, Flex, H3 } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { KeyCollectionType } from 'domain/key/types';
import { ICylinderCollection, CylinderCollectionType } from 'domain/cylinder/types';
import { CylinderCollection } from 'domain/cylinder/components';

import KeyModel from 'models/key';
import Validity from './validity';
import Schedule from './schedule';
import KeyInfo from './key_info';
import Copy from './actions/copy';
import Card from 'components/card';
import HandIn from './actions/hand_in';
import HandOut from './actions/hand_out';
import BlockKey from './actions/block_key';
import UnblockKey from './actions/unblock_key';
import CylinderAccessInfo from './cylinder_access_info';

interface IKeySummaryProps {
    keyData: { key?: KeyModel; collectionType?: KeyCollectionType };
    keyAccessCylinderCollections: Array<ICylinderCollection>;
}

const KeySummary = (props: IKeySummaryProps) => {
    const {
        keyData: { key },
        keyAccessCylinderCollections
    } = props;

    const { t } = useTranslation();

    if (!key) {
        return null;
    }

    const renderKeyAccessCylinderCollections = () => {
        if (key.isBlocked()) {
            const cylindersAtRisk = keyAccessCylinderCollections.find(
                collection => collection.type === CylinderCollectionType.AT_RISK
            );

            if (cylindersAtRisk && !cylindersAtRisk.items.length) {
                return (
                    <Card>
                        <Text>{t(TKey.keyAccessNoCylindersAtRisk)}</Text>
                    </Card>
                );
            }
        }

        const hasItems = keyAccessCylinderCollections.find(collection => collection.items.length);

        if (!hasItems) {
            return (
                <Card>
                    <Text>{t(TKey.keyAccessNoAccess)}</Text>
                </Card>
            );
        }

        return (
            <Card>
                <Flex flexDirection="column" width="100%">
                    {keyAccessCylinderCollections.map((collection, index) => {
                        return (
                            <CylinderCollection
                                key={`${collection.type}_${index}`}
                                collectionData={collection}
                                hideItemStatus
                            />
                        );
                    })}
                </Flex>
            </Card>
        );
    };

    const renderActions = () => {
        return (
            <>
                <HandIn keyData={key} />
                <HandOut keyData={key} />
                <BlockKey keyData={key} />
                <UnblockKey keyData={key} />
                <Copy keyData={key} />
            </>
        );
    };

    const renderKeyDetails = () => {
        const isKeyBlocked = key.isBlocked();
        const isKeyHandedOut = key.isHandedOut();

        const { validity, schedule, uuid } = key;

        return (
            <Flex flexDirection="column" width="100%">
                <KeyInfo disabled={isKeyBlocked || !isKeyHandedOut} keyData={key} />
                <Validity keyId={uuid} disabled={isKeyBlocked} value={validity} />
                <Schedule keyId={uuid} disabled={isKeyBlocked} value={schedule} />
                <CylinderAccessInfo keyId={uuid} disabled={isKeyBlocked} />
                {renderKeyAccessCylinderCollections()}
            </Flex>
        );
    };

    return (
        <Section width="100%">
            <Flex flexDirection="column" height="100%">
                <SectionHeader title={<H3>{t(TKey.genericDetails)}</H3>} actions={renderActions()} />
                <SectionBody style={{ overflowY: 'auto' }}>{renderKeyDetails()}</SectionBody>
            </Flex>
        </Section>
    );
};

export default KeySummary;
