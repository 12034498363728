import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { ISchedule, IKey } from 'models/types';
import { FormattedWeek } from 'domain/schedule/types';

import formatSchedule from 'domain/schedule/format';
import { parseAndMergeSchedule } from 'domain/schedule/parse';
import { isEnabled } from 'domain/schedule/utils';

interface ScheduleInterface {
    keyData: IKey;
    keyId: string;
    onSave: (data: IKey) => void;
    defaultEnabled?: boolean;

    // TODO: Fix any type here with FormRenderProps from 'react-final-form'
    // JIRA Ticket: https://jira.assaabloy.net/browse/CSMBC-6762
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
}

interface FormValues {
    days: FormattedWeek;
    enabled: boolean;
}

function Schedule(props: ScheduleInterface) {
    const { keyData, onSave, defaultEnabled, children } = props;
    const schedule: ISchedule = keyData.schedule;

    // Transform input schedule data to structure we can consume in UI.
    // Memoize the schedule transformation.
    const days = React.useMemo(() => formatSchedule(schedule), [schedule]);

    const isScheduleEnabled = defaultEnabled || isEnabled(schedule);

    // Transform back data to original schedule structure and pass back the data.
    const handleSubmit = (formValues: FormValues) => {
        const data = parseAndMergeSchedule(keyData, formValues);
        onSave(data);
    };

    const initialValues = {
        days,
        enabled: isScheduleEnabled
    };

    return (
        <FinalForm initialValues={initialValues} onSubmit={handleSubmit} mutators={{ ...arrayMutators }}>
            {({ handleSubmit, form, ...rest }) => (
                <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit} id="key-schedule-editor">
                    {children({ handleSubmit, form, ...rest })}
                </form>
            )}
        </FinalForm>
    );
}

export default Schedule;
