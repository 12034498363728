import React, { useState, useEffect, useCallback } from 'react';

export default function<T>(initialData: T, onFinish: (finalData: T) => void, onBack: () => void, initialStep = 0) {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [wizardData, setWizardData] = useState<T>(initialData);
    const [stepCount, setStepCount] = useState(0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setData = useCallback((newData: any) => {
        setWizardData(newData);
    }, []);

    const goNext = () => {
        if (currentStep === stepCount) {
            onFinish(wizardData);
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const goBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        } else {
            onBack();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Wizard: React.FunctionComponent<any> = ({ children }) => {
        const childrenLength = children.length;

        useEffect(() => {
            setStepCount(childrenLength - 1);
        }, [childrenLength]);

        return children[currentStep];
    };

    return {
        Wizard,
        goNext,
        goBack,
        setData,
        currentStep,
        data: wizardData
    };
}
