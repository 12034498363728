import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CliqGoLogoSvg } from './cliq_go.svg';

const CustomSvg = styled.svg`
    height: 6rem;
    width: 6rem;
    fill: #ecece7;
`;

interface IProps {
    height?: string;
    width?: string;
}

const CliqGoLogo = (props: IProps): JSX.Element => {
    return (
        <CustomSvg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
            <CliqGoLogoSvg />
        </CustomSvg>
    );
};

export default CliqGoLogo;
