import React, { ComponentProps } from 'react';
import { Text, Box } from 'aa-react-ui';
import Checkbox from 'lib/aa-react-ui/checkbox';
import Card from 'components/card';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ICheckBoxRow extends ComponentProps<any> {
    onChange: (newValue: { [x: string]: boolean }) => void;
    name: string;
    checked: boolean;
    text: string;
}

const CheckBoxRow = (props: ICheckBoxRow) => {
    const { onChange, name, checked, text, ...rest } = props;

    return (
        <Card
            bg={checked ? 'primary.05' : 'white'}
            p="full"
            onClick={() => {
                onChange({ [name]: !checked });
            }}>
            <Box width="100%">
                <Text>{text}</Text>
            </Box>
            <Checkbox
                {...rest}
                checked={checked}
                onChange={() => {
                    onChange({ [name]: !checked });
                }}
            />
        </Card>
    );
};

export default CheckBoxRow;
