import React, { memo } from 'react';
import { Routes } from 'router';
import StyledLink from 'components/list_link';
import { ICylinderCollection } from 'domain/cylinder/types';
import { useTranslation } from 'locale';
import ItemContent from '../cylinder_collection_item/cylinder_collection_item_content';
import { H5 } from 'aa-react-ui';
import { COLLECTION_NAME_TRANSLATION_KEY_MAP } from 'domain/cylinder/constants';

interface ICylinderCollectionProps {
    collectionData: ICylinderCollection;
    selectedCylinderId?: string;
    hideItemStatus?: boolean;
}

const CylinderCollection = (props: ICylinderCollectionProps) => {
    const { collectionData, hideItemStatus } = props;
    const { t } = useTranslation();

    const hasData = collectionData && collectionData.items && collectionData.items.length;

    if (!hasData) {
        return null;
    }

    const { type, items = [] } = collectionData;

    return (
        <>
            <H5 color="contrast.90" p="half">
                {t(COLLECTION_NAME_TRANSLATION_KEY_MAP[type])}
            </H5>

            {items.map(item => {
                const cylinder = item;
                return (
                    <StyledLink
                        key={cylinder.uuid}
                        to={`${Routes.CYLINDERS}/${cylinder.uuid}`}
                        activeClassName="selected">
                        <ItemContent
                            uuid={item.uuid}
                            name={item.name}
                            marking={item.marking}
                            iconColor={item.getColor()}
                            hideItemStatus={hideItemStatus}
                            status={item.getStates()}
                        />
                    </StyledLink>
                );
            })}
        </>
    );
};

export default memo(CylinderCollection);
