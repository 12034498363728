import {
    SET_KEY_FILTER,
    SET_KEY_SEARCH_QUERY,
    SET_CURRENT_SELECTED_KEY,
    UPDATE_KEY,
    ISetKeyFilterAction,
    ISetSearchQueryAction,
    ISetCurrentSelectedKey,
    UPDATE_MULTIPLE_KEYS
} from './action_type';
import { RequestStatus } from 'app_redux/types';
import { createRequestReducer } from 'app_redux/factory';
import { KeyStateFilter } from '../types';

const defaultFilter = KeyStateFilter.ALL;

const keyFilterReducer = (state: KeyStateFilter = defaultFilter, action: ISetKeyFilterAction) => {
    const { type, keyFilter } = action;
    if (type === SET_KEY_FILTER) {
        return keyFilter;
    }

    return state;
};

const defaultSearchQuery = '';

const keySearchQueryReducer = (state: string = defaultSearchQuery, action: ISetSearchQueryAction) => {
    const { type, searchQuery } = action;

    if (type === SET_KEY_SEARCH_QUERY) {
        return searchQuery;
    }

    return state;
};

const defaultSelected = '';

const selectedKeyReducer = (state: string = defaultSelected, action: ISetCurrentSelectedKey) => {
    const { type, keyId = '' } = action;

    if (type === SET_CURRENT_SELECTED_KEY) {
        return keyId;
    }

    return state;
};

const updateKeyRequestReducer = createRequestReducer(UPDATE_KEY, {
    status: RequestStatus.IDLE,
    error: undefined,
    data: null
});

const updateMultipleKeysRequestReducer = createRequestReducer(UPDATE_MULTIPLE_KEYS, {
    status: RequestStatus.IDLE,
    error: undefined,
    data: null
});

export {
    keySearchQueryReducer,
    keyFilterReducer,
    selectedKeyReducer,
    updateKeyRequestReducer,
    updateMultipleKeysRequestReducer
};
