import React from 'react';
import { useTranslation, TKey } from 'locale';
import { Text, Alert, Box } from 'aa-react-ui';
import { Routes } from 'router';
import { useParams, useHistory } from 'react-router-dom';
import StepView from 'components/wizard/step_view';
import LinkButton from 'components/link_button';
import { buildRoute } from 'router/helper';

const SuccessSummary = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams<{ keyId: string; assigned: string }>();

    const getSuccessMessage = () => {
        if (parseInt(params.assigned, 10) === 0) {
            return t(TKey.blockKeyUnblockSuccessNoAssignment);
        }

        if (parseInt(params.assigned, 10) > 0) {
            return t(TKey.blockKeyUnblockSuccessWithAssignment);
        }

        return t(TKey.blockKeyUnblockSuccessNoAffected);
    };

    const onSubmit = () => {
        history.push(buildRoute(Routes.KEYS_KEY, { keyId: params.keyId }));
    };

    return (
        <StepView
            title={t(TKey.blockKeyUnblockHeader)}
            subTitle={t(TKey.genericSuccessHeader)}
            hideBack
            onSubmit={onSubmit}>
            <Box m="quarter">
                <Alert
                    variant="warning"
                    text={
                        <Text>
                            {getSuccessMessage()}
                            <LinkButton to={Routes.TASK_LIST} ml="half" size="sm" variant="primary">
                                {t(TKey.genericTaskList)}
                            </LinkButton>
                        </Text>
                    }
                />
            </Box>
        </StepView>
    );
};

export default SuccessSummary;
