import React, { useEffect, Fragment, useState } from 'react';
import { Box, Button } from 'aa-react-ui';
import { Routes } from 'router';
import { useTranslation, TKey } from 'locale';
import { LoginForm, CliqConnectInstructionModal, BrandLogo } from 'domain/authentication/components';
import { useLocation, useHistory } from 'react-router-dom';
import { useAppSession } from 'domain/authentication/redux/hooks';

const Login = (): JSX.Element => {
    const history = useHistory();
    const location = useLocation<{ from: Location; previousFrom: Location }>();

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [instructionModalIsOpen, setInstructionModalIsOpen] = useState<boolean>(false);
    const {
        createSession,
        session: { isLoggedIn }
    } = useAppSession();

    useEffect(() => {
        if (isLoggedIn) {
            if (location.state) {
                history.push(location.state.from.pathname, { from: location.state.previousFrom });
            } else {
                history.push(Routes.DASHBOARD);
            }
        }
    }, [history, isLoggedIn, location]);

    const handleSubmit = (systemId: string, password: string) => {
        setError('');
        setLoading(true);

        createSession(systemId, password).then((errorMessage: string) => {
            if (errorMessage) {
                setError(errorMessage);
                setLoading(false);
            }
        });
    };

    return (
        <Fragment>
            <BrandLogo />
            <Box position="absolute" top={0} left={0} padding="1rem">
                <Button
                    onClick={() => {
                        setInstructionModalIsOpen(true);
                    }}
                    variant="primary">
                    {t(TKey.actionActivateSystem)}
                </Button>
            </Box>

            <LoginForm
                error={error}
                loading={loading}
                onSubmit={handleSubmit}
                onForgotPassword={() => history.push(Routes.FORGOT_PASSWORD)}
            />

            <CliqConnectInstructionModal
                isOpen={instructionModalIsOpen}
                onDone={() => setInstructionModalIsOpen(false)}
            />
        </Fragment>
    );
};

export default Login;
