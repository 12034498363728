import { IAppStoreState } from 'app_redux/types';
import { createSelector } from 'reselect';

import hardwareUtils from 'domain/hardware/utils/hardware_utils';

import { IHardwareDataState } from './types';
import { KeyStateFilter } from 'domain/key/types';

const handwareRequestSelector = (state: IAppStoreState) => state.hardware;
const hardwareDataSelector = (state: IAppStoreState) => state.hardware.data;
const keySearchQuerySelector = (state: IAppStoreState) => state.key.searchQuery;
const keyFilterSelector = (state: IAppStoreState) => state.key.filter;

const cylinderSearchQuerySelector = (state: IAppStoreState) => state.cylinder.searchQuery;
const cylinderFilterSelector = (state: IAppStoreState) => state.cylinder.filter;

export const getHardwareRequest = createSelector(handwareRequestSelector, request => request);

export const getKeyCollections = createSelector(
    [keySearchQuerySelector, keyFilterSelector, hardwareDataSelector],
    (keySearchQuery: string, keyFilter: KeyStateFilter, data?: IHardwareDataState) => {
        if (data) {
            return hardwareUtils
                .populate(data.keys, data.cylinders)
                .keyUtils()
                .getKeyCollections(keyFilter, keySearchQuery);
        } else {
            return [];
        }
    }
);

export const getCylinderCollections = createSelector(
    [cylinderSearchQuerySelector, cylinderFilterSelector, hardwareDataSelector],
    (searchQuery, filter, data?) => {
        if (data) {
            return hardwareUtils
                .populate(data.keys, data.cylinders)
                .cylinderUtils()
                .getCylinderCollection(filter, searchQuery);
        } else {
            return [];
        }
    }
);
