import React, { useState, useEffect, useRef } from 'react';
import { SectionHeader, Section, SectionBody, H3 } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { History } from 'history';
import { useDirtyPrompt } from 'components/hooks';
import { ValidityState } from 'domain/validity/types';
import { KeyCollectionType } from 'domain/key/types';
import { useNotification } from 'domain/notification/redux/hooks';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';
import { useUpdateKeyRequest } from 'domain/key/redux/hooks';

import KeyModel from 'models/key';
import commonUtils from 'common/utils/common';
import KeyCollectionItem from 'domain/key/components/key_collection_item';
import EditorActions from 'components/editor_actions';
import ValiditySelector from 'domain/validity/components/validity_selector';
import ValidityModel from 'models/validity';

interface IKeyValidityEditor {
    history: History;
    keyData: { key?: KeyModel; collectionType?: KeyCollectionType };
}

const KeyValidityEditor = (props: IKeyValidityEditor) => {
    const [currentValidity, setCurrentValidity] = useState<ValidityModel | undefined>();
    const [validityIsDirty, setValidityIsDirty] = useState(false);
    const { updateKey, loading } = useUpdateKeyRequest();
    const { promptIfDirty } = useDirtyPrompt(validityIsDirty);
    const { showError, showSuccess } = useNotification();
    const { t } = useTranslation();

    const pristineKeyRef = useRef<KeyModel | undefined>();

    const { history, keyData } = props;
    const { key, collectionType } = keyData;

    useEffect(() => {
        if (key) {
            const keyClone = commonUtils.cloneDeep(key);
            pristineKeyRef.current = keyClone;
            const { validity } = key;
            setCurrentValidity(validity);
        }
    }, [key]);

    useEffect(() => {
        const pristineKey = pristineKeyRef.current;
        if (pristineKey && currentValidity) {
            if (currentValidity.newState === ValidityState.RANGE) {
                setValidityIsDirty(!commonUtils.isEqual(pristineKey.validity, currentValidity));
            } else {
                setValidityIsDirty(!commonUtils.isEqual(pristineKey.validity.newState, currentValidity.newState));
            }
        }
    }, [currentValidity, key]);

    if (!key) {
        return null;
    }

    if (key.isBlocked()) {
        return null;
    }

    const goBack = () => {
        history.push(buildRoute(Routes.KEYS_KEY, { keyId: key.uuid }));
    };

    const onSaveClick = async () => {
        const newKey = pristineKeyRef.current;
        if (newKey && currentValidity) {
            newKey.validity = currentValidity;
            const updateSuccess = await updateKey(newKey.uuid, newKey.toDataTransferObject());
            if (updateSuccess) {
                showSuccess(TKey.genericSuccessSaved);
                goBack();
                return;
            }

            showError(TKey.genericErrorSaving);
        }
    };

    const onBackClick = async () => {
        await promptIfDirty();
        goBack();
    };

    return (
        <Section width="100%">
            <SectionHeader
                title={<H3>{t(TKey.keyValidityHeader)}</H3>}
                actions={
                    <EditorActions
                        editorDirty={validityIsDirty}
                        loading={loading}
                        onSave={onSaveClick}
                        onBack={onBackClick}
                    />
                }
            />
            <SectionBody height="100%" overflow="hidden" width="100%">
                <KeyCollectionItem data={key} collectionType={collectionType} disabled={key.isBlocked()} />
                <ValiditySelector
                    value={currentValidity}
                    onSelect={newValidity => {
                        setCurrentValidity(newValidity);
                    }}
                />
            </SectionBody>
        </Section>
    );
};

export default KeyValidityEditor;
