import React, { useEffect } from 'react';
import { SectionHeader, Section, SectionBody, Input, Flex, H3, Spinner, H5 } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { ListWrapper } from 'domain/key/components/wrappers';
import { CylinderFilterSelect, CylinderCollection } from 'domain/cylinder/components';
import { CylinderFilter, ICylinderCollection } from 'domain/cylinder/types';
import { useHistory, useParams } from 'react-router-dom';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';

import timerUtils from 'common/utils/timers';

interface ICylinderListProps {
    cylinderCollections: Array<ICylinderCollection>;
    setFilter: (filter: CylinderFilter) => void;
    defaultKeyFilter: CylinderFilter;
    setSearchQuery: (query: string) => void;
}

const SEARCH_DEBOUNCE_TIME_IN_MILLIS = 200;

const CylinderList = (props: ICylinderListProps) => {
    const { cylinderCollections = [], setFilter, defaultKeyFilter, setSearchQuery } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const { cylinderId: selectedCylinderId } = useParams<{ cylinderId: string }>();

    useEffect(() => {
        if (!selectedCylinderId && cylinderCollections.length) {
            const visibleItems = cylinderCollections.find(collection => collection.items.length);

            if (visibleItems) {
                const firstVisibleItem = visibleItems.items[0];

                history.push(buildRoute(Routes.CYLINDERS_CYLINDER, { cylinderId: firstVisibleItem.uuid }));
            }
        }
    }, [selectedCylinderId, cylinderCollections, history]);

    const debounceSetSearchQuery = timerUtils.debounce(setSearchQuery, SEARCH_DEBOUNCE_TIME_IN_MILLIS);

    const renderCylinderCollections = () => {
        if (!cylinderCollections.length) {
            return (
                <Flex justifyContent="center" alignItems="center" height="inherit">
                    <Spinner />
                </Flex>
            );
        }

        const isCylinderListEmpty = !cylinderCollections.find(collection => collection.items.length);

        if (isCylinderListEmpty) {
            return <H5>{t(TKey.cylinderListEmptyAfterSearchAndFilter)}</H5>;
        }

        return cylinderCollections.map(collection => {
            return (
                <CylinderCollection
                    key={collection.type}
                    collectionData={collection}
                    selectedCylinderId={selectedCylinderId}
                />
            );
        });
    };

    return (
        <Section width="100%">
            <Flex flexDirection="column" height="100%">
                <SectionHeader
                    title={<H3>{t(TKey.genericCylinders)}</H3>}
                    actions={
                        <CylinderFilterSelect value={defaultKeyFilter} onChange={newFilter => setFilter(newFilter)} />
                    }
                />
                <SectionBody height="100%" overflow="hidden">
                    <Flex height="inherit" flexDirection="column">
                        <Input
                            onChange={e => {
                                debounceSetSearchQuery(e.target.value);
                            }}
                            placeholder={t(TKey.actionSearch)}
                            type="text"
                        />
                        <ListWrapper>{renderCylinderCollections()}</ListWrapper>
                    </Flex>
                </SectionBody>
            </Flex>
        </Section>
    );
};

export default CylinderList;
