import React from 'react';
import { Alert, Box } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { KeyCollectionItem } from 'domain/key/components';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useKey, useUpdateKeyRequest } from 'domain/key/redux/hooks';
import { currentAuthorizationsForKey } from 'domain/key/key_helper';
import { useCylinders, useUpdateCylinderRequest } from 'domain/cylinder/redux/hooks';
import { isAssignmentPossible, getCylindersWithAssignedTaskKey } from 'domain/cylinder/cylinder_helper';
import { KeyState } from 'models/types';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import { useNotification } from 'domain/notification/redux/hooks';
import KeyModel from 'models/key';
import StepView from 'components/wizard/step_view';
import CommonUtils from 'common/utils/common';

const Summary = () => {
    const history = useHistory();
    const location = useLocation<{ from: Location }>();
    const params = useParams<{ keyId: string; taskKeyId: string }>();
    const selectedKey = useKey(params.keyId);
    const taskKey = useKey(params.taskKeyId);
    const cylinders = useCylinders();
    const { updateMultipleCylinders, loading: cylindersUpdating } = useUpdateCylinderRequest();
    const { updateKey, loading: keyUpdating } = useUpdateKeyRequest();
    const { showError, showSuccess } = useNotification();

    const { t } = useTranslation();

    if (!selectedKey) {
        return null;
    }

    const authorizations = currentAuthorizationsForKey(selectedKey);

    const onConfirm = async () => {
        const keyToUpdate = CommonUtils.cloneDeep(selectedKey);

        keyToUpdate.state = KeyState.BLOCKED;
        const updateKeySuccess = await updateKey(keyToUpdate.uuid, keyToUpdate);
        if (!updateKeySuccess) {
            showError(TKey.blockKeyError);
            return;
        }

        if (!taskKey) {
            showSuccess(TKey.blockKeySuccessNoAffected);
            history.push(buildRoute(Routes.BLOCK_KEY_SUCCESS, { keyId: selectedKey.uuid }));
            return;
        }

        const cylindersWithAssignTaskKey = getCylindersWithAssignedTaskKey(authorizations, cylinders, taskKey);
        const updateCylindersSuccess = await updateMultipleCylinders(cylindersWithAssignTaskKey);
        if (updateCylindersSuccess) {
            showSuccess(TKey.blockKeySuccessNoAffected);
            history.push(
                buildRoute(Routes.BLOCK_KEY_SUCCESS, {
                    keyId: selectedKey.uuid,
                    assigned: `${cylindersWithAssignTaskKey.length}`
                })
            );
            return;
        }

        showError(TKey.blockKeyError);
    };

    const onBack = () => {
        const { state } = location;
        if (state && state.from) {
            history.push(state.from.pathname);
            return;
        }

        history.push(Routes.BLOCK_KEY);
    };

    const renderKeyAuthInfo = () => {
        if (authorizations.length > 0) {
            return <Alert title={t(TKey.genericWarning)} text={t(TKey.blockKeySummaryWarning)} variant="warning" />;
        }
        return <Alert title={t(TKey.genericInfo)} text={t(TKey.blockKeySummaryInfo)} variant="info" />;
    };

    const renderAssignmentWarning = () => {
        if (!taskKey || isAssignmentPossible(authorizations, cylinders)) {
            return null;
        }

        return (
            <Alert
                title={t(TKey.genericWarning)}
                text={t(TKey.keyAssignmentNotPossibleForAllAffectedCylinders)}
                variant="warning"
            />
        );
    };

    return (
        <StepView
            title={t(TKey.blockKeyHeader)}
            subTitle={t(TKey.genericSummary)}
            onBack={onBack}
            loading={cylindersUpdating || keyUpdating}
            disableSubmit={!selectedKey}
            onSubmit={onConfirm}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.blockKeySummaryBodyPart1)} variant="warning" />
            </Box>

            <KeyCollectionItem data={new KeyModel(selectedKey)} hideStatus />

            {taskKey && (
                <>
                    <Box m="quarter" mt="half">
                        <Alert
                            text={t(TKey.blockKeySummaryBodyPart2, {
                                count: authorizations.length
                            })}
                            variant="warning"
                        />
                    </Box>
                    <KeyCollectionItem data={new KeyModel(taskKey)} />
                </>
            )}

            <Box m="quarter" mt="half">
                {renderAssignmentWarning()}
            </Box>

            <Box m="quarter" mt="half">
                <Alert
                    text={t(TKey.blockKeySummaryNumAffectedCylinders, {
                        count: authorizations.length
                    })}
                    variant="info"
                />
            </Box>

            <Box m="quarter" mt="half">
                {renderKeyAuthInfo()}
            </Box>
        </StepView>
    );
};

export default Summary;
