import React from 'react';
import { Text, Box, Alert } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'router';
import { useCylinder } from 'domain/cylinder/redux/hooks';
import { useKey } from 'domain/key/redux/hooks';
import LinkButton from 'components/link_button';
import StepView from 'components/wizard/step_view';
import { buildRoute } from 'router/helper';

const SuccessSummary = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams<{ cylinderId: string; keyId: string }>();
    const selectedCylinder = useCylinder(params.cylinderId);
    const selectedKey = useKey(params.keyId);

    if (!selectedCylinder) {
        return null;
    }

    const onSubmit = () => {
        const cylinderAccessLogRoute = buildRoute(Routes.CYLINDERS_CYLINDER_ACCESS_LOG, {
            cylinderId: selectedCylinder.uuid
        });

        history.push(cylinderAccessLogRoute);
    };

    const getSubtitle = () => {
        if (selectedKey) {
            return t(TKey.requestAuditTrailSuccessWithAssignment);
        } else {
            return t(TKey.requestAuditTrailSuccessNoAssignment);
        }
    };

    return (
        <StepView title={t(TKey.requestAuditTrailHeader)} hideBack onSubmit={onSubmit}>
            <Box m="quarter">
                <Alert
                    variant="warning"
                    text={
                        <Text>
                            {getSubtitle()}
                            <LinkButton to={Routes.TASK_LIST} ml="half" size="sm" variant="primary">
                                {t(TKey.genericTaskList)}
                            </LinkButton>
                        </Text>
                    }
                />
            </Box>
        </StepView>
    );
};

export default SuccessSummary;
