import React from 'react';
import { useTranslation, TKey } from 'locale';
import KeyModel from 'models/key';
import { Text } from 'aa-react-ui';
import LinkButton from 'components/link_button';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';

interface IProps {
    keyData?: KeyModel;
}

const Copy = (props: IProps) => {
    const { keyData } = props;
    const { t } = useTranslation();

    if (!keyData) {
        return null;
    }

    if (!keyData.isBlocked()) {
        return (
            <LinkButton to={buildRoute(Routes.COPY_DETAILS, { keyId: keyData.uuid })} mr="quarter">
                <Text>{t(TKey.actionKeyCopy)}</Text>
            </LinkButton>
        );
    }

    return null;
};

export default Copy;
