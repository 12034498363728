import React from 'react';

import { useTranslation, TKey } from 'locale';

import Portal from 'components/portal';
import { Modal, Button } from 'aa-react-ui';
import Center from './utils';
import Backdrop from './backdrop';

interface SimpleModalProps {
    title?: string;
    text?: string;
    disableActions?: boolean;
    secondaryAction: () => void;
    secondaryActionText?: string;
    primaryAction: () => void;
    primaryActionText?: string;
    display: boolean;
}

function SimpleModal(props: SimpleModalProps) {
    const {
        title,
        text,
        disableActions,
        secondaryAction,
        secondaryActionText,
        primaryAction,
        primaryActionText,
        display,
        ...rest
    } = props;

    const { t } = useTranslation();

    const PRIMARY_ACTION_TEXT = primaryActionText || t(TKey.actionConfirm);
    const SECONDARY_ACTION_TEXT = secondaryActionText || t(TKey.actionCancel);

    return (
        <Portal>
            {display && <Backdrop bg="contrast.90" />}

            {display && (
                <Center>
                    <Modal width="50%" {...rest}>
                        <Modal.Header title={title}>{null}</Modal.Header>
                        <Modal.Body mb="full">{text}</Modal.Body>
                        <Modal.Footer>
                            {primaryAction && (
                                <Button
                                    disabled={disableActions}
                                    variant="secondary"
                                    data-testid="confirm-primary-action"
                                    onClick={primaryAction}>
                                    {PRIMARY_ACTION_TEXT}
                                </Button>
                            )}
                            {secondaryAction && (
                                <Button
                                    disabled={disableActions}
                                    variant="primary"
                                    data-testid="confirm-secondary-action"
                                    ml="half"
                                    onClick={secondaryAction}>
                                    {SECONDARY_ACTION_TEXT}
                                </Button>
                            )}
                        </Modal.Footer>
                    </Modal>
                </Center>
            )}
        </Portal>
    );
}

export default SimpleModal;
