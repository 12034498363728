import React from 'react';
import { History } from 'history';
import { SectionHeader, Section, SectionBody, Button, H3, Flex, Box, Text } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { CylinderCollectionType } from 'domain/cylinder/types';
import { Routes } from 'router';
import { IKeyCollection } from 'domain/key/types';
import { KeyCollection } from 'domain/key/components';

import CylinderModel from 'models/cylinder';
import CylinderInfo from './cylinder_info';
import AccessLogInfo from './access_log_info';
import KeyAccessInfo from './key_access_info';
import Card from 'components/card';

interface ICylinderSummaryProps {
    history: History;
    cylinderData: { cylinder?: CylinderModel; collectionType?: CylinderCollectionType };
    cylinderAccessKeyCollections: Array<IKeyCollection>;
}

const CylinderSummary: React.FunctionComponent<ICylinderSummaryProps> = props => {
    const { cylinderData, cylinderAccessKeyCollections, history } = props;
    const { cylinder, collectionType } = cylinderData;

    const { t } = useTranslation();

    if (!cylinder) {
        return null;
    }

    const renderAction = () => {
        const { uuid } = cylinder;

        if (cylinder.isInStock()) {
            return (
                <Button onClick={() => history.push(`${Routes.CYLINDERS}/${uuid}/install`)} mr="quarter">
                    {t(TKey.actionCylinderInstall)}
                </Button>
            );
        }

        if (cylinder.isInstalled()) {
            return (
                <Button onClick={() => history.push(`${Routes.CYLINDERS}/${uuid}/uninstall`)} mr="quarter">
                    {t(TKey.actionCylinderUninstall)}
                </Button>
            );
        }

        return null;
    };

    const renderCylinderAccessKeyCollections = () => {
        const isListEmpty = !cylinderAccessKeyCollections.find(collection => collection.items.length);

        if (isListEmpty) {
            return (
                <Card>
                    <Text fontSize="1rem">{t(TKey.cylinderDetailsNoAuthorization)}</Text>
                </Card>
            );
        }

        return (
            <Card>
                <Box mt="half" mb="half" width="100%">
                    {cylinderAccessKeyCollections.map(({ type, items }) => (
                        <KeyCollection key={type} parentCylinder={cylinder} items={items} type={type} hideItemStatus />
                    ))}
                </Box>
            </Card>
        );
    };

    const renderCylinderSummary = () => {
        const isEditDisabled = cylinder.isInStock();
        const auditTrailRequested = cylinder.hasRequestedAuditTrail();

        return (
            <Flex height="inherit" flexDirection="column">
                <CylinderInfo cylinderData={cylinder} collectionType={collectionType} disabled={isEditDisabled} />
                <AccessLogInfo cylinderId={cylinder.uuid} auditTrailRequested={auditTrailRequested} />
                <KeyAccessInfo cylinderId={cylinder.uuid} collectionType={collectionType} />
                {renderCylinderAccessKeyCollections()}
            </Flex>
        );
    };

    return (
        <Section width="100%">
            <Flex flexDirection="column" height="100%">
                <SectionHeader title={<H3>{t(TKey.genericDetails)}</H3>} actions={renderAction()} />
                <SectionBody style={{ overflowY: 'auto' }}>{renderCylinderSummary()}</SectionBody>
            </Flex>
        </Section>
    );
};

export default CylinderSummary;
