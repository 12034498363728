import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getSelectedCylinder, getCylinderAccessData } from 'domain/cylinder/redux/selectors';

import CylinderKeyAccessEditor from './cylinder_access_editor';

const maptStateToProps = (state: IAppStoreState) => {
    return {
        cylinderData: getSelectedCylinder(state),
        cylinderAccessData: getCylinderAccessData(state)
    };
};

export default connect(maptStateToProps)(CylinderKeyAccessEditor);
