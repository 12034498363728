import CylinderList from './cylinder_list';

import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getCylinderCollections } from 'domain/hardware/redux/selectors';
import { setCylinderFilter, setCylinderSearchQuery } from 'domain/cylinder/redux/actions';
import { getCylinderFilter } from 'domain/cylinder/redux/selectors';

const mapStateToProps = (state: IAppStoreState) => {
    return {
        cylinderCollections: getCylinderCollections(state),
        defaultKeyFilter: getCylinderFilter(state)
    };
};

const mapDispatchToProps = {
    setFilter: setCylinderFilter,
    setSearchQuery: setCylinderSearchQuery
};

export default connect(mapStateToProps, mapDispatchToProps)(CylinderList);
