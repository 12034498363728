import React from 'react';
import { Text, Alert, Box } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { CylinderCollectionType } from 'domain/cylinder/types';

interface ICylinderInfoTextProps {
    collectionType?: CylinderCollectionType;
    hasTasks?: boolean;
    hasPendingTasks?: boolean;
    hasStartedTasks?: boolean;
}

const CylinderInfoText = (props: ICylinderInfoTextProps) => {
    const { collectionType, hasPendingTasks, hasStartedTasks, hasTasks } = props;

    const { t } = useTranslation();

    const getCollectionInfoText = () => {
        switch (collectionType) {
            case CylinderCollectionType.AT_RISK:
                return t(TKey.cylinderDetailsInfoAtRisk);
            case CylinderCollectionType.PENDING_UPDATE:
                return t(TKey.cylinderDetailsInfoNeedsUpdateStatus);
            case CylinderCollectionType.OTHER:
                return t(TKey.cylinderDetailsInfoUpToDate);
            default:
                return t(TKey.cylinderDetailsInfoUpToDate);
        }
    };

    const getTaskInfoText = () => {
        if (hasStartedTasks) {
            return ` ${t(TKey.cylinderDetailsInfoNeedsUpdateAssignedPart1)} ${t(
                TKey.cylinderDetailsInfoNeedsUpdateAssignedPart2
            )}`;
        }

        if (hasTasks && !hasPendingTasks) {
            return ` ${t(TKey.cylinderDetailsInfoNeedsUpdateUnassigned)}`;
        }

        return '';
    };

    if (!collectionType) {
        return null;
    }

    return (
        <Box p="quarter">
            <Alert
                text={
                    <Text data-testid="cylinder-info-text">
                        {getCollectionInfoText()}
                        <br />
                        {getTaskInfoText()}
                    </Text>
                }
                variant="warning"
            />
        </Box>
    );
};

export default CylinderInfoText;
