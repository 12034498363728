import { IKey, ICylinder } from 'models/types';
import CylinderModel from 'models/cylinder';

interface ITaskMap {
    [x: string]: {
        started: Array<ICylinder>;
        pending: Array<ICylinder>;
    };
}

const getTasks = (cylinders: Array<ICylinder> = []) => {
    return cylinders.reduce((result: ITaskMap, item) => {
        const cylinder = new CylinderModel(item);

        if (cylinder.hasAssignedTaskKey()) {
            const keyUuid = cylinder.assignedTaskKey() || '';

            if (!result[keyUuid]) {
                result[keyUuid] = {
                    started: [],
                    pending: []
                };
            }

            cylinder.hasStartedTasks() && result[keyUuid].started.push(item);

            cylinder.hasPendingTasks() && result[keyUuid].pending.push(item);
        }

        return result;
    }, {});
};

const getLoals = (item: IKey, cylinders: Array<ICylinder>) => {
    return item.authorizations.reduce((result: Array<ICylinder>, auth) => {
        const loal = cylinders.find(cylinder => auth.cylinderUuid === cylinder.uuid);
        if (loal) {
            result.push(loal);
        }

        return result;
    }, []);
};

const getLouks = (item: ICylinder, keys: Array<IKey>) => {
    return item.unauthorizedKeys.reduce((result: Array<IKey>, auth) => {
        const louk = keys.find(key => auth.keyUuid === key.uuid);
        if (louk) {
            result.push(louk);
        }

        return result;
    }, []);
};

export const normalizeHardwareData = (keys: Array<IKey>, cylinders: Array<ICylinder>) => {
    const tasks = getTasks(cylinders);

    const normalizedKeys = keys.map(item => {
        const availableTasks = tasks[item.uuid];
        item.associatedTasks = availableTasks || { started: [], pending: [] };
        item.loals = getLoals(item, cylinders);

        return item;
    });

    const normalizedCylinders = cylinders.map(item => {
        item.louks = getLouks(item, keys);
        return item;
    });

    return {
        keys: normalizedKeys,
        cylinders: normalizedCylinders
    };
};
