const storageFactory = () => {
    const getStorageManager = (session = false) => {
        if (session) {
            return sessionStorage;
        }
        return localStorage;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getItem = (key: string, fromSession = false): any | null => {
        const storageManager = getStorageManager(fromSession);

        const jsonString = storageManager.getItem(key);
        if (jsonString) {
            return JSON.parse(jsonString);
        }
        return null;
    };

    const removeItem = (key: string, fromSession = false): void => {
        const storageManager = getStorageManager(fromSession);
        storageManager.removeItem(key);
    };

    // eslint-disable-next-line
    const setItem = (key: string, value: any, toSession = false) => {
        const storageManager = getStorageManager(toSession);

        const jsonString = JSON.stringify(value);
        storageManager.setItem(key, jsonString);
    };

    const clear = (session = false) => {
        const storageManager = getStorageManager(session);
        storageManager.clear();
    };

    const clearAll = () => {
        sessionStorage.clear();
        localStorage.clear();
    };

    return {
        getItem,
        setItem,
        removeItem,
        clear,
        clearAll
    };
};

export default storageFactory();
