import React from 'react';
import {
    ITemplateAction as ITemplateActionAlias,
    ITemplateOptions as ITemplateOptionsAlias,
    IPromptApi as IPromptApiAlias,
    ITemplateProps as ITemplatePropsAlias
} from './types';
import DefaultPromptTemplate from './prompt_template';

const PromptServiceContext = React.createContext<(options: ITemplateOptions) => Promise<void>>(Promise.reject);

export const usePrompt = () => React.useContext(PromptServiceContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PromptServiceProvider = ({ children }: any) => {
    const [promptState, setPromptState] = React.useState<ITemplateOptions | null>(null);

    const openDialog = (options: ITemplateOptions) => {
        setPromptState(options);
        return Promise.resolve();
    };

    const handleClose = () => {
        setPromptState(null);
    };

    let PromptTemplate = DefaultPromptTemplate;

    if (promptState && promptState.template) {
        PromptTemplate = promptState.template;
    }

    return (
        <PromptServiceContext.Provider value={openDialog}>
            {children}
            {promptState ? (
                <PromptTemplate
                    isOpen={Boolean(promptState)}
                    title={promptState.title}
                    message={promptState.message}
                    actions={promptState.actions}
                    promptApi={{
                        close: handleClose
                    }}
                />
            ) : null}
        </PromptServiceContext.Provider>
    );
};

export type ITemplateAction = ITemplateActionAlias;
export type ITemplateOptions = ITemplateOptionsAlias;
export type IPromptApi = IPromptApiAlias;
export type ITemplateProps = ITemplatePropsAlias;
