import React from 'react';
import { Card } from 'aa-react-ui';
import MarkdownView from 'components/markdown_view';

const License = () => {
    return (
        <Card width="100%" p="full" overflow="hidden">
            <MarkdownView path={require('./license.md')} />
        </Card>
    );
};

export default License;
