import { ISessionState } from 'domain/authentication/redux/types';

import { IHardwareDataState } from 'domain/hardware/redux/types';
import { INotification } from 'domain/notification/redux/types';
import { KeyStateFilter } from 'domain/key/types';
import { CylinderFilter, IAuditTrail } from 'domain/cylinder/types';

export enum RequestStatus {
    IDLE = 'IDLE',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED'
}

export interface IApiRequestState<T = undefined> {
    status: RequestStatus;
    error?: string;
    data?: T;
}

export interface IApiRequestActionType<T = undefined> {
    type: string;
    status?: RequestStatus;
    error?: string;
    data?: T;
}

export interface IAppStoreState {
    locale: string;
    session: ISessionState;
    notifications: Array<INotification>;

    hardware: IApiRequestState<IHardwareDataState>;
    key: {
        searchQuery: string;
        filter: KeyStateFilter;
        selectedKeyId: string;
        updateRequestState: IApiRequestState;
        updateMultipleRequestState: IApiRequestState;
    };
    cylinder: {
        searchQuery: string;
        filter: CylinderFilter;
        selectedCylinderId: string;
        updateRequestState: IApiRequestState;
        accessLogRequestState: IApiRequestState<Array<IAuditTrail>>;
        updateMultipleRequestState: IApiRequestState;
    };
}
