import { TKey } from 'locale';

export const MINUTES_PER_HOUR = 60;

export const WEEK_DAYS: Array<string> = [
    TKey.genericDayMonday,
    TKey.genericDayTuesday,
    TKey.genericDayWednesday,
    TKey.genericDayThursday,
    TKey.genericDayFriday,
    TKey.genericDaySaturday,
    TKey.genericDaySunday
];

export const DAYS_ENUMS: Array<string> = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const CUSTOM_THEME_COLOR = {
    red: 'error.90',
    orange: 'warning.90',
    darkBlue: 'primary.90',
    blue: 'primary.70',
    lightBlue: 'primary.10',
    lightGray: 'contrast.10',
    gray: 'contrast.40',
    extraLightBlue: 'primary.05'
};

export const THEME_COLOR_PREFIX = 'colors.';
