/* eslint-disable */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers';

const configureStore = function(middleware: Array<any>) {
    const enhancer = composeWithDevTools(applyMiddleware(...middleware));
    return createStore(reducers, enhancer);
};

const middlewares: Array<any> = [thunkMiddleware];

export default configureStore(middlewares);
