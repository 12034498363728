import { LOAD_HARDWARE } from './action_types';
import { createRequestReducer } from 'app_redux/factory';
import { IHardwareDataState } from './types';
import { RequestStatus } from 'app_redux/types';

const hardwareReducer = createRequestReducer<IHardwareDataState>(LOAD_HARDWARE, {
    status: RequestStatus.IDLE,
    error: undefined,
    data: {
        keys: [],
        cylinders: []
    }
});

export { hardwareReducer };
