import React from 'react';
import { useTranslation, TKey } from 'locale';
import { Box, Alert } from 'aa-react-ui';
import { ICopySettings } from '../types';
import { KeyValidityText, KeyAccessText, KeyScheduleText } from 'domain/key/components';
import StepView from 'components/wizard/step_view';

interface IProps {
    isLoading?: boolean;
    onBack: () => void;
    onNext: () => void;
    data: ICopySettings;
    setData: (newData: ICopySettings) => void;
}

const SelectionSummary = (props: IProps) => {
    const { onBack, onNext, data, isLoading } = props;
    const { t } = useTranslation();

    const { sourceKey } = data;

    if (!sourceKey) {
        return null;
    }

    return (
        <StepView
            title={t(TKey.copyKeyHeader)}
            loading={isLoading}
            onBack={onBack}
            onSubmit={onNext}
            submitText={t(TKey.actionConfirm)}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.copyKeySummaryBody)} />
            </Box>

            <KeyValidityText keyData={sourceKey} />
            <KeyScheduleText keyData={sourceKey} />
            <KeyAccessText keyData={sourceKey} />

            <Box m="quarter" mt="full">
                <Alert title={t(TKey.genericWarning)} text={t(TKey.copyKeySummaryNotice)} variant="warning" />
            </Box>
        </StepView>
    );
};

export default SelectionSummary;
