import { FormApi } from 'final-form';
import { NewIntervalsInterface } from 'domain/schedule/types';
import { compareIntervals } from 'domain/schedule/utils';

function onSave(form: FormApi, close: () => void) {
    return (interval: NewIntervalsInterface) => {
        const { days, startHour, startMinutes, endHour, endMinutes } = interval;

        const formState = form.getState().values.days;

        days.forEach((day: string) => {
            const currentIntervals = formState[day] || [];

            const newInterval = {
                day,
                startHour,
                startMinutes,
                endHour,
                endMinutes
            };

            const newIntervals = [...currentIntervals];

            const hasMatch = currentIntervals.find(compareIntervals(newInterval));

            if (!hasMatch) {
                newIntervals.push(newInterval);
                form.change(`days.${day}`, newIntervals);
            }
        });

        close();
    };
}

export default onSave;
