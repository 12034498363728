import React, { useState } from 'react';
import { Box } from 'aa-react-ui';
import AssaAbloyLogo from '../assa_abloy_logo';
import CliqGoLogo from '../cliq_go_logo';
import DevOptions from '../dev_options';
import styled from 'styled-components';
import { catchOnDebounceCount } from 'common/utils/timers/timers';

const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const DEV_OPTIONS_TRIGGER_CLICKS = 3;

function CliqBrandLogo(): JSX.Element {
    const [devOptionVisible, setDevOptionVisible] = useState(false);

    const wrapperClickHandler = catchOnDebounceCount(() => {
        setDevOptionVisible(true);
    }, DEV_OPTIONS_TRIGGER_CLICKS);

    return (
        <>
            <LogoWrapper data-testid="brand-logo" onClick={wrapperClickHandler}>
                <Box m="full">
                    <AssaAbloyLogo />
                </Box>
                <CliqGoLogo />
            </LogoWrapper>

            <DevOptions
                visible={devOptionVisible}
                onDone={() => {
                    setDevOptionVisible(false);
                }}
            />
        </>
    );
}

export { AssaAbloyLogo, CliqGoLogo };

export default CliqBrandLogo;
