import httpClient, { HTTP_STATUS_CODE } from 'common/services/http_client';
import { TKey } from 'locale';

import { IDsUrls, IApiVersion, ISystem } from './types';

export const getAvailableSystems = async (url: string): Promise<[string | null, Array<ISystem>?]> => {
    try {
        const results: { data: Array<ISystem> } = await httpClient.get(url);
        const { data } = results;

        return [null, data];
    } catch (e) {
        const { response } = e;

        if (response) {
            const { status } = response;

            if (status === HTTP_STATUS_CODE.UNAUTHORIZED) {
                return [TKey.loginErrorNoAccessToThisSystem];
            }
        }

        return [TKey.genericErrorUnexpected];
    }
};

export const getDirectoryServiceUrls = async (url: string): Promise<[string | null, IDsUrls?]> => {
    try {
        const results: {
            data: IDsUrls;
        } = await httpClient.get(url);

        const { data } = results;

        if (data) {
            return [null, data];
        }

        return [TKey.genericErrorUnexpected];
    } catch (e) {
        const { response } = e;

        if (response) {
            const { status } = response;

            if (status === HTTP_STATUS_CODE.NOT_ACCEPTABLE || status === HTTP_STATUS_CODE.NOT_FOUND) {
                return [TKey.loginErrorSystemNotFound];
            }
        }

        return [TKey.genericErrorUnexpected];
    }
};

export const getCwmCompatibleApiVersion = async (url: string): Promise<[string | null, IApiVersion?]> => {
    try {
        const results: { data: IApiVersion } = await httpClient.get(url);
        const { data } = results;

        return [null, data];
    } catch (e) {
        const { response } = e;

        if (response) {
            const { status } = response;
            let error;
            switch (status) {
                case HTTP_STATUS_CODE.NOT_ACCEPTABLE:
                    error = TKey.loginErrorSystemNotCompatible;
                    break;
                case HTTP_STATUS_CODE.UNAUTHORIZED:
                    error = TKey.loginErrorNoAccessToThisSystem;
                    break;
                default:
                    error = TKey.genericErrorUnexpected;
            }
            return [error];
        }

        return [TKey.genericErrorUnexpected];
    }
};

export default {
    getAvailableSystems,
    getCwmCompatibleApiVersion,
    getDirectoryServiceUrls
};
