import moment from 'moment';
import 'moment-duration-format';
import 'moment-timezone';

import { DEFAULT_DATE_FORMAT } from 'common/configs';

interface Duration extends moment.Duration {
    format: (template?: string, settings?: DurationSettings) => string;
}

interface DurationSettings {
    forceLength?: boolean;
    precision?: number;
    template?: string;
    trim?: boolean;
}

export const minutesToTime = (minutes: number) => {
    const duration: Duration = moment.duration(minutes, 'minutes') as Duration;
    return duration.format('HH:mm', { trim: false });
};

export const periodToTime = (period: Array<number>) => [minutesToTime(period[0]), minutesToTime(period[1])];

export const formatDateString = (dateString: string, format?: string) =>
    moment(dateString, moment.ISO_8601, true).format(format || DEFAULT_DATE_FORMAT);

export const formatDate = (date: Date, format: string = DEFAULT_DATE_FORMAT) =>
    moment(date, moment.localeData().longDateFormat('L')).format(format);

export const getTimeZoneUtcOffset = (timeZoneName: string = moment.tz.guess()) => moment.tz(timeZoneName).utcOffset();

export const isDateRangeValid = (from: string, to: string) => moment(from).diff(to) <= 0;
