import React from 'react';
import styled, { StyledComponentProps } from 'styled-components';
import { ReactComponent as RadioOnIcon } from './radio_on.svg';
import { ReactComponent as RadioOffIcon } from './radio_off.svg';
import { IconWrapper } from 'components/icon';

interface RadioButtonWrapperProps {
    checked?: boolean;
}

const RadioButtonWrapper = styled.div<RadioButtonWrapperProps>`
    cursor: pointer;
    height: 24px;
    width: 24px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface RadioButtonProps extends StyledComponentProps<'input', any, {}, never> {
    checked?: boolean;
}

const RadioButton = (props: RadioButtonProps) => {
    const { checked, name = 'radio-button', ...rest } = props;

    return (
        <RadioButtonWrapper checked={checked}>
            <input
                {...rest}
                type="radio"
                checked={checked}
                name={name}
                style={{
                    opacity: 0,
                    position: 'absolute',
                    width: 'inherit',
                    height: 'inherit',
                    cursor: 'pointer'
                }}
            />
            {checked ? (
                <IconWrapper icon={RadioOnIcon} fill="primary.70" height="24px" width="24px" />
            ) : (
                <IconWrapper icon={RadioOffIcon} fill="contrast.40" height="24px" width="24px" />
            )}
        </RadioButtonWrapper>
    );
};

export default RadioButton;
