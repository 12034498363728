import React from 'react';
import styled, { StyledComponentProps } from 'styled-components';
import { ReactComponent as CheckIcon } from './check.svg';
import { ReactComponent as UncheckIcon } from './uncheck.svg';
import { IconWrapper } from 'components/icon';
import { Box } from 'aa-react-ui';

interface BaseCheckBoxProps {
    checked?: boolean;
}

const CheckBoxContainer = styled.div<BaseCheckBoxProps>`
    cursor: pointer;
    height: 24px;
    width: 24px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CheckBoxProps extends StyledComponentProps<'input', any, {}, never> {
    checked?: boolean;
}

const Checkbox = (props: CheckBoxProps) => {
    const { checked, ...rest } = props;

    return (
        <CheckBoxContainer checked={checked}>
            <input
                {...rest}
                type="checkbox"
                checked={checked}
                style={{
                    opacity: 0,
                    position: 'absolute',
                    width: 'inherit',
                    height: 'inherit',
                    cursor: 'pointer'
                }}
            />

            <Box>
                {checked ? (
                    <IconWrapper icon={CheckIcon} fill="primary.70" height="24px" width="24px" />
                ) : (
                    <IconWrapper icon={UncheckIcon} fill="contrast.40" height="24px" width="24px" />
                )}
            </Box>
        </CheckBoxContainer>
    );
};

export default Checkbox;
