import React, { useState } from 'react';
import { useTranslation, TKey } from 'locale';
import { useCylinders } from 'domain/cylinder/redux/hooks';
import { useHistory } from 'react-router-dom';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import { CylinderRadioList } from 'domain/cylinder/components';

import StepView from 'components/wizard/step_view';
import CylinderModel from 'models/cylinder';

const SelectCylinder = () => {
    const cylinders = useCylinders();
    const history = useHistory();
    const { t } = useTranslation();

    const [selectedCylinder, setSelectedCylinder] = useState<string | undefined>();

    const onBack = () => {
        history.push(Routes.DASHBOARD);
    };

    const onSubmit = () => {
        if (selectedCylinder) {
            const verifyRoute = buildRoute(Routes.ACCESS_LOG_REQUEST_VERIFY, {
                cylinderId: selectedCylinder
            });

            history.push(verifyRoute);
        }
    };

    const cylinderModels = cylinders
        .filter(item => !item.auditTrailRequested)
        .map(cylinder => new CylinderModel(cylinder));

    return (
        <StepView
            title={t(TKey.requestAuditTrailHeader)}
            subTitle={t(TKey.requestAuditTrailSelectCylinderBody)}
            onBack={onBack}
            disableSubmit={!selectedCylinder}
            submitText={t(TKey.actionNext)}
            onSubmit={onSubmit}>
            <CylinderRadioList
                items={cylinderModels}
                onSelect={(uuid: string) => {
                    setSelectedCylinder(uuid);
                }}
            />
        </StepView>
    );
};

export default SelectCylinder;
