export enum ValidityState {
    ALWAYS = 'ALWAYS',
    NEVER = 'NEVER',
    RANGE = 'RANGE'
}

export interface IValidityStateData {
    state: ValidityState;
    startDate: string;
    endDate: string;
}
