import { useEffect } from 'react';
import { usePrompt } from 'lib/aa-prompt-service';
import { useTranslation, TKey } from 'locale';

export default function useDirtyPrompt(isDirty: boolean) {
    const showPrompt = usePrompt();
    const { t } = useTranslation();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const callback = (event: any) => {
            if (isDirty) {
                const confirmationText = t(TKey.actionConfirmOnExit);
                event.returnValue = confirmationText;
                return confirmationText;
            }
        };

        window.onbeforeunload = callback;

        return () => {
            window.onbeforeunload = null;
        };
    }, [isDirty, t]);

    const promptIfDirty = () => {
        return new Promise(resolve => {
            if (isDirty) {
                showPrompt({
                    title: t(TKey.actionConfirm),
                    message: t(TKey.actionConfirmOnExit),
                    actions: [
                        {
                            text: t(TKey.actionConfirm),
                            variant: 'primary',
                            onClick: promptApi => {
                                promptApi.close();
                                resolve && resolve();
                            }
                        },
                        {
                            text: t(TKey.actionCancel),
                            onClick: promptApi => {
                                promptApi.close();
                            }
                        }
                    ]
                });
            } else {
                resolve && resolve();
            }
        });
    };

    return {
        promptIfDirty
    };
}
