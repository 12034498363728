import React from 'react';
import { useTranslation, TKey } from 'locale';
import KeyModel from 'models/key';
import { Text } from 'aa-react-ui';
import LinkButton from 'components/link_button';
import { Routes } from 'router';

interface IProps {
    keyData?: KeyModel;
}

const HandOut = (props: IProps) => {
    const { keyData } = props;
    const { t } = useTranslation();

    if (!keyData) {
        return null;
    }

    if (!keyData.isBlocked() && !keyData.isHandedOut()) {
        return (
            <LinkButton to={`${Routes.HAND_OUT}/${keyData.uuid}/settings`} mr="quarter">
                <Text>{t(TKey.actionKeyHandOut)}</Text>
            </LinkButton>
        );
    }

    return null;
};

export default HandOut;
