import React, { useState } from 'react';
import { useField } from 'react-final-form';

import { FormattedInterval, NewIntervalsInterface } from 'domain/schedule/types';

import TimeSlot from '../timeslot';
import TimeSlotForm from '../timeslot-form';

interface TimeContainerProps {
    name: string;
    remove: () => void;
    update: (value: FormattedInterval) => void;
}

function TimeContainer(props: TimeContainerProps) {
    const { remove, update, name } = props;

    const [editing, setEditing] = useState<boolean>(false);

    const { input } = useField(name);

    const interval: FormattedInterval = input.value || {};

    const toggleEditing = () => setEditing(!editing);

    const handleOnSave = (interval: NewIntervalsInterface) => {
        const { days, ...data } = interval;
        const [day] = days;

        const updatedInterval = {
            ...data,
            day
        };

        update(updatedInterval);
        toggleEditing();
    };

    return (
        <>
            <TimeSlot data={interval} remove={remove} edit={toggleEditing} />
            <TimeSlotForm open={editing} initialValues={interval} onSave={handleOnSave} onCancel={toggleEditing} />
        </>
    );
}

export default TimeContainer;
