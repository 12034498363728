import React from 'react';

import { Routes } from 'router';
import { H5 } from 'aa-react-ui';
import { useTranslation } from 'locale';

import { KEY_COLLECTION_NAME_TRANSLATION_KEY_MAP } from 'domain/key/constants';

import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';

import ListLink from 'components/list_link';
import CardContent from 'domain/key/components/key_card_item/content';

interface IKeyCollectionProps {
    items: Array<KeyModel>;
    type: string;
    parentCylinder?: CylinderModel;
    hideItemStatus?: boolean;
}

const List = (props: IKeyCollectionProps) => {
    const { items = [], type, parentCylinder, hideItemStatus } = props;
    const { t } = useTranslation();

    if (!items.length) {
        return null;
    }

    return (
        <>
            <H5 color="contrast.90" p="half">
                {t(KEY_COLLECTION_NAME_TRANSLATION_KEY_MAP[type])}
            </H5>

            {items.map((item: KeyModel) => (
                <ListLink key={item.uuid} to={`${Routes.KEYS}/${item.uuid}`} activeClassName="selected">
                    <CardContent item={item} parentCylinder={parentCylinder} hideItemStatus={hideItemStatus} />
                </ListLink>
            ))}
        </>
    );
};

const MemoizedKeyList = React.memo(List);

export default MemoizedKeyList;
