import React from 'react';
import { History } from 'history';
import { SectionHeader, Section, SectionBody, H3, Flex } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { CylinderCollectionType } from 'domain/cylinder/types';
import { CylinderCollectionItem, NameEditor } from 'domain/cylinder/components';
import { useUpdateCylinderRequest } from 'domain/cylinder/redux/hooks';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';
import { useNotification } from 'domain/notification/redux/hooks';

import CylinderModel from 'models/cylinder';
import commonUtils from 'common/utils/common';
import EditorActions from 'components/editor_actions';

import { AnyObject } from 'react-final-form';
import { Form } from 'components/form';
import RouteGuard from 'components/route-guard';

interface ICylinderNameEditorProps {
    history: History;
    cylinderData: { cylinder?: CylinderModel; collectionType?: CylinderCollectionType };
}

const CylinderNameEditor: React.FunctionComponent<ICylinderNameEditorProps> = props => {
    const { t } = useTranslation();
    const { updateCylinder } = useUpdateCylinderRequest();
    const { showError, showSuccess } = useNotification();

    const { history, cylinderData } = props;
    const { cylinder, collectionType } = cylinderData;

    if (!cylinder) {
        return null;
    }

    const goBack = () => {
        history.push(buildRoute(Routes.CYLINDERS_CYLINDER, { cylinderId: cylinder.uuid }));
    };

    const onSubmit = async (values: AnyObject) => {
        const { name } = values;
        const cylinderCopy = commonUtils.cloneDeep(cylinder);

        if (cylinderCopy) {
            cylinderCopy.setStateInstalled();
            cylinderCopy.name = name;

            const updateSuccess = await updateCylinder(cylinderCopy.uuid, cylinderCopy.toDataTransferObject());
            if (updateSuccess) {
                showSuccess(TKey.genericSuccessSaved);
                goBack();
            } else {
                showError(TKey.genericErrorSaving);
            }
        }
    };

    return (
        <Form onSubmit={onSubmit} initialValues={{ name: cylinder.name }}>
            {({ dirty, errors, submitting, handleSubmit }) => (
                <Section width="100%">
                    <RouteGuard when={dirty} />
                    <Flex flexDirection="column" height="100%">
                        <SectionHeader
                            title={<H3>{t(TKey.genericName)}</H3>}
                            actions={
                                <EditorActions
                                    editorDirty={dirty && !(errors && errors.name)}
                                    loading={submitting}
                                    onSave={handleSubmit}
                                    onBack={goBack}
                                />
                            }
                        />
                        <SectionBody style={{ overflowY: 'auto' }}>
                            <Flex flexDirection="column" height="inherit">
                                <CylinderCollectionItem dataModel={cylinder} collectionType={collectionType} />
                                <NameEditor disabled={submitting} />
                            </Flex>
                        </SectionBody>
                    </Flex>
                </Section>
            )}
        </Form>
    );
};

export default CylinderNameEditor;
