import React from 'react';
import { useField } from 'react-final-form';

import { Box, Label, Input, Text, Alert } from 'aa-react-ui';

import { TKey, useTranslation } from 'locale';

const MAX_INPUT_CHAR_LENGTH = 100;

const validateName = (str: string) => (str && !!str.trim() ? undefined : 'Required');

interface NameEditorProps {
    disabled: boolean;
}

const NameEditor = ({ disabled }: NameEditorProps) => {
    const { t } = useTranslation();
    const { input, meta } = useField('name', {
        validate: validateName
    });

    return (
        <Box m="quarter" height="100%">
            <Label>{t(TKey.genericName)}</Label>
            <Input
                disabled={disabled}
                data-testid="cylinder-name-input"
                maxLength={MAX_INPUT_CHAR_LENGTH}
                spellCheck={false}
                placeholder={t(TKey.cylinderNamePlaceholder)}
                {...input}
            />
            <Text py="half">{t(TKey.cylinderNameHint)}</Text>

            {meta.touched && meta.error && <Alert title={t(TKey.genericErrorEmptyFields)} variant="error" />}
        </Box>
    );
};

export default NameEditor;
