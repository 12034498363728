import React, { useState } from 'react';
import { H5, Box, Alert } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';

import { useParams, useHistory } from 'react-router-dom';
import { useCylinder } from 'domain/cylinder/redux/hooks';
import { useAssignableKeys } from 'domain/key/redux/hooks';
import { KeyRadioList } from 'domain/key/components';
import { IKey } from 'models/types';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import StepView from 'components/wizard/step_view';

const SelectKey = () => {
    const params = useParams<{ cylinderId: string }>();
    const selectedCylinder = useCylinder(params.cylinderId);
    const assignableKeys = useAssignableKeys(selectedCylinder);
    const history = useHistory<{ from: Location }>();
    const [selectedKey, setSelectedKey] = useState<IKey | undefined>();

    const { t } = useTranslation();

    const onBack = () => {
        const {
            location: { state }
        } = history;

        if (state && state.from) {
            history.push(state.from.pathname);
        } else {
            history.push(buildRoute(Routes.ACCESS_LOG_REQUEST_VERIFY, { cylinderId: params.cylinderId }));
        }
    };

    const onSubmit = () => {
        if (selectedCylinder) {
            const summaryRoute = buildRoute(Routes.ACCESS_LOG_REQUEST_SUMMARY, {
                cylinderId: selectedCylinder.uuid,
                keyId: selectedKey ? selectedKey.uuid : ''
            });
            history.push(summaryRoute);
        }
    };

    return (
        <StepView
            title={t(TKey.requestAuditTrailHeader)}
            onBack={onBack}
            submitText={selectedKey ? t(TKey.actionNext) : t(TKey.actionSkip)}
            onSubmit={onSubmit}>
            <H5 mb="full" pl="half">
                {t(TKey.requestAuditTrailSelectProgrammingKeyHeader)}
            </H5>

            <Box m="quarter" mb="half">
                <Alert text={t(TKey.requestAuditTrailSelectProgrammingKeyBody)} variant="warning" />
            </Box>

            <KeyRadioList
                items={assignableKeys}
                onSelect={key => {
                    setSelectedKey(key);
                }}
            />
        </StepView>
    );
};

export default SelectKey;
