import React, { useEffect } from 'react';
import { History } from 'history';
import { SectionHeader, Section, SectionBody, H3, Box, Text, Button, Flex, Alert, Spinner } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { CylinderCollectionItem, AccessLog } from 'domain/cylinder/components';
import { Routes } from 'router';
import { CylinderCollectionType } from 'domain/cylinder/types';
import { useUpdateCylinderRequest, useAccessLogs } from 'domain/cylinder/redux/hooks';
import { useErrorPrompt } from 'components/hooks';
import { useKeys } from 'domain/key/redux/hooks';
import { buildRoute } from 'router/helper';
import { useNotification } from 'domain/notification/redux/hooks';

import CommonUtils from 'common/utils/common';
import LinkButton from 'components/link_button';
import EditorActions from 'components/editor_actions';
import CylinderModel from 'models/cylinder';
import Card from 'components/card';

interface ICylinderAccessLogProps {
    history: History;
    cylinderData: { cylinder?: CylinderModel; collectionType?: CylinderCollectionType };
    showTimeZoneWarning?: boolean;
}

const CylinderAccessLog: React.FunctionComponent<ICylinderAccessLogProps> = props => {
    const { history, cylinderData, showTimeZoneWarning } = props;

    const { cylinder, collectionType } = cylinderData;
    const { t } = useTranslation();
    const { showErrorPrompt } = useErrorPrompt();
    const { updateCylinder, loading } = useUpdateCylinderRequest();
    const { loadLogs, loading: accessLogsLoading, accessLogs } = useAccessLogs();
    const { showError, showSuccess } = useNotification();

    const keys = useKeys();

    useEffect(() => {
        if (cylinder) {
            loadLogs(cylinder.uuid);
        }
    }, [loadLogs, cylinder]);

    if (!cylinder) {
        return null;
    }

    const onBackClick = () => {
        history.push(buildRoute(Routes.CYLINDERS_CYLINDER, { cylinderId: cylinder.uuid }));
    };

    const cancelAccessLogRequest = async () => {
        if (cylinder.hasStartedTasks()) {
            showErrorPrompt(TKey.cylinderAuditTrailCancelWarning);
            return;
        }

        const cylinderToUpdate = CommonUtils.cloneDeep(cylinder.toDataTransferObject());
        cylinderToUpdate.auditTrailRequested = false;

        const updateSuccess = await updateCylinder(cylinderToUpdate.uuid, cylinderToUpdate);
        if (updateSuccess) {
            showSuccess(TKey.genericSuccessSaved);
        } else {
            showError(TKey.genericErrorSaving);
        }
    };

    const auditTrailsRequested = cylinder.hasRequestedAuditTrail();

    const renderAuditTrailRequested = () => {
        if (auditTrailsRequested) {
            return (
                <Box m="quarter">
                    <Alert
                        variant="info"
                        text={
                            <Text>
                                {`${t(TKey.cylinderAuditTrailRequestPendingBody1)} ${t(
                                    TKey.cylinderAuditTrailRequestPendingBody2
                                )}`}
                                <LinkButton to={Routes.TASK_LIST} ml="half" size="sm" variant="primary">
                                    {t(TKey.genericTaskList)}
                                </LinkButton>
                            </Text>
                        }
                    />
                </Box>
            );
        }

        return null;
    };

    const renderAccessLogRequestAction = () => {
        if (auditTrailsRequested) {
            return (
                <Flex alignItems="center">
                    <Button
                        disabled={loading}
                        m="quarter"
                        data-testid="cancel-request-button"
                        onClick={cancelAccessLogRequest}>
                        {loading ? <Spinner /> : t(TKey.cylinderAuditTrailCancelRequest)}
                    </Button>
                </Flex>
            );
        }

        return (
            <Card mt="quarter" mb="quarter">
                <Flex flexDirection="column">
                    <Text>{t(TKey.cylinderAuditTrailRequestInfo)}</Text>

                    <LinkButton
                        mt="full"
                        variant="primary"
                        to={{
                            pathname: `${Routes.ACCESS_LOG_REQUEST}/${cylinder.uuid}/select-key`,
                            state: {
                                from: history.location
                            }
                        }}>
                        <Text>{t(TKey.requestAuditTrailHeader)}</Text>
                    </LinkButton>
                </Flex>
            </Card>
        );
    };

    return (
        <Section width="100%">
            <Flex flexDirection="column" height="100%">
                <SectionHeader
                    title={<H3>{t(TKey.genericAuditTrail)}</H3>}
                    actions={<EditorActions hideSave onBack={onBackClick} />}
                />
                <SectionBody style={{ overflowY: 'auto' }}>
                    <Flex flexDirection="column" height="inherit">
                        <CylinderCollectionItem dataModel={cylinder} collectionType={collectionType} />
                        {renderAuditTrailRequested()}
                        {renderAccessLogRequestAction()}
                        <AccessLog
                            value={accessLogs}
                            loading={accessLogsLoading}
                            keys={keys}
                            showTimeZoneWarning={showTimeZoneWarning}
                        />
                    </Flex>
                </SectionBody>
            </Flex>
        </Section>
    );
};

export default CylinderAccessLog;
