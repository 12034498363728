import React from 'react';
import { Box, Alert } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { KeyCollectionItem } from 'domain/key/components';
import { useKey, useUpdateKeyRequest } from 'domain/key/redux/hooks';
import { useCylinders, useUpdateCylinderRequest } from 'domain/cylinder/redux/hooks';
import { getAffectedCylindersBy } from 'domain/cylinder/cylinder_helper';
import { KeyState } from 'models/types';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';
import { useNotification } from 'domain/notification/redux/hooks';

import KeyModel from 'models/key';
import CommonUtils from 'common/utils/common';
import StepView from 'components/wizard/step_view';

const SelectionSummary = () => {
    const history = useHistory();
    const location = useLocation<{ from: Location }>();
    const params = useParams<{ keyId: string; taskKeyId: string }>();
    const cylinders = useCylinders();
    const keyToBeUnblock = useKey(params.keyId);
    const selectedTaskKey = useKey(params.taskKeyId);
    const { t } = useTranslation();
    const { updateKey, loading: keyUpdateInProgress } = useUpdateKeyRequest();
    const { updateMultipleCylinders, loading: cylinderUpdateInProgress } = useUpdateCylinderRequest();
    const { showError, showSuccess } = useNotification();

    if (!keyToBeUnblock) {
        return null;
    }

    const cylindersAffectedByKeyToBeUnblock = getAffectedCylindersBy(keyToBeUnblock, cylinders);

    const onBack = () => {
        const { state } = location;
        if (state && state.from) {
            history.push(state.from.pathname);
            return;
        }

        history.push(buildRoute(Routes.UNBLOCK_KEY_VERIFY, { keyId: keyToBeUnblock.uuid }));
    };

    const getSuccessRoute = () => {
        let assigned = '';

        if (selectedTaskKey) {
            assigned = `${cylindersAffectedByKeyToBeUnblock.length}`;
        } else if (cylindersAffectedByKeyToBeUnblock.length) {
            assigned = '0';
        }

        return buildRoute(Routes.UNBLOCK_KEY_SUCCESS, {
            keyId: keyToBeUnblock.uuid,
            assigned
        });
    };

    const onSubmit = async () => {
        const keyToUpdate = CommonUtils.cloneDeep(keyToBeUnblock);
        keyToUpdate.name = '';
        keyToUpdate.state = KeyState.IN_STOCK;

        const updateKeySuccess = await updateKey(keyToUpdate.uuid, keyToUpdate);
        if (!updateKeySuccess) {
            showError(TKey.blockKeyUnblockError);
            return;
        }

        if (!selectedTaskKey) {
            showSuccess(TKey.blockKeyUnblockSuccessNoAffected);
            history.push(getSuccessRoute());
            return;
        }

        if (cylindersAffectedByKeyToBeUnblock.length) {
            const cylindersToUpdate = CommonUtils.cloneDeep(cylindersAffectedByKeyToBeUnblock);

            const cylindersToUpdateWithTaskKey = cylindersToUpdate.map(item => {
                item.assignedToKeyUuid = selectedTaskKey.uuid;
                return item;
            });

            const updateCylindersSuccess = await updateMultipleCylinders(cylindersToUpdateWithTaskKey);
            if (updateCylindersSuccess) {
                showSuccess(TKey.blockKeyUnblockSuccessNoAffected);
                history.push(getSuccessRoute());
                return;
            }

            showError(TKey.blockKeyUnblockError);
        }
    };

    const renderTaskAssigmentNotPossibleWarning = () => {
        if (!selectedTaskKey) {
            return null;
        }

        const cylindersAffectedByTaskKey = getAffectedCylindersBy(selectedTaskKey, cylinders);

        if (cylindersAffectedByTaskKey.some(cylinder => cylinder.assignedToKeyUuid)) {
            return (
                <Box mb="full" mt="full">
                    <Alert text={t(TKey.keyAssignmentNotPossibleForAllAffectedCylinders)} variant="warning" />
                </Box>
            );
        }

        return null;
    };

    return (
        <StepView
            title={t(TKey.blockKeyUnblockHeader)}
            subTitle={t(TKey.genericSummary)}
            loading={keyUpdateInProgress || cylinderUpdateInProgress}
            onSubmit={onSubmit}
            onBack={onBack}>
            <Box mb="half" m="quarter">
                <Alert text={t(TKey.blockKeyUnblockSummaryBodyPart1)} variant="warning" />
            </Box>

            <KeyCollectionItem data={new KeyModel(keyToBeUnblock)} />

            {selectedTaskKey && (
                <>
                    <Box mb="full" mt="full">
                        <Alert text={t(TKey.blockKeyUnblockSummaryBodyPart2)} variant="info" />
                    </Box>
                    <KeyCollectionItem data={new KeyModel(selectedTaskKey)} />
                </>
            )}

            {renderTaskAssigmentNotPossibleWarning()}

            <Box mb="half" m="quarter">
                <Alert
                    text={t(TKey.blockKeySummaryNumAffectedCylinders, {
                        count: cylindersAffectedByKeyToBeUnblock.length
                    })}
                    variant="info"
                />
            </Box>

            <Box mb="half" m="quarter">
                <Alert title={t(TKey.genericWarning)} text={t(TKey.blockKeyUnblockSummaryWarning)} variant="warning" />
            </Box>
        </StepView>
    );
};

export default SelectionSummary;
