import { ITask } from 'models/types';

import TaskModel from 'models/task';

export const getAssignedButNotStartedTasks = (tasks: Array<ITask>) => {
    return tasks.filter(item => {
        const taskModel = new TaskModel(item);
        return taskModel.isAssignedButNotStarted();
    });
};

export const getStartedTasks = (tasks: Array<ITask>) => {
    return tasks.filter(item => {
        const taskModel = new TaskModel(item);
        return taskModel.isStarted();
    });
};
