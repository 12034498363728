import { Dispatch } from 'react';
import {
    ISetKeyFilterAction,
    ISetSearchQueryAction,
    ISetCurrentSelectedKey,
    SET_KEY_FILTER,
    SET_CURRENT_SELECTED_KEY,
    SET_KEY_SEARCH_QUERY,
    UPDATE_KEY,
    UPDATE_MULTIPLE_KEYS
} from './action_type';
import { IKey } from 'models/types';
import httpClient, { HTTP_STATUS_CODE } from 'common/services/http_client';
import { cliqUrlBuilder } from 'common/services/directory_service';
import { loadHardwareData } from 'domain/hardware/redux/actions';
import { createRequestActionCreators } from 'app_redux/factory';
import { KeyStateFilter } from '../types';

export const setSearchQuery = (searchQuery: string) => {
    return (dispatch: Dispatch<ISetSearchQueryAction>) => {
        dispatch({
            type: SET_KEY_SEARCH_QUERY,
            searchQuery
        });
    };
};

export const setFilter = (keyFilter: KeyStateFilter) => {
    return (dispatch: Dispatch<ISetKeyFilterAction>) => {
        dispatch({
            type: SET_KEY_FILTER,
            keyFilter
        });
    };
};

export const setCurrentSelectedKey = (id: string) => {
    return (dispatch: Dispatch<ISetCurrentSelectedKey>) => {
        dispatch({
            type: SET_CURRENT_SELECTED_KEY,
            keyId: id
        });
    };
};

const updateKeyActionCreators = createRequestActionCreators(UPDATE_KEY);

export const updateKey = (keyId: string, data: IKey) => {
    const { request, success, failure } = updateKeyActionCreators;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: any) => {
        dispatch(request());

        try {
            const updateUrl = `${cliqUrlBuilder.keys()}/${keyId}`;
            const response = await httpClient.put(updateUrl, data);

            if (response.status === HTTP_STATUS_CODE.OK) {
                await loadHardwareData()(dispatch);
            }

            dispatch(success());
            return true;
        } catch (e) {
            dispatch(failure());
            return false;
        }
    };
};

const updateMultipleKeysActionCreators = createRequestActionCreators(UPDATE_MULTIPLE_KEYS);

export const updateMultipleKeys = (data: Array<IKey>) => {
    const { request, success, failure } = updateMultipleKeysActionCreators;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: any) => {
        dispatch(request());

        try {
            const promiseList = data.map(key => {
                const updateUrl = `${cliqUrlBuilder.keys()}/${key.uuid}`;
                return httpClient.put(updateUrl, key);
            });

            const response = await httpClient.all(promiseList);

            const errorStatus = response.find(res => res.status !== HTTP_STATUS_CODE.OK);

            if (!errorStatus) {
                await loadHardwareData()(dispatch);

                dispatch(success());
                return true;
            } else {
                return false;
            }
        } catch (e) {
            dispatch(failure());
            return false;
        }
    };
};
