import React from 'react';
import { useField } from 'react-final-form';

import { Select } from 'aa-react-ui';

interface SelectProps {
    name: string;
    data: Array<string | number>;
    disabled?: boolean;
}

function SelectValue(props: SelectProps) {
    const { name, data, disabled, ...rest } = props;

    const { input } = useField(name);

    return (
        <Select disabled={disabled} {...input} {...rest}>
            {data.map((value: string | number) => (
                <option key={value} value={value}>
                    {value}
                </option>
            ))}
        </Select>
    );
}

export default SelectValue;
