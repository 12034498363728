import React from 'react';
import styled from 'styled-components';
import { ReactComponent as AaIcon } from './assa_abloy.svg';

interface IProps {
    height?: string;
    width?: string;
}

const CustomSvg = styled.svg`
    height: ${(props: IProps) => props.height || '2rem'};
    width: ${(props: IProps) => props.width || '15rem'};
    fill: white;
`;

const AssaAbloyLogo = (props: IProps): JSX.Element => {
    return (
        <CustomSvg {...props} version="1.1" data-testid="assa-abloy-icon" viewBox="0 0 100 13.66">
            <AaIcon />
        </CustomSvg>
    );
};

export default AssaAbloyLogo;
