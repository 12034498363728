import React from 'react';
import { Select } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { KeyStateFilter } from 'domain/key/types';

interface IFilterProps {
    value: KeyStateFilter;
    onChange: (newFilter: KeyStateFilter) => void;
}

const KeyStateFilterDropdown = (props: IFilterProps) => {
    const { value, onChange } = props;

    const { t } = useTranslation();

    return (
        <Select
            value={value}
            placeholder="Select filter"
            onChange={e => {
                onChange(e.target.value as KeyStateFilter);
            }}>
            <option value={KeyStateFilter.ALL}>{t(TKey.genericAll)}</option>
            <option value={KeyStateFilter.HANDED_OUT}>{t(TKey.genericHandedOut)}</option>
            <option value={KeyStateFilter.IN_STOCK}>{t(TKey.genericInStock)}</option>
            <option value={KeyStateFilter.BLOCKED}>{t(TKey.genericBlocked)}</option>
        </Select>
    );
};

export default KeyStateFilterDropdown;
