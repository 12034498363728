import React, { useState, useEffect } from 'react';
import { SectionHeader, SectionFooter, Text, H3, Box, Flex, Section } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { Routes } from 'router';
import { CylinderIcon } from 'components/icon';
import { useCylinders } from 'domain/cylinder/redux/hooks';
import { ICylinder } from 'models/types';
import { getPendingCylinders } from 'domain/cylinder/cylinder_helper';
import { useHardwareData } from 'domain/hardware/redux/hooks';

import LinkButton from 'components/link_button';
import WidgetLoader from '../widget_loader';

const CylinderWidget = () => {
    const [pendingCylinders, setPendingCylinders] = useState<Array<ICylinder>>([]);

    const { t } = useTranslation();
    const allCylinders = useCylinders();
    const { loading } = useHardwareData();

    useEffect(() => {
        setPendingCylinders(getPendingCylinders(allCylinders));
    }, [allCylinders, setPendingCylinders]);

    if (loading) {
        return <WidgetLoader />;
    }

    const headerText = `${allCylinders.length} ${t(TKey.genericCylinders)}`;
    const subHeaderText = pendingCylinders.length
        ? t(TKey.genericNumNeedUpdating, { count: pendingCylinders.length })
        : '';

    return (
        <Section width="100%">
            <SectionHeader
                actions={
                    <LinkButton variant="primary" to={Routes.CYLINDERS}>
                        <Text>{t(TKey.actionViewAll)}</Text>
                    </LinkButton>
                }>
                <CylinderIcon height="4rem" width="4rem" stroke="black" strokeWidth="3px" />

                <Box ml="half">
                    <H3>{headerText}</H3>
                    <Text color="warning.80" mt="half">
                        {subHeaderText}
                    </Text>
                </Box>
            </SectionHeader>
            <SectionFooter>
                <Flex justifyContent="flex-end">
                    <LinkButton m="quarter" to={Routes.ACCESS_LOG_REQUEST}>
                        <Text>{t(TKey.requestAuditTrailHeader)}</Text>
                    </LinkButton>

                    <LinkButton m="quarter" to={Routes.TASK_LIST}>
                        <Text>{t(TKey.genericTaskList)}</Text>
                    </LinkButton>
                </Flex>
            </SectionFooter>
        </Section>
    );
};

export default CylinderWidget;
