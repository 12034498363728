import { useSelector, useDispatch } from 'react-redux';
import { getKeys, getUpdateKeyRequest, getUpdateMultipleKeyRequest, getSelectedKeyId } from './selectors';
import { filterAssignableKeys, sortKeysOnStateAndNameAndMarking, getKeyAccessList } from '../key_helper';
import { ICylinder, KeyState, IKey } from 'models/types';
import { updateKey, updateMultipleKeys, setCurrentSelectedKey } from './actions';
import { RequestStatus } from 'app_redux/types';
import { getAllCylinders } from 'domain/cylinder/redux/selectors';
import { useCallback } from 'react';
import KeyModel from 'models/key';

export const useAssignableKeys = (toCylinder?: ICylinder) => {
    const allKeys = useSelector(getKeys);

    if (toCylinder) {
        return filterAssignableKeys([toCylinder], allKeys);
    }

    return [];
};

export const useFilteredKeys = (state: KeyState) => {
    const allKeys = useSelector(getKeys);
    return allKeys
        .filter(item => item.state === state)
        .sort((lhs, rhs) => sortKeysOnStateAndNameAndMarking(new KeyModel(lhs), new KeyModel(rhs)));
};

export const useUpdateKeyRequest = () => {
    const dispatch = useDispatch();
    const updateSingleRequestState = useSelector(getUpdateKeyRequest);
    const updateMultipleRequestState = useSelector(getUpdateMultipleKeyRequest);

    const updateSingle = useCallback(
        (keyId: string, data: IKey) => {
            const dataModel = new KeyModel(data);
            return updateKey(keyId, dataModel.toDataTransferObject())(dispatch);
        },
        [dispatch]
    );

    const updateMultiple = useCallback(
        (data: Array<IKey>) => {
            const dataObjects = data.map(item => {
                const dataModel = new KeyModel(item);
                return dataModel.toDataTransferObject();
            });

            return updateMultipleKeys(dataObjects)(dispatch);
        },
        [dispatch]
    );

    return {
        updateKey: updateSingle,
        updateMultipleKeys: updateMultiple,
        loading:
            updateSingleRequestState.status === RequestStatus.IN_PROGRESS ||
            updateMultipleRequestState.status === RequestStatus.IN_PROGRESS
    };
};

export const useNonBlockedKeys = () => {
    const allKeys = useSelector(getKeys);

    return allKeys
        .filter(item => item.state !== KeyState.BLOCKED)
        .sort((lhs, rhs) => sortKeysOnStateAndNameAndMarking(new KeyModel(lhs), new KeyModel(rhs)));
};

export const useKeys = () => {
    return useSelector(getKeys);
};

export const useKey = (keyId: string) => {
    const allKeys = useSelector(getKeys);

    return allKeys.find(item => item.uuid === keyId);
};

export const useKeyAccess = (key?: IKey) => {
    const cylinders = useSelector(getAllCylinders);

    if (key) {
        return getKeyAccessList(key, cylinders);
    }

    return [];
};

export const useKeySelection = () => {
    const dispatch = useDispatch();
    const selectedKeyId = useSelector(getSelectedKeyId);

    const setSelectedKeyId = useCallback(
        (keyId: string) => {
            setCurrentSelectedKey(keyId)(dispatch);
        },
        [dispatch]
    );

    return {
        selectedKeyId,
        setSelectedKeyId
    };
};
