import storageService from '../storage_service';
import { getDirectoryServiceUrls, getCwmCompatibleApiVersion, getAvailableSystems } from './helpers';
import { TKey } from 'locale';

import dsStore from './ds_store';
import cliqUrlBuilder from './cliq_url_builder';

function directoryServiceFactory() {
    const init = async (aaCode: number, mksName: string): Promise<string | undefined> => {
        const APP_DS_URL = dsStore.getDirectoryServiceUrl();

        const url = `${APP_DS_URL}/directory/consumer/cliq/${aaCode}/${mksName}`;
        const [dsError, dsData] = await getDirectoryServiceUrls(url);

        if (dsError) {
            return dsError;
        }

        dsStore.setCreds({ aaCode, mksName });
        dsStore.setServiceUrls(dsData);

        const [cwmError] = await getCwmCompatibleApiVersion(cliqUrlBuilder.versionCompatibility());

        if (cwmError) {
            return cwmError;
        }

        const [availableSystemsError, availableSystems = []] = await getAvailableSystems(cliqUrlBuilder.systems());

        if (availableSystemsError) {
            return availableSystemsError;
        }

        const defaultSystem = availableSystems.find(system => {
            if (system.aaCode === aaCode && system.mksName === mksName) {
                return true;
            }
            return false;
        });

        if (defaultSystem) {
            dsStore.setDefaultSystem(defaultSystem);
            return;
        }

        return TKey.loginErrorNoAccessToThisSystem;
    };

    const tearDown = () => {
        storageService.clear(true);
    };

    const getDefaultSystem = () => dsStore.getDefaultSystem();

    const getDefaultSystemId = () => {
        const defaultSystem = dsStore.getDefaultSystem();
        if (defaultSystem) {
            return `${defaultSystem.aaCode}:${defaultSystem.mksName}`;
        }
        return '';
    };

    return {
        init,
        tearDown,
        getDefaultSystem,
        getDefaultSystemId
    };
}
const directoryService = directoryServiceFactory();

export default directoryService;
