import { ITask, TaskType, TaskState } from './types';

export default class TaskModel {
    readonly data: ITask;

    constructor(jsonTask: ITask) {
        this.data = jsonTask;
    }

    isStarted() {
        return this.data.state === TaskState.ASSIGNED;
    }

    isAssignedButNotStarted() {
        return this.data.state === TaskState.PENDING_ASSIGNED;
    }

    isPendingUnassigned() {
        return this.data.state === TaskState.PENDING_UNASSIGNED;
    }

    isUnassigned() {
        return this.data.state === TaskState.UNASSIGNED;
    }

    isAuditTrailType() {
        return this.data.type === TaskType.READ_AUDIT_TRAIL;
    }

    toDataObject() {
        return this.data;
    }
}
