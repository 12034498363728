import React, { useState } from 'react';
import { useTranslation, TKey } from 'locale';
import { ICopySettings } from '../types';
import { useNonBlockedKeys } from 'domain/key/redux/hooks';
import { IKey } from 'models/types';
import { Box, Alert } from 'aa-react-ui';

import StepView from 'components/wizard/step_view';
import KeyCheckList from 'domain/key/components/key_check_list';

interface IProps {
    onBack: () => void;
    onNext: () => void;
    data: ICopySettings;
    setData: (newData: ICopySettings) => void;
}

const SelectTargetKey = (props: IProps) => {
    const { onBack, onNext, data, setData } = props;
    const { sourceKey } = data;
    const { t } = useTranslation();
    const nonBlockedKeys = useNonBlockedKeys();
    const [targetKeys, setTargetKeys] = useState<Array<IKey>>(data.targetKeys);

    if (!sourceKey) {
        return null;
    }

    const getPossibleTargetKeys = () => {
        return nonBlockedKeys.filter(item => item.uuid !== sourceKey.uuid);
    };

    const onSubmit = () => {
        setData({
            ...data,
            targetKeys
        });
        onNext && onNext();
    };

    return (
        <StepView
            title={t(TKey.copyKeyHeader)}
            subTitle={t(TKey.genericAvailable)}
            onBack={onBack}
            disableSubmit={!targetKeys.length}
            onSubmit={onSubmit}
            submitText={t(TKey.actionNext)}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.copyKeyTargetBody)} />
            </Box>

            <KeyCheckList
                initiallySelected={data.targetKeys}
                items={getPossibleTargetKeys()}
                onChange={checkedItems => {
                    setTargetKeys(checkedItems);
                }}
            />
        </StepView>
    );
};

export default SelectTargetKey;
