import HardwareModel from './hardware';
import ScheduleModel from './schedule';
import ValidityModel from './validity';
import { IKey, ICliqIdentity, KeyState } from './types';
import LoalEntryModel from './loal_entry';
import { KeyStateInt } from 'domain/key/types';

export const hasPendingTasks = (data: IKey): boolean => {
    const { associatedTasks } = data;
    return associatedTasks && associatedTasks.pending && associatedTasks.pending.length > 0 ? true : false;
};

export default class KeyModel extends HardwareModel {
    public uuid: string;

    public validity: ValidityModel;
    public schedule: ScheduleModel;

    public authorizations: Array<LoalEntryModel>;

    readonly data: IKey;
    readonly cliqIdentity: ICliqIdentity;
    readonly marking: string;

    constructor(json: IKey) {
        super(json.name, json.marking, json.uuid, json.version);

        this.data = json;
        this.uuid = json.uuid;
        this.cliqIdentity = json.cliqIdentity;
        this.marking = json.marking;
        this.schedule = new ScheduleModel(json.schedule);
        this.validity = new ValidityModel(json.validity);

        const newList = [];
        if (json.authorizations) {
            for (const auth of json.authorizations) {
                newList.push(new LoalEntryModel(auth));
            }
            newList.sort((lhs, rhs) => lhs.stateIntRepresentation() - rhs.stateIntRepresentation());
            this.authorizations = newList;
        } else {
            this.authorizations = [];
        }
    }

    stateIntRepresentation() {
        switch (this.data.state) {
            case KeyState.BLOCKED:
                return KeyStateInt.BLOCKED;
            case KeyState.HANDED_OUT:
                return KeyStateInt.HANDED_OUT;
            case KeyState.IN_STOCK:
                return KeyStateInt.IN_STOCK;
            default:
                return KeyStateInt.UNKNOWN;
        }
    }

    hasPendingJob() {
        return (
            this.hasPendingTasks() || this.hasPendingValidity() || this.hasPendingSchedule() || this.hasPendingAccess()
        );
    }

    isInStock() {
        return this.data.state === KeyState.IN_STOCK;
    }

    setState(state: KeyState) {
        this.data.state = state;
    }

    isBlocked() {
        return this.data.state === KeyState.BLOCKED;
    }

    isHandedOut() {
        return this.data.state === KeyState.HANDED_OUT;
    }

    hasPendingTasks = () => hasPendingTasks(this.data);

    hasStartedTasks() {
        const { associatedTasks } = this.data;
        return associatedTasks && associatedTasks.started.length > 0;
    }

    hasPendingSchedule() {
        return this.schedule && this.schedule.isPending();
    }

    hasPendingValidity() {
        return this.validity && this.validity.isPending();
    }

    hasPendingAccess() {
        return this.authorizations.some((loal: LoalEntryModel) => loal.isPending());
    }

    removeAllAuthorizations() {
        this.authorizations = this.authorizations.reduce((auths: Array<LoalEntryModel>, loal) => {
            if (loal.isRemoved()) {
                auths.push(loal);
            }

            if (loal.isCurrent()) {
                loal.setStateRemoved();
                auths.push(loal);
            }

            return auths;
        }, []);
    }

    getLoals() {
        return this.data.loals || [];
    }

    toDataTransferObject() {
        const authList = [];
        for (const auth of this.authorizations) {
            if (!auth.isPlaceholder()) {
                authList.push(auth.toDataObject());
            }
        }
        return {
            name: this.data.name,
            state: this.data.state,
            version: this.data.version,
            schedule: this.schedule.toDataObject(),
            validity: this.validity.toDataObject(),
            authorizations: authList,
            uuid: this.uuid,
            cliqIdentity: this.cliqIdentity,
            marking: this.marking
        };
    }
}
