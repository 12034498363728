import React from 'react';
import { Routes } from 'router';
import SelectKey from './select_key';
import VerifySelection from './verify_selection';
import { Route } from 'react-router-dom';
import Summary from './summary';
import SelectTaskKey from './select_task_key';
import Success from './success';

const BlockKey = () => {
    return (
        <>
            <Route exact path={Routes.BLOCK_KEY} component={SelectKey} />
            <Route exact path={Routes.BLOCK_KEY_VERIFY} component={VerifySelection} />
            <Route exact path={Routes.BLOCK_KEY_TASK_ASSIGN_KEY} component={SelectTaskKey} />
            <Route exact path={Routes.BLOCK_KEY_SUMMARY} component={Summary} />
            <Route exact path={Routes.BLOCK_KEY_SUCCESS} component={Success} />
        </>
    );
};

export default BlockKey;
