import React, { useState, useMemo } from 'react';
import { Section, Flex, SectionHeader, H3, SectionBody, Text, Alert } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { IKey, ICylinder } from 'models/types';
import { UnassignedTaskList, AssignedTaskList, SelectTaskKeyModal } from 'domain/task/components';
import { useHardwareData } from 'domain/hardware/redux/hooks';
import { useUpdateCylinderRequest, useCylinders } from 'domain/cylinder/redux/hooks';
import { useKeys } from 'domain/key/redux/hooks';
import { useNotification } from 'domain/notification/redux/hooks';
import {
    assignTaskKeyIdToCylinders,
    unassignedTaskKeyFromCylinders,
    filterUnassignedCylinders,
    filterAssignedCylinders,
    getAssignedTaskList
} from 'domain/cylinder/cylinder_helper';
import ContentLoader from 'components/content_loader';

function TaskList(): JSX.Element {
    const { t } = useTranslation();

    const keys = useKeys();
    const cylinders = useCylinders();
    const [isSelectKeyModalOpen, setIsSelectKeyModalOpen] = useState(false);
    const [cylindersToAssign, setCylindersToAssign] = useState<Array<ICylinder>>([]);
    const { loading } = useHardwareData();
    const { updateMultipleCylinders, loading: updating } = useUpdateCylinderRequest();
    const { showSuccess, showError } = useNotification();

    const unassignedTasks = useMemo(
        () =>
            filterUnassignedCylinders(cylinders).map(item => ({
                cylinder: item,
                selected: false
            })),
        [cylinders]
    );

    const assignedTasks = useMemo(() => getAssignedTaskList(filterAssignedCylinders(cylinders), keys), [
        cylinders,
        keys
    ]);

    const onSelectTaskKeyCancelClick = () => {
        setIsSelectKeyModalOpen(false);
    };

    const onAssignClick = (cylinders: Array<ICylinder>) => {
        setCylindersToAssign(cylinders);
        setIsSelectKeyModalOpen(true);
    };

    const onUnassignClick = async (cylindersToUnassign: Array<ICylinder>) => {
        if (cylindersToUnassign.length) {
            const cylindersToUpdate = unassignedTaskKeyFromCylinders(cylindersToUnassign);
            const updateSuccess = await updateMultipleCylinders(cylindersToUpdate);
            if (updateSuccess) {
                showSuccess(TKey.genericSuccessSaved);
                return;
            }

            showError(TKey.genericErrorSaving);
        }
    };

    const onSelectTaskKeySaveClick = async (selectedKey?: IKey) => {
        setIsSelectKeyModalOpen(false);

        if (selectedKey) {
            const cylindersToUpdate = assignTaskKeyIdToCylinders(selectedKey.uuid, cylindersToAssign);
            const updateSuccess = await updateMultipleCylinders(cylindersToUpdate);
            if (updateSuccess) {
                showSuccess(TKey.genericSuccessSaved);
                return;
            }
            showError(TKey.genericErrorSaving);
        }
    };

    const renderTaskLists = () => {
        if (loading) {
            return <ContentLoader />;
        }

        if (unassignedTasks.length > 0 || assignedTasks.length > 0) {
            return (
                <>
                    <Alert text={t(TKey.taskBody)} variant="warning" />
                    <UnassignedTaskList tasks={unassignedTasks} onAssign={onAssignClick} isLoading={updating} />
                    <AssignedTaskList tasks={assignedTasks} onUnassign={onUnassignClick} isLoading={updating} />
                </>
            );
        } else {
            return <Text>{t(TKey.taskListEmpty)}</Text>;
        }
    };

    return (
        <>
            <Flex width="100%" alignItems="center" justifyContent="center">
                <Section width={[1, 0.5]} height="100%" style={{ overflowY: 'scroll' }}>
                    <Flex height="100%" flexDirection="column">
                        <SectionHeader title={<H3>{t(TKey.genericTaskList)}</H3>} />
                        <SectionBody height="100%">{renderTaskLists()}</SectionBody>
                    </Flex>
                </Section>
            </Flex>
            {isSelectKeyModalOpen && (
                <SelectTaskKeyModal
                    cylindersToAssign={cylindersToAssign}
                    keys={keys}
                    onCancel={onSelectTaskKeyCancelClick}
                    onSave={onSelectTaskKeySaveClick}
                />
            )}
        </>
    );
}

export default TaskList;
