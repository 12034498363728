const DEFAULT_WAIT_IN_MILLIS = 20;
const THROTTLE_WAIT = 250;

export function debounce<T extends Function>(callback: T, wait = DEFAULT_WAIT_IN_MILLIS) {
    let h = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callable = (...args: any) => {
        clearTimeout(h);
        h = setTimeout(() => callback(...args), wait);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (callable as any) as T;
}

export function catchOnDebounceCount<T extends Function>(callback: T, count: number) {
    let h = 0;
    let counter = 0;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callable = (...args: any) => {
        counter = counter + 1;
        clearTimeout(h);
        h = setTimeout(() => {
            if (counter === count) {
                callback(...args);
            }
            counter = 0;
        }, THROTTLE_WAIT);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (callable as any) as T;
}
