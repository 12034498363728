import { useSelector, useDispatch } from 'react-redux';
import { getAppSession } from './selectors';
import { createUserSession, destroyAppSession } from './actions';
import { useCallback } from 'react';

export const useAppSession = () => {
    const dispatch = useDispatch();
    const session = useSelector(getAppSession);

    const createSession = useCallback(
        (systemId: string, password: string) => createUserSession(systemId, password)(dispatch),
        [dispatch]
    );

    const destroySession = useCallback(() => destroyAppSession()(dispatch), [dispatch]);

    return {
        session,
        createSession,
        destroySession
    };
};
