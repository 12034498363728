import { createSelector } from 'reselect';
import { IAppStoreState } from 'app_redux/types';
import { INotification } from './types';

const notificationsSelector = (state: IAppStoreState) => {
    return state.notifications;
};

export const getAppNotifications = createSelector(
    notificationsSelector,
    (notifications: Array<INotification>) => notifications
);
