import React from 'react';
import ReactContentLoader from 'react-content-loader';

const ContentLoader = () => (
    <ReactContentLoader speed={2} width="100%" height="100%" viewBox="0 0 400 193">
        <rect x="10" y="15" rx="3" ry="3" width="60%" height="17" />
        <rect x="10" y="51" rx="3" ry="3" width="30%" height="15" />
        <rect x="10" y="82" rx="3" ry="3" width="90%" height="177" />
    </ReactContentLoader>
);

export default ContentLoader;
