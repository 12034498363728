import React from 'react';
import { BrandLogo } from 'domain/authentication/components';
import BaseContainer from 'components/base_container';

function Activate(): JSX.Element {
    return (
        <BaseContainer>
            <BrandLogo />
            <h1>ACTIVATE</h1>
        </BaseContainer>
    );
}

export default Activate;
