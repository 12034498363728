import React, { useState } from 'react';
import { useTranslation, TKey } from 'locale';
import { Flex, Box, Alert, Button } from 'aa-react-ui';

import StepView from 'components/wizard/step_view';
import { IKey } from 'models/types';

import ScheduleEditor from 'domain/schedule/components/schedule';
import ToggleSchedule from 'domain/schedule/components/switch';
import DayList from 'domain/schedule/components/day_list';
import AddTimeSlot from 'domain/schedule/components/timeslot-add';

interface IProps {
    onNext?: () => void;
    onBack?: () => void;
    data: IKey;
    setData: (newData: IKey) => void;
}

const Schedule = (props: IProps) => {
    const { onNext, onBack, data, setData } = props;

    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const HEADER = t(TKey.handOutHeader);
    const ADD_TIME = t(TKey.actionKeyScheduleAddTimePeriod);
    const NEXT = t(TKey.actionNext);
    const MESSAGE = t(TKey.handOutScheduleBody);

    const triggerSave = () => {
        const el = document.getElementById('key-schedule-editor');

        if (!el) {
            return;
        }

        el.dispatchEvent(new Event('submit', { cancelable: true }));
    };

    const handleSave = (data: IKey) => {
        setData(data);
        onNext && onNext();
    };

    return (
        <StepView title={HEADER} onBack={onBack} submitText={NEXT} onSubmit={triggerSave}>
            <Box mb="half" m="quarter">
                <Alert text={MESSAGE} />
            </Box>

            <ScheduleEditor keyData={data} keyId={data.uuid} onSave={handleSave} defaultEnabled={true}>
                {() => (
                    <Flex flexDirection="column">
                        <ToggleSchedule />
                        <Button variant="primary" ml="auto" mb="full" size="sm" onClick={() => setVisible(true)}>
                            {ADD_TIME}
                        </Button>
                        <AddTimeSlot open={visible} close={() => setVisible(false)} />
                        <DayList />
                    </Flex>
                )}
            </ScheduleEditor>
        </StepView>
    );
};

export default Schedule;
