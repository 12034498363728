import React, { useState, Fragment } from 'react';
import { History } from 'history';
import { Button, Text, Box } from 'aa-react-ui';
import { Routes } from 'router';
import { BrandLogo, CliqConnectInstructionModal } from 'domain/authentication/components';
import { TKey, useTranslation } from 'locale';

interface IProps {
    history: History;
}

const ForgotPassword = (props: IProps): JSX.Element => {
    const { history } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { t } = useTranslation();

    return (
        <Fragment>
            <BrandLogo />

            <Box position="absolute" top={0} left={0} padding="1rem">
                <Button onClick={() => history.push(Routes.AUTH)} variant="primary">
                    {t(TKey.actionEnrolmentToLogin)}
                </Button>
            </Box>

            <Box width="24rem" marginTop="24px">
                <Text textAlign="center" color="white" fontSize="18px">
                    {t(TKey.loginForgotPasswordBody)}
                </Text>
            </Box>

            <Box width="22rem" marginTop="24px">
                <Button
                    data-testid="activate"
                    width="100%"
                    onClick={(): void => {
                        setIsOpen(true);
                    }}>
                    <Text as="strong">{t(TKey.actionActivate)}</Text>
                </Button>
            </Box>
            <CliqConnectInstructionModal isOpen={isOpen} onDone={() => setIsOpen(false)} />
        </Fragment>
    );
};

export default ForgotPassword;
