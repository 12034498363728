import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';

import { isCylinderAtRiskForLoal } from 'domain/cylinder/cylinder_helper';

export const hasKeyAuthAtRisk = (key: KeyModel, cylinders: Array<CylinderModel>) => {
    const loalAtRisk = key.authorizations.find(auth => {
        const cylinder = cylinders.find((item: CylinderModel) => item.uuid === auth.cylinderUuid);

        if (!cylinder) {
            return false;
        }

        return isCylinderAtRiskForLoal(auth, cylinder, key);
    });

    return !!loalAtRisk;
};

export const isKeyHarmful = (key: KeyModel, cylinders?: Array<CylinderModel>): boolean => {
    const associatedLoalCylinders = cylinders || key.getLoals().map(item => new CylinderModel(item));

    const isBlocked = key && key.isBlocked();
    const isValid = key.validity.isCurrentlyValid();
    const isAtRisk = hasKeyAuthAtRisk(key, associatedLoalCylinders);

    return isBlocked && isValid && isAtRisk;
};
