import { connect } from 'react-redux';

import { IAppStoreState } from 'app_redux/types';
import { setAppLocale } from 'domain/locale/redux/actions';
import { getAppLocale } from 'domain/locale/redux/selectors';

import { IAuthLayoutProps } from './auth_layout';
import { ComponentType } from 'react';

export default function container(component: ComponentType<IAuthLayoutProps>) {
    const mapStateToProps = (state: IAppStoreState) => ({
        appLocale: getAppLocale(state)
    });

    const mapDispatchToProps = {
        setAppLocale
    };

    return connect(mapStateToProps, mapDispatchToProps)(component);
}
