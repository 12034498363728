import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { FormRenderProps } from 'react-final-form';

import { SectionHeader, Section, SectionBody, Flex, H3, Button, Spinner } from 'aa-react-ui';

import { useTranslation, TKey } from 'locale';
import { useNotification } from 'domain/notification/redux/hooks';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';
import { useUpdateKeyRequest } from 'domain/key/redux/hooks';

import { IKey } from 'models/types';
import KeyModel from 'models/key';
import getCollectionType from 'domain/key/collectionType';
import KeyCollectionItem from 'domain/key/components/key_collection_item';

import RouteGuard from 'components/route-guard';

import Schedule from 'domain/schedule/components/schedule';
import ToggleSchedule from 'domain/schedule/components/switch';
import DayList from 'domain/schedule/components/day_list';
import AddTimeSlot from 'domain/schedule/components/timeslot-add';

interface IKeyScheduleEditor {
    keyData?: IKey;
}

const KeyScheduleEditor = (props: IKeyScheduleEditor) => {
    const { keyData } = props;
    const { schedule } = keyData || {};

    const history = useHistory();
    const { t } = useTranslation();
    const { updateKey, loading } = useUpdateKeyRequest();
    const { showError, showSuccess } = useNotification();
    const [displayAddTime, setDisplayAddTime] = useState(false);

    const toggleDisplayAddTime = () => setDisplayAddTime(!displayAddTime);

    const BACK = t(TKey.actionBack);
    const SAVE = t(TKey.actionSave);
    const HEADER = t(TKey.keyScheduleHeader);
    const ADD_TIME = t(TKey.actionKeyScheduleAddTimePeriod);

    if (!keyData || !schedule) {
        return null;
    }

    // TODO: We convert the key to a key model here because of legacy reasons.
    // Jira ticket: https://jira.assaabloy.net/browse/CSMBC-6763
    const keyAsModel = new KeyModel(keyData);
    const collectionType = getCollectionType(keyAsModel);

    const goBack = () => {
        const keyRoute = buildRoute(Routes.KEYS_KEY, { keyId: keyData.uuid });
        history.push(keyRoute);
    };

    const isKeyBlocked = keyAsModel && keyAsModel.isBlocked();

    async function handleSave(data: IKey) {
        if (!keyData) {
            return;
        }

        const updateSuccess = await updateKey(keyData.uuid, data);

        if (!updateSuccess) {
            showError(TKey.genericErrorSaving);
        }

        showSuccess(TKey.genericSuccessSaved);
        goBack();
        return;
    }

    return (
        <Schedule onSave={handleSave} keyData={keyData} keyId={keyData.uuid}>
            {({ pristine, values }: FormRenderProps) => (
                <Section width="100%" height="100%">
                    <Flex flexDirection="column" height="100%">
                        <SectionHeader
                            title={<H3>{HEADER}</H3>}
                            actions={
                                <>
                                    <Button mr="quarter" onClick={goBack}>
                                        {BACK}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={pristine || loading}
                                        data-testid="editor-action-save">
                                        {loading ? <Spinner data-testid="spinner" size="1rem" /> : SAVE}
                                    </Button>
                                </>
                            }
                        />
                        <SectionBody style={{ overflowY: 'auto' }}>
                            <KeyCollectionItem
                                data={keyAsModel}
                                collectionType={collectionType}
                                disabled={isKeyBlocked}
                            />
                            {isKeyBlocked === false && (
                                <Flex flexDirection="column">
                                    <ToggleSchedule />
                                    <RouteGuard when={pristine === false} />
                                    {values.enabled === true && (
                                        <>
                                            <Button
                                                variant="primary"
                                                ml="auto"
                                                mb="full"
                                                size="sm"
                                                onClick={toggleDisplayAddTime}>
                                                {ADD_TIME}
                                            </Button>
                                            <AddTimeSlot open={displayAddTime} close={toggleDisplayAddTime} />
                                            <DayList />
                                        </>
                                    )}
                                </Flex>
                            )}
                        </SectionBody>
                    </Flex>
                </Section>
            )}
        </Schedule>
    );
};

export default KeyScheduleEditor;
