import React, { useState } from 'react';
import { TKey, useTranslation } from 'locale';
import { dsStore } from 'common/services/directory_service';
import { Button, Section, SectionBody, SectionFooter, Flex, SectionHeader, H3 } from 'aa-react-ui';
import Modal from 'components/modal';
import RadioButtonRow from 'components/radio_button_row';

export enum DsUrl {
    PRODUCTION = 'https://directoryservice.assaabloy.net',
    STAGING = 'https://stageds.dcs-services.net:443',
    REF = 'https://directory.dcs-services.net'
}

interface IProps {
    visible: boolean;
    onDone?: () => void;
}

const DevOptions = (props: IProps) => {
    const { visible, onDone } = props;

    const [currentDsUrl, setCurrentDsUrl] = useState(dsStore.getDirectoryServiceUrl());
    const { t } = useTranslation();

    const onDonePress = () => {
        dsStore.setDirectoryServiceUrl(currentDsUrl);
        onDone && onDone();
    };

    const handleOnCheck = (newValue: { [x: string]: string }) => {
        const { dsUrl } = newValue;
        setCurrentDsUrl(dsUrl);
    };

    return (
        <Modal isOpen={visible}>
            <Section minWidth="34rem" data-testid="dev-option-section">
                <SectionHeader>
                    <H3>Directory service</H3>
                </SectionHeader>
                <SectionBody>
                    <RadioButtonRow
                        data-testid="ref-url-radio"
                        text="Ref"
                        name="dsUrl"
                        checked={currentDsUrl === DsUrl.REF}
                        value={DsUrl.REF}
                        onCheck={handleOnCheck}
                    />

                    <RadioButtonRow
                        data-testid="staging-url-radio"
                        text="Staging"
                        name="dsUrl"
                        checked={currentDsUrl === DsUrl.STAGING}
                        value={DsUrl.STAGING}
                        onCheck={handleOnCheck}
                    />
                    <RadioButtonRow
                        data-testid="production-url-radio"
                        text="Production"
                        name="dsUrl"
                        checked={currentDsUrl === DsUrl.PRODUCTION}
                        value={DsUrl.PRODUCTION}
                        onCheck={handleOnCheck}
                    />
                </SectionBody>
                <SectionFooter>
                    <Flex justifyContent="flex-end">
                        <Button data-testid="done-button" onClick={onDonePress}>
                            {t(TKey.actionDone)}
                        </Button>
                    </Flex>
                </SectionFooter>
            </Section>
        </Modal>
    );
};

export default DevOptions;
