import KeyModel from 'models/key';
import { KeyCollectionType } from 'domain/key/types';
import { isKeyHarmful } from 'domain/key/key_harmful';

function getKeyCollectionType(key: KeyModel) {
    if (isKeyHarmful(key)) {
        return KeyCollectionType.HARMFUL;
    }

    if (key.hasPendingJob()) {
        return KeyCollectionType.PENDING;
    }

    if (key.hasStartedTasks()) {
        return KeyCollectionType.WITH_TASKS;
    }

    return KeyCollectionType.OTHER;
}

export default getKeyCollectionType;
