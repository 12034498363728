import React, { ReactNode } from 'react';
import { H3, H5, Section, SectionHeader, SectionBody, SectionFooter, Flex, Text, Button, Spinner } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import FadeView from 'components/fade_view';

interface IProps {
    title?: ReactNode;
    subTitle?: ReactNode;
    submitText?: string;
    hideBack?: boolean;
    onSubmit?: () => void;
    disableSubmit?: boolean;
    backText?: string;
    onBack?: () => void;
    loading?: boolean;
}

const StepView: React.FunctionComponent<IProps> = props => {
    const {
        title,
        subTitle,
        children,
        onBack,
        disableSubmit,
        onSubmit,
        submitText,
        backText,
        hideBack,
        loading
    } = props;
    const { t } = useTranslation();

    const renderHeader = () => {
        if (!title) {
            return null;
        }

        return (
            <SectionHeader>
                <Flex flexDirection="column" width="100%">
                    <H3 pl="half">{title}</H3>

                    {subTitle && (
                        <H5 mt="full" pl="half" color="contrast.70">
                            {subTitle}
                        </H5>
                    )}
                </Flex>
            </SectionHeader>
        );
    };

    const renderFooter = () => {
        return (
            <SectionFooter>
                <Flex justifyContent={hideBack ? 'flex-end' : 'space-between'}>
                    {!hideBack && (
                        <Button disabled={loading} data-testid="back-button" onClick={onBack}>
                            {backText || t(TKey.actionBack)}
                        </Button>
                    )}
                    <Button
                        data-testid="submit-button"
                        onClick={onSubmit}
                        variant="primary"
                        disabled={disableSubmit || loading}>
                        <Text>{loading ? <Spinner size="1.2rem" /> : submitText || t(TKey.actionConfirm)}</Text>
                    </Button>
                </Flex>
            </SectionFooter>
        );
    };

    return (
        <Flex width="100%" alignItems="center" justifyContent="center">
            <Section width={[1, 0.5]} maxHeight="100%" style={{ overflowY: 'auto' }}>
                <FadeView>
                    {renderHeader()}
                    <SectionBody>{children}</SectionBody>
                    {renderFooter()}
                </FadeView>
            </Section>
        </Flex>
    );
};

export default StepView;
