import React from 'react';
import { Box, Flex } from 'aa-react-ui';

import KeyWidget from './key_widget';
import CylinderWidget from './cylinder_widget';

const GoDashboard = () => {
    return (
        <Flex width="100%" flexDirection="row">
            <Box width="100%" m="half">
                <KeyWidget />
            </Box>
            <Box width="100%" m="half">
                <CylinderWidget />
            </Box>
        </Flex>
    );
};

export default GoDashboard;
