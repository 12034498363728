import React from 'react';
import { Box, Alert, Text } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { useHistory, useParams } from 'react-router-dom';
import { KeyCollectionItem } from 'domain/key/components';
import { useKey } from 'domain/key/redux/hooks';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';
import { getAffectedCylindersBy } from 'domain/cylinder/cylinder_helper';
import { useCylinders } from 'domain/cylinder/redux/hooks';
import StepView from 'components/wizard/step_view';
import KeyModel from 'models/key';

const VerifySelection = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const params = useParams<{ keyId: string }>();
    const selectedKey = useKey(params.keyId);
    const cylinders = useCylinders();

    if (!selectedKey) {
        return null;
    }

    const onBack = () => {
        history.push(buildRoute(Routes.KEYS_KEY, { keyId: selectedKey.uuid }));
    };

    const onNext = () => {
        const affectedCylinders = getAffectedCylindersBy(selectedKey, cylinders);
        if (affectedCylinders.length > 0) {
            history.push(
                buildRoute(Routes.UNBLOCK_KEY_TASK_KEY, {
                    keyId: selectedKey.uuid
                })
            );
        } else {
            history.push(
                buildRoute(Routes.UNBLOCK_KEY_SUMMARY, {
                    keyId: selectedKey.uuid
                })
            );
        }
    };

    return (
        <StepView
            title={t(TKey.blockKeyUnblockHeader)}
            subTitle={t(TKey.blockKeyUnblockVerifyHeader)}
            submitText={t(TKey.actionNext)}
            onSubmit={onNext}
            onBack={onBack}>
            <Box m="quarter" mb="half">
                <Alert variant="warning" text={t(TKey.blockKeyUnblockVerifyBodyPart1)} />
            </Box>

            <KeyCollectionItem data={new KeyModel(selectedKey)} />

            <Box m="quarter" mt="half">
                <Alert
                    variant="info"
                    text={
                        <>
                            <Text mb="full">{t(TKey.blockKeyUnblockVerifyBodyPart2)}</Text>
                            <Text>{t(TKey.blockKeyUnblockVerifyBodyPart3)}</Text>
                        </>
                    }
                />
            </Box>
        </StepView>
    );
};

export default VerifySelection;
