import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, H5, Text, Box } from 'aa-react-ui';

import { IKey, KeyState } from 'models/types';
import { KEY_STATE_TRANSLATION_KEY_MAP, KEY_STATUS_TRANSLATION_KEY_MAP } from 'domain/key/constants';
import { getKeyStatus, getKeyIconColor } from 'domain/key/key_helper';

import { IconWrapper, KeyBlockedIcon, KeyIcon } from 'components/icon';

import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';

import LoukInfo from '../key_collection_item/louk_info';

interface ItemProps {
    item: KeyModel;
    parentCylinder?: CylinderModel;
    hideItemStatus?: boolean;
    onClick?: () => void;
}

const KeyCardContent: React.FunctionComponent<ItemProps> = props => {
    const { item, parentCylinder, onClick, hideItemStatus, children } = props;
    const { data } = item;

    const { t } = useTranslation();

    if (!item) {
        return null;
    }

    const blocked = data.state === KeyState.BLOCKED;

    const icon = blocked ? KeyBlockedIcon : KeyIcon;
    const iconStroke = getKeyIconColor(item);

    const buildHeaderText = (key: IKey, blocked: boolean) => {
        const { name } = key;

        const state = KEY_STATE_TRANSLATION_KEY_MAP[key.state];

        if (name && blocked) {
            return `${t(state)} (${name})`;
        }

        if (name) {
            return name;
        }

        return t(state);
    };

    const status = getKeyStatus(item)
        .filter(i => i)
        .map(j => {
            return t(KEY_STATUS_TRANSLATION_KEY_MAP[j]);
        })
        .join(' / ');

    const headerText = buildHeaderText(data, blocked);

    return (
        <>
            <IconWrapper icon={icon} stroke={iconStroke} width="3rem" />
            <Flex flexDirection="column" width="100%" onClick={onClick}>
                <H5 data-testid="key-collection-item-header">{headerText}</H5>
                <Text color="contrast.60">{item.marking}</Text>
                {!hideItemStatus && <Text color="warning.90">{status}</Text>}
                {parentCylinder && <LoukInfo cylinder={parentCylinder} keyId={data.uuid} />}
            </Flex>
            {children && <Box>{children}</Box>}
        </>
    );
};

export default KeyCardContent;
