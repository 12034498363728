import React from 'react';
import { ITemplateProps } from 'lib/aa-prompt-service';
import { Section, SectionHeader, H3, SectionFooter, Button, SectionBody, Text, Flex } from 'aa-react-ui';
import Modal from 'components/modal';

const PromptTemplate = (props: ITemplateProps) => {
    const { actions, promptApi, title, isOpen, message } = props;

    const renderActions = () => {
        return actions.map(action => {
            return (
                <Button
                    data-testid={`prompt-action-${action.text}`}
                    m="quarter"
                    key={action.text}
                    onClick={() => {
                        action.onClick(promptApi);
                    }}
                    variant={action.variant}>
                    {action.text}
                </Button>
            );
        });
    };

    return (
        <Modal isOpen={isOpen}>
            <Section>
                <SectionHeader>
                    <H3>{title}</H3>
                </SectionHeader>
                <SectionBody>
                    <Text>{message}</Text>
                </SectionBody>

                <SectionFooter>
                    <Flex justifyContent="flex-end">{renderActions()}</Flex>
                </SectionFooter>
            </Section>
        </Modal>
    );
};

export default PromptTemplate;
