import React from 'react';
import { Alert, Box } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { CylinderCollectionItem } from 'domain/cylinder/components';
import { KeyCollectionItem } from 'domain/key/components';
import { useParams, useHistory } from 'react-router-dom';
import { useCylinder, useUpdateCylinderRequest } from 'domain/cylinder/redux/hooks';
import { useKey } from 'domain/key/redux/hooks';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import { useNotification } from 'domain/notification/redux/hooks';

import CylinderModel from 'models/cylinder';
import KeyModel from 'models/key';
import CommonUtils from 'common/utils/common';
import StepView from 'components/wizard/step_view';

const SelectionSummary = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams<{ cylinderId: string; keyId: string }>();
    const selectedCylinder = useCylinder(params.cylinderId);
    const selectedKey = useKey(params.keyId);
    const { updateCylinder, loading } = useUpdateCylinderRequest();
    const { showError, showSuccess } = useNotification();

    if (!selectedCylinder) {
        return null;
    }

    const onBack = () => {
        const selectKeyRoute = buildRoute(Routes.ACCESS_LOG_REQUEST_SELECT_KEY, {
            cylinderId: selectedCylinder.uuid
        });

        history.push(selectKeyRoute);
    };

    const onSubmit = async () => {
        const cylinderToUpdate = CommonUtils.cloneDeep(selectedCylinder);
        cylinderToUpdate.auditTrailRequested = true;

        if (selectedKey) {
            cylinderToUpdate.assignedToKeyUuid = selectedKey.uuid;
        }

        const updateSuccess = await updateCylinder(cylinderToUpdate.uuid, cylinderToUpdate);
        if (updateSuccess) {
            showSuccess(TKey.genericSuccessSaved);
            const successRoute = buildRoute(Routes.ACCESS_LOG_REQUEST_SUCCESS, {
                cylinderId: selectedCylinder ? selectedCylinder.uuid : '',
                keyId: selectedKey ? selectedKey.uuid : ''
            });

            history.push(successRoute);
        } else {
            showError(TKey.genericErrorSaving);
        }
    };

    return (
        <StepView
            title={t(TKey.requestAuditTrailHeader)}
            onBack={onBack}
            submitText={t(TKey.actionConfirm)}
            onSubmit={onSubmit}
            loading={loading}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.requestAuditTrailSummaryBodyPart1)} variant="info" />
            </Box>

            <CylinderCollectionItem hideStatus dataModel={new CylinderModel(selectedCylinder)} />

            {selectedKey && (
                <>
                    <Box m="quarter" mt="half" mb="half">
                        <Alert text={t(TKey.requestAuditTrailSummaryBodyPart2)} variant="warning" />
                    </Box>

                    <KeyCollectionItem hideStatus data={new KeyModel(selectedKey)} />
                </>
            )}
        </StepView>
    );
};

export default SelectionSummary;
