import React, { useState, useCallback, createRef, useEffect } from 'react';
import useFuse from 'react-use-fuse';
import _ from 'lodash';

import { Box, Flex, Alert, InputGroup, Input, Button } from 'aa-react-ui';
import Checkbox from 'lib/aa-react-ui/checkbox';

import { TKey, useTranslation } from 'locale';

import { AuthorizationState } from 'models/types';

import { IKeyAccessInfo } from 'domain/key/types';
import Item from 'domain/cylinder/components/cylinder_collection_item/cylinder_collection_item_light';
import { getAuthorizationToggleState } from 'domain/hardware/hardware_helper';

interface CylinderListItem {
    uuid: string;
    name: string;
    marking: string;
    state: AuthorizationState;
    iconColor?: string;
    selected?: boolean;
    onClick?: () => void;
}

interface IKeyAccessCheckListProps {
    data: Array<IKeyWithCace>;
    onChange?: (newData: IKeyAccessInfo[]) => void;
}

interface IKeyWithCace extends IKeyAccessInfo {
    iconColor?: string;
}

interface AuthorizationStateMap {
    [key: string]: AuthorizationState;
}

const KeyAccessCheckList: React.FunctionComponent<IKeyAccessCheckListProps> = props => {
    const { data, onChange } = props;

    const { t } = useTranslation();

    const searchInputRef = createRef<HTMLInputElement>();

    const [cylinderStateMap, setCylinderStateMap] = useState<AuthorizationStateMap>({});

    useEffect(() => {
        if (!onChange) {
            return;
        }

        const updatedData = data.map((item: IKeyAccessInfo) => {
            const { cylinder } = item;
            const { uuid: uuidMatch } = cylinder;

            const newState = cylinderStateMap[uuidMatch];

            if (!newState) {
                return item;
            }

            return Object.assign(item, { state: newState });
        });

        onChange(updatedData);
    }, [cylinderStateMap, data, onChange]);

    const handleSelect = useCallback((uuid: string, state: AuthorizationState) => {
        const nextState = getAuthorizationToggleState(state);

        setCylinderStateMap(prevState => ({
            ...prevState,
            [uuid]: nextState
        }));
    }, []);

    const searchData: Array<CylinderListItem> = data.map(element => {
        const { cylinder, iconColor, state } = element;
        const { uuid } = cylinder;

        const draftState = cylinderStateMap[uuid];
        const computedState = draftState || state;
        const selected = !!(computedState === AuthorizationState.CURRENT || computedState === AuthorizationState.ADDED);

        const onClick = () => handleSelect(uuid, computedState);

        return Object.assign(element, {
            uuid: cylinder.uuid,
            name: cylinder.name,
            marking: cylinder.marking,
            iconColor,
            onClick,
            state: computedState,
            selected
        });
    });

    const { result, search, term, reset } = useFuse({
        data: searchData,
        options: {
            keys: ['name', 'marking']
        }
    });

    const formattedResult = result
        .filter(({ item }: { item: CylinderListItem }) => item)
        .map(({ item }: { item: CylinderListItem }) => item);

    const listData = term ? formattedResult : searchData;

    const debouncedSearch = useCallback(
        _.debounce((value: string) => {
            search(value);
        }, 500),
        []
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(e.target.value);
    };

    const handleSearchReset = () => {
        if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.value = '';
        }

        reset();
    };

    return (
        <Box>
            <InputGroup mb="full">
                <Input
                    ref={searchInputRef}
                    onChange={handleSearch}
                    placeholder={t(TKey.actionSearch)}
                    data-testid="search-input"
                />
                <Button onClick={handleSearchReset} disabled={!term} data-testid="search-reset">
                    {t(TKey.actionCancel)}
                </Button>
            </InputGroup>

            {!result.length && (
                <Box mt="full">
                    <Alert
                        title={t(TKey.actionSearch)}
                        data-testid="search-alert"
                        variant="info"
                        text={t(TKey.cylinderListEmptyAfterSearchAndFilter)}
                    />
                </Box>
            )}

            <Flex data-testid="key-access-checklist" mt="full" flexDirection="column">
                {listData.map((item: CylinderListItem) => (
                    <Item
                        key={item.uuid}
                        selected={item.selected}
                        marking={item.marking}
                        name={item.name}
                        iconColor={item.iconColor}>
                        <Checkbox checked={item.selected} onChange={item.onClick} data-testid="check-box-input" />
                    </Item>
                ))}
            </Flex>
        </Box>
    );
};

export default KeyAccessCheckList;
