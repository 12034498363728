import React from 'react';
import { Text, Box } from 'aa-react-ui';
import { StyledComponentProps } from 'styled-components';
import RadioButton from 'lib/aa-react-ui/radio_button';
import Card from 'components/card';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IRadioButtonRowProps extends StyledComponentProps<'input', any, {}, never> {
    onCheck: (value: { [x: string]: string }) => void;
    name: string;
    value: string;
    checked: boolean;
    text: string;
}

const RadioButtonRow = (props: IRadioButtonRowProps) => {
    const { onCheck, name, value, checked, text, ...rest } = props;

    return (
        <Card
            bg={checked ? 'primary.05' : 'white'}
            p="full"
            onClick={() => {
                onCheck({ [name]: value });
            }}>
            <Box width="100%">
                <Text>{text}</Text>
            </Box>
            <RadioButton
                {...rest}
                checked={checked}
                name="ds-url"
                onChange={() => {
                    onCheck({ [name]: value });
                }}
            />
        </Card>
    );
};

export default RadioButtonRow;
