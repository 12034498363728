import React, { useState, useCallback } from 'react';
import { Section, Flex, SectionHeader, H3, SectionBody, Label } from 'aa-react-ui';

import { useDirtyPrompt } from 'components/hooks';
import { KeyCollectionType, IKeyAccessInfo } from 'domain/key/types';
import { TKey, useTranslation } from 'locale';
import { KeyCollectionItem, KeyAccessCheckList } from 'domain/key/components';
import { sanitizeKeyAccess } from 'domain/key/key_helper';
import { useNotification } from 'domain/notification/redux/hooks';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import { useUpdateKeyRequest } from 'domain/key/redux/hooks';
import { useHistory } from 'react-router-dom';

import KeyModel from 'models/key';
import EditorActions from 'components/editor_actions';
import Card from 'components/card';

interface IKeyCylinderAccessEditorProps {
    keyAccessData: Array<IKeyAccessInfo>;
    keyData: { key?: KeyModel; collectionType?: KeyCollectionType };
}

const KeyAccessEditor: React.FunctionComponent<IKeyCylinderAccessEditorProps> = props => {
    const { keyData, keyAccessData } = props;
    const { key, collectionType } = keyData;

    const [keyAccessDataState, setKeyAccessDataState] = useState<Array<IKeyAccessInfo>>([]);

    const isDirty = !!Object.keys(keyAccessDataState).length;

    const history = useHistory();
    const { t } = useTranslation();
    const { promptIfDirty } = useDirtyPrompt(isDirty);
    const { updateKey, loading } = useUpdateKeyRequest();
    const { showError, showSuccess } = useNotification();

    const onChange = useCallback((cylinderAccess: IKeyAccessInfo[]) => {
        setKeyAccessDataState(cylinderAccess);
    }, []);

    if (!key) {
        return null;
    }

    const goBack = () => {
        const keyDetailsRoute = buildRoute(Routes.KEYS_KEY, { keyId: key.uuid });
        history.push(keyDetailsRoute);
    };

    const onSave = async () => {
        const keyToUpdate = key.toDataTransferObject();
        keyToUpdate.authorizations = sanitizeKeyAccess(keyAccessDataState, keyToUpdate.authorizations);
        const updateSuccess = await updateKey(keyToUpdate.uuid, keyToUpdate);
        if (updateSuccess) {
            showSuccess(TKey.genericSuccessSaved);
            goBack();
            return;
        }

        showError(TKey.genericErrorSaving);
    };

    const onBackClick = async () => {
        await promptIfDirty();
        goBack();
    };

    return (
        <Section width="100%">
            <Flex flexDirection="column" height="100%">
                <SectionHeader
                    title={<H3>{t(TKey.genericEditAccess)}</H3>}
                    actions={
                        <EditorActions loading={loading} onSave={onSave} onBack={onBackClick} editorDirty={isDirty} />
                    }
                />

                <SectionBody height="100%" overflow="hidden">
                    <Flex height="inherit" flexDirection="column" width="100%">
                        <KeyCollectionItem data={key} collectionType={collectionType} />

                        <Card height="100%" style={{ overflowY: 'auto' }}>
                            <Flex flexDirection="column" width="100%">
                                <Label ml="half">{t(TKey.keyAccessBody)}</Label>

                                <KeyAccessCheckList data={keyAccessData} onChange={onChange} />
                            </Flex>
                        </Card>
                    </Flex>
                </SectionBody>
            </Flex>
        </Section>
    );
};

export default KeyAccessEditor;
