import { dsStore } from 'common/services/directory_service';

export enum EventName {
    SESSION_TIMEOUT = 'SESSION_TIMEOUT',
    KEY_UPDATED = 'KEY_UPDATED'
}

const NormalCloseEventCode = 1000;

class SocketServiceFactory {
    currentSystem: { aaCode: number; mksName: string } | undefined;
    eventCallBackMap: { [x: string]: Array<() => void> } = {};
    socket: WebSocket | undefined;
    reconnectConfig = {
        delayInMillis: 5000,
        maxCollisions: 5,
        retry: 0,
        enabled: true
    };
    reconnectTimeoutId: number | undefined;
    reconnectDelay = 0;

    onMessage(event: MessageEvent): void {
        const { data } = event;

        if (!data) {
            return;
        }

        const eventData = JSON.parse(data);
        const { type } = eventData;

        if (!type) {
            return;
        }

        const callBacks = this.eventCallBackMap[type];

        if (callBacks) {
            try {
                callBacks.forEach(fn => {
                    fn();
                });
            } catch (e) {
                console.log(e);
            }
        }
    }

    onClose(event: CloseEvent) {
        this.eventCallBackMap = {};

        if (event.code !== NormalCloseEventCode && this.reconnectConfig.enabled) {
            this.reconnectConfig.retry += 1;
            this.reconnectDelay = this.nextReconnectDelayInMillis();

            this.reconnectTimeoutId = setTimeout(() => {
                console.log('setTimeout callback');
                if (this.currentSystem) {
                    this.open(this.currentSystem.aaCode, this.currentSystem.mksName);
                }
            }, this.reconnectDelay);
        }
    }

    nextReconnectDelayInMillis() {
        const maxRetry = Math.min(this.reconnectConfig.retry, this.reconnectConfig.maxCollisions);
        const baseValue = 2;
        const slots = Math.pow(baseValue, maxRetry) - 1;
        return this.reconnectConfig.delayInMillis * slots;
    }

    onOpen() {
        this.reconnectConfig.enabled = true;
        this.reconnectConfig.retry = 0;
    }

    open(aaCode: number, mksName: string) {
        this.currentSystem = { aaCode, mksName };
        const serviceUrls = dsStore.getServiceUrls();

        if ((!this.socket || this.socket.readyState === WebSocket.CLOSED) && serviceUrls) {
            try {
                const socketUrl = `wss://${
                    serviceUrls.cwmUrl.split('//')[1]
                }/websockets/cliqgo/systems/${aaCode}/${mksName}`;

                this.socket = new WebSocket(socketUrl);
                this.socket.onopen = this.onOpen.bind(this);
                this.socket.onclose = this.onClose.bind(this);
                this.socket.onmessage = this.onMessage.bind(this);
            } catch (e) {
                console.error(e);
            }
        }
    }

    close(code = NormalCloseEventCode, reason = '') {
        if (this.socket) {
            this.reconnectConfig.enabled = false;
            clearTimeout(this.reconnectTimeoutId);

            try {
                this.socket.close(code, reason);
            } catch (error) {
                console.log(error);
            }
        }
    }

    addListener(eventName: EventName, callback: () => void) {
        if (!this.eventCallBackMap[eventName]) {
            this.eventCallBackMap[eventName] = [];
        }

        this.eventCallBackMap[eventName].push(callback);
    }

    removeListener(eventName: EventName, callback: () => void) {
        if (this.eventCallBackMap[eventName]) {
            this.eventCallBackMap[eventName] = this.eventCallBackMap[eventName].filter(item => item !== callback);
        }
    }
}

export default new SocketServiceFactory();
