import { initReactI18next, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import I18nextIcu from 'i18next-icu';
import httpClient from 'common/services/http_client';
import SupportedLocale from './supported_locale';

const icu = new I18nextIcu();

export const loadLocale = async (locale: string) => {
    const url = `/translation/${locale}.json`;

    try {
        const results = await httpClient.get(url);
        return i18n.addResourceBundle(locale, 'translation', results.data, true, true);
    } catch (e) {
        console.log(e);
    }

    return;
};

export const initilizeLocaleSettings = async (defaultLocale: string): Promise<void> => {
    await i18n
        .use(icu)
        .use(initReactI18next)
        .init({
            resources: {},
            lng: defaultLocale,
            fallbackLng: SupportedLocale.enGB,
            interpolation: {
                escapeValue: false
            }
        });

    return loadLocale(defaultLocale);
};

export const changeLocale = async (locale: string) => {
    await loadLocale(locale);
    return i18n.changeLanguage(locale);
};

export { useTranslation };
