import React from 'react';
import { Toast, Box } from 'aa-react-ui';
import { useTranslation } from 'locale';
import { useNotification } from 'domain/notification/redux/hooks';

const TOAST_LENGTH_IN_MILLIS = 3000;

const toastTimerStore: { [toastId: string]: { timerId: number } } = {};

const removeToastTimer = (toastId: number) => {
    const toastInfo = toastTimerStore[toastId];
    if (toastInfo) {
        clearTimeout(toastInfo.timerId);
        delete toastTimerStore[toastId];
    }
};

const scheduleToast = (
    toastId: number,
    onDone?: (toastId: number) => void,
    toastLengthInMillis: number = TOAST_LENGTH_IN_MILLIS
) => {
    const toastInfo = toastTimerStore[toastId];
    if (!toastInfo) {
        const timerId = setTimeout(() => {
            onDone && onDone(toastId);
            removeToastTimer(toastId);
        }, toastLengthInMillis);

        toastTimerStore[toastId] = { timerId };
    }
};

const Notifications = () => {
    const { t } = useTranslation();
    const { hideNotification, notifications } = useNotification();

    return (
        <Box position="fixed" top={0} right={0} zIndex={19000} p="full">
            {notifications.map(item => {
                const { id, message, type } = item;
                scheduleToast(id, hideNotification);
                return <Toast key={id} title="" text={t(message)} variant={type} />;
            })}
        </Box>
    );
};

export default Notifications;
