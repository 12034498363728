import React from 'react';
import { Card, Flex, H5, Button, Box, Spinner } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { CylinderCollectionItem } from 'domain/cylinder/components';
import { IAssignedTask } from 'domain/task/types';
import { KeyCollectionItem } from 'domain/key/components';
import { DownRightArrow } from 'components/icon';
import { ICylinder } from 'models/types';
import { isCylinderUnassignable } from 'domain/cylinder/cylinder_helper';
import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';

interface IAssignedTaskListProps {
    tasks: Array<IAssignedTask>;
    onUnassign: (cylinders: Array<ICylinder>) => void;
    isLoading?: boolean;
}

const AssignedTaskList: React.FunctionComponent<IAssignedTaskListProps> = props => {
    const { tasks = [], onUnassign, isLoading } = props;
    const { t } = useTranslation();

    if (tasks.length === 0) {
        return null;
    }

    const isKeyUnassignableFor = (assingedCylinders: Array<ICylinder>) =>
        assingedCylinders.some(item => isCylinderUnassignable(item));

    const renderCylinders = (cylinders: Array<ICylinder>) =>
        cylinders.map(cylinderItem => {
            return (
                <Flex width="100%" pl="full" key={cylinderItem.uuid} test-dataid={`cylinder-${cylinderItem.uuid}`}>
                    <DownRightArrow height="2rem" width="2rem" fill="black" stroke="white" />
                    <Box width="100%">
                        <CylinderCollectionItem showTaskInfo hideStatus dataModel={new CylinderModel(cylinderItem)}>
                            {isCylinderUnassignable(cylinderItem) && (
                                <Box>
                                    <Button
                                        data-testid="unassign-button"
                                        disabled={isLoading}
                                        onClick={() => {
                                            onUnassign([cylinderItem]);
                                        }}
                                        size="sm">
                                        {t(TKey.actionTaskUnassign)}
                                    </Button>
                                </Box>
                            )}
                        </CylinderCollectionItem>
                    </Box>
                </Flex>
            );
        });

    return (
        <Card p="full" mt="half" mb="full">
            <Flex justifyContent="space-between">
                <H5 color="contrast.90" p="half">
                    {t(TKey.taskListAssignedHeader)}
                </H5>
                {isLoading && <Spinner size="16px" />}
            </Flex>

            <Flex m="half" flexDirection="column">
                {tasks.map(item => {
                    if (!item.key) {
                        return null;
                    }

                    return (
                        <Box key={item.key.uuid} data-testid={`task-row-${item.key.uuid}`}>
                            <KeyCollectionItem hideStatus data={new KeyModel(item.key)}>
                                {isKeyUnassignableFor(item.cylinders) && (
                                    <Box>
                                        <Button
                                            data-testid="unassign-all-button"
                                            disabled={isLoading}
                                            onClick={() => {
                                                onUnassign(item.cylinders);
                                            }}
                                            size="sm">
                                            {t(TKey.actionTaskUnassignAll)}
                                        </Button>
                                    </Box>
                                )}
                            </KeyCollectionItem>
                            {renderCylinders(item.cylinders)}
                        </Box>
                    );
                })}
            </Flex>
        </Card>
    );
};

export default AssignedTaskList;
