import { Dispatch } from 'redux';
import httpClient, { HTTP_STATUS_CODE } from 'common/services/http_client';
import { cliqUrlBuilder } from 'common/services/directory_service';
import { LOAD_HARDWARE } from './action_types';
import { createRequestActionCreators } from 'app_redux/factory';
import { TKey } from 'locale';

import { IKey, ICylinder } from 'models/types';
import { IHardwareDataState } from './types';
import { normalizeHardwareData } from './data_helper';

const loadHardwareActionCreators = createRequestActionCreators<IHardwareDataState>(LOAD_HARDWARE);

export const loadHardwareData = () => {
    const { request, success, failure } = loadHardwareActionCreators;

    return async (dispatch: Dispatch) => {
        dispatch(request());

        try {
            const cylinderResponse: {
                status: number;
                data: Array<ICylinder>;
            } = await httpClient.get(cliqUrlBuilder.cylinders());

            const keyResponse: { status: number; data: Array<IKey> } = await httpClient.get(cliqUrlBuilder.keys());

            const { status: keyStatus } = keyResponse;
            const { status: cylinderStatus } = cylinderResponse;

            if (keyStatus === HTTP_STATUS_CODE.OK && cylinderStatus === HTTP_STATUS_CODE.OK) {
                const normalizedData = normalizeHardwareData(keyResponse.data, cylinderResponse.data);

                dispatch(
                    success({
                        keys: normalizedData.keys,
                        cylinders: normalizedData.cylinders
                    })
                );
                return true;
            }

            dispatch(failure(TKey.genericErrorUnexpected));
            return true;
        } catch (e) {
            dispatch(failure(TKey.genericErrorUnexpected));
            return false;
        }
    };
};
