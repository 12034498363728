import React, { useState } from 'react';
import { Box, Alert } from 'aa-react-ui';
import { useFilteredKeys } from 'domain/key/redux/hooks';
import { KeyRadioList } from 'domain/key/components';
import { TKey, useTranslation } from 'locale';
import { useHistory } from 'react-router-dom';
import { Routes } from 'router';
import { KeyState } from 'models/types';
import { buildRoute } from 'router/helper';
import StepView from 'components/wizard/step_view';

const SelectKey = () => {
    const handedOutKeys = useFilteredKeys(KeyState.HANDED_OUT);
    const history = useHistory();
    const { t } = useTranslation();

    const [selectedKeyId, setSelectedKeyId] = useState('');

    const onNext = () => {
        const selectSettingRoute = buildRoute(Routes.HAND_IN_SETTINGS, { keyId: selectedKeyId });
        history.push(selectSettingRoute);
    };

    const onBack = () => {
        history.push(Routes.DASHBOARD);
    };

    return (
        <StepView
            title={t(TKey.handInHeader)}
            subTitle={t(TKey.genericAvailable)}
            onBack={onBack}
            disableSubmit={!selectedKeyId}
            submitText={t(TKey.actionNext)}
            onSubmit={onNext}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.handInSelectKeyBody)} variant="warning" />
            </Box>

            <KeyRadioList
                items={handedOutKeys}
                onSelect={key => {
                    setSelectedKeyId(key.uuid);
                }}
            />
        </StepView>
    );
};

export default SelectKey;
