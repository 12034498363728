import React, { useState, useCallback } from 'react';
import { SectionHeader, Section, SectionBody, H3, Flex, Alert } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { CylinderCollectionType, ICylinderAccessInfo } from 'domain/cylinder/types';
import { useDirtyPrompt } from 'components/hooks';
import { CylinderCollectionItem } from 'domain/cylinder/components';
import { sanitizeCylinderAccessInfo } from 'domain/cylinder/cylinder_helper';
import { useUpdateKeyRequest } from 'domain/key/redux/hooks';
import { useHistory } from 'react-router-dom';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';
import { useNotification } from 'domain/notification/redux/hooks';

import { AuthorizationState } from 'models/types';

import Card from 'components/card';
import CylinderModel from 'models/cylinder';
import EditorActions from 'components/editor_actions';
import AccessList from './access_list';

interface ICylinderKeyAccessEditorProps {
    cylinderAccessData: Array<ICylinderAccessInfo>;
    cylinderData: { cylinder?: CylinderModel; collectionType?: CylinderCollectionType };
}

interface AccessData {
    [uuid: string]: AuthorizationState;
}

const CylinderKeyAccessEditor: React.FunctionComponent<ICylinderKeyAccessEditorProps> = props => {
    const { cylinderData, cylinderAccessData } = props;
    const { cylinder, collectionType } = cylinderData;

    const history = useHistory();
    const { updateMultipleKeys, loading } = useUpdateKeyRequest();
    const { showError, showSuccess } = useNotification();

    const { t } = useTranslation();

    const [accessData, setAccessData] = useState<ICylinderAccessInfo[]>(cylinderAccessData);

    const isDirty = !!Object.keys(accessData).length;
    const { promptIfDirty } = useDirtyPrompt(isDirty);

    const handleSelect = useCallback((uuid: string, newState: AuthorizationState) => {
        setAccessData(accessData =>
            accessData.map(access => {
                const keyUUID = access.key.uuid;
                if (keyUUID !== uuid) {
                    return access;
                }

                return {
                    ...access,
                    state: newState
                };
            })
        );
    }, []);

    if (!cylinder) {
        return null;
    }

    const goBack = () => {
        history.push(buildRoute(Routes.CYLINDERS_CYLINDER, { cylinderId: cylinder.uuid }));
    };

    const onBackClick = async () => {
        await promptIfDirty();
        goBack();
    };

    const handleSave = async () => {
        const sanitizedLoalData = accessData.map((data, index) =>
            sanitizeCylinderAccessInfo(data, cylinderAccessData[index])
        );

        const keysToUpdate = sanitizedLoalData.map(data => data.key);

        const updateSuccess = await updateMultipleKeys(keysToUpdate);

        if (updateSuccess) {
            showSuccess(TKey.cylinderAccessSuccess);
            goBack();
        } else {
            showError(TKey.cylinderAccessError);
        }
    };

    const renderActions = () => (
        <EditorActions loading={loading} onSave={handleSave} onBack={onBackClick} editorDirty={isDirty} />
    );

    return (
        <Section width="100%">
            <Flex flexDirection="column" height="100%">
                <SectionHeader title={<H3>{t(TKey.genericCylinderAccess)}</H3>} actions={renderActions()} />
                <SectionBody style={{ overflowY: 'auto' }}>
                    <Flex height="inherit" flexDirection="column" width="100%">
                        <CylinderCollectionItem dataModel={cylinder} collectionType={collectionType} />
                        <Card>
                            <Flex flexDirection="column" width="100%">
                                <Alert text={t(TKey.cylinderAccessBody)} variant="info" />
                                <AccessList cylinder={cylinder} listData={cylinderAccessData} onChange={handleSelect} />
                            </Flex>
                        </Card>
                    </Flex>
                </SectionBody>
            </Flex>
        </Section>
    );
};

export default CylinderKeyAccessEditor;
