import React, { useState, useEffect } from 'react';
import { SectionHeader, Section, SectionFooter, Box, Text, H3, Flex } from 'aa-react-ui';
import { KeyIcon } from 'components/icon';
import { TKey, useTranslation } from 'locale';
import { Routes } from 'router';
import { useFilteredKeys, useKeys } from 'domain/key/redux/hooks';
import { KeyState, IKey } from 'models/types';
import { getPendingKeys } from 'domain/key/key_helper';
import { useHardwareData } from 'domain/hardware/redux/hooks';

import LinkButton from 'components/link_button';
import WidgetLoader from '../widget_loader';

const KeyWidget = () => {
    const allKeys = useKeys();
    const handedOutKeys = useFilteredKeys(KeyState.HANDED_OUT);
    const inStockKeys = useFilteredKeys(KeyState.IN_STOCK);
    const { loading } = useHardwareData();

    const [pendingKeys, setPendingKeys] = useState<Array<IKey>>([]);

    useEffect(() => {
        setPendingKeys(getPendingKeys(allKeys));
    }, [allKeys, setPendingKeys]);

    const { t } = useTranslation();

    if (loading) {
        return <WidgetLoader />;
    }

    const headerText = `${allKeys.length} ${t(TKey.genericKeys)}`;
    const subHeaderText = pendingKeys.length ? t(TKey.genericNumNeedUpdating, { count: pendingKeys.length }) : '';

    return (
        <Section width="100%">
            <SectionHeader
                actions={
                    <LinkButton variant="primary" to={Routes.KEYS}>
                        <Text>{t(TKey.actionViewAll)}</Text>
                    </LinkButton>
                }>
                <KeyIcon height="4rem" width="4rem" stroke="black" strokeWidth="3px" />
                <Box ml="half">
                    <H3>{headerText}</H3>
                    <Text color="warning.80" mt="half">
                        {subHeaderText}
                    </Text>
                </Box>
            </SectionHeader>

            <SectionFooter justifyContent="flex-end">
                <Flex flexDirection="row" justifyContent="flex-end">
                    <LinkButton disabled={inStockKeys.length === 0} to={Routes.HAND_OUT} m="quarter">
                        <Text>{t(TKey.actionKeyHandOut)}</Text>
                    </LinkButton>

                    <LinkButton disabled={handedOutKeys.length === 0} to={Routes.HAND_IN} m="quarter">
                        <Text>{t(TKey.actionKeyHandIn)}</Text>
                    </LinkButton>

                    <LinkButton to={Routes.BLOCK_KEY} m="quarter">
                        <Text>{t(TKey.actionKeyBlock)}</Text>
                    </LinkButton>
                </Flex>
            </SectionFooter>
        </Section>
    );
};

export default KeyWidget;
