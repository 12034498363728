import CylinderModel from 'models/cylinder';
import { AuthorizationState, IKey } from 'models/types';

export interface ICylinderCollectionMap {
    atRisk: ICylinderCollection;
    pendingUpdate: ICylinderCollection;
    upToDate: ICylinderCollection;
}

export interface IKeyAccessCylinderCollectionMap {
    atRisk: ICylinderCollection;
    toBeAdded: ICylinderCollection;
    toBeRemoved: ICylinderCollection;
    upToDate: ICylinderCollection;
    blockedIn: ICylinderCollection;
}

export enum CylinderFilter {
    ALL = 'ALL',
    IN_STOCK = 'IN_STOCK',
    INSTALLED = 'INSTALLED'
}

export enum CylinderCollectionType {
    AT_RISK = 'AT_RISK',
    PENDING_UPDATE = 'PENDING_UPDATE',
    TO_BE_ADDED = 'TO_BE_ADDED',
    TO_BE_REMOVED = 'TO_BE_REMOVED',
    OTHER = 'OTHER',
    BLOCKED_IN = 'BLOCKED_IN',
    UNKNOWN = 'UNKNOWN'
}

export enum CylinderStateInt {
    INSTALLED = 1,
    IN_STOCK = 2,
    UNKNOWN = 100
}

export interface ICylinderCollection {
    type: CylinderCollectionType;
    items: Array<CylinderModel>;
}

export enum CylinderStatus {
    PENDING_ACCESS = 'PENDING_ACCESS',
    PENDING_ACCESS_LOG = 'PENDING_ACCESS_LOG'
}

export enum AuditTrailType {
    NORMAL = 'NORMAL'
}
export enum AuditTrailEvent {
    OPEN = 'OPEN'
}
export enum AuditTrailResult {
    FAILURE = 'FAILURE'
}

export interface IAuditTrail {
    date: string;
    type: AuditTrailType;
    cylinderUuid: string;
    keyUuid: string;
    event: AuditTrailEvent;
    result: AuditTrailResult;
}

export interface ICylinderAccessInfo {
    state: AuthorizationState;
    cylinderId: string;
    key: IKey;
}
