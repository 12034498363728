import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { H3, Flex, Label, InputGroup, Button, Section, SectionHeader, SectionBody, SectionFooter } from 'aa-react-ui';

import { FormattedInterval, NewIntervalsInterface } from 'domain/schedule/types';
import { TKey, useTranslation } from 'locale';
import ModalWrapper from 'components/modal';

import { getTimeData, getInitialValues } from './utils';

import SelectValue from './select';
import SelectDays from './days';

const NUMBER_OF_HOURS_PER_DAY = 24;
const NUMBER_OF_MINUTES_PER_DAY = 59;
const MINUTES_STEP = 5;

export interface TimeSlotEditProps {
    open: boolean;
    onSave: (value: NewIntervalsInterface) => void;
    onCancel?: () => void;
    initialValues?: FormattedInterval;
}

function TimeSlotForm(props: TimeSlotEditProps) {
    const { initialValues, onSave, onCancel, open } = props;

    const { t } = useTranslation();

    const hoursInDropdown = getTimeData(NUMBER_OF_HOURS_PER_DAY);
    const minutesInDropdown = getTimeData(NUMBER_OF_MINUTES_PER_DAY, MINUTES_STEP);

    const isEditingExistingTimeslot = initialValues && initialValues.startHour ? true : false;

    // Translation keys
    const EDIT_HEADER = t(TKey.keyScheduleEditHeader);
    const ADD_HEADER = t(TKey.keyScheduleAddHeader);
    const SUBMIT = t(TKey.actionDone);
    const CANCEL = t(TKey.actionCancel);
    const FROM = t(TKey.genericFrom);
    const TO = t(TKey.genericTo);

    // Display different header depending on if adding or editing.
    const HEADER = isEditingExistingTimeslot ? EDIT_HEADER : ADD_HEADER;

    // Set some sane defaults for form, merge in provided initial values.
    const defaultInitialValues = getInitialValues(initialValues);

    return (
        <ModalWrapper isOpen={open}>
            <FinalForm onSubmit={onSave} initialValues={defaultInitialValues}>
                {({ handleSubmit, pristine }) => (
                    <Section>
                        <SectionHeader>
                            <H3>{HEADER}</H3>
                        </SectionHeader>

                        <SectionBody>
                            {isEditingExistingTimeslot === false && (
                                <Flex flexDirection="row" alignItems="center" justifyContent="space-between" mb="full">
                                    <SelectDays />
                                </Flex>
                            )}
                            <Flex flexDirection="row" alignItems="center" justifyContent="space-between" mb="full">
                                <Label mb={0}>{FROM}</Label>
                                <InputGroup>
                                    <SelectValue
                                        name="startHour"
                                        data={hoursInDropdown}
                                        data-testid="timeslot-form-startHour"
                                    />
                                    <SelectValue
                                        name="startMinutes"
                                        data={minutesInDropdown}
                                        data-testid="timeslot-form-startMinutes"
                                    />
                                </InputGroup>
                            </Flex>

                            <Flex flexDirection="row" alignItems="center" justifyContent="space-between" mb="full">
                                <Label mb={0}>{TO}</Label>
                                <InputGroup>
                                    <SelectValue
                                        name="endHour"
                                        data={hoursInDropdown}
                                        data-testid="timeslot-form-endHour"
                                    />
                                    <SelectValue
                                        name="endMinutes"
                                        data={minutesInDropdown}
                                        data-testid="timeslot-form-endMinutes"
                                    />
                                </InputGroup>
                            </Flex>
                        </SectionBody>
                        <SectionFooter>
                            <Button
                                type="submit"
                                variant="primary"
                                onClick={handleSubmit}
                                disabled={pristine}
                                data-testid="timeslot-form-submit">
                                {SUBMIT}
                            </Button>
                            {onCancel && (
                                <Button
                                    variant="secondary"
                                    ml="half"
                                    onClick={onCancel}
                                    data-testid="timeslot-form-cancel">
                                    {CANCEL}
                                </Button>
                            )}
                        </SectionFooter>
                    </Section>
                )}
            </FinalForm>
        </ModalWrapper>
    );
}

export default TimeSlotForm;
