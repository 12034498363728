import React, { useState, useMemo } from 'react';
import { KeyCollectionItem } from 'domain/key/components';
import KeyModel from 'models/key';
import { IKey } from 'models/types';
import Checkbox from 'lib/aa-react-ui/checkbox';

interface IKeyCheckListProps {
    items: Array<IKey>;
    initiallySelected: Array<IKey>;
    hideStatus?: boolean;
    onChange: (selectedKeys: Array<IKey>) => void;
}

interface ISelectedItems {
    [x: string]: {
        checked: boolean;
        key: IKey;
    };
}

const KeyCheckList: React.FunctionComponent<IKeyCheckListProps> = props => {
    const { items, onChange, hideStatus, initiallySelected = [] } = props;

    const defaultSelected = useMemo(() => {
        return initiallySelected.reduce((result: ISelectedItems, item) => {
            result[item.uuid] = {
                checked: true,
                key: item
            };
            return result;
        }, {});
    }, [initiallySelected]);

    const [selected, setSelected] = useState<ISelectedItems>(defaultSelected);

    const getSelectedKeys = (newSelected: ISelectedItems) => {
        return Object.values(newSelected).reduce((result: Array<IKey>, item) => {
            if (item.checked) {
                result.push(item.key);
            }
            return result;
        }, []);
    };

    const handleCheckChange = (checked: boolean, checkedKey: IKey) => {
        const newSelectedMap = {
            ...selected,
            ...{
                [checkedKey.uuid]: {
                    checked,
                    key: checkedKey
                }
            }
        };

        setSelected(newSelectedMap);

        onChange && onChange(getSelectedKeys(newSelectedMap));
    };

    return (
        <>
            {items.map(item => {
                const isSelected = !!(selected[item.uuid] && selected[item.uuid].checked);

                return (
                    <KeyCollectionItem
                        hideStatus={hideStatus}
                        key={item.uuid}
                        data={new KeyModel(item)}
                        onClick={() => {
                            handleCheckChange(!isSelected, item);
                        }}
                        selected={isSelected}>
                        <Checkbox
                            data-testid="check-box-input"
                            checked={isSelected}
                            name="keys"
                            onChange={() => {
                                handleCheckChange(!isSelected, item);
                            }}
                        />
                    </KeyCollectionItem>
                );
            })}
        </>
    );
};

export default KeyCheckList;
