import React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';

import { Flex, Text } from 'aa-react-ui';
import Checkbox from 'lib/aa-react-ui/checkbox';

import { WEEK_DAYS, DAYS_ENUMS } from 'common/constants';
import { useTranslation } from 'locale';

interface DaySelectInterface {
    value: string;
    name: string;
}

const FlexLabel = styled.label`
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    align-items: center;
    cursor: pointer;

    border: 1px solid ${p => p.theme.colors.contrast['20']};
    background: ${p => p.theme.colors.contrast['05']};

    padding: ${p => p.theme.space.half};

    &:last-child {
        margin-right: 0;
    }
`;

function DaySelection() {
    const { t } = useTranslation();

    const values = DAYS_ENUMS.map((value: string, index: number) => ({
        value,
        name: WEEK_DAYS[index]
    }));

    return (
        <Flex mb="full">
            {values.map((day: DaySelectInterface) => (
                <Field name="days" key={day.value} value={day.value} type="checkbox">
                    {({ input }) => (
                        <FlexLabel>
                            <Checkbox {...input} />
                            <Text ml="0.25rem">{t(day.name)}</Text>
                        </FlexLabel>
                    )}
                </Field>
            ))}
        </Flex>
    );
}

export default DaySelection;
