import React, { useState } from 'react';
import { Flex } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { HandInOption } from 'domain/key/types';
import RadioButtonRow from 'components/radio_button_row';

interface IProps {
    defaultValue: HandInOption;
    onSelect?: (newValue: HandInOption) => void;
}

const KeyHandInOptions = (props: IProps) => {
    const { onSelect, defaultValue } = props;
    const { t } = useTranslation();

    const [selectedOption, setSelecteOption] = useState(defaultValue);

    const handleChange = ({ handInOption }: { [x: string]: string }) => {
        setSelecteOption(handInOption as HandInOption);
        onSelect && onSelect(handInOption as HandInOption);
    };

    return (
        <Flex flexDirection="column">
            <RadioButtonRow
                data-testid="keep-settings-radio"
                onCheck={handleChange}
                text={t(TKey.actionHandInOptionsKeepSettings)}
                checked={selectedOption === HandInOption.KEEP_SETTINGS}
                value={HandInOption.KEEP_SETTINGS}
                name="handInOption"
            />

            <RadioButtonRow
                data-testid="reset-settings-radio"
                onCheck={handleChange}
                text={t(TKey.actionHandInOptionsReset)}
                checked={selectedOption === HandInOption.RESET_SETTINGS}
                value={HandInOption.RESET_SETTINGS}
                name="handInOption"
            />
        </Flex>
    );
};

export default KeyHandInOptions;
