import React, { useState, useEffect } from 'react';
import { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';

import { useTranslation, TKey } from 'locale';

import SimpleModal from 'components/simpleModal';

interface Props {
    when: boolean;
}

// Used to stop a route transition and display a prompt modal.
function RouteLeavingGuard(props: Props) {
    const { when } = props;
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [lastLocation, setLastLocation] = useState<string | null>(null);
    const { t } = useTranslation();

    const TITLE = t(TKey.actionConfirm);
    const MESSAGE = t(TKey.actionConfirmOnExit);
    const CONFIRM = t(TKey.actionConfirm);
    const ABORT = t(TKey.actionCancel);

    const handleBlock = (nextLocation: Location) => {
        if (confirmed) {
            return true; // true will not block transition
        }

        const { pathname } = nextLocation;
        setLastLocation(pathname);
        setVisible(true);

        return false; // false will block transition.
    };

    const onConfirm = () => {
        setConfirmed(true);
        setVisible(false);
    };

    const onCancel = () => {
        setConfirmed(false);
        setVisible(false);
    };

    useEffect(() => {
        if (confirmed && lastLocation) {
            history.push(lastLocation);
        }
    }, [when, confirmed, lastLocation, history]);

    return (
        <>
            <Prompt when={when} message={handleBlock} />

            <SimpleModal
                title={TITLE}
                text={MESSAGE}
                primaryAction={onCancel}
                primaryActionText={ABORT}
                secondaryAction={onConfirm}
                secondaryActionText={CONFIRM}
                display={visible}
                data-testid="route-guard"
            />
        </>
    );
}
export default RouteLeavingGuard;
