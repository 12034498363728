import { INotificationOptions } from './types';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './action_type';

export const notificationFactory = () => {
    let notificationCounter = 0;

    return (options: INotificationOptions) => {
        notificationCounter++;

        return {
            id: notificationCounter,
            ...options
        };
    };
};

const createNotification = notificationFactory();

export const createNotificationActionCreators = () => {
    return {
        addNotification: (options: INotificationOptions) => {
            return {
                type: ADD_NOTIFICATION,
                notification: createNotification(options)
            };
        },
        removeNotification: (id: number) => {
            return {
                type: REMOVE_NOTIFICATION,
                id
            };
        }
    };
};
