enum TKey {
    empty = '',
    actionActivate = 'action.activate',
    actionActivateSystem = 'action.activateSystem',
    actionAdd = 'action.add',
    actionBack = 'action.back',
    actionCancel = 'action.cancel',
    actionConfirm = 'action.confirm',
    actionConfirmOnExit = 'action.confirmOnExit',
    actionCookieAccept = 'action.cookie.accept',
    actionCylinderAccessShowOnlySelected = 'action.cylinder.access.showOnlySelected',
    actionCylinderInstall = 'action.cylinder.install',
    actionCylinderUninstall = 'action.cylinder.uninstall',
    actionDelete = 'action.delete',
    actionDeselectAll = 'action.deselectAll',
    actionDone = 'action.done',
    actionEdit = 'action.edit',
    actionEnrolmentLicenseAccept = 'action.enrolment.license.accept',
    actionEnrolmentToLogin = 'action.enrolment.toLogin',
    actionHandInOptionsKeepSettings = 'action.handIn.options.keepSettings',
    actionHandInOptionsReset = 'action.handIn.options.reset',
    actionKeyBlock = 'action.key.block',
    actionKeyCopy = 'action.key.copy',
    actionKeyHandIn = 'action.key.handIn',
    actionKeyHandOut = 'action.key.handOut',
    actionKeyScheduleAddTimePeriod = 'action.key.schedule.addTimePeriod',
    actionKeyScheduleClear = 'action.key.schedule.clear',
    actionKeyScheduleEnable = 'action.key.schedule.enable',
    actionKeyScheduleShowCurrent = 'action.key.schedule.showCurrent',
    actionKeyScheduleShowNew = 'action.key.schedule.showNew',
    actionKeyUnblock = 'action.key.unblock',
    actionLogin = 'action.login',
    actionLogout = 'action.logout',
    actionNext = 'action.next',
    actionPrevious = 'action.previous',
    actionReactivate = 'action.reactivate',
    actionSave = 'action.save',
    actionSearch = 'action.search',
    actionSelectAll = 'action.selectAll',
    actionSkip = 'action.skip',
    actionStartOver = 'action.startOver',
    actionTaskAssignSelection = 'action.task.assignSelection',
    actionTaskUnassign = 'action.task.unassign',
    actionTaskUnassignAll = 'action.task.unassignAll',
    actionUpdate = 'action.update',
    actionView = 'action.view',
    actionViewAll = 'action.viewAll',
    activationCliqConnectPcInstructionsText = 'activation.cliqConnectPcInstructionsText',
    activationCliqConnectPcInstructionsTitle = 'activation.cliqConnectPcInstructionsTitle',
    blockKeyCompleteTasksWarning = 'blockKey.completeTasksWarning',
    blockKeyError = 'blockKey.error',
    blockKeyHeader = 'blockKey.header',
    blockKeySelectKeyBody = 'blockKey.selectKey.body',
    blockKeySelectKeyHeader = 'blockKey.selectKey.header',
    blockKeySelectKeyListEmpty = 'blockKey.selectKey.listEmpty',
    blockKeySelectProgrammingKeyBody = 'blockKey.selectProgrammingKey.body',
    blockKeySelectProgrammingKeyHeader = 'blockKey.selectProgrammingKey.header',
    blockKeySelectProgrammingKeyListEmpty = 'blockKey.selectProgrammingKey.listEmpty',
    blockKeySuccessNoAffected = 'blockKey.success.noAffected',
    blockKeySuccessNoAssignment = 'blockKey.success.noAssignment',
    blockKeySuccessWithAssignment = 'blockKey.success.withAssignment',
    blockKeySummaryBodyPart1 = 'blockKey.summary.body.part1',
    blockKeySummaryBodyPart2 = 'blockKey.summary.body.part2',
    blockKeySummaryInfo = 'blockKey.summary.info',
    blockKeySummaryNumAffectedCylinders = 'blockKey.summary.numAffectedCylinders',
    blockKeySummaryWarning = 'blockKey.summary.warning',
    blockKeyUnblockCompleteTasksWarning = 'blockKey.unblock.completeTasksWarning',
    blockKeyUnblockError = 'blockKey.unblock.error',
    blockKeyUnblockErrorNeedToFinishTasks = 'blockKey.unblock.errorNeedToFinishTasks',
    blockKeyUnblockHeader = 'blockKey.unblock.header',
    blockKeyUnblockSuccessNoAffected = 'blockKey.unblock.success.noAffected',
    blockKeyUnblockSuccessNoAssignment = 'blockKey.unblock.success.noAssignment',
    blockKeyUnblockSuccessWithAssignment = 'blockKey.unblock.success.withAssignment',
    blockKeyUnblockSummaryBodyPart1 = 'blockKey.unblock.summary.body.part1',
    blockKeyUnblockSummaryBodyPart2 = 'blockKey.unblock.summary.body.part2',
    blockKeyUnblockSummaryWarning = 'blockKey.unblock.summary.warning',
    blockKeyUnblockVerifyBodyPart1 = 'blockKey.unblock.verify.body.part1',
    blockKeyUnblockVerifyBodyPart2 = 'blockKey.unblock.verify.body.part2',
    blockKeyUnblockVerifyBodyPart3 = 'blockKey.unblock.verify.body.part3',
    blockKeyUnblockVerifyHeader = 'blockKey.unblock.verify.header',
    blockKeyVerifyBodyPart1 = 'blockKey.verify.body.part1',
    blockKeyVerifyBodyPart2 = 'blockKey.verify.body.part2',
    blockKeyVerifyBodyPart2b = 'blockKey.verify.body.part2b',
    blockKeyVerifyHeader = 'blockKey.verify.header',
    cookieInfo = 'cookie.info',
    copyKeyError = 'copyKey.error',
    copyKeyHeader = 'copyKey.header',
    copyKeyNoAvailableTargets = 'copyKey.noAvailableTargets',
    copyKeyNoKey = 'copyKey.noKey',
    copyKeyOptionsBody = 'copyKey.options.body',
    copyKeySuccess = 'copyKey.success',
    copyKeySummaryBody = 'copyKey.summary.body',
    copyKeySummaryNotice = 'copyKey.summary.notice',
    copyKeySummaryNumCylinderAccesses = 'copyKey.summary.numCylinderAccesses',
    copyKeyTargetBody = 'copyKey.target.body',
    cylinderAccessBody = 'cylinder.access.body',
    cylinderAccessError = 'cylinder.access.error',
    cylinderAccessSuccess = 'cylinder.access.success',
    cylinderAccessingBlockedKeys = 'cylinder.accessingBlockedKeys',
    cylinderAccessingKeys = 'cylinder.accessingKeys',
    cylinderAuditTrailCancelRequest = 'cylinder.auditTrail.cancelRequest',
    cylinderAuditTrailCancelWarning = 'cylinder.auditTrail.cancelWarning',
    cylinderAuditTrailEventOpen = 'cylinder.auditTrail.event.open',
    cylinderAuditTrailEventUnknown = 'cylinder.auditTrail.event.unknown',
    cylinderAuditTrailHeadersKey = 'cylinder.auditTrail.headers.key',
    cylinderAuditTrailHeadersTime = 'cylinder.auditTrail.headers.time',
    cylinderAuditTrailLegendFailure = 'cylinder.auditTrail.legend.failure',
    cylinderAuditTrailLegendSuccess = 'cylinder.auditTrail.legend.success',
    cylinderAuditTrailNoAuditTrails = 'cylinder.auditTrail.noAuditTrails',
    cylinderAuditTrailRequestInfo = 'cylinder.auditTrail.request.info',
    cylinderAuditTrailRequestPendingBody1 = 'cylinder.auditTrail.request.pending.body1',
    cylinderAuditTrailRequestPendingBody2 = 'cylinder.auditTrail.request.pending.body2',
    cylinderAuditTrailRequestWarning = 'cylinder.auditTrail.request.warning',
    cylinderAuditTrailSendRequest = 'cylinder.auditTrail.sendRequest',
    cylinderDetailsHeader = 'cylinder.details.header',
    cylinderDetailsInfoAtRisk = 'cylinder.details.info.atRisk',
    cylinderDetailsInfoNeedsUpdateAssignedPart1 = 'cylinder.details.info.needsUpdate.assigned.part1',
    cylinderDetailsInfoNeedsUpdateAssignedPart2 = 'cylinder.details.info.needsUpdate.assigned.part2',
    cylinderDetailsInfoNeedsUpdateStatus = 'cylinder.details.info.needsUpdate.status',
    cylinderDetailsInfoNeedsUpdateUnassigned = 'cylinder.details.info.needsUpdate.unassigned',
    cylinderDetailsInfoUpToDate = 'cylinder.details.info.upToDate',
    cylinderDetailsNoAuthorization = 'cylinder.details.noAuthorization',
    cylinderFailedToPopulate = 'cylinder.failedToPopulate',
    cylinderInstallError = 'cylinder.install.error',
    cylinderInstallSuccess = 'cylinder.install.success',
    cylinderListEmpty = 'cylinder.list.empty',
    cylinderListEmptyAfterFilter = 'cylinder.list.emptyAfterFilter',
    cylinderListEmptyAfterSearchAndFilter = 'cylinder.list.emptyAfterSearchAndFilter',
    cylinderNameError = 'cylinder.name.error',
    cylinderNameHint = 'cylinder.name.hint',
    cylinderNamePlaceholder = 'cylinder.name.placeholder',
    cylinderNameSuccess = 'cylinder.name.success',
    cylinderUninstallError = 'cylinder.uninstall.error',
    cylinderUninstallSuccess = 'cylinder.uninstall.success',
    enrolmentActivationCodeAaCodePlaceholder = 'enrolment.activationCode.aaCodePlaceholder',
    enrolmentActivationCodeBody = 'enrolment.activationCode.body',
    enrolmentActivationCodeErrorInvalid = 'enrolment.activationCode.error.invalid',
    enrolmentActivationCodeHeader = 'enrolment.activationCode.header',
    enrolmentActivationCodeOtpPart = 'enrolment.activationCode.otpPart',
    enrolmentActivationCodeSystemNamePlaceholder = 'enrolment.activationCode.systemNamePlaceholder',
    enrolmentActivationCodeWaiting = 'enrolment.activationCode.waiting',
    enrolmentDoneBody = 'enrolment.done.body',
    enrolmentDoneHeader = 'enrolment.done.header',
    enrolmentEnterPasswordActiveXInfo = 'enrolment.enterPassword.activeXInfo',
    enrolmentEnterPasswordBody = 'enrolment.enterPassword.body',
    enrolmentEnterPasswordErrorCertificate = 'enrolment.enterPassword.error.certificate',
    enrolmentEnterPasswordErrorInvalidMaxPassword = 'enrolment.enterPassword.error.invalidMaxPassword',
    enrolmentEnterPasswordErrorInvalidMinPassword = 'enrolment.enterPassword.error.invalidMinPassword',
    enrolmentEnterPasswordErrorInvalidPassword = 'enrolment.enterPassword.error.invalidPassword',
    enrolmentEnterPasswordHeader = 'enrolment.enterPassword.header',
    enrolmentEnterPasswordWaiting = 'enrolment.enterPassword.waiting',
    enrolmentErrorBrowserNotSupported = 'enrolment.error.browserNotSupported',
    enrolmentErrorInvalidState = 'enrolment.error.invalidState',
    enrolmentHeader = 'enrolment.header',
    enrolmentKeyBody = 'enrolment.key.body',
    enrolmentLicenseBody = 'enrolment.license.body',
    enrolmentOtpEmailBody = 'enrolment.otp.email.body',
    enrolmentOtpEmailHeader = 'enrolment.otp.email.header',
    enrolmentOtpEmailHelpText = 'enrolment.otp.email.helpText',
    enrolmentOtpErrorInvalid = 'enrolment.otp.error.invalid',
    enrolmentOtpErrorNoTriesLeft = 'enrolment.otp.error.noTriesLeft',
    enrolmentOtpErrorNumTriesLeft = 'enrolment.otp.error.numTriesLeft',
    enrolmentOtpPlaceholder = 'enrolment.otp.placeholder',
    enrolmentOtpSmsBody = 'enrolment.otp.sms.body',
    enrolmentOtpSmsHeader = 'enrolment.otp.sms.header',
    enrolmentOtpSmsHelpText = 'enrolment.otp.sms.helpText',
    enrolmentOtpWaiting = 'enrolment.otp.waiting',
    enrolmentReplaceWarning = 'enrolment.replaceWarning',
    enrolmentSelectMethodBody = 'enrolment.selectMethod.body',
    enrolmentSelectMethodErrorInvalid = 'enrolment.selectMethod.error.invalid',
    enrolmentSelectMethodErrorSelect = 'enrolment.selectMethod.error.select',
    enrolmentSelectMethodHeader = 'enrolment.selectMethod.header',
    enrolmentSelectMethodInfoEmail = 'enrolment.selectMethod.info.email',
    enrolmentSelectMethodInfoKey = 'enrolment.selectMethod.info.key',
    enrolmentSelectMethodInfoPhone = 'enrolment.selectMethod.info.phone',
    enrolmentSelectMethodWaiting = 'enrolment.selectMethod.waiting',
    genericAbout = 'generic.about',
    genericAccess = 'generic.access',
    genericAccount = 'generic.account',
    genericAll = 'generic.all',
    genericAllRightsReservedYear = 'generic.allRightsReservedYear',
    genericAppName = 'generic.appName',
    genericAtRisk = 'generic.atRisk',
    genericAuditTrail = 'generic.auditTrail',
    genericAvailable = 'generic.available',
    genericBlocked = 'generic.blocked',
    genericBlockedInCylinder = 'generic.blockedInCylinder',
    genericCompanyName = 'generic.companyName',
    genericConnecting = 'generic.connecting',
    genericCylinder = 'generic.cylinder',
    genericCylinderAccess = 'generic.cylinderAccess',
    genericCylinders = 'generic.cylinders',
    genericDashboard = 'generic.dashboard',
    genericDatePlaceholder = 'generic.datePlaceholder',
    genericDay = 'generic.day',
    genericDayFriday = 'generic.day.friday',
    genericDayMonday = 'generic.day.monday',
    genericDaySaturday = 'generic.day.saturday',
    genericDaySunday = 'generic.day.sunday',
    genericDayThursday = 'generic.day.thursday',
    genericDayTuesday = 'generic.day.tuesday',
    genericDayWednesday = 'generic.day.wednesday',
    genericDefault = 'generic.default',
    genericDescription = 'generic.description',
    genericDetails = 'generic.details',
    genericDisconnected = 'generic.disconnected',
    genericDownloadInfo = 'generic.download.info',
    genericDownloadLinkText = 'generic.download.linkText',
    genericEditAccess = 'generic.editAccess',
    genericEndDate = 'generic.endDate',
    genericEnterPassword = 'generic.enterPassword',
    genericErrorEmptyFields = 'generic.error.emptyFields',
    genericErrorHeader = 'generic.error.header',
    genericErrorInput = 'generic.error.input',
    genericErrorInternet = 'generic.error.internet',
    genericErrorNetwork = 'generic.error.network',
    genericErrorPasswordsDontMatch = 'generic.error.passwordsDontMatch',
    genericErrorSaving = 'generic.error.saving',
    genericErrorUnexpected = 'generic.error.unexpected',
    genericErrorUnknown = 'generic.error.unknown',
    genericFilter = 'generic.filter',
    genericFrom = 'generic.from',
    genericHandedOut = 'generic.handedOut',
    genericHelp = 'generic.help',
    genericInStock = 'generic.inStock',
    genericInfo = 'generic.info',
    genericInstalled = 'generic.installed',
    genericKey = 'generic.key',
    genericKeyUpdated = 'generic.keyUpdated',
    genericKeys = 'generic.keys',
    genericLanguage = 'generic.language',
    genericLicenseAgreement = 'generic.licenseAgreement',
    genericListEmpty = 'generic.list.empty',
    genericLoading = 'generic.loading',
    genericLogin = 'generic.login',
    genericMarking = 'generic.marking',
    genericMaxNameLength = 'generic.maxNameLength',
    genericMenu = 'generic.menu',
    genericMinNameLength = 'generic.minNameLength',
    genericName = 'generic.name',
    genericNeedUpdate = 'generic.needUpdate',
    genericNotImplemented = 'generic.notImplemented',
    genericNotLoggedIn = 'generic.notLoggedIn',
    genericNumAtRisk = 'generic.numAtRisk',
    genericNumNeedUpdating = 'generic.numNeedUpdating',
    genericNumToBeBlocked = 'generic.numToBeBlocked',
    genericOther = 'generic.other',
    genericPassword = 'generic.password',
    genericPasswordTooShort = 'generic.passwordTooShort',
    genericPending = 'generic.pending',
    genericPrivacyPolicy = 'generic.privacyPolicy',
    genericReenterPassword = 'generic.reenterPassword',
    genericRefreshBody = 'generic.refresh.body',
    genericRefreshLink = 'generic.refresh.link',
    genericSchedule = 'generic.schedule',
    genericSelectSystem = 'generic.selectSystem',
    genericShortDayFriday = 'generic.shortDay.friday',
    genericShortDayMonday = 'generic.shortDay.monday',
    genericShortDaySaturday = 'generic.shortDay.saturday',
    genericShortDaySunday = 'generic.shortDay.sunday',
    genericShortDayThursday = 'generic.shortDay.thursday',
    genericShortDayTuesday = 'generic.shortDay.tuesday',
    genericShortDayWednesday = 'generic.shortDay.wednesday',
    genericStartDate = 'generic.startDate',
    genericStepKOfN = 'generic.stepKOfN',
    genericSuccessHeader = 'generic.success.header',
    genericSuccessSaved = 'generic.success.saved',
    genericSummary = 'generic.summary',
    genericSystemId = 'generic.systemId',
    genericSystems = 'generic.systems',
    genericTaskList = 'generic.taskList',
    genericTasks = 'generic.tasks',
    genericThirdPartyLicenses = 'generic.thirdPartyLicenses',
    genericTo = 'generic.to',
    genericToBeAdded = 'generic.toBeAdded',
    genericToBeBlockedInCylinder = 'generic.toBeBlockedInCylinder',
    genericToBeRemoved = 'generic.toBeRemoved',
    genericUnknown = 'generic.unknown',
    genericUpToDate = 'generic.upToDate',
    genericValidity = 'generic.validity',
    genericVersion = 'generic.version',
    genericWarning = 'generic.warning',
    genericWithTasks = 'generic.withTasks',
    handInError = 'handIn.error',
    handInHeader = 'handIn.header',
    handInOptionsBody = 'handIn.options.body',
    handInOptionsHeader = 'handIn.options.header',
    handInSelectKeyBody = 'handIn.selectKey.body',
    handInSelectKeyListEmpty = 'handIn.selectKey.listEmpty',
    handInSuccessErased = 'handIn.success.erased',
    handInSuccessKept = 'handIn.success.kept',
    handInSummaryBody = 'handIn.summary.body',
    handInSummaryResetNotice = 'handIn.summary.resetNotice',
    handInWarning = 'handIn.warning',
    handOutAccessBody = 'handOut.access.body',
    handOutDoneBody = 'handOut.done.body',
    handOutError = 'handOut.error',
    handOutHeader = 'handOut.header',
    handOutNameBody = 'handOut.name.body',
    handOutScheduleBody = 'handOut.schedule.body',
    handOutSelectKeyBody = 'handOut.selectKey.body',
    handOutSelectKeyListEmpty = 'handOut.selectKey.listEmpty',
    handOutSuccess = 'handOut.success',
    handOutSummaryBody = 'handOut.summary.body',
    handOutSummaryNumCylinderAccesses = 'handOut.summary.numCylinderAccesses',
    handOutValidityBody = 'handOut.validity.body',
    handOutValidityHeader = 'handOut.validity.header',
    keyAccessBlockedAdded = 'key.access.blocked.added',
    keyAccessBlockedCurrent = 'key.access.blocked.current',
    keyAccessBlockedMaybe = 'key.access.blocked.maybe',
    keyAccessBlockedRemoved = 'key.access.blocked.removed',
    keyAccessBody = 'key.access.body',
    keyAccessCylindersAtRisk = 'key.access.cylindersAtRisk',
    keyAccessNoAccess = 'key.access.noAccess',
    keyAccessNoCylindersAtRisk = 'key.access.noCylindersAtRisk',
    keyAssignmentNotPossibleForAllAffectedCylinders = 'key.assignmentNotPossibleForAllAffectedCylinders',
    keyBlockedIn = 'key.blockedIn',
    keyDetailsAccessibleEditSchedule = 'key.details.accessibleEditSchedule',
    keyDetailsAccessibleEditValidity = 'key.details.accessibleEditValidity',
    keyDetailsHeader = 'key.details.header',
    keyDetailsInfoBlocked = 'key.details.info.blocked',
    keyDetailsInfoDangerous = 'key.details.info.dangerous',
    keyDetailsInfoNeedsUpdate = 'key.details.info.needsUpdate',
    keyDetailsInfoPending = 'key.details.info.pending',
    keyDetailsInfoUpToDate = 'key.details.info.upToDate',
    keyDetailsInfoUpdaterKey = 'key.details.info.updaterKey',
    keyFailedToPopulate = 'key.failedToPopulate',
    keyListEmpty = 'key.list.empty',
    keyListEmptyAfterFilter = 'key.list.emptyAfterFilter',
    keyListEmptyAfterSearchAndFilter = 'key.list.emptyAfterSearchAndFilter',
    keyNameHint = 'key.name.hint',
    keyNamePlaceholder = 'key.name.placeholder',
    keyScheduleAddFromTime = 'key.schedule.add.fromTime',
    keyScheduleAddHeader = 'key.schedule.add.header',
    keyScheduleAddToTime = 'key.schedule.add.toTime',
    keyScheduleAddWeekDays = 'key.schedule.add.weekDays',
    keyScheduleClearQuestion = 'key.schedule.clearQuestion',
    keyScheduleEditHeader = 'key.schedule.edit.header',
    keyScheduleErrorInvalidPeriod = 'key.schedule.error.invalidPeriod',
    keyScheduleErrorNoDay = 'key.schedule.error.noDay',
    keyScheduleHeader = 'key.schedule.header',
    keyScheduleListView = 'key.schedule.listView',
    keyScheduleNewSchedule = 'key.schedule.newSchedule',
    keyScheduleNoPeriods = 'key.schedule.noPeriods',
    keyScheduleNoScheduleInfo = 'key.schedule.noScheduleInfo',
    keyScheduleNumPeriods = 'key.schedule.numPeriods',
    keyScheduleOff = 'key.schedule.off',
    keyScheduleOffDay = 'key.schedule.offDay',
    keyScheduleOffWarning = 'key.schedule.offWarning',
    keyScheduleWeekView = 'key.schedule.weekView',
    keyStored = 'key.stored',
    keyValidityAlways = 'key.validity.always',
    keyValidityDuringDate = 'key.validity.duringDate',
    keyValidityExpired = 'key.validity.expired',
    keyValidityFromXToY = 'key.validity.fromXToY',
    keyValidityHeader = 'key.validity.header',
    keyValidityNever = 'key.validity.never',
    keyValidityNewValidity = 'key.validity.newValidity',
    keyValidityRange = 'key.validity.range',
    keyValidityTimezoneWarning = 'key.validity.timezoneWarning',
    keyValidityToday = 'key.validity.today',
    keyValidityValidForDays = 'key.validity.validForDays',
    keyValidityValidForHours = 'key.validity.validForHours',
    keyValidityValidForMinutes = 'key.validity.validForMinutes',
    keyValidityValidForYears = 'key.validity.validForYears',
    keyValidityWillBeFromDate = 'key.validity.willBeFromDate',
    languageAr = 'language.ar',
    languageBg = 'language.bg',
    languageDa = 'language.da',
    languageDe = 'language.de',
    languageEnGb = 'language.en-gb',
    languageEnUs = 'language.en-us',
    languageEs = 'language.es',
    languageFi = 'language.fi',
    languageFr = 'language.fr',
    languageIt = 'language.it',
    languageJa = 'language.ja',
    languageKo = 'language.ko',
    languageNb = 'language.nb',
    languageNl = 'language.nl',
    languagePl = 'language.pl',
    languagePtBr = 'language.pt-br',
    languageRu = 'language.ru',
    languageSv = 'language.sv',
    languageTh = 'language.th',
    languageZhCn = 'language.zh-cn',
    languageZhTw = 'language.zh-tw',
    loginErrorGeneral = 'login.error.general',
    loginErrorNoAccessToThisSystem = 'login.error.noAccessToThisSystem',
    loginErrorSystemNotCompatible = 'login.error.systemNotCompatible',
    loginErrorSystemNotFound = 'login.error.systemNotFound',
    loginErrorWrongPassword = 'login.error.wrongPassword',
    loginForgotPasswordBody = 'login.forgotPassword.body',
    loginForgotPasswordHeader = 'login.forgotPassword.header',
    loginLoginToX = 'login.loginToX',
    loginSessionExpired = 'login.sessionExpired',
    pathsAbout = 'paths.about',
    pathsAdministrators = 'paths.administrators',
    pathsBlock = 'paths.block',
    pathsCopy = 'paths.copy',
    pathsCylinders = 'paths.cylinders',
    pathsEnrolment = 'paths.enrolment',
    pathsHandin = 'paths.handin',
    pathsHandout = 'paths.handout',
    pathsIndex = 'paths.index',
    pathsKeys = 'paths.keys',
    pathsLogin = 'paths.login',
    pathsTasks = 'paths.tasks',
    requestAuditTrailHeader = 'requestAuditTrail.header',
    requestAuditTrailSelectCylinderBody = 'requestAuditTrail.selectCylinder.body',
    requestAuditTrailSelectCylinderHeader = 'requestAuditTrail.selectCylinder.header',
    requestAuditTrailSelectCylinderListEmpty = 'requestAuditTrail.selectCylinder.listEmpty',
    requestAuditTrailSelectProgrammingKeyBody = 'requestAuditTrail.selectProgrammingKey.body',
    requestAuditTrailSelectProgrammingKeyHeader = 'requestAuditTrail.selectProgrammingKey.header',
    requestAuditTrailSelectProgrammingKeyListEmpty = 'requestAuditTrail.selectProgrammingKey.listEmpty',
    requestAuditTrailSuccessNoAssignment = 'requestAuditTrail.success.noAssignment',
    requestAuditTrailSuccessWithAssignment = 'requestAuditTrail.success.withAssignment',
    requestAuditTrailSummaryBodyPart1 = 'requestAuditTrail.summary.body.part1',
    requestAuditTrailSummaryBodyPart2 = 'requestAuditTrail.summary.body.part2',
    requestAuditTrailVerifyBodyPart1 = 'requestAuditTrail.verify.body.part1',
    requestAuditTrailVerifyBodyPart2 = 'requestAuditTrail.verify.body.part2',
    taskBody = 'task.body',
    taskListAssignedHeader = 'task.list.assigned.header',
    taskListAssignedStatusPendingAdded = 'task.list.assigned.status.pendingAdded',
    taskListAssignedStatusPendingQueued = 'task.list.assigned.status.pendingQueued',
    taskListAssignedStatusPendingRemoved = 'task.list.assigned.status.pendingRemoved',
    taskListAssignedStatusStarted = 'task.list.assigned.status.started',
    taskListEmpty = 'task.list.empty',
    taskListUnassignedHeader = 'task.list.unassigned.header',
    taskSelectKeyBody = 'task.selectKey.body',
    taskUnassignAllConfirmation = 'task.unassignAllConfirmation',
    taskUnassignConfirmation = 'task.unassignConfirmation'
}

export default TKey;
