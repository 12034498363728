import React, { useState } from 'react';
import Modal from 'components/modal';
import { Button, Flex, SectionFooter, SectionHeader, H3, SectionBody, Section, Box, Alert } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { ICylinder, IKey } from 'models/types';
import TaskKeySelector from '../task_key_selector';

interface ISelectTaskKeyModalProps {
    cylindersToAssign: Array<ICylinder>;
    keys: Array<IKey>;
    onCancel?: () => void;
    onSave?: (selectedKey?: IKey) => void;
}

const SelectTaskKeyModal = (props: ISelectTaskKeyModalProps) => {
    const { onSave, onCancel, keys, cylindersToAssign } = props;
    const { t } = useTranslation();

    const [selectedTaskKey, setSelectedTaskKey] = useState<IKey | undefined>();

    return (
        <Modal isOpen>
            <Section data-testid="select-task-key-modal">
                <SectionHeader>
                    <H3>{t(TKey.blockKeySelectProgrammingKeyHeader)}</H3>
                </SectionHeader>

                <SectionBody>
                    <Box m="quarter" mb="half">
                        <Alert text={t(TKey.taskSelectKeyBody)} variant="warning" />
                    </Box>

                    <TaskKeySelector
                        keys={keys}
                        cylindersToAssign={cylindersToAssign}
                        onSelect={selectedKey => {
                            setSelectedTaskKey(selectedKey);
                        }}
                    />
                </SectionBody>

                <SectionFooter>
                    <Flex justifyContent="space-between">
                        <Button
                            data-testid="cancel-button"
                            onClick={() => {
                                onCancel && onCancel();
                            }}>
                            {t(TKey.actionCancel)}
                        </Button>
                        <Button
                            data-testid="save-button"
                            disabled={!selectedTaskKey}
                            variant="primary"
                            onClick={() => {
                                onSave && onSave(selectedTaskKey);
                            }}>
                            {t(TKey.actionSave)}
                        </Button>
                    </Flex>
                </SectionFooter>
            </Section>
        </Modal>
    );
};

export default SelectTaskKeyModal;
