import React from 'react';
import { Alert, Box } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { CylinderCollectionItem } from 'domain/cylinder/components';
import { useParams, useHistory } from 'react-router-dom';
import { useCylinder } from 'domain/cylinder/redux/hooks';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';

import StepView from 'components/wizard/step_view';
import CylinderModel from 'models/cylinder';

const VerifySelection = () => {
    const { t } = useTranslation();

    const history = useHistory();
    const params = useParams<{ cylinderId: string }>();
    const selectedCylinder = useCylinder(params.cylinderId);

    if (!selectedCylinder) {
        return null;
    }

    const onBack = () => {
        history.push(Routes.ACCESS_LOG_REQUEST);
    };

    const onSubmit = () => {
        const selectKeyRoute = buildRoute(Routes.ACCESS_LOG_REQUEST_SELECT_KEY, {
            cylinderId: selectedCylinder.uuid
        });
        history.push(selectKeyRoute);
    };

    return (
        <StepView
            title={t(TKey.requestAuditTrailHeader)}
            onBack={onBack}
            submitText={t(TKey.actionNext)}
            onSubmit={onSubmit}>
            <Box m="quarter" mb="full">
                <Alert text={t(TKey.requestAuditTrailVerifyBodyPart1)} variant="warning" />
            </Box>

            <CylinderCollectionItem dataModel={new CylinderModel(selectedCylinder)} />

            <Box m="quarter" mt="full">
                <Alert text={t(TKey.requestAuditTrailVerifyBodyPart2)} variant="info" />
            </Box>
        </StepView>
    );
};

export default VerifySelection;
