import React, { useState } from 'react';
import { Input, Button, Text, Box, Form, Label, Spinner, Flex, FormGroup, Alert } from 'aa-react-ui';

import AnchorButton from 'components/anchor_button';
import directoryService from 'common/services/directory_service';

import { useTranslation, TKey } from 'locale';

interface IState {
    systemId: string;
    password: string;
}

interface IProps {
    error?: string;
    loading?: boolean;
    onSubmit?: (systemId: string, password: string) => void;
    onForgotPassword?: () => void;
}

function LoginForm(props: IProps): JSX.Element {
    const { loading, error, onForgotPassword, onSubmit } = props;
    const cachedSystemId = directoryService.getDefaultSystemId();

    const { t } = useTranslation();
    const [formData, setFormData] = useState<IState>({ systemId: cachedSystemId, password: '' });

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        const newFormData = { ...formData, [name]: value };
        setFormData(newFormData);
    };

    const { systemId, password } = formData;

    return (
        <Box width="20rem" mt="full">
            <Form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    onSubmit && onSubmit(systemId, password);
                }}>
                <FormGroup flexDirection="column" pb={0}>
                    <Label color="white">{t(TKey.genericSystemId)}</Label>
                    <Input
                        value={formData.systemId}
                        color="black"
                        data-testid="system-id"
                        onChange={inputChangeHandler}
                        type="text"
                        name="systemId"
                    />
                </FormGroup>

                <FormGroup flexDirection="column" pb={0}>
                    <Label color="white">{t(TKey.genericPassword)}</Label>
                    <Input
                        value={password}
                        color="black"
                        data-testid="password"
                        onChange={inputChangeHandler}
                        type="password"
                        name="password"
                    />
                </FormGroup>

                {error && <Alert title={t(TKey.genericErrorHeader)} text={t(error)} variant="error" />}

                <Box style={{ textAlign: 'right' }}>
                    <AnchorButton
                        data-testid="forgot-password-link"
                        ml="auto"
                        variant="transparent"
                        onClick={onForgotPassword}>
                        {t(TKey.loginForgotPasswordHeader)}
                    </AnchorButton>
                </Box>

                <Button
                    data-testid="login-button"
                    width="100%"
                    type="submit"
                    value="Submit"
                    disabled={!(systemId && password) || loading}>
                    <Flex justifyContent="center" alignItems="center">
                        {loading ? (
                            <Spinner data-testid="login-progress" size="1rem" />
                        ) : (
                            <Text as="strong">{t(TKey.actionLogin)}</Text>
                        )}
                    </Flex>
                </Button>
            </Form>
        </Box>
    );
}

export default LoginForm;
