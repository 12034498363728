import React, { useEffect } from 'react';

import { Flex, Spinner } from 'aa-react-ui';
import BaseContainer from 'components/base_container';
import { Routes } from 'router';
import { useAppSession } from 'domain/authentication/redux/hooks';
import { useHistory } from 'react-router-dom';

const Logout = (): JSX.Element => {
    const history = useHistory();
    const { destroySession } = useAppSession();

    useEffect(() => {
        destroySession().then(() => {
            history.push(Routes.AUTH);
        });
    }, [history, destroySession]);

    return (
        <BaseContainer>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="2rem" />
            </Flex>
        </BaseContainer>
    );
};

export default Logout;
