import React from 'react';
import ContentLoader from 'react-content-loader';
import { Section, SectionBody } from 'aa-react-ui';

const WidgetLoader = () => (
    <Section width="100%">
        <SectionBody>
            <ContentLoader speed={2}>
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <circle cx="20" cy="20" r="20" />
            </ContentLoader>
        </SectionBody>
    </Section>
);

export default WidgetLoader;
