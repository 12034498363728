enum SupportedLocale {
    ar = 'ar',
    bg = 'bg',
    da = 'da',
    de = 'de',
    enGB = 'en_gb',
    enUS = 'en_us',
    es = 'es',
    fi = 'fi',
    fr = 'fr',
    it = 'it',
    ja = 'ja',
    ko = 'ko',
    nb = 'nb',
    nl = 'nl',
    pl = 'pl',
    ptBr = 'pt_br',
    ru = 'ru',
    sv = 'sv',
    th = 'th',
    zhCN = 'zh_cn',
    zhTW = 'zh_tw'
}

export default SupportedLocale;
