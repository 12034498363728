import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { SectionHeader, Section, SectionBody, Input, Flex, Spinner, H5, H3 } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import { ListWrapper } from 'domain/key/components/wrappers';
import { KeyStateFilterDropdown } from 'domain/key/components';
import { KeyStateFilter, IKeyCollection } from 'domain/key/types';

import KeyCollection from 'domain/key/components/key_collection';

import timerUtils from 'common/utils/timers';

interface IkeyListProps {
    keyCollections: Array<IKeyCollection>;
    setSearchQuery: (query: string) => void;
    setFilter: (KeyFilter: KeyStateFilter) => void;
    defaultKeyFilter: KeyStateFilter;
}

const SEARCH_DEBOUNCE_TIME_IN_MILLIS = 200;

const KeyList = (props: IkeyListProps) => {
    const { keyCollections = [], setSearchQuery, defaultKeyFilter, setFilter } = props;

    const { t } = useTranslation();
    const { keyId } = useParams<{ keyId: string }>();
    const history = useHistory();

    useEffect(() => {
        if (!keyId && keyCollections.length) {
            const visibleItems = keyCollections.find(collection => collection.items.length);

            if (visibleItems) {
                const firstVisibleItem = visibleItems.items[0];

                history.push(buildRoute(Routes.KEYS_KEY, { keyId: firstVisibleItem.uuid }));
            }
        }
    }, [keyId, keyCollections, history]);

    const debounceSetSearchQuery = timerUtils.debounce(setSearchQuery, SEARCH_DEBOUNCE_TIME_IN_MILLIS);

    const isKeyListEmpty = !keyCollections.find(collection => collection.items.length);

    return (
        <Section width="100%">
            <Flex flexDirection="column" height="100%">
                <SectionHeader
                    title={<H3>{t(TKey.genericKeys)}</H3>}
                    actions={<KeyStateFilterDropdown value={defaultKeyFilter} onChange={setFilter} />}
                />
                <SectionBody height="100%" overflow="hidden">
                    <Flex height="inherit" flexDirection="column">
                        <Input
                            onChange={e => {
                                debounceSetSearchQuery(e.target.value);
                            }}
                            placeholder={t(TKey.actionSearch)}
                            type="text"
                        />

                        <ListWrapper>
                            {!keyCollections.length && (
                                <Flex justifyContent="center" alignItems="center" height="inherit">
                                    <Spinner />
                                </Flex>
                            )}

                            {isKeyListEmpty && <H5>{t(TKey.keyListEmptyAfterSearchAndFilter)}</H5>}

                            {keyCollections.map(({ type, items }) => (
                                <KeyCollection key={type} items={items} type={type} />
                            ))}
                        </ListWrapper>
                    </Flex>
                </SectionBody>
            </Flex>
        </Section>
    );
};

export default KeyList;
