import React from 'react';
import { Flex, FlexItem } from 'aa-react-ui';
import CylinderList from './cylinder_list';
import { Route } from 'react-router-dom';
import { Routes } from 'router';
import CylinderDetails from './cylinder_details';

function Cylinders(): JSX.Element {
    return (
        <Flex width="100%">
            <FlexItem width="30%" m="quarter">
                <CylinderList />
            </FlexItem>
            <FlexItem width="70%" m="quarter">
                <Route path={Routes.CYLINDERS_CYLINDER} component={CylinderDetails} />
            </FlexItem>
        </Flex>
    );
}

export default Cylinders;
