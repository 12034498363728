import React from 'react';
import { TKey, useTranslation } from 'locale';

import CylinderModel from 'models/cylinder';
import { CylinderCollectionType, CylinderStatus } from 'domain/cylinder/types';
import { CylinderState } from 'models/types';

import { IKeyAccessInfo } from 'domain/key/types';

import { getCylinderStatus, getCylinderState, getCylinderBackgroundColor } from 'domain/cylinder/cylinder_helper';
import { H5, Text, Flex, Box } from 'aa-react-ui';
import Card from 'components/card';
import { TaskInfo } from 'domain/task/components';
import { IconWrapper, CylinderIcon } from 'components/icon';

const CYLINDER_STATUS_TRANSLATION_KEY_MAP = {
    [CylinderStatus.PENDING_ACCESS]: TKey.genericAccess,
    [CylinderStatus.PENDING_ACCESS_LOG]: TKey.genericAuditTrail
};

const CYLINDER_STATE_TRANSLATION_KEY_MAP = {
    [CylinderState.IN_STOCK]: TKey.genericInStock,
    [CylinderState.INSTALLED]: TKey.genericInstalled,
    [CylinderState.UNKNOWN]: TKey.empty
};

interface CachedValues extends IKeyAccessInfo {
    iconColor?: string;
}

interface ICylinderCollectionItemProps {
    dataModel?: CylinderModel;
    collectionType?: CylinderCollectionType;
    selected?: boolean;
    disabled?: boolean;
    hideStatus?: boolean;
    showTaskInfo?: boolean;
    onClick?: () => void;

    item?: CachedValues;
}

const CylinderCollectionItem: React.FunctionComponent<ICylinderCollectionItemProps> = props => {
    const { dataModel, selected, hideStatus, showTaskInfo, disabled, onClick, children } = props;
    const { t } = useTranslation();

    if (!dataModel) {
        return null;
    }

    const buildHeaderText = () => {
        const cylinderState = getCylinderState(dataModel);
        const state = CYLINDER_STATE_TRANSLATION_KEY_MAP[cylinderState];
        const { name } = dataModel;

        if (name) {
            return name;
        }

        return t(state);
    };

    const headerText = buildHeaderText();

    const { marking } = dataModel;

    const status = getCylinderStatus(dataModel)
        .filter(i => i)
        .map(j => {
            return t(CYLINDER_STATUS_TRANSLATION_KEY_MAP[j]);
        })
        .join(' / ');

    const iconColor = dataModel.getColor();
    const cylinderBackground = getCylinderBackgroundColor(disabled, selected);

    return (
        <Card p="half" mt="quarter" mb="quarter" bg={cylinderBackground} onClick={onClick}>
            <IconWrapper icon={CylinderIcon} stroke={iconColor} width="3rem" />
            <Flex flexDirection="column" width="100%">
                <H5>{headerText}</H5>
                <Text color="contrast.60">{marking}</Text>
                {!hideStatus && <Text color="warning.90">{status}</Text>}
                {showTaskInfo && <TaskInfo uuid={dataModel.uuid} />}
            </Flex>
            <Box>{children}</Box>
        </Card>
    );
};

export default CylinderCollectionItem;
