import CylinderUninstall from './cylinder_uninstall';

import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getSelectedCylinder } from 'domain/cylinder/redux/selectors';

const maptStateToProps = (state: IAppStoreState) => {
    return {
        cylinderData: getSelectedCylinder(state)
    };
};

export default connect(maptStateToProps)(CylinderUninstall);
