import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSession } from 'domain/authentication/redux/hooks';
import { useNotification } from 'domain/notification/redux/hooks';
import { TKey } from 'locale';
import socketService, { EventName } from 'common/services/socket_service';
import TimersUtils from 'common/utils/timers';
import httpClient, { HTTP_STATUS_CODE } from 'common/services/http_client';
import Routes from 'router/routes';

const debounceTimeInMillis = 500;

const SessionWatcher = () => {
    const { destroySession } = useAppSession();
    const { showError } = useNotification();
    const { pathname } = useLocation();
    useEffect(() => {
        const onSessionExpire = TimersUtils.debounce(() => {
            if (pathname !== Routes.AUTH) {
                showError(TKey.loginSessionExpired);
                destroySession();
            }
        }, debounceTimeInMillis);

        socketService.addListener(EventName.SESSION_TIMEOUT, onSessionExpire);

        httpClient.addListener(HTTP_STATUS_CODE.UNAUTHORIZED, onSessionExpire);

        return () => {
            socketService.removeListener(EventName.SESSION_TIMEOUT, onSessionExpire);
            httpClient.removeListener(HTTP_STATUS_CODE.UNAUTHORIZED, onSessionExpire);
        };
    }, [destroySession, showError, pathname]);

    return null;
};

export default SessionWatcher;
