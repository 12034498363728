import React, { useState } from 'react';
import { KeyCollectionItem } from 'domain/key/components';
import KeyModel from 'models/key';
import RadioButton from 'lib/aa-react-ui/radio_button';
import { IKey } from 'models/types';

interface IKeyRadioListProps {
    items: Array<IKey>;
    hideStatus?: boolean;
    onSelect: (selectedKey: IKey) => void;
    defaultCheckedKeyId?: string;
}

const KeyRadioList: React.FunctionComponent<IKeyRadioListProps> = props => {
    const { items, onSelect, defaultCheckedKeyId = '', hideStatus } = props;

    const [selectedKeyId, setSelectedKeyId] = useState(defaultCheckedKeyId);

    const handleCheckChange = (checkedKey: IKey) => {
        setSelectedKeyId(checkedKey.uuid);

        onSelect && onSelect(checkedKey);
    };

    return (
        <>
            {items.map(item => {
                const isSelected = item.uuid === selectedKeyId;

                return (
                    <KeyCollectionItem
                        hideStatus={hideStatus}
                        key={item.uuid}
                        data={new KeyModel(item)}
                        onClick={() => {
                            handleCheckChange(item);
                        }}
                        selected={isSelected}>
                        <RadioButton
                            data-testid="radio-button"
                            checked={isSelected}
                            name="keys"
                            onChange={() => {
                                handleCheckChange(item);
                            }}
                        />
                    </KeyCollectionItem>
                );
            })}
        </>
    );
};

export default KeyRadioList;
