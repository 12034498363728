import React from 'react';
import { ArrowRightIcon, CylinderIcon } from 'components/icon';
import { Routes } from 'router';
import { Flex, Text } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { buildRoute } from 'router/helper';
import Card from 'components/card';
import StyledLink from 'components/styled_link';

interface ICylinderAccessInfoProps {
    keyId?: string;
    disabled?: boolean;
}
const CylinderAccessInfo: React.FunctionComponent<ICylinderAccessInfoProps> = props => {
    const { disabled, keyId } = props;
    const { t } = useTranslation();

    if (!keyId) {
        return null;
    }

    const accessEditRoute = buildRoute(Routes.KEYS_KEY_ACCESS, { keyId });

    return (
        <StyledLink to={accessEditRoute} data-testid={`cylinder-access-edit-link-${keyId}`} disabled={disabled}>
            <Card bg={disabled ? 'contrast.10' : 'white'}>
                <Flex flexDirection="column" width="100%">
                    <Flex flexDirection="row">
                        <Flex flexDirection="row" width="100%">
                            <CylinderIcon height="32px" width="32px" stroke="black" strokeWidth="3px" />
                            <Text pl="half" fontSize="1rem" fontWeight="bold" color="contrast.90">
                                {t(TKey.genericCylinderAccess)}
                            </Text>
                        </Flex>
                        {!disabled && <ArrowRightIcon height="2rem" width="2rem" />}
                    </Flex>
                </Flex>
            </Card>
        </StyledLink>
    );
};

export default CylinderAccessInfo;
