import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

import cardCSS from './css';

const CardLink = styled(NavLink)`
    ${cardCSS}
`;

export default CardLink;
