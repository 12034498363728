import styled from 'styled-components';
import { Button } from 'aa-react-ui';

const AnchorButton = styled(Button)`
    cursor: pointer;
    border: none;
    &:selection {
        border: none;
    }
    &:hover {
        border: none;
        text-decoration: underline !important;
    }
`;

AnchorButton.defaultProps = {
    color: 'white'
};

export default AnchorButton;
