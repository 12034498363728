import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from 'router';

import SelectKey from './select_key';
import SelectSettings from './select_settings';

const HandOut = () => {
    return (
        <>
            <Route exact path={Routes.HAND_OUT} component={SelectKey} />
            <Route exact path={Routes.HAND_OUT_SETTINGS} component={SelectSettings} />
        </>
    );
};

export default HandOut;
