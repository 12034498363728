import CylinderNameEditor from './cylinder_name_editor';

import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getSelectedCylinder } from 'domain/cylinder/redux/selectors';

const maptStateToProps = (state: IAppStoreState) => {
    return {
        cylinderData: getSelectedCylinder(state)
    };
};

export default connect(maptStateToProps)(CylinderNameEditor);
