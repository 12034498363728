import React, { ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';

interface IProps {
    children?: ReactNode;
    duration?: number;
}

const DEFAULT_DURATION_IN_MILLIS = 300;

export default (props: IProps): JSX.Element => {
    const { children, duration = DEFAULT_DURATION_IN_MILLIS } = props;

    const animStyleProp = useSpring({
        opacity: 1,
        height: '100%',
        width: '100%',
        from: { opacity: 0 },
        config: {
            duration
        }
    });

    return <animated.div style={animStyleProp}>{children}</animated.div>;
};
