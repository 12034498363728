import { useDispatch, useSelector } from 'react-redux';
import { getHardwareRequest } from './selectors';
import { loadHardwareData } from './actions';
import { useCallback } from 'react';
import { RequestStatus } from 'app_redux/types';

export const useHardwareData = () => {
    const dispatch = useDispatch();
    const { status } = useSelector(getHardwareRequest);

    const loadData = useCallback(() => loadHardwareData()(dispatch), [dispatch]);

    return { loadData, loading: status === RequestStatus.IN_PROGRESS };
};
