import React, { useEffect, useState } from 'react';
import Table, { Thead, TCell, TRow, TBody } from 'lib/aa-react-ui/table';
import { Text, Flex, Surface, Spinner } from 'aa-react-ui';
import { IAuditTrail, AuditTrailResult } from 'domain/cylinder/types';
import { filterAccessLog } from 'domain/cylinder/cylinder_helper';
import dateTimeUtils from 'common/utils/date_time';
import moment from 'moment';
import DateRange from 'components/date_range';
import Card from 'components/card';
import { findHardware } from 'domain/hardware/hardware_helper';
import { IKey } from 'models/types';
import KeyModel from 'models/key';
import { useTranslation } from 'react-i18next';
import { TKey } from 'locale';
import { FIRST_POSSIBLE_DATE } from 'common/configs';
import KeyCell from './key_cell';
import RowWrapper from './row';
interface IAccessLogProps {
    from?: string | Date;
    to?: string | Date;
    value: Array<IAuditTrail>;
    loading?: boolean;
    keys: Array<IKey>;
    showTimeZoneWarning?: boolean;
}

const AccessLog: React.FunctionComponent<IAccessLogProps> = props => {
    const { value, loading, keys, from, to, showTimeZoneWarning } = props;

    const startDate = from
        ? moment(from).toDate()
        : moment(new Date())
              .subtract(1, 'month')
              .toDate();
    const endDate = moment(to || new Date()).toDate();

    const [dateRange, setDateRange] = useState({ startDate, endDate });
    const [filteredLogs, setFilteredLogs] = useState<Array<IAuditTrail>>([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (value) {
            setFilteredLogs(filterAccessLog(value, dateRange.startDate, dateRange.endDate));
        }
    }, [value, dateRange, setFilteredLogs]);

    const renderKey = (uuid: string) => {
        const key = findHardware(uuid, keys);

        if (key) {
            return <KeyCell keyData={new KeyModel(key as IKey)} />;
        } else {
            return null;
        }
    };

    const renderRows = () => {
        if (loading) {
            return null;
        }

        return filteredLogs.map((log, index) => {
            const { date, result } = log;

            const accessDenied = result === AuditTrailResult.FAILURE;

            return (
                <RowWrapper as={TRow} key={`log_${index}`} disabled={accessDenied}>
                    <TCell>{dateTimeUtils.formatDateString(date, 'MM/DD/YYYY hh:mm A')}</TCell>
                    <TCell>{renderKey(log.keyUuid)}</TCell>
                </RowWrapper>
            );
        });
    };

    const renderTable = () => {
        return (
            <Table overflowY="auto">
                <Thead>
                    <RowWrapper as={TRow}>
                        <TCell width="30%">{t(TKey.cylinderAuditTrailHeadersTime)}</TCell>
                        <TCell>{t(TKey.cylinderAuditTrailHeadersKey)}</TCell>
                    </RowWrapper>
                </Thead>
                <TBody>{renderRows()}</TBody>
            </Table>
        );
    };

    const renderTableGuide = () => {
        return (
            <Flex flexDirection="column">
                <Flex alignItems="center">
                    <Surface mr="half" height="15px" width="15px" bg="white" border="default"></Surface>
                    <Text>{t(TKey.cylinderAuditTrailLegendSuccess)}</Text>
                </Flex>
                <Flex alignItems="center">
                    <Surface mr="half" height="15px" width="15px" bg="error.10" border="default"></Surface>
                    <Text>{t(TKey.cylinderAuditTrailLegendFailure)}</Text>
                </Flex>
            </Flex>
        );
    };

    const renderEmptyTableText = () => {
        if (filteredLogs.length === 0 && !loading) {
            return (
                <Flex alignItems="center" width="100%" p="half">
                    <Text data-testid="log-list-empty-message">{t(TKey.genericListEmpty)}</Text>
                </Flex>
            );
        }

        return null;
    };

    const renderTableLoader = () => {
        if (loading) {
            return (
                <Flex
                    data-testid="table-loader"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="200px"
                    width="100%">
                    <Spinner />
                </Flex>
            );
        }

        return null;
    };

    return (
        <Card height="100%" style={{ overflow: 'hidden' }}>
            <Flex flexDirection="column" width="100%">
                <Flex justifyContent="space-between" alignItems="flex-end">
                    <DateRange
                        minDate={FIRST_POSSIBLE_DATE}
                        startDate={dateTimeUtils.formatDate(dateRange.startDate)}
                        endDate={dateTimeUtils.formatDate(dateRange.endDate)}
                        onChange={newDateRange => {
                            setDateRange({
                                startDate: moment(newDateRange.startDate).toDate(),
                                endDate: moment(newDateRange.endDate).toDate()
                            });
                        }}
                        flexDirection="row"
                    />
                    {renderTableGuide()}
                </Flex>
                <Flex mt="half" flexDirection="column" height="100%" style={{ overflowY: 'auto' }}>
                    {showTimeZoneWarning && (
                        <Text mb="full" color="warning.90">
                            {t(TKey.keyValidityTimezoneWarning)}
                        </Text>
                    )}

                    {renderTable()}
                    {renderTableLoader()}
                    {renderEmptyTableText()}
                </Flex>
            </Flex>
        </Card>
    );
};

export default AccessLog;
