import React from 'react';
import { Card } from 'aa-react-ui';
import MarkdownView from 'components/markdown_view';

const Policy = () => {
    return (
        <Card width="100%" p="full" overflow="hidden">
            <MarkdownView path={require('./policy.md')} />
        </Card>
    );
};

export default Policy;
