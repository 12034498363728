import { KeyStateFilter } from '../types';

export const SET_KEY_FILTER = 'SET_KEY_FILTER';
export const SET_KEY_SEARCH_QUERY = 'SET_KEY_SEARCH_QUERY';
export const SET_CURRENT_SELECTED_KEY = 'SET_CURRENT_SELECTED_KEY';

export const UPDATE_KEY = 'UPDATE_KEY';
export const UPDATE_MULTIPLE_KEYS = 'UPDATE_MULTIPLE_KEYS';

export interface ISetKeyFilterAction {
    type: typeof SET_KEY_FILTER;
    keyFilter: KeyStateFilter;
}

export interface ISetSearchQueryAction {
    type: typeof SET_KEY_SEARCH_QUERY;
    searchQuery: string;
}

export interface ISetCurrentSelectedKey {
    type: typeof SET_CURRENT_SELECTED_KEY;
    keyId: string;
}
