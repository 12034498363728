import React from 'react';
import { Flex, Text } from 'aa-react-ui';
import { ScheduleIcon, ArrowRightIcon } from 'components/icon';
import { useTranslation, TKey } from 'locale';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import ScheduleDuration from 'domain/schedule/components/schedule_duration';
import ScheduleModel from 'models/schedule';
import StyledLink from 'components/styled_link';
import Card from 'components/card';

interface IScheduleProps {
    keyId?: string;
    value?: ScheduleModel;
    disabled?: boolean;
}

const Schedule = (props: IScheduleProps) => {
    const { value, disabled, keyId } = props;
    const { t } = useTranslation();

    if (!keyId || !value) {
        return null;
    }

    const scheduleEditRoute = buildRoute(Routes.KEYS_KEY_SCHEDULE, { keyId });

    return (
        <StyledLink to={scheduleEditRoute} data-testid={`schedule-edit-link-${keyId}`} disabled={disabled}>
            <Card bg={disabled ? 'contrast.10' : 'white'}>
                <Flex flexDirection="column" width="100%">
                    <Flex flexDirection="row">
                        <Flex flexDirection="row" width="100%">
                            <ScheduleIcon stroke="black" strokeWidth="3px" height="32px" width="32px" />
                            <Text pl="half" fontSize="1rem" fontWeight="bold" color="contrast.90">
                                {t(TKey.genericSchedule)}
                            </Text>
                        </Flex>
                        {!disabled && <ArrowRightIcon height="2rem" width="2rem" />}
                    </Flex>
                    <ScheduleDuration value={value} />
                </Flex>
            </Card>
        </StyledLink>
    );
};

export default Schedule;
