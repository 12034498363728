import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IProps {
    disabled?: boolean;
}

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;

    pointer-events: ${(props: IProps) => (props.disabled ? 'none' : 'auto')};

    &:link,
    &:focus,
    &:visited,
    &:hover &:active {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }
`;

export default StyledLink;
