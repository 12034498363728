import {
    SET_CYLINDER_FILTER,
    SET_CYLINDER_SEARCH_QUERY,
    SET_SELECTED_CYLINDER,
    UPDATE_CYLINDER,
    ISetCurrentSelectedCylinder,
    ISetCylinderFilterAction,
    ISetCylinderSearchQueryAction,
    LOAD_ACCESS_LOG,
    UPDATE_MULTIPLE_CYLINDERS
} from './action_types';
import { RequestStatus } from 'app_redux/types';
import { createRequestReducer } from 'app_redux/factory';
import { CylinderFilter, IAuditTrail } from '../types';

export const cylinderFilterReducer = (state: CylinderFilter = CylinderFilter.ALL, action: ISetCylinderFilterAction) => {
    const { type, filter } = action;
    if (type === SET_CYLINDER_FILTER) {
        return filter;
    }

    return state;
};

const defaultSearchQuery = '';

export const cylinderSearchQueryReducer = (
    state: string = defaultSearchQuery,
    action: ISetCylinderSearchQueryAction
) => {
    const { type, searchQuery } = action;

    if (type === SET_CYLINDER_SEARCH_QUERY) {
        return searchQuery || '';
    }

    return state;
};

const defaultSelected = '';

export const selectedCylinderReducer = (state: string = defaultSelected, action: ISetCurrentSelectedCylinder) => {
    const { type, cylinderId = '' } = action;

    if (type === SET_SELECTED_CYLINDER) {
        return cylinderId;
    }

    return state;
};

export const updateCylinderRequestReducer = createRequestReducer(UPDATE_CYLINDER, {
    status: RequestStatus.IDLE,
    error: undefined,
    data: null
});

export const cylinderAccessLogRequestReducer = createRequestReducer<Array<IAuditTrail>>(LOAD_ACCESS_LOG, {
    status: RequestStatus.IDLE,
    error: undefined,
    data: []
});

export const updateMultipleCylindersRequestReducer = createRequestReducer(UPDATE_MULTIPLE_CYLINDERS, {
    status: RequestStatus.IDLE,
    error: undefined,
    data: null
});
