import React, { useState } from 'react';
import moment from 'moment';
import { Label, Box, Flex } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';

import DatePicker, { CustomDateInput } from 'components/date_picker';
import { LAST_POSSIBLE_DATE, FIRST_POSSIBLE_DATE, DEFAULT_DATE_FORMAT } from 'common/configs';
import dateTimeUtils from 'common/utils/date_time';
import { FlexDirectionProps } from 'styled-system';
import { IDateRange } from './types';

interface IDateRangeProps extends FlexDirectionProps {
    onChange: (range: IDateRange) => void;
    disabled?: boolean;
    startDate?: string;
    endDate?: string;
    minDate?: string;
    maxDate?: string;
}

const rangeOffset = 2;

const DateRange: React.FunctionComponent<IDateRangeProps> = props => {
    const defaultStartDate = moment().format(DEFAULT_DATE_FORMAT);
    const defaultEndDate = moment()
        .add(rangeOffset, 'years')
        .format(DEFAULT_DATE_FORMAT);

    const defaultMinDate = moment().format(DEFAULT_DATE_FORMAT);
    const defaultMaxDate = moment(LAST_POSSIBLE_DATE).format(DEFAULT_DATE_FORMAT);

    const { minDate = defaultMinDate, maxDate = defaultMaxDate } = props;
    let { startDate = defaultStartDate, endDate = defaultEndDate } = props;
    const { t } = useTranslation();
    const { disabled, onChange, ...rest } = props;

    if (
        (startDate === FIRST_POSSIBLE_DATE && endDate === LAST_POSSIBLE_DATE) ||
        (startDate === LAST_POSSIBLE_DATE && endDate === FIRST_POSSIBLE_DATE)
    ) {
        startDate = defaultStartDate;
        endDate = defaultEndDate;
        onChange({ startDate, endDate });
    }

    const [range, setRange] = useState({ startDate, endDate });

    const handleChange = (newRange: IDateRange) => {
        if (moment(newRange.endDate).isAfter(newRange.startDate)) {
            onChange(newRange);
            setRange(newRange);
        } else {
            const validNewRange = {
                startDate: newRange.startDate,
                endDate: newRange.startDate
            };
            onChange(validNewRange);
            setRange(validNewRange);
        }
    };

    return (
        <Flex data-testid="date-range" {...rest}>
            <Box m="half">
                <Label>{t(TKey.genericStartDate)}</Label>
                <DatePicker
                    disabled={disabled}
                    value={range.startDate}
                    minDate={new Date(minDate)}
                    maxDate={new Date(maxDate)}
                    selected={new Date(startDate)}
                    onChange={(date: Date) => {
                        const newRange = { startDate: dateTimeUtils.formatDate(date), endDate };
                        handleChange(newRange);
                    }}
                    customInput={<CustomDateInput data-testid="date-picker-input-start-date" />}
                />
            </Box>
            <Box m="half">
                <Label>{t(TKey.genericEndDate)}</Label>
                <DatePicker
                    disabled={disabled}
                    value={range.endDate}
                    minDate={new Date(startDate)}
                    maxDate={new Date(LAST_POSSIBLE_DATE)}
                    selected={new Date(endDate)}
                    onChange={(date: Date) => {
                        const newRange = { startDate, endDate: dateTimeUtils.formatDate(date) };
                        handleChange(newRange);
                    }}
                    customInput={<CustomDateInput data-testid="date-picker-input-end-date" />}
                />
            </Box>
        </Flex>
    );
};

DateRange.defaultProps = {
    flexDirection: 'column'
};

export default DateRange;
