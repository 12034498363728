import React from 'react';
import { Card, H3, Flex, Button, Text, Box } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { Routes } from 'router';
import { Link } from 'react-router-dom';
import preval from 'preval.macro';

import { version } from '../../../package.json';

const timeStamp = preval`module.exports = new Date().getTime()`;
const APP_VERSION = `${version} (${timeStamp})`;

function About(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Flex flexDirection="column" alignItems="center">
            <Card p="3rem">
                <Flex flexDirection="column" m="quarter">
                    <H3 mb="full">{t(TKey.genericAbout)}</H3>

                    <Text>{t(TKey.genericAllRightsReservedYear, { year: 2020 })}</Text>
                    <Text>{`${t(TKey.genericVersion)}: ${APP_VERSION}`}</Text>
                </Flex>

                <Flex flexDirection="column" mt="full">
                    <Box mt="half">
                        <Link to={Routes.ABOUT_LICENCE}>
                            <Button as={Box} size="sm">
                                {t(TKey.genericLicenseAgreement)}
                            </Button>
                        </Link>
                    </Box>

                    <Box mt="half">
                        <Link to={Routes.ABOUT_THIRD_PARTY_LICENSE}>
                            <Button as={Box} size="sm">
                                {t(TKey.genericThirdPartyLicenses)}
                            </Button>
                        </Link>
                    </Box>

                    <Box mt="half">
                        <Link to={Routes.ABOUT_POLICY}>
                            <Button as={Box} size="sm">
                                {t(TKey.genericPrivacyPolicy)}
                            </Button>
                        </Link>
                    </Box>
                </Flex>
            </Card>
        </Flex>
    );
}

export default About;
