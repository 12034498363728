import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getKeyCollections } from 'domain/hardware/redux/selectors';
import { setSearchQuery, setFilter } from 'domain/key/redux/actions';
import { getKeyFilter } from 'domain/key/redux/selectors';

import KeyList from './key_list';

const mapStateToProps = (state: IAppStoreState) => {
    return {
        keyCollections: getKeyCollections(state),
        defaultKeyFilter: getKeyFilter(state)
    };
};

const mapDispatchToProps = {
    setSearchQuery,
    setFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyList);
