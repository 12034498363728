import React, { ReactNode } from 'react';
import { Text, Flex, Button } from 'aa-react-ui';
import { CheckCircle } from 'components/icon';

interface IOptionProps {
    children?: ReactNode;
    label: ReactNode;
    selected?: boolean;
    onClick?: () => void;
}

const Option = (props: IOptionProps) => {
    const { children, label, selected, onClick } = props;

    return (
        <>
            <Button
                data-testid={`validity-option-${label}`}
                bg={selected ? 'contrast.10' : 'transparent'}
                m="quarter"
                onClick={() => onClick && onClick()}>
                <Flex width="100%" flexDirection="row" alignItems="center">
                    <Flex width="100%" justifyContent="flex-start">
                        <Text color="contrast.90">{label}</Text>
                    </Flex>
                    {selected ? <CheckCircle data-testid="check-circle-icon" height="1.5rem" width="1.5rem" /> : null}
                </Flex>
            </Button>
            {children}
        </>
    );
};

export default Option;
