import React from 'react';
import { Alert, Box, Text } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { KeyCollectionType } from 'domain/key/types';

interface IkeyInfoTextProps {
    collectionType?: KeyCollectionType;
}

const KeyInfoText = (props: IkeyInfoTextProps) => {
    const { collectionType: keyGroup } = props;

    const { t } = useTranslation();

    const getKeyInfoText = (group: KeyCollectionType) => {
        switch (group) {
            case KeyCollectionType.HARMFUL:
                return TKey.keyDetailsInfoDangerous;
            case KeyCollectionType.PENDING:
                return TKey.keyDetailsInfoNeedsUpdate;
            case KeyCollectionType.WITH_TASKS:
                return TKey.keyDetailsInfoUpdaterKey;
            case KeyCollectionType.OTHER:
                return TKey.keyDetailsInfoUpToDate;
            default:
                return TKey.keyDetailsInfoUpToDate;
        }
    };

    if (!keyGroup) {
        return null;
    }

    return (
        <Box p="quarter">
            <Alert text={<Text data-testid="key-info-text">{t(getKeyInfoText(keyGroup))}</Text>} variant="warning" />
        </Box>
    );
};

export default KeyInfoText;
