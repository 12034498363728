import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'aa-react-ui';

interface IProps {
    children?: ReactNode;
}

const CollapseWrapper = styled(Box)`
    flex-shrink: 0;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.025), 0 4px 4px rgba(0, 0, 0, 0.025),
        0 8px 8px rgba(0, 0, 0, 0.025), 0 16px 16px rgba(0, 0, 0, 0.025), 0 32px 32px rgba(0, 0, 0, 0.025);
`;

const ContentWrapper = styled(Flex)``;

ContentWrapper.defaultProps = {
    bg: 'primary.60',
    p: 'full',
    color: 'white',
    id: 'app-header'
};

const TopHeader = (props: IProps) => {
    return (
        <CollapseWrapper>
            <ContentWrapper>{props.children}</ContentWrapper>
        </CollapseWrapper>
    );
};

export default TopHeader;
