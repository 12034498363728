import storageService from 'common/services/storage_service';

import { SET_LOGGED_IN, SetIsLoggedInActionType } from './action_types';
import { ISessionState } from './types';
import directoryService from 'common/services/directory_service';

const IS_LOGGED_IN = 'SESSION_KEY:IS_LOGGED_IN';

const defaultState: ISessionState = {
    isLoggedIn: storageService.getItem(IS_LOGGED_IN, true),
    system: directoryService.getDefaultSystem()
};

const sessionReducer = (state: ISessionState = defaultState, action: SetIsLoggedInActionType) => {
    const { type, isLoggedIn, system } = action;

    if (type === SET_LOGGED_IN) {
        storageService.setItem(IS_LOGGED_IN, isLoggedIn, true);
        return {
            ...state,
            isLoggedIn,
            system
        };
    }
    return state;
};

export { sessionReducer };
