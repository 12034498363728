import React, { useEffect } from 'react';
import { History } from 'history';
import { Routes } from 'router';
import { Button, FormGroup, Text, Box } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { BrandLogo } from 'domain/authentication/components';
import BaseContainer from 'components/base_container';

interface IProps {
    history: History;
}

const Home = (props: IProps): JSX.Element => {
    const { history } = props;

    const { t } = useTranslation();

    //TODO: no home page
    useEffect(() => {
        history.push(Routes.AUTH);
    }, [history]);

    return (
        <BaseContainer>
            <BrandLogo />
            <Box width="20rem" marginTop="24px">
                <FormGroup>
                    <Button
                        data-testid="activate"
                        width="100%"
                        onClick={(): void => {
                            history.push(Routes.ABOUT_ACTIVATE);
                        }}>
                        <Text as="strong">{t(TKey.actionActivate)}</Text>
                    </Button>
                </FormGroup>
                <FormGroup>
                    <Button
                        data-testid="login"
                        variant="info"
                        width="100%"
                        onClick={(): void => {
                            history.push(Routes.AUTH);
                        }}>
                        <Text as="strong">{t(TKey.actionLogin)}</Text>
                    </Button>
                </FormGroup>
            </Box>
        </BaseContainer>
    );
};

export default Home;
