import { connect } from 'react-redux';

import { getSelectedKey } from 'domain/key/redux/selectors';
import { IAppStoreState } from 'app_redux/types';
import KeyNameEditor from './key_name_editor';

const maptStateToProps = (state: IAppStoreState) => {
    return {
        keyData: getSelectedKey(state)
    };
};

export default connect(maptStateToProps)(KeyNameEditor);
