import { createSelector } from 'reselect';
import { ISessionState } from './types';
import { IAppStoreState } from 'app_redux/types';
import dateTimeUtils from 'common/utils/date_time';

const sessionSelector = (state: IAppStoreState) => {
    return state.session;
};

export const getIsLoggedIn = createSelector(sessionSelector, (session: ISessionState) => session.isLoggedIn);

export const getAppSession = createSelector(sessionSelector, (session: ISessionState) => session);

export const getSystemUtcOffset = createSelector(sessionSelector, (session: ISessionState) => {
    const { system } = session;

    if (system) {
        return dateTimeUtils.getTimeZoneUtcOffset(system.timeZone);
    }

    return Infinity;
});
