import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

const Link = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: ${p => p.theme.space.full};
    text-decoration: none;
    border-image-width: 0px;
    border: 0px;
    color: white;
    outline: none;

    & svg {
        margin-bottom: ${p => p.theme.space.half};
    }

    &.active {
        background-color: ${p => p.theme.colors.contrast['80']};
    }

    &:hover {
        border-image-width: 0px;
        border: 0px;
        color: white;
        text-decoration: none;
        background-color: ${p => p.theme.colors.contrast['60']};
    }
`;

export default Link;
