import React, { useState } from 'react';
import { Box, Alert } from 'aa-react-ui';
import { useFilteredKeys } from 'domain/key/redux/hooks';
import { KeyRadioList } from 'domain/key/components';
import { TKey, useTranslation } from 'locale';
import { useHistory } from 'react-router-dom';
import { Routes } from 'router';
import { KeyState } from 'models/types';
import StepView from 'components/wizard/step_view';
import { buildRoute } from 'router/helper';

const SelectKey = () => {
    const history = useHistory();
    const inStockKeys = useFilteredKeys(KeyState.IN_STOCK);
    const { t } = useTranslation();
    const [selectedKeyId, setSelectedKeyId] = useState('');

    const onBack = () => {
        history.push(Routes.DASHBOARD);
    };

    const onSubmit = () => {
        history.push(buildRoute(Routes.HAND_OUT_SETTINGS, { keyId: selectedKeyId }));
    };

    return (
        <StepView
            title={t(TKey.handOutHeader)}
            subTitle={t(TKey.genericAvailable)}
            onBack={onBack}
            submitText={t(TKey.actionNext)}
            disableSubmit={!selectedKeyId}
            onSubmit={onSubmit}>
            <Box mb="half" m="quarter">
                <Alert text={t(TKey.handOutSelectKeyBody)} />
            </Box>

            <KeyRadioList
                items={inStockKeys}
                onSelect={key => {
                    setSelectedKeyId(key.uuid);
                }}
            />
        </StepView>
    );
};

export default SelectKey;
