import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useKey, useUpdateKeyRequest } from 'domain/key/redux/hooks';
import { Routes } from 'router';
import { KeyState, IKey } from 'models/types';
import { useNotification } from 'domain/notification/redux/hooks';
import { TKey } from 'locale';

import useWizard from 'components/wizard/useWizard';
import Name from './name';
import Validity from './validity';
import Schedule from './schedule';
import Access from './access';
import Summary from './summary';
import CommonUtils from 'common/utils/common';
import { buildRoute } from 'router/helper';

import RouteGuard from 'components/route-guard';

const SelectSettings = () => {
    const history = useHistory();
    const params = useParams<{ keyId: string }>();
    const location = useLocation<{ from: Location }>();
    const keyToHandOut = useKey(params.keyId);
    const { updateKey, loading } = useUpdateKeyRequest();
    const { showError, showSuccess } = useNotification();
    const [hasSaved, setHasSaved] = useState<boolean>(false);

    const onWizardFinish = async (finalData?: IKey) => {
        if (finalData) {
            const keyToHandOut = CommonUtils.cloneDeep(finalData);

            keyToHandOut.state = KeyState.HANDED_OUT;
            const updateSuccess = await updateKey(keyToHandOut.uuid, keyToHandOut);
            if (updateSuccess) {
                setHasSaved(true);
                showSuccess(TKey.handOutSuccess);
                history.push(buildRoute(Routes.KEYS_KEY, { keyId: keyToHandOut.uuid }));
                return;
            }

            showError(TKey.handOutError);
        }
    };

    const onWizardBack = () => {
        const { state } = location;
        if (state && state.from) {
            history.push(state.from.pathname);
            return;
        }

        history.push(Routes.HAND_OUT);
    };

    const { Wizard, goBack, goNext, data, setData } = useWizard<IKey | undefined>(
        keyToHandOut,
        onWizardFinish,
        onWizardBack
    );

    useEffect(() => {
        if (keyToHandOut) {
            setData(keyToHandOut);
        }
    }, [keyToHandOut, setData]);

    if (!data) {
        return null;
    }

    return (
        <>
            <RouteGuard when={hasSaved === false} />
            <Wizard>
                <Name onNext={goNext} onBack={goBack} data={data} setData={setData} />
                <Validity onNext={goNext} onBack={goBack} data={data} setData={setData} />
                <Schedule onNext={goNext} onBack={goBack} data={data} setData={setData} />
                <Access onNext={goNext} onBack={goBack} data={data} setData={setData} />
                <Summary onNext={goNext} onBack={goBack} data={data} setData={setData} loading={loading} />
            </Wizard>
        </>
    );
};

export default SelectSettings;
