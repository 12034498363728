import KeyModel from 'models/key';
import { ICylinder, AuthorizationState } from 'models/types';

export interface ICylinderAccessKeyCollectioMap {
    toBeBlockedInCylinder: IKeyCollection;
    toBeRemoved: IKeyCollection;
    toBeAdded: IKeyCollection;
    upToDate: IKeyCollection;
    blockedInCylinder: IKeyCollection;
}

export interface IKeyCollectionMap {
    harmful: IKeyCollection;
    pending: IKeyCollection;
    withTasks: IKeyCollection;
    updated: IKeyCollection;
}

export enum KeyStateFilter {
    ALL = 'ALL',
    HANDED_OUT = 'HANDED_OUT',
    IN_STOCK = 'IN_STOCK',
    BLOCKED = 'BLOCKED'
}

export enum KeyStateInt {
    BLOCKED = 1,
    HANDED_OUT = 2,
    IN_STOCK = 3,
    UNKNOWN = 100
}

export enum KeyCollectionType {
    HARMFUL = 'HARMFUL',
    PENDING = 'PENDING',
    WITH_TASKS = 'WITH_TASKS',
    OTHER = 'OTHER',
    TO_BE_BLOCKED_IN_CYLINDER = 'TO_BE_BLOCKED_IN_CYLINDER',
    TO_BE_ADDED = 'TO_BE_ADDED',
    TO_BE_REMOVED = 'TO_BE_REMOVED',
    BLOCKED_IN_CYLINDER = 'BLOCKED_IN_CYLINDER',
    UNKNOWN = 'UNKNOWN',
    UP_TO_DATE = 'UP_TO_DATE'
}

export interface IKeyCollection {
    type: KeyCollectionType;
    items: Array<KeyModel>;
}

export enum KeyPendingStatus {
    ACCESS = 'ACCESS',
    SCHEDULE = 'SCHEDULE',
    TASKS = 'TASKS',
    VALIDITY = 'VALIDITY'
}

export interface IKeyAccessInfo {
    state: AuthorizationState;
    keyId: string;
    cylinder: ICylinder;
}

export enum HandInOption {
    KEEP_SETTINGS = 'KEEP_SETTINGS',
    RESET_SETTINGS = 'RESET_SETTINGS'
}
