import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { TKey, useTranslation } from 'locale';

import { Surface, Label, Text } from 'aa-react-ui';

import TimeSlotContainer from './container';
import DayWrapper from './wrapper';

interface DayProps {
    day: string;
    title: string;
}

function Day(props: DayProps) {
    const { day, title } = props;

    const { t } = useTranslation();
    const { fields } = useFieldArray(`days.${day}`);

    const inactive = fields.length === 0;

    const DAY_LABEL = t(title);
    const INACTIVE_LABEL = t(TKey.keyScheduleOffDay);

    return (
        <DayWrapper>
            <Surface mb="half" borderBottom="default">
                <Label>{DAY_LABEL}</Label>
            </Surface>
            {inactive && <Text muted>{INACTIVE_LABEL}</Text>}

            {fields.map((name: string, index: number) => (
                <TimeSlotContainer
                    key={index}
                    name={name}
                    remove={() => fields.remove(index)}
                    update={(value: object) => {
                        fields.update(index, value);
                    }}
                />
            ))}
        </DayWrapper>
    );
}

export default Day;
