import { combineReducers } from 'redux';

import { sessionReducer } from 'domain/authentication/redux/reducers';
import { localeReducer } from 'domain/locale/redux/reducers';
import { hardwareReducer } from 'domain/hardware/redux/reducers';
import {
    keySearchQueryReducer,
    keyFilterReducer,
    selectedKeyReducer,
    updateKeyRequestReducer,
    updateMultipleKeysRequestReducer
} from 'domain/key/redux/reducers';

import { notificationReducer } from 'domain/notification/redux/reducers';
import {
    cylinderFilterReducer,
    cylinderSearchQueryReducer,
    selectedCylinderReducer,
    updateCylinderRequestReducer,
    cylinderAccessLogRequestReducer,
    updateMultipleCylindersRequestReducer
} from 'domain/cylinder/redux/reducers';

const keyReducer = combineReducers({
    searchQuery: keySearchQueryReducer,
    filter: keyFilterReducer,
    selectedKeyId: selectedKeyReducer,
    updateRequestState: updateKeyRequestReducer,
    updateMultipleRequestState: updateMultipleKeysRequestReducer
});

const cylinderReducer = combineReducers({
    searchQuery: cylinderSearchQueryReducer,
    filter: cylinderFilterReducer,
    selectedCylinderId: selectedCylinderReducer,
    updateRequestState: updateCylinderRequestReducer,
    accessLogRequestState: cylinderAccessLogRequestReducer,
    updateMultipleRequestState: updateMultipleCylindersRequestReducer
});

const reducersMap = {
    session: sessionReducer,
    locale: localeReducer,
    notifications: notificationReducer,

    hardware: hardwareReducer,
    key: keyReducer,
    cylinder: cylinderReducer
};

/* eslint-disable-next-line */
export default combineReducers(reducersMap as any);
