import React, { useState } from 'react';
import { Box, Surface, Text, Button, Flex } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import storageService from 'common/services/storage_service';
import styled from 'styled-components';

const COOKIE_ACCEPTANCE = 'SESSION_KEY:COOKIE_ACCEPTANCE';

const cookieAccpetance = storageService.getItem(COOKIE_ACCEPTANCE, true);

const InfoText = styled(Text)`
    font-size: '1rem';
    @media (min-width: 320px) {
        font-size: '0.5rem';
    }
`;

const InfoContainer = styled(Flex)`
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 500px) {
        flex-direction: column;
        justify-content: space-between;
    }
`;

const CookieBanner = () => {
    const { t } = useTranslation();
    const [showCookieBanner, setShowCookieBanner] = useState(!cookieAccpetance);

    if (!showCookieBanner) {
        return null;
    }

    return (
        <Box position="absolute" bottom={0} width="100%" zIndex={999999}>
            <Surface bg="contrast.30" padding="1rem">
                <InfoContainer>
                    <Box width="80%">
                        <InfoText>{t(TKey.cookieInfo)}</InfoText>
                    </Box>
                    <Box marginTop="1rem">
                        <Button
                            onClick={() => {
                                storageService.setItem(COOKIE_ACCEPTANCE, true, true);
                                setShowCookieBanner(false);
                            }}>
                            {t(TKey.actionCookieAccept)}
                        </Button>
                    </Box>
                </InfoContainer>
            </Surface>
        </Box>
    );
};

export default CookieBanner;
