import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from 'router';

import SelectCylinder from './select_cylinder';
import VerifySelection from './verify_selection';
import SelectKey from './select_key';
import SelectionSummary from './selection_summary';
import SuccessSummary from './success_summary';

const AccessLogRequest = () => {
    return (
        <>
            <Route exact path={Routes.ACCESS_LOG_REQUEST} component={SelectCylinder} />
            <Route exact path={Routes.ACCESS_LOG_REQUEST_VERIFY} component={VerifySelection} />
            <Route exact path={Routes.ACCESS_LOG_REQUEST_SELECT_KEY} component={SelectKey} />
            <Route exact path={Routes.ACCESS_LOG_REQUEST_SUMMARY} component={SelectionSummary} />
            <Route exact path={Routes.ACCESS_LOG_REQUEST_SUCCESS} component={SuccessSummary} />
        </>
    );
};

export default AccessLogRequest;
