import { Box } from 'aa-react-ui';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

interface IProps {
    disabled?: boolean;
}

const RowWrapper = styled(Box)<IProps>`
    display: table-row;
    background-color: ${props => (props.disabled ? themeGet('colors.error.10') : 'white')};
`;

export default RowWrapper;
