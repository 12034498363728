export enum CylinderState {
    IN_STOCK = 'IN_STOCK',
    INSTALLED = 'INSTALLED',
    UNKNOWN = 'UNKNOWN'
}

export enum HardwareStateInt {
    BLOCKED = 3,
    IN_STOCK = 2,
    HANDED_OUT = 1,
    INSTALLED = 0
}

export enum KeyState {
    BLOCKED = 'BLOCKED',
    IN_STOCK = 'IN_STOCK',
    HANDED_OUT = 'HANDED_OUT',
    UNKNOWN = 'UNKNOWN'
}

export enum TaskType {
    READ_AUDIT_TRAIL = 'READ_AUDIT_TRAIL',
    BLOCK_KEY = 'BLOCK_KEY',
    UNBLOCK_KEY = 'UNBLOCK_KEY'
}

export enum TaskState {
    ASSIGNED = 'ASSIGNED',
    UNASSIGNED = 'UNASSIGNED',
    PENDING_ASSIGNED = 'PENDING_ASSIGNED',
    PENDING_UNASSIGNED = 'PENDING_UNASSIGNED'
}

export interface ISchedule {
    programmedIntervals: Array<string>;
    desiredIntervals: Array<string>;
}

export interface IValidity {
    desiredInterval: string;
    programmedInterval: string;
}

export interface ICliqIdentity {
    aaCode: number;
    mksName: string;
    mksNamePadded: string;
    gr: number;
    uid: number;
}

export interface ITask {
    type: TaskType;
    startDate: string;
    state: TaskState;
}

export interface IKeyAuthorization {
    keyUuid: string;
    state: AuthorizationState;
}

export enum AuthorizationState {
    ADDED = 'ADDED',
    REMOVED = 'REMOVED',
    CURRENT = 'CURRENT',
    PLACEHOLDER = 'PLACEHOLDER'
}

export enum AuthorizationStateInt {
    ADDED = 0,
    REMOVED = 1,
    CURRENT = 2,
    PLACEHOLDER = 3,
    DEFAULT = -1
}

export interface ICylinderAuthorization {
    cylinderUuid: string;
    state: AuthorizationState;
}

export interface IHardware {
    version: string;
    uuid: string;
    marking: string;
    name: string;
}

export interface IKey extends IHardware {
    authorizations: Array<ICylinderAuthorization>;
    cliqIdentity: ICliqIdentity;
    marking: string;
    name: string;
    schedule: ISchedule;
    state: KeyState;
    validity: IValidity;
    associatedTasks?: {
        started: Array<ICylinder>;
        pending: Array<ICylinder>;
    };
    loals?: Array<ICylinder>;
}

export interface ICylinder extends IHardware {
    assignedToKeyUuid: string | null;
    auditTrailRequested: boolean;
    cliqIdentity: ICliqIdentity;
    state: CylinderState;
    tasks: Array<ITask>;
    unauthorizedKeys: Array<IKeyAuthorization>;
    versionPlug: string;
    louks?: Array<IKey>;
}
