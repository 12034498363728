import { useSelector, useDispatch } from 'react-redux';
import {
    getAllCylinders,
    getUpdateCylinderRequestStatus,
    getUpdateMultipleCylindersRequestStatus,
    getSelectedCylinderId,
    getAccessLogRequest
} from './selectors';
import { updateCylinder, updateMultipleCylinders, setCurrentSelectedCylinder, loadAccessLog } from './actions';
import { ICylinder } from 'models/types';
import { RequestStatus } from 'app_redux/types';
import { useCallback } from 'react';
import CylinderModel from 'models/cylinder';

export const useCylinder = (cylinderId: string) => {
    const allCylinders = useSelector(getAllCylinders);
    return allCylinders.find(item => item.uuid === cylinderId);
};

export const useCylinders = () => useSelector(getAllCylinders);

export const useUpdateCylinderRequest = () => {
    const dispatch = useDispatch();
    const updateRequestStatus = useSelector(getUpdateCylinderRequestStatus);
    const multipleUpdateRequestStatus = useSelector(getUpdateMultipleCylindersRequestStatus);

    const updateSingle = useCallback(
        (cylinderId: string, data: ICylinder) => {
            const dataModel = new CylinderModel(data);
            return updateCylinder(cylinderId, dataModel.toDataTransferObject())(dispatch);
        },
        [dispatch]
    );
    const updateMultiple = useCallback(
        (data: Array<ICylinder>) => {
            const dataObjects = data.map(item => {
                const dataModel = new CylinderModel(item);
                return dataModel.toDataTransferObject();
            });

            return updateMultipleCylinders(dataObjects)(dispatch);
        },
        [dispatch]
    );

    return {
        updateCylinder: updateSingle,
        updateMultipleCylinders: updateMultiple,
        loading:
            updateRequestStatus === RequestStatus.IN_PROGRESS ||
            multipleUpdateRequestStatus === RequestStatus.IN_PROGRESS
    };
};

export const useCylinderSelection = () => {
    const dispatch = useDispatch();
    const selectedCylinderId = useSelector(getSelectedCylinderId);

    const setSelectedCylinderId = useCallback(
        (cylinderId: string) => {
            setCurrentSelectedCylinder(cylinderId)(dispatch);
        },
        [dispatch]
    );

    return {
        selectedCylinderId,
        setSelectedCylinderId
    };
};

export const useAccessLogs = () => {
    const dispatch = useDispatch();

    const { status, data } = useSelector(getAccessLogRequest);
    const loadLogs = useCallback(cylinderId => loadAccessLog(cylinderId)(dispatch), [dispatch]);

    return {
        loadLogs,
        accessLogs: data || [],
        loading: status === RequestStatus.IN_PROGRESS
    };
};
