import styled from 'styled-components';
import { transparentize } from 'polished';
import { Surface } from 'aa-react-ui';
import { themeGet } from '@styled-system/theme-get';

const transparantAmount = 0.5;

const Card = styled(Surface)`
    display: flex;
    flex-direction: row;
    text-overflow: ellipsis;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin: 0.2rem;
    text-decoration: none !important;

    border-radius: ${p => themeGet('radii.default')(p)};

    :hover {
        border-color: ${p => transparentize(transparantAmount, themeGet('colors.contrast.40')(p))};
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    }
`;

Card.defaultProps = {
    border: 'default',
    p: 'full'
};

export default Card;
