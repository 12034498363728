import React, { useEffect } from 'react';
import { Surface, Flex, Text, Box } from 'aa-react-ui';
import { AssaAbloyLogo } from 'domain/authentication/components';
import { useAppLocale } from 'domain/locale/redux/hooks';

import { useHardwareData } from 'domain/hardware/redux/hooks';

import SideBar from '../components/side_bar';
import Header from 'components/header';
import LocaleSelector from 'domain/locale/components/locale_selector';
import NewDataConfirmation from './newDataNotification';
import { useAppSession } from 'domain/authentication/redux/hooks';

function NavigationHeader() {
    const { locale, setLocale } = useAppLocale();
    const { session } = useAppSession();
    return (
        <Header>
            <AssaAbloyLogo width="10rem" />
            <Text fontSize="18px" paddingLeft="0.2rem">
                CLIQ™ Go
            </Text>
            <Box position="absolute" top={0} right={0}>
                <Flex alignItems="center">
                    {session.system && <Text>{session.system.displayName}</Text>}
                    <LocaleSelector
                        value={locale}
                        onChange={newLocale => {
                            setLocale(newLocale);
                        }}
                    />
                </Flex>
            </Box>
        </Header>
    );
}

const DashboardLayout: React.FunctionComponent = props => {
    const { children } = props;
    const { loadData } = useHardwareData();

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <>
            <Surface height="100vh" width="100%" bg="contrast.20" position="fixed">
                <NavigationHeader />
                <Flex height="inherit" paddingTop="4rem">
                    <SideBar />
                    <Flex padding="0.5rem" width="100%" height="100%">
                        {children}
                    </Flex>
                </Flex>
            </Surface>

            <NewDataConfirmation />
        </>
    );
};

export default DashboardLayout;
