import React, { memo } from 'react';

import { Flex } from 'aa-react-ui';

import { getCylinderBackgroundColor } from 'domain/cylinder/cylinder_helper';

import Card from 'components/card';

import Content from './cylinder_collection_item_content';

interface CylinderListItem {
    name: string;
    marking: string;
    onClick?: () => void;
    iconColor?: string;
    selected?: boolean;
    disabled?: boolean;
    hideStatus?: boolean;
    showTaskInfo?: boolean;
    children?: JSX.Element;
}

const Item: React.FunctionComponent<CylinderListItem> = props => {
    const { onClick, iconColor, name, marking, selected, disabled, hideStatus, showTaskInfo, children } = props;

    return (
        <Card as="label" bg={getCylinderBackgroundColor(disabled, selected)} onClick={onClick}>
            <Content
                iconColor={iconColor}
                name={name}
                marking={marking}
                hideStatus={hideStatus}
                showTaskInfo={showTaskInfo}
            />
            {children && <Flex ml="auto">{children}</Flex>}
        </Card>
    );
};

export default memo(Item, (prevProps: CylinderListItem, nextProps: CylinderListItem) => {
    const { selected: prevState } = prevProps;
    const { selected: nextState } = nextProps;

    const stateMatch = prevState === nextState;

    return stateMatch;
});
