import React from 'react';
import { Flex, FlexItem } from 'aa-react-ui';
import KeyList from './key_list';
import KeyDetails from './key_details';

function Keys(): JSX.Element {
    return (
        <Flex width="100%">
            <FlexItem width="30%" m="quarter">
                <KeyList />
            </FlexItem>
            <FlexItem width="70%" m="quarter">
                <KeyDetails />
            </FlexItem>
        </Flex>
    );
}

export default Keys;
