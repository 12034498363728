enum Routes {
    ROOT = '/',
    AUTH = '/auth',
    FORGOT_PASSWORD = '/auth/forgot-password',
    LOGOUT = '/logout',
    DASHBOARD = '/go',
    KEYS = '/go/keys',
    KEYS_KEY = '/go/keys/:keyId?',
    KEYS_KEY_VALIDITY = '/go/keys/:keyId/validity',
    KEYS_KEY_SCHEDULE = '/go/keys/:keyId/schedule',
    KEYS_KEY_NAME = '/go/keys/:keyId/name',
    KEYS_KEY_ACCESS = '/go/keys/:keyId/access',
    CYLINDERS = '/go/cylinders',
    CYLINDERS_CYLINDER = '/go/cylinders/:cylinderId?',
    CYLINDERS_CYLINDER_NAME = '/go/cylinders/:cylinderId/name',
    CYLINDERS_CYLINDER_INSTALL = '/go/cylinders/:cylinderId/install',
    CYLINDERS_CYLINDER_UNINSTALL = '/go/cylinders/:cylinderId/uninstall',
    CYLINDERS_CYLINDER_ACCESS_LOG = '/go/cylinders/:cylinderId/access-log',
    CYLINDERS_CYLINDER_ACCESS = '/go/cylinders/:cylinderId/access',
    TASK_LIST = '/go/tasklist',
    ACTIVATE = '/activate',
    HAND_IN = '/go/hand-in',
    HAND_IN_SETTINGS = '/go/hand-in/:keyId/settings',
    ACCESS_LOG_REQUEST = '/go/access-log-request',
    ACCESS_LOG_REQUEST_VERIFY = '/go/access-log-request/:cylinderId/verify',
    ACCESS_LOG_REQUEST_SELECT_KEY = '/go/access-log-request/:cylinderId/select-key',
    ACCESS_LOG_REQUEST_SUMMARY = '/go/access-log-request/:cylinderId/summary/:keyId?',
    ACCESS_LOG_REQUEST_SUCCESS = '/go/access-log-request/:cylinderId/success/:keyId?',
    BLOCK_KEY = '/go/block-key',
    BLOCK_KEY_VERIFY = '/go/block-key/:keyId/verify',
    BLOCK_KEY_TASK_ASSIGN_KEY = '/go/block-key/:keyId/task-key',
    BLOCK_KEY_SUMMARY = '/go/block-key/:keyId/summary/:taskKeyId?',
    BLOCK_KEY_SUCCESS = '/go/block-key/:keyId/success/:assigned?',
    UNBLOCK_KEY = '/go/unblock-key',
    UNBLOCK_KEY_VERIFY = '/go/unblock-key/:keyId/verify',
    UNBLOCK_KEY_TASK_KEY = '/go/unblock-key/:keyId/task-key',
    UNBLOCK_KEY_SUMMARY = '/go/unblock-key/:keyId/summary/:taskKeyId?',
    UNBLOCK_KEY_SUCCESS = '/go/unblock-key/:keyId/success/:assigned?',
    ABOUT = '/about',
    ABOUT_THIRD_PARTY_LICENSE = '/about/thirpartylicense',
    ABOUT_POLICY = '/about/policy',
    ABOUT_LICENCE = '/about/license',
    ABOUT_ACTIVATE = '/about/activate',
    HAND_OUT = '/go/hand-out',
    HAND_OUT_SETTINGS = '/go/hand-out/:keyId/settings',
    COPY_DETAILS = '/go/copy-details/:keyId'
}

export default Routes;
