import React, { useState, useEffect } from 'react';
import { Flex } from 'aa-react-ui';
import ReactMarkdown from 'react-markdown';

interface IMarkdownViewProps {
    path: string;
}

const MarkdownView = (props: IMarkdownViewProps) => {
    const { path } = props;
    const [data, setData] = useState('');

    useEffect(() => {
        fetch(path)
            .then(response => response.text())
            .then(text => {
                setData(text);
            });
    }, [path]);

    return (
        <Flex flexDirection="column">
            <ReactMarkdown source={data}></ReactMarkdown>
        </Flex>
    );
};

export default MarkdownView;
