import React from 'react';
import { Text } from 'aa-react-ui';

import { useTranslation, TKey } from 'locale';
import moment from 'moment';
import { ValidityState, IValidityStateData } from 'domain/validity/types';

const displayDateFormat = 'MMM DD, YYYY';

interface IProps extends IValidityStateData {
    color?: string;
}

const ValidityInfoText = (props: IProps) => {
    const { state, startDate, endDate, color } = props;

    const { t } = useTranslation();

    const getValidityStateTranslationKey = () => {
        switch (state) {
            case ValidityState.ALWAYS:
                return TKey.keyValidityAlways;
            case ValidityState.NEVER:
                return TKey.keyValidityNever;
            case ValidityState.RANGE:
                return TKey.keyValidityRange;
            default:
                return '';
        }
    };

    const getDateRangeText = (forState: ValidityState, from: string, to: string) => {
        if (forState === ValidityState.RANGE) {
            return `(${t(TKey.keyValidityFromXToY, {
                x: moment(from).format(displayDateFormat),
                y: moment(to).format(displayDateFormat)
            })})`;
        }
        return '';
    };

    return (
        <Text fontSize="1rem" color={color} data-testid="validity-info-text">
            {t(getValidityStateTranslationKey())}
            <Text pl="half">{getDateRangeText(state, startDate, endDate)}</Text>
        </Text>
    );
};

export default ValidityInfoText;
