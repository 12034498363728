import React, { forwardRef, Ref } from 'react';
import { Button } from 'aa-react-ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomDateInput = (props: any, ref: Ref<HTMLButtonElement>) => {
    const { value, onClick, onKeyDown, disabled, ...rest } = props;

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'ArrowRight') {
            e.key = 'PageDown';
        }

        if (e.key === 'ArrowLeft') {
            e.key = 'PageUp';
        }

        onKeyDown(e);
    };

    return (
        <Button
            ref={ref}
            data-testid="custom-date-input-button"
            onKeyDown={handleKeyDown}
            disabled={disabled}
            width="100%"
            onClick={onClick}
            {...rest}>
            {value}
        </Button>
    );
};

export default forwardRef(CustomDateInput);
