import React from 'react';
import { Text, Box, Alert } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { useParams, useHistory } from 'react-router-dom';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import LinkButton from 'components/link_button';
import StepView from 'components/wizard/step_view';

const Success = () => {
    const params = useParams<{ keyId: string; assigned?: string }>();
    const history = useHistory();

    const { t } = useTranslation();

    const onConfirm = () => {
        history.push(buildRoute(Routes.KEYS_KEY, { keyId: params.keyId }));
    };

    const renderSuccessMessage = () => {
        if (params.assigned) {
            return t(TKey.blockKeySuccessWithAssignment);
        } else {
            return t(TKey.blockKeySuccessNoAssignment);
        }
    };

    return (
        <StepView title={t(TKey.blockKeyHeader)} subTitle={t(TKey.genericSuccessHeader)} hideBack onSubmit={onConfirm}>
            <Box m="quarter">
                <Alert
                    variant="warning"
                    text={
                        <Text>
                            {renderSuccessMessage()}
                            <LinkButton to={Routes.TASK_LIST} ml="half" size="sm" variant="primary">
                                {t(TKey.genericTaskList)}
                            </LinkButton>
                        </Text>
                    }
                />
            </Box>
        </StepView>
    );
};

export default Success;
