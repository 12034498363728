import React from 'react';
import { Flex, Box } from 'aa-react-ui';
import styled from 'styled-components';
import FadeView from 'components/fade_view';
import Portal from 'components/portal';

const ModalContentContainer = styled(Box)`
    min-width: 300px;
    max-height: 100%;
    max-width: 70rem;
`;

interface IWrapperProps {
    isOpen: boolean;
}

const ModalWrapper = styled(Box)`
    display: ${(props: IWrapperProps) => (props.isOpen ? 'block' : 'none')};
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
`;

const ModalContent = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.4);
`;

interface IModalProps {
    isOpen: boolean;
}

const BaseModal: React.FunctionComponent<IModalProps> = props => {
    const { children, isOpen, ...rest } = props;

    return (
        <Portal>
            <ModalWrapper isOpen={isOpen}>
                {isOpen ? (
                    <FadeView>
                        <ModalContent>
                            <ModalContentContainer {...rest}>
                                <Flex flexDirection="column" height="100%" width="100%">
                                    {children}
                                </Flex>
                            </ModalContentContainer>
                        </ModalContent>
                    </FadeView>
                ) : null}
            </ModalWrapper>
        </Portal>
    );
};

export default BaseModal;
