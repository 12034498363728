import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './action_type';
import { INotification } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notificationReducer = (state: Array<INotification> = [], action: any) => {
    const { id, notification, type } = action;

    switch (type) {
        case ADD_NOTIFICATION:
            return [notification, ...state];

        case REMOVE_NOTIFICATION:
            return state.filter(item => item.id !== id);

        default:
            return state;
    }
};
