import { ISchedule } from 'models/types';
import { FormattedInterval } from 'domain/schedule/types';
import { DAYS_ENUMS } from 'common/constants';

export const FULL_WEEK_TIME_PERIOD = 'R/-W--T00:00/T24:00';

/**
 * Check if a schedule is enabled or not.
 * If a scheme is NOT enabled for a key. The key will have a "FULL_WEEK_TIME_PERIOD"
 * added to the scheme only in order to make the key valid all hours.
 * @param scheme scheme object
 */
function isEnabled(scheme: ISchedule): boolean {
    const { desiredIntervals } = scheme;

    return !desiredIntervals.includes(FULL_WEEK_TIME_PERIOD);
}

/**
 * Sorts a Formatted interval array by days.
 * @param a Formatted interval
 * @param b Formatted interval
 */
function sortByDay(a: FormattedInterval, b: FormattedInterval) {
    const { day: dayA } = a || {};
    const { day: dayB } = b || {};

    const aIndex = DAYS_ENUMS.indexOf(dayA);
    const bIndex = DAYS_ENUMS.indexOf(dayB);

    return aIndex - bIndex;
}

/**
 * Compare two intervals if they have the same data.
 */
const compareIntervals = (a: FormattedInterval) => (b: FormattedInterval) => {
    return (
        a.startHour === b.startHour &&
        a.startMinutes === b.startMinutes &&
        a.endHour === b.endHour &&
        a.endMinutes === b.endMinutes
    );
};

export { isEnabled, sortByDay, compareIntervals };
