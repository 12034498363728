import React, { useState } from 'react';
import { Select, Flex } from 'aa-react-ui';

import { useTranslation, SupportedLocale, TKey } from 'locale';

interface IProps {
    value: string;
    onChange?: (value: string) => void;
}

const LocaleSelector = (props: IProps) => {
    const { value: defaultValue, onChange } = props;

    const [value, setValue] = useState<string>(defaultValue);

    const { t } = useTranslation();

    return (
        <Flex padding="1rem" flexDirection="row" justifyContent="center" alignItems="center">
            <Select
                value={value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const newLocale = e.target.value;

                    setValue(newLocale);
                    onChange && onChange(newLocale);
                }}>
                <option value={SupportedLocale.ar}>{t(TKey.languageAr)}</option>
                <option value={SupportedLocale.bg}>{t(TKey.languageBg)}</option>
                <option value={SupportedLocale.da}>{t(TKey.languageDa)}</option>
                <option value={SupportedLocale.de}>{t(TKey.languageDe)}</option>
                <option value={SupportedLocale.enGB}>{t(TKey.languageEnGb)}</option>
                <option value={SupportedLocale.enUS}>{t(TKey.languageEnUs)}</option>
                <option value={SupportedLocale.es}>{t(TKey.languageEs)}</option>
                <option value={SupportedLocale.fi}>{t(TKey.languageFi)}</option>
                <option value={SupportedLocale.fr}>{t(TKey.languageFr)}</option>
                <option value={SupportedLocale.it}>{t(TKey.languageIt)}</option>
                <option value={SupportedLocale.ja}>{t(TKey.languageJa)}</option>
                <option value={SupportedLocale.ko}>{t(TKey.languageKo)}</option>
                <option value={SupportedLocale.ar}>{t(TKey.languageAr)}</option>
                <option value={SupportedLocale.nb}>{t(TKey.languageNb)}</option>
                <option value={SupportedLocale.nl}>{t(TKey.languageNl)}</option>
                <option value={SupportedLocale.pl}>{t(TKey.languagePl)}</option>
                <option value={SupportedLocale.ptBr}>{t(TKey.languagePtBr)}</option>
                <option value={SupportedLocale.ru}>{t(TKey.languageRu)}</option>
                <option value={SupportedLocale.sv}>{t(TKey.languageSv)}</option>
                <option value={SupportedLocale.th}>{t(TKey.languageTh)}</option>
                <option value={SupportedLocale.zhCN}>{t(TKey.languageZhCn)}</option>
                <option value={SupportedLocale.zhTW}>{t(TKey.languageZhTw)}</option>
            </Select>
        </Flex>
    );
};

export default LocaleSelector;
