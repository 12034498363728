import { TKey } from 'locale';
import { CylinderCollectionType } from './types';

export const COLLECTION_NAME_TRANSLATION_KEY_MAP: { [x: string]: TKey } = {
    [CylinderCollectionType.AT_RISK]: TKey.genericAtRisk,
    [CylinderCollectionType.PENDING_UPDATE]: TKey.genericNeedUpdate,
    [CylinderCollectionType.OTHER]: TKey.genericUpToDate,
    [CylinderCollectionType.TO_BE_ADDED]: TKey.genericToBeAdded,
    [CylinderCollectionType.TO_BE_REMOVED]: TKey.genericToBeRemoved,
    [CylinderCollectionType.BLOCKED_IN]: TKey.keyBlockedIn
};
