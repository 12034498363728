import { IAppStoreState, IApiRequestState } from 'app_redux/types';
import { createSelector } from 'reselect';
import { IHardwareDataState } from 'domain/hardware/redux/types';
import hardwareUtils from 'domain/hardware/utils/hardware_utils';
import { KeyStateFilter } from '../types';
import { getKeyAccessList } from '../key_helper';

const keyFilterSelector = (state: IAppStoreState) => state.key.filter;
const hardwareDataSelector = (state: IAppStoreState) => state.hardware.data;
const selectedKeySelector = (state: IAppStoreState) => state.key.selectedKeyId;
const updateKeyRequestStateSelector = (state: IAppStoreState) => state.key.updateRequestState;
const updateMultipleKeysRequestStateSelector = (state: IAppStoreState) => state.key.updateMultipleRequestState;

export const getKeyFilter = createSelector(keyFilterSelector, (keyFilter: KeyStateFilter) => keyFilter);

export const getSelectedKey = createSelector(
    [selectedKeySelector, hardwareDataSelector],
    (keyId: string, data?: IHardwareDataState) => {
        if (data) {
            const { cylinders, keys } = data;

            return hardwareUtils
                .populate(keys, cylinders)
                .keyUtils()
                .getKeyInfoById(keyId);
        }

        return {};
    }
);

export const getActiveKeyFromState = createSelector(
    [selectedKeySelector, hardwareDataSelector],
    (keyId: string, data?: IHardwareDataState) => {
        const { keys = [] } = data || {};

        const key = keys.find(({ uuid }) => uuid === keyId);

        return key || {};
    }
);

export const getSelectedKeyId = createSelector(selectedKeySelector, (keyId: string) => keyId);

export const getUpdateKeyRequest = createSelector(
    updateKeyRequestStateSelector,
    (request: IApiRequestState) => request
);

export const getUpdateMultipleKeyRequest = createSelector(
    updateMultipleKeysRequestStateSelector,
    (request: IApiRequestState) => request
);

export const getUpdateKeyRequestStatus = createSelector(
    updateKeyRequestStateSelector,
    (request: IApiRequestState) => request.status
);

export const getKeys = createSelector(hardwareDataSelector, (data?: IHardwareDataState) => {
    if (data) {
        return data.keys;
    }
    return [];
});

export const getUpdateMultipleKeysRequestStatus = createSelector(
    updateMultipleKeysRequestStateSelector,
    (request: IApiRequestState) => request.status
);

export const getKeyAccessCylinderCollections = createSelector(
    [selectedKeySelector, hardwareDataSelector],
    (selectedKeyId, data?) => {
        if (data) {
            const { cylinders, keys } = data;

            return hardwareUtils
                .populate(keys, cylinders)
                .cylinderUtils()
                .getKeyAccessCylinderCollections(selectedKeyId, '');
        }

        return [];
    }
);
export const getKeyAccessData = createSelector([selectedKeySelector, hardwareDataSelector], (selectedKeyId, data?) => {
    if (data) {
        const { keys, cylinders } = data;

        const selectedKey = keys.find(i => i.uuid === selectedKeyId);
        if (selectedKey) {
            return getKeyAccessList(selectedKey, cylinders);
        }
    }

    return [];
});
