import React, { useState } from 'react';

import Item from 'domain/cylinder/components/cylinder_collection_item/cylinder_collection_item_light';

import CylinderModel from 'models/cylinder';
import RadioButton from 'lib/aa-react-ui/radio_button';

interface ICylinderRadioListProps {
    items: Array<CylinderModel>;
    onSelect?: (uuid: string) => void;
}

const CylinderRadioList = (props: ICylinderRadioListProps) => {
    const { items, onSelect } = props;

    const [selectedCylinderId, setSelectedCylinderId] = useState('');

    const handleRadioChange = (uuid: string) => {
        setSelectedCylinderId(uuid);
        onSelect && onSelect(uuid);
    };

    return (
        <>
            {items.map(item => {
                const isSelected = item.uuid === selectedCylinderId;

                return (
                    <Item
                        key={item.uuid}
                        selected={isSelected}
                        name={item.name}
                        marking={item.marking}
                        iconColor={item.getColor()}
                        onClick={() => {
                            handleRadioChange(item.uuid);
                        }}>
                        <RadioButton data-testid="radio-button" checked={isSelected} />
                    </Item>
                );
            })}
        </>
    );
};

export default CylinderRadioList;
