import React from 'react';
import { Flex, Text } from 'aa-react-ui';
import { ValidityIcon, ArrowRightIcon } from 'components/icon';
import { useTranslation, TKey } from 'locale';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import Card from 'components/card';
import ValidityInfoText from 'domain/validity/components/validity_info_text';
import ValidityModel from 'models/validity';
import StyledLink from 'components/styled_link';

interface IValidityProps {
    keyId?: string;
    value?: ValidityModel;
    disabled?: boolean;
}

const Validity = (props: IValidityProps) => {
    const { keyId, value, disabled } = props;
    const { t } = useTranslation();

    if (!keyId || !value) {
        return null;
    }

    const renderCurrentValidity = () => {
        const { currentRangeBeginDate, currentRangeEndDate, currentState } = value;
        return (
            <ValidityInfoText state={currentState} startDate={currentRangeBeginDate} endDate={currentRangeEndDate} />
        );
    };

    const renderNewValidity = () => {
        if (value.isPending()) {
            const { newState, newRangeBeginDate, newRangeEndDate } = value;

            return (
                <Text fontSize="1rem" color="warning.90">
                    {`${t(TKey.keyValidityNewValidity)}: `}
                    <ValidityInfoText state={newState} startDate={newRangeBeginDate} endDate={newRangeEndDate} />
                </Text>
            );
        }

        return null;
    };

    const validityEditRoute = buildRoute(Routes.KEYS_KEY_VALIDITY, { keyId });

    return (
        <StyledLink to={validityEditRoute} data-testid={`validity-edit-link-${keyId}`} disabled={disabled}>
            <Card bg={disabled ? 'contrast.10' : 'white'}>
                <Flex flexDirection="column" width="100%">
                    <Flex flexDirection="row">
                        <Flex flexDirection="row" width="100%">
                            <ValidityIcon stroke="black" strokeWidth="3px" height="28px" width="28px" />
                            <Text pl="half" fontSize="1rem" fontWeight="bold" color="contrast.90">
                                {t(TKey.genericValidity)}
                            </Text>
                        </Flex>
                        {!disabled ? <ArrowRightIcon height="2rem" width="2rem" /> : null}
                    </Flex>

                    <Flex pt="half" flexDirection="column">
                        {renderCurrentValidity()}
                        {renderNewValidity()}
                    </Flex>
                </Flex>
            </Card>
        </StyledLink>
    );
};

export default Validity;
