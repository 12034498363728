import CylinderSummary from './cylinder_summary';

import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getSelectedCylinder, getCylinderAccessKeyCollections } from 'domain/cylinder/redux/selectors';

const maptStateToProps = (state: IAppStoreState) => {
    return {
        cylinderData: getSelectedCylinder(state),
        cylinderAccessKeyCollections: getCylinderAccessKeyCollections(state)
    };
};

const mapDispatchToProps = {};

export default connect(maptStateToProps, mapDispatchToProps)(CylinderSummary);
