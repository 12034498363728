import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, H5 } from 'aa-react-ui';

import TaskInfo from 'domain/task/components/task_info/task_info';
import { IconWrapper, CylinderIcon } from 'components/icon';

interface CylinderListItem {
    uuid?: string;
    name: string;
    marking: string;
    iconColor?: string;
    hideItemStatus?: boolean;
    hideStatus?: boolean;
    showTaskInfo?: boolean;
    status?: Array<string>;
}

const Item: React.FunctionComponent<CylinderListItem> = props => {
    const { iconColor, name, marking, hideStatus, showTaskInfo, status, uuid } = props;

    const { t } = useTranslation();

    return (
        <>
            <IconWrapper icon={CylinderIcon} stroke={iconColor} width="3rem" />
            <Flex flexDirection="column">
                <H5>{name}</H5>
                <Text color="contrast.60">{marking}</Text>
                {!hideStatus && status && <Text color="warning.90">{status.map(k => t(k))}</Text>}
                {showTaskInfo && uuid && <TaskInfo uuid={uuid} />}
            </Flex>
        </>
    );
};

export default memo(Item);
