import React from 'react';
import { TKey, useTranslation } from 'locale';
import { IKey } from 'models/types';
import { Label, Card, Box } from 'aa-react-ui';
import { ScheduleIcon } from 'components/icon';
import ScheduleModel from 'models/schedule';

interface IProps {
    keyData: IKey;
}

const KeyScheduleText = (props: IProps) => {
    const { keyData } = props;
    const { t } = useTranslation();

    const generateScheduleString = (numberOfPeriods: number, noSchedule: boolean) => {
        if (noSchedule) {
            return t(TKey.keyScheduleOff);
        } else if (numberOfPeriods === 0) {
            return t(TKey.keyScheduleNoPeriods);
        } else {
            return t(TKey.keyScheduleNumPeriods, {
                postProcess: 'interpolate',
                count: numberOfPeriods
            });
        }
    };

    const schedule = new ScheduleModel(keyData.schedule);

    return (
        <Card flexDirection="row" p="half" m="quarter">
            <Box mr="half">
                <ScheduleIcon stroke="black" strokeWidth="3px" height="2rem" width="2rem" />
            </Box>
            <Label>{generateScheduleString(schedule.numberOfPendingPeriods(), !schedule.usePending)}</Label>
        </Card>
    );
};

export default KeyScheduleText;
