import React, { useState } from 'react';
import { Box, Alert } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Routes } from 'router';
import { useKeys, useKey } from 'domain/key/redux/hooks';
import { useCylinders } from 'domain/cylinder/redux/hooks';
import { currentAuthorizationsForKey } from 'domain/key/key_helper';
import { TaskKeySelector } from 'domain/task/components';
import { buildRoute } from 'router/helper';
import StepView from 'components/wizard/step_view';

const SelectTaskKey = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation<{ from: Location }>();
    const params = useParams<{ keyId: string }>();

    const keys = useKeys();
    const cylinders = useCylinders();

    const keyToBlock = useKey(params.keyId);
    const [selectedTaskKeyId, setSelectedTaskKeyId] = useState('');

    if (!keyToBlock) {
        return null;
    }

    const onSubmit = () => {
        const blockKeySummaryRoute = buildRoute(Routes.BLOCK_KEY_SUMMARY, {
            keyId: keyToBlock.uuid,
            taskKeyId: selectedTaskKeyId
        });
        history.push(blockKeySummaryRoute, { from: history.location });
    };

    const onBack = () => {
        const { state } = location;
        if (state && state.from) {
            history.push(state.from.pathname);
        } else {
            history.push(buildRoute(Routes.BLOCK_KEY_VERIFY, { keyId: keyToBlock.uuid }));
        }
    };

    const getCylindersToAssign = () => {
        const loalItems = currentAuthorizationsForKey(keyToBlock);

        return cylinders.filter(item => {
            return loalItems.some(loalItem => {
                return loalItem.cylinderUuid === item.uuid;
            });
        });
    };

    return (
        <StepView
            title={t(TKey.blockKeyHeader)}
            subTitle={t(TKey.blockKeySelectProgrammingKeyHeader)}
            onBack={onBack}
            submitText={selectedTaskKeyId ? t(TKey.actionNext) : t(TKey.actionSkip)}
            onSubmit={onSubmit}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.blockKeySelectProgrammingKeyBody)} variant="warning" />
            </Box>

            <TaskKeySelector
                keys={keys.filter(key => key.uuid !== keyToBlock.uuid)}
                cylindersToAssign={getCylindersToAssign()}
                onSelect={taskKey => {
                    setSelectedTaskKeyId(taskKey.uuid);
                }}
            />
        </StepView>
    );
};

export default SelectTaskKey;
