import React from 'react';
import { useField } from 'react-final-form';

import { TKey, useTranslation } from 'locale';

import { Flex, Label, Alert } from 'aa-react-ui';
import Checkbox from 'lib/aa-react-ui/checkbox';

function Switch() {
    const { t } = useTranslation();
    const { input } = useField('enabled', {
        type: 'checkbox'
    });

    const SWITCH_LABEL = t(TKey.actionKeyScheduleEnable);
    const INACTIVE_TEXT = t(TKey.keyScheduleNoScheduleInfo);

    return (
        <>
            <Flex my="full">
                <Checkbox data-testid="schedule-switch" {...input} />
                <Label ml="half" mb="0" as="strong">
                    {SWITCH_LABEL}
                </Label>
            </Flex>

            {input.checked === false && (
                <Alert variant="info" data-testid="key-schedule-status-inactive" title={INACTIVE_TEXT} />
            )}
        </>
    );
}

export default Switch;
