import { IApiRequestState, IApiRequestActionType, RequestStatus } from './types';

const buildActions = (type: string) => {
    return {
        REQUEST: `${type}_REQUEST`,
        SUCCESS: `${type}_SUCCESS`,
        FAILIRE: `${type}_FAILURE`,
        RESET: `${type}_RESET`
    };
};

export function createRequestReducer<T>(requestType: string, defaultState: IApiRequestState<T>) {
    const actionType = buildActions(requestType);

    return (state: IApiRequestState<T> = defaultState, action: IApiRequestActionType<T>): IApiRequestState<T> => {
        const { type, error = '', data } = action;

        switch (type) {
            case actionType.REQUEST:
                return {
                    ...state,
                    status: RequestStatus.IN_PROGRESS
                };
            case actionType.SUCCESS:
                return {
                    status: RequestStatus.SUCCEEDED,
                    error,
                    data
                };
            case actionType.FAILIRE:
                return {
                    ...state,
                    status: RequestStatus.FAILED,
                    error
                };
            case actionType.RESET:
                return {
                    ...state,
                    status: RequestStatus.IDLE
                };
            default:
                return state;
        }
    };
}

export function createRequestActionCreators<T = undefined>(requestType: string) {
    const actionType = buildActions(requestType);

    return {
        request: (): IApiRequestActionType<T> => ({
            type: actionType.REQUEST
        }),
        success: (data?: T): IApiRequestActionType<T> => ({
            type: actionType.SUCCESS,
            data
        }),
        failure: (error?: string): IApiRequestActionType<T> => ({
            type: actionType.FAILIRE,
            error
        }),
        reset: (): IApiRequestActionType<T> => ({
            type: actionType.RESET
        })
    };
}
