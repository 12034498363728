import styled from 'styled-components';
import { Box } from 'aa-react-ui';

const Container = styled(Box)`
    height: 100vh;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

Container.defaultProps = {
    bg: 'primary.70'
};

export default Container;
