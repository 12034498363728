import { useEffect } from 'react';
import { useNotification } from 'domain/notification/redux/hooks';
import { TKey } from 'locale';
import httpClient, { HTTP_STATUS_CODE } from 'common/services/http_client';
import TimersUtils from 'common/utils/timers';

const debounceTimeInMillis = 500;

const HttpErrroWatcher = () => {
    const { showError } = useNotification();

    useEffect(() => {
        const onUnknownError = TimersUtils.debounce(() => {
            showError(TKey.genericErrorUnexpected);
        }, debounceTimeInMillis);

        //TODO: Show generic error messages here https://jira.assaabloy.net/browse/CSMBC-6656
        httpClient.addListener(HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR, onUnknownError);

        return () => {
            httpClient.removeListener(HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR, onUnknownError);
        };
    }, [showError]);

    return null;
};

export default HttpErrroWatcher;
