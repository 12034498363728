import { IAppStoreState, IApiRequestState } from 'app_redux/types';
import { createSelector } from 'reselect';
import { IHardwareDataState } from 'domain/hardware/redux/types';
import hardwareUtils from 'domain/hardware/utils/hardware_utils';
import { CylinderFilter, IAuditTrail } from '../types';
import {
    getCylinderAccessInfoList,
    filterUnassignedCylinders,
    filterAssignedCylinders,
    getAssignedTaskList
} from '../cylinder_helper';
import { KeyState } from 'models/types';

const cylinderFilterSelector = (state: IAppStoreState) => state.cylinder.filter;
const hardwareDataSelector = (state: IAppStoreState) => state.hardware.data;
const selectedCylinderSelector = (state: IAppStoreState) => state.cylinder.selectedCylinderId;
const updateCylinderRequestStateSelector = (state: IAppStoreState) => state.cylinder.updateRequestState;
const accessLogRequestStateSelector = (state: IAppStoreState) => state.cylinder.accessLogRequestState;
const updateMultipleCylinderssRequestStateSelector = (state: IAppStoreState) =>
    state.cylinder.updateMultipleRequestState;

export const getCylinderFilter = createSelector(cylinderFilterSelector, (filter: CylinderFilter) => filter);

const getIdProp = (_: IAppStoreState, cylinderId: string) => cylinderId;

const getCylinderInfo = (cylinderId: string, data?: IHardwareDataState) => {
    if (data) {
        const { cylinders, keys } = data;

        return hardwareUtils
            .populate(keys, cylinders)
            .cylinderUtils()
            .getCylinderInfoById(cylinderId);
    }

    return {};
};

export const getSelectedCylinder = createSelector([selectedCylinderSelector, hardwareDataSelector], getCylinderInfo);

export const getCylinderById = () => createSelector([getIdProp, hardwareDataSelector], getCylinderInfo);

export const getSelectedCylinderId = createSelector(selectedCylinderSelector, (cylinderId: string) => cylinderId);

export const getUpdateCylinderRequest = createSelector(
    updateCylinderRequestStateSelector,
    (request: IApiRequestState) => request
);

export const getUpdateCylinderRequestStatus = createSelector(
    updateCylinderRequestStateSelector,
    (request: IApiRequestState) => request.status
);

export const getAccessLogRequest = createSelector(
    accessLogRequestStateSelector,
    (request: IApiRequestState<Array<IAuditTrail>>) => request
);

export const getAccessLogReqeustStatus = createSelector(
    accessLogRequestStateSelector,
    (request: IApiRequestState<Array<IAuditTrail>>) => request.status
);

export const getCylinderAccessData = createSelector(
    [selectedCylinderSelector, hardwareDataSelector],
    (cylinderId: string, data?: IHardwareDataState) => {
        if (!data) {
            return [];
        }

        const keys = data.keys.filter(({ state }) => state !== KeyState.BLOCKED);

        return getCylinderAccessInfoList(cylinderId, keys);
    }
);

export const getCylinderAccessKeyCollections = createSelector(
    [selectedCylinderSelector, hardwareDataSelector],
    (cylinderId: string, data?: IHardwareDataState) => {
        if (data) {
            return hardwareUtils
                .populate(data.keys, data.cylinders)
                .keyUtils()
                .getCylinderAccessKeyCollections(cylinderId, '');
        }
        return [];
    }
);

export const getUnassignedCylinders = createSelector([hardwareDataSelector], (data?: IHardwareDataState) => {
    if (data) {
        return filterUnassignedCylinders(data.cylinders);
    }
    return [];
});

export const getAssignedTasks = createSelector([hardwareDataSelector], (data?: IHardwareDataState) => {
    if (data) {
        const assignedCylinders = filterAssignedCylinders(data.cylinders);
        return getAssignedTaskList(assignedCylinders, data.keys);
    }
    return [];
});

export const getUpdateMultipleCylindersRequestStatus = createSelector(
    updateMultipleCylinderssRequestStateSelector,
    (request: IApiRequestState) => request.status
);

export const getAllCylinders = createSelector(hardwareDataSelector, (data?: IHardwareDataState) => {
    if (data) {
        return data.cylinders;
    }

    return [];
});
