import React, { useState } from 'react';
import styled from 'styled-components';

import { ICylinderAccessInfo } from 'domain/cylinder/types';
import { Box } from 'aa-react-ui';
import KeyModel from 'models/key';
import { AuthorizationState } from 'models/types';
import Checkbox from 'lib/aa-react-ui/checkbox';
import { getAuthorizationToggleState } from 'domain/hardware/hardware_helper';
import CylinderModel from 'models/cylinder';

import Key from 'domain/key/components/key_card_item/content';
import css from 'components/list_link/css';

const KeyContainer = styled.div`
    ${css}
`;

interface ItemProps {
    data: ICylinderAccessInfo;
    cylinder?: CylinderModel;
    onChange?: (uuid: string, state: AuthorizationState) => void;
}

const Item: React.FunctionComponent<ItemProps> = props => {
    const { data, cylinder, onChange } = props;

    const [state, setState] = useState<AuthorizationState>(data.state);

    const handleChange = () => {
        const newState = getAuthorizationToggleState(state);
        const { uuid } = data.key;

        setState(newState);

        if (typeof onChange === 'function') {
            onChange(uuid, newState);
        }
    };

    const selected = state === AuthorizationState.CURRENT || state === AuthorizationState.ADDED;

    const keyModel = new KeyModel(data.key);

    return (
        <KeyContainer className={selected ? 'selected' : ''}>
            <Key parentCylinder={cylinder} hideItemStatus onClick={handleChange} item={keyModel} />
            <Checkbox data-testid="check-box-input" checked={selected} onChange={handleChange} />
        </KeyContainer>
    );
};

interface IAccessListProps {
    cylinder?: CylinderModel;
    listData: Array<ICylinderAccessInfo>;
    onChange?: (uuid: string, state: AuthorizationState) => void;
}

const AccessList: React.FunctionComponent<IAccessListProps> = props => {
    const { listData, onChange, cylinder } = props;

    return (
        <Box mt="full">
            {listData.map(data => (
                <Item key={data.key.uuid} data={data} onChange={onChange} cylinder={cylinder} />
            ))}
        </Box>
    );
};

export default React.memo(AccessList);
