import React from 'react';
import { Alert, Box } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { KeyCollectionItem } from 'domain/key/components';
import { HandInOption } from 'domain/key/types';
import { IHandInSettings } from '../types';
import StepView from 'components/wizard/step_view';
import KeyModel from 'models/key';
interface IProps {
    data: IHandInSettings;
    setData: (newData: IHandInSettings) => void;
    onBack?: () => void;
    onNext?: () => void;
    isLoading?: boolean;
}

const SelectionSummary = (props: IProps) => {
    const { onNext, onBack, data, isLoading } = props;
    const { t } = useTranslation();

    const { key, handInOption } = data;

    return (
        <StepView title={t(TKey.handInHeader)} onBack={onBack} onSubmit={onNext} loading={isLoading}>
            <Box m="quarter" mb="half" mt="half">
                <Alert text={t(TKey.handInSummaryBody)} variant="warning" />
            </Box>

            {key && <KeyCollectionItem data={new KeyModel(key)} />}

            {handInOption === HandInOption.RESET_SETTINGS && (
                <Box m="quarter" mb="half" mt="half">
                    <Alert text={t(TKey.handInSummaryResetNotice)} variant="warning" />
                </Box>
            )}
        </StepView>
    );
};

export default SelectionSummary;
