import React from 'react';
import { IconWrapper, ArrowRightIcon, KeyIcon } from 'components/icon';
import StyledLink from 'components/styled_link';
import { Routes } from 'router';
import Card from 'components/card';
import { Flex, Text } from 'aa-react-ui';

import { useTranslation, TKey } from 'locale';
import { CylinderCollectionType } from 'domain/cylinder/types';
import { buildRoute } from 'router/helper';

const COLOR_MAP: { [x: string]: string } = {
    [CylinderCollectionType.AT_RISK]: 'error.90',
    [CylinderCollectionType.PENDING_UPDATE]: 'warning.90',
    [CylinderCollectionType.OTHER]: 'primary.90'
};

interface IKeyAccessInfoProps {
    cylinderId: string;
    disabled?: boolean;
    collectionType?: CylinderCollectionType;
}
const KeyAccessInfo: React.FunctionComponent<IKeyAccessInfoProps> = props => {
    const { disabled, cylinderId, collectionType = CylinderCollectionType.OTHER } = props;
    const { t } = useTranslation();

    const getIconColor = () => {
        const color = COLOR_MAP[collectionType];

        if (color) {
            return color;
        } else {
            return COLOR_MAP[CylinderCollectionType.OTHER];
        }
    };

    const accessEditRoute = buildRoute(Routes.CYLINDERS_CYLINDER_ACCESS, { cylinderId });

    return (
        <StyledLink to={accessEditRoute} data-testid={`key-access-edit-link-${cylinderId}`} disabled={disabled}>
            <Card bg={disabled ? 'contrast.10' : 'white'}>
                <Flex flexDirection="row" width="100%">
                    <Flex flexDirection="row" width="100%">
                        <IconWrapper icon={KeyIcon} height="2rem" width="2.5rem" fill="white" stroke={getIconColor()} />
                        <Text pl="half" fontSize="1rem" fontWeight="bold" color="contrast.90">
                            {t(TKey.cylinderAccessingKeys)}
                        </Text>
                    </Flex>
                    {!disabled && <ArrowRightIcon height="2rem" width="2rem" />}
                </Flex>
            </Card>
        </StyledLink>
    );
};

export default KeyAccessInfo;
