import React from 'react';
import { Text, Label, Flex, Card } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { IKey } from 'models/types';
import { KeyIcon } from 'components/icon';
import { KeyValidityText, KeyScheduleText, KeyAccessText } from 'domain/key/components';
import StepView from 'components/wizard/step_view';

interface IProps {
    loading?: boolean;
    onNext?: () => void;
    onBack?: () => void;
    data: IKey;
    setData: (newData: IKey) => void;
}

const Summary = (props: IProps) => {
    const { onNext, onBack, data: keyData, loading, setData } = props;
    const { t } = useTranslation();

    if (!keyData) {
        return null;
    }

    const onSubmit = () => {
        setData(keyData);
        onNext && onNext();
    };

    const renderName = () => {
        const { name } = keyData;

        return (
            <Card p="half" m="quarter">
                <Flex>
                    <KeyIcon stroke="black" height="2rem" width="2rem" strokeWidth="3px" />
                    <Label ml="half">{name}</Label>
                    <Text color="contrast.50" ml="half">
                        {keyData.marking}
                    </Text>
                </Flex>
            </Card>
        );
    };

    return (
        <StepView
            title={t(TKey.handOutHeader)}
            subTitle={t(TKey.handOutSummaryBody)}
            onBack={onBack}
            onSubmit={onSubmit}
            loading={loading}>
            {renderName()}
            <KeyValidityText keyData={keyData} />
            <KeyScheduleText keyData={keyData} />
            <KeyAccessText keyData={keyData} />
        </StepView>
    );
};

export default Summary;
