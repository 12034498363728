import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';
import LoukEntryModel from 'models/louk_entry';
import CommonUtils from 'common/utils/common';
import LoalEntryModel from 'models/loal_entry';

import { KeyState, AuthorizationState, ICylinder, IKey, ICylinderAuthorization } from 'models/types';
import { KeyStateInt, KeyPendingStatus, KeyCollectionType, IKeyAccessInfo } from './types';
import { sortOnNameAndMarking, sortOnAlphaNumeric } from 'domain/hardware/hardware_helper';
import { sortCylindersOnStateAndNameAndMarking } from 'domain/cylinder/cylinder_helper';
import { CUSTOM_THEME_COLOR } from 'common/constants';

import { isKeyHarmful } from './key_harmful';

export const isKeyBlockedInCylinder = (key: KeyModel, cylinder: CylinderModel) =>
    cylinder.unauthorizedKeys.some((louk: LoukEntryModel) => louk.keyUuid === key.uuid && louk.isCurrent());

export const hasKeyAccessInCylinder = (key: KeyModel, cylinder: CylinderModel) =>
    !isKeyBlockedInCylinder(key, cylinder) && !key.validity.isCurrentlyNeverValid() && !key.validity.hasExpired();

export const isKeyHarmfulInCylinder = (key: KeyModel, cylinder: CylinderModel) =>
    key && key.isBlocked() && key.validity.isCurrentlyValid() && !isKeyBlockedInCylinder(key, cylinder);

export const getKeyState = (keyData: KeyModel) => {
    if (keyData.isBlocked()) {
        return KeyState.BLOCKED;
    }

    if (keyData.isInStock()) {
        return KeyState.IN_STOCK;
    }

    if (keyData.isHandedOut()) {
        return KeyState.HANDED_OUT;
    }

    return KeyState.UNKNOWN;
};

export const sortKeysOnStateAndNameAndMarking = (a: KeyModel, b: KeyModel) => {
    const aStateInt = a.stateIntRepresentation();
    const bStateInt = b.stateIntRepresentation();
    const stateDiff = aStateInt - bStateInt;
    if (stateDiff !== 0) {
        return stateDiff;
    } else if (aStateInt < KeyStateInt.IN_STOCK) {
        return sortOnNameAndMarking(a, b);
    } else {
        return sortOnAlphaNumeric(a.marking, b.marking);
    }
};

export const getKeyStatus = (keyData: KeyModel) => {
    const status = [];

    if (keyData.hasPendingAccess()) {
        status.push(KeyPendingStatus.ACCESS);
    }

    if (keyData.hasPendingSchedule()) {
        status.push(KeyPendingStatus.SCHEDULE);
    }

    if (keyData.hasPendingTasks()) {
        status.push(KeyPendingStatus.TASKS);
    }

    if (keyData.hasPendingValidity()) {
        status.push(KeyPendingStatus.VALIDITY);
    }

    return status;
};

export const getKeyName = (keyData: KeyModel): string => {
    if (keyData.name) {
        return keyData.name;
    }

    return '';
};

export const getKeyCollectionType = (key: KeyModel) => {
    if (isKeyHarmful(key)) {
        return KeyCollectionType.HARMFUL;
    } else if (key.hasPendingJob()) {
        return KeyCollectionType.PENDING;
    } else if (key.hasStartedTasks() && key.isInStock()) {
        return KeyCollectionType.WITH_TASKS;
    } else if (key.isInStock()) {
        return KeyCollectionType.UNKNOWN;
    }
};

export const getKeyIconColor = (key: KeyModel) => {
    if (isKeyHarmful(key)) {
        return CUSTOM_THEME_COLOR.red;
    }

    if (key.isBlocked()) {
        return CUSTOM_THEME_COLOR.gray;
    }

    if (key.hasPendingJob()) {
        return CUSTOM_THEME_COLOR.orange;
    }

    if (key.isInStock()) {
        return CUSTOM_THEME_COLOR.gray;
    }

    return CUSTOM_THEME_COLOR.darkBlue;
};

export const getKeyBackgroundColor = (disabled = false, selected = false) => {
    if (disabled) {
        return CUSTOM_THEME_COLOR.lightGray;
    }
    if (selected) {
        return CUSTOM_THEME_COLOR.extraLightBlue;
    }

    return 'transparent';
};

export const getKeyAccessList = (key: IKey, cylinders: Array<ICylinder>): Array<IKeyAccessInfo> => {
    const { uuid: keyId } = key;

    if (keyId) {
        const loalInfo = cylinders.map(cylinder => {
            const authAccess = key.authorizations.find(auth => auth.cylinderUuid === cylinder.uuid);

            const cylinderModel = new CylinderModel(cylinder);
            const iconColor = cylinderModel.getColor();

            if (authAccess) {
                return {
                    state: authAccess.state,
                    keyId,
                    cylinder,
                    iconColor
                };
            }

            return {
                state: AuthorizationState.PLACEHOLDER,
                keyId,
                cylinder,
                iconColor
            };
        });

        return [...loalInfo].sort((lhs, rhs) =>
            sortCylindersOnStateAndNameAndMarking(new CylinderModel(lhs.cylinder), new CylinderModel(rhs.cylinder))
        );
    }

    return [];
};

export const sanitizeKeyAccess = (
    newAccessData: Array<IKeyAccessInfo>,
    keyAuthorizations: Array<ICylinderAuthorization>
) => {
    let authorizations = CommonUtils.cloneDeep(keyAuthorizations);

    newAccessData.forEach(access => {
        const cylinderAuthAccess = authorizations.find(
            cylinderAccess => cylinderAccess.cylinderUuid === access.cylinder.uuid
        );

        if (cylinderAuthAccess) {
            if (access.state === AuthorizationState.PLACEHOLDER || cylinderAuthAccess.state !== access.state) {
                authorizations = authorizations.filter(auth => auth.cylinderUuid !== access.cylinder.uuid);
            }

            if (access.state !== AuthorizationState.PLACEHOLDER && cylinderAuthAccess.state !== access.state) {
                authorizations.push({
                    state: access.state,
                    cylinderUuid: access.cylinder.uuid
                });
            }
        }

        if (
            !cylinderAuthAccess &&
            (access.state === AuthorizationState.ADDED || access.state === AuthorizationState.REMOVED)
        ) {
            authorizations.push({
                state: access.state,
                cylinderUuid: access.cylinder.uuid
            });
        }
    });

    return authorizations;
};

export const filterAssignableKeys = (toCylinders: Array<ICylinder>, fromKeys: Array<IKey>) => {
    const isBlocked = (cylinder: ICylinder, key: IKey) =>
        cylinder.unauthorizedKeys.some(louk => {
            const loukModel = new LoukEntryModel(louk);
            return loukModel.keyUuid === key.uuid && !loukModel.isPlaceholder();
        });

    return fromKeys
        .filter(key => {
            const keyModel = new KeyModel(key);
            return !keyModel.isBlocked() && !toCylinders.some(cylinder => isBlocked(cylinder, key));
        })
        .sort((lhs, rhs) => sortKeysOnStateAndNameAndMarking(new KeyModel(lhs), new KeyModel(rhs)));
};

export const filterHandedOutKeys = (allKeys: Array<IKey>) => allKeys.filter(item => item.state === KeyState.HANDED_OUT);

export const currentAuthorizationsForKey = (key: IKey) => {
    if (key) {
        return key.authorizations.filter(loalItem => {
            const loalItemModel = new LoalEntryModel(loalItem);
            return loalItemModel.isCurrent() || loalItemModel.isRemoved();
        });
    }
    return [];
};

export const getPendingKeys = (allKeys: Array<IKey>) => {
    return allKeys.reduce((result: Array<IKey>, item: IKey) => {
        const key = new KeyModel(item);

        if (!key.isBlocked() && key.hasPendingJob()) {
            result.push(item);
        }

        return result;
    }, []);
};
