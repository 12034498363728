import dsStore from './ds_store';

export const CWM_REST_API_VERSION = 4;
export const CWM_REST_ROOT_PATH = 'seam/resource/rest';

function cliqUrlBuilderFactory() {
    const cwmRestResource = (): string => {
        const serviceUrls = dsStore.getServiceUrls();

        if (serviceUrls) {
            return `${serviceUrls.cwmUrl}/${CWM_REST_ROOT_PATH}`;
        }

        return '';
    };

    const reporting = () => `${cwmRestResource()}/v${CWM_REST_API_VERSION}/reporting/systems`;

    const reportPath = () => {
        const credentials = dsStore.getCreds();

        if (credentials) {
            const { aaCode, mksName } = credentials;
            return `${reporting()}/${aaCode}/${mksName}`;
        }

        return '';
    };

    const systems = (): string => `${cwmRestResource()}/v${CWM_REST_API_VERSION}/systems`;

    const defaultSystem = (aaCode?: number, mksName?: string): string => {
        const credentials = dsStore.getCreds();
        if (credentials) {
            const { aaCode: defaultAaCode, mksName: defaultMksName } = credentials;

            return `${systems()}/${aaCode || defaultAaCode}/${mksName || defaultMksName}`;
        }

        return '';
    };

    const defaultSystemLogin = (aaCode?: number, mksName?: string): string => `${defaultSystem(aaCode, mksName)}/login`;

    const defaultSystemLogout = (aaCode?: number, mksName?: string): string =>
        `${defaultSystem(aaCode, mksName)}/logout`;

    const versionCompatibility = (): string => `${cwmRestResource()}/version-compatibility/${CWM_REST_API_VERSION}`;

    const keys = () => `${defaultSystem()}/keys`;

    const cylinders = () => `${defaultSystem()}/cylinders`;
    const auditTrail = (cylinderId: string) => `${reportPath()}/audit_trails/query?cylinder=${cylinderId}`;

    return {
        defaultSystemLogin,
        defaultSystemLogout,
        versionCompatibility,
        systems,
        keys,
        cylinders,
        auditTrail
    };
}

export default cliqUrlBuilderFactory();
