import React from 'react';
import { IKey } from 'models/types';
import { ValidityInfoText } from 'domain/validity/components';
import { Card, Box, Label } from 'aa-react-ui';
import { ValidityIcon } from 'components/icon';
import ValidityModel from 'models/validity';

interface IProps {
    keyData: IKey;
}

const KeyValidityText = (props: IProps) => {
    const { keyData } = props;

    const validity = new ValidityModel(keyData.validity);

    return (
        <Card flexDirection="row" p="half" m="quarter">
            <Box mr="half">
                <ValidityIcon stroke="black" strokeWidth="3px" height="2rem" width="2rem" />
            </Box>
            <Label>
                <ValidityInfoText
                    state={validity.newState}
                    startDate={validity.newRangeBeginDate}
                    endDate={validity.newRangeEndDate}
                />
            </Label>
        </Card>
    );
};

export default KeyValidityText;
