import { IKey } from 'models/types';
import { FormattedWeek, FormattedInterval, FormValues } from 'domain/schedule/types';
import { FULL_WEEK_TIME_PERIOD, sortByDay } from './utils';
import { DAYS_ENUMS } from 'common/constants';

/**
 * @param interval A interval as a object consumed by UI.
 * @returns {string} The interval transformed to what backend accepts.
 */
export function parseInterval(interval: FormattedInterval): string {
    const { day, startHour, startMinutes, endHour, endMinutes } = interval;

    // In UI we want days to start at 0, backend wants it to start at 1
    const index = DAYS_ENUMS.indexOf(day);
    const parsedDay = index + 1;

    return `R/-W-${parsedDay}T${startHour}:${startMinutes}/T${endHour}:${endMinutes}`;
}
/**
 * Given a week object with days as array. Merge those days in to
 * one array.
 * @param input A FormattedWeek object
 */
export function toArray(input: FormattedWeek) {
    return Object.keys(input).reduce((acc: FormattedInterval[], key: string) => {
        const day = input[key];
        return [...acc, ...day];
    }, []);
}

/**
 *
 * @param input Transform a FormattedWeek back to original structure.
 * @returns string[];
 */
export function parseIntervals(input: FormValues): Array<string> {
    const { days, enabled } = input;

    const desiredIntervals = toArray(days)
        .sort(sortByDay)
        .map(parseInterval);

    return enabled ? desiredIntervals : [FULL_WEEK_TIME_PERIOD];
}

export function mergeDesiredScheduleWithKey(key: IKey, desiredIntervals: Array<string>): IKey {
    const { schedule } = key;
    const { programmedIntervals } = schedule;

    return {
        ...key,
        schedule: {
            programmedIntervals,
            desiredIntervals
        }
    };
}

export function parseAndMergeSchedule(key: IKey, schedule: FormValues): IKey {
    const desiredIntervals = parseIntervals(schedule);
    return mergeDesiredScheduleWithKey(key, desiredIntervals);
}
