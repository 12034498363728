import React from 'react';
import { Routes } from 'router';
import { Route } from 'react-router-dom';
import SelectKey from './select_key';
import SelectSettings from './select_setting';

const HandIn = () => {
    return (
        <>
            <Route exact path={Routes.HAND_IN} component={SelectKey} />
            <Route exact path={Routes.HAND_IN_SETTINGS} component={SelectSettings} />
        </>
    );
};

export default HandIn;
