import {
    SET_CYLINDER_FILTER,
    SET_CYLINDER_SEARCH_QUERY,
    SET_SELECTED_CYLINDER,
    ISetCurrentSelectedCylinder,
    ISetCylinderSearchQueryAction,
    UPDATE_CYLINDER,
    UPDATE_MULTIPLE_CYLINDERS,
    LOAD_ACCESS_LOG
} from './action_types';

import { Dispatch } from 'redux';
import { createRequestActionCreators } from 'app_redux/factory';
import { cliqUrlBuilder } from 'common/services/directory_service';
import { loadHardwareData } from 'domain/hardware/redux/actions';
import { ICylinder } from 'models/types';
import { CylinderFilter, IAuditTrail } from '../types';
import httpClient, { HTTP_STATUS_CODE } from 'common/services/http_client';

export const setCylinderSearchQuery = (searchQuery: string) => {
    return (dispatch: Dispatch<ISetCylinderSearchQueryAction>) => {
        dispatch({
            type: SET_CYLINDER_SEARCH_QUERY,
            searchQuery
        });
    };
};

export const setCylinderFilter = (filter: CylinderFilter) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: any) => {
        dispatch({
            type: SET_CYLINDER_FILTER,
            filter
        });
    };
};

export const setCurrentSelectedCylinder = (id: string) => {
    return (dispatch: Dispatch<ISetCurrentSelectedCylinder>) => {
        dispatch({
            type: SET_SELECTED_CYLINDER,
            cylinderId: id
        });
    };
};

const updateCylinderActionCreators = createRequestActionCreators(UPDATE_CYLINDER);

export const updateCylinder = (cylinderId: string, data: ICylinder) => {
    const { request, success, failure } = updateCylinderActionCreators;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: any) => {
        dispatch(request());

        try {
            const updateUrl = `${cliqUrlBuilder.cylinders()}/${cylinderId}`;
            const response = await httpClient.put(updateUrl, data);

            if (response.status === HTTP_STATUS_CODE.OK) {
                await loadHardwareData()(dispatch);
            }

            dispatch(success());
            return true;
        } catch (e) {
            dispatch(failure());
            return false;
        }
    };
};

const loadAccessLogActionCreators = createRequestActionCreators<Array<IAuditTrail>>(LOAD_ACCESS_LOG);

export const loadAccessLog = (cylinderId: string) => {
    const { request, success, failure } = loadAccessLogActionCreators;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: any) => {
        dispatch(request());

        try {
            const response = await httpClient.get(cliqUrlBuilder.auditTrail(cylinderId));
            if (response.status === HTTP_STATUS_CODE.OK) {
                dispatch(success(response.data));
            } else {
                dispatch(failure());
            }
        } catch (e) {
            dispatch(failure());
        }
    };
};

const updateMultipleCylindersActionCreators = createRequestActionCreators(UPDATE_MULTIPLE_CYLINDERS);

export const updateMultipleCylinders = (cylinders: Array<ICylinder>) => {
    const { request, success, failure } = updateMultipleCylindersActionCreators;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: any) => {
        dispatch(request());

        try {
            const promiseList = cylinders.map(item => {
                const updateUrl = `${cliqUrlBuilder.cylinders()}/${item.uuid}`;
                return httpClient.put(updateUrl, item);
            });

            const response = await httpClient.all(promiseList);

            const errorStatus = response.find(res => res.status !== HTTP_STATUS_CODE.OK);

            if (!errorStatus) {
                await loadHardwareData()(dispatch);

                dispatch(success());
                return true;
            } else {
                return false;
            }
        } catch (e) {
            dispatch(failure());
            return false;
        }
    };
};
