import React from 'react';
import KeyModel from 'models/key';
import { getHardwareMarking } from 'domain/hardware/hardware_helper';
import { getKeyName } from 'domain/key/key_helper';
import { Flex, Text } from 'aa-react-ui';

interface IKeyCellProps {
    keyData: KeyModel;
}

const KeyCell: React.FunctionComponent<IKeyCellProps> = props => {
    const { keyData } = props;

    if (keyData) {
        const name = getKeyName(keyData);
        const marking = getHardwareMarking(keyData);

        return (
            <Flex flexDirection="column">
                <Text data-testid="keycell-name" as="strong">
                    {name}
                </Text>
                <Text fontSize="1rem">{marking}</Text>
            </Flex>
        );
    }
    return null;
};

export default KeyCell;
