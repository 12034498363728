import { ISchedule } from './types';
import commonUtils from 'common/utils/common';
import dateTimeUtils from 'common/utils/date_time';

const FULL_SCHEDULE_STRING = 'R/-W--T00:00/T24:00';
const MINUTES_PER_HOUR = 60;
const SCHDULE_STRING_INDEX = 5;
const MINUTES_DAY_START = 0;
const MINUTES_PER_DAY = 1440;

export default class ScheduleModel {
    readonly data: ISchedule;

    public programmedPeriods: Array<Array<number>>;
    public pendingPeriods: Array<Array<number>>;
    public useProgrammed: boolean;
    public usePending: boolean;

    constructor(data: ISchedule) {
        this.data = data;
        if (this.data) {
            this.programmedPeriods = this.parsePeriods(this.data.programmedIntervals);
            this.pendingPeriods = this.parsePeriods(this.data.desiredIntervals);
            this.useProgrammed = !this.data.programmedIntervals.includes(FULL_SCHEDULE_STRING);
            this.usePending = !this.data.desiredIntervals.includes(FULL_SCHEDULE_STRING);
        } else {
            this.programmedPeriods = [];
            this.pendingPeriods = [];
            this.useProgrammed = false;
            this.usePending = false;
        }
    }

    isPending() {
        if (this.useProgrammed !== this.usePending) {
            return true;
        }
        return this.useProgrammed && !commonUtils.isEqual(this.data.desiredIntervals, this.data.programmedIntervals);
    }

    isProgrammedStateUsed() {
        return this.useProgrammed;
    }

    isPendingStateUsed() {
        return this.usePending;
    }
    numberOfProgrammedPeriods() {
        return this.data.programmedIntervals.length;
    }

    numberOfPendingPeriods() {
        return this.data.desiredIntervals.length;
    }

    setPendingStateNotUsed() {
        this.data.desiredIntervals = [FULL_SCHEDULE_STRING];
        this.pendingPeriods = this.parsePeriods(this.data.desiredIntervals);
        this.usePending = false;
    }

    setPendingStateUsed() {
        this.data.desiredIntervals = [];
        this.pendingPeriods = this.parsePeriods(this.data.desiredIntervals);
        this.usePending = true;
    }

    updatePendingIntervals() {
        const newDesiredIntervals = [];
        for (let day = 0; day < this.pendingPeriods.length; day++) {
            const dayPeriod = this.pendingPeriods[day];
            if (dayPeriod.length) {
                const [startTime, endTime] = dateTimeUtils.periodToTime(dayPeriod);
                newDesiredIntervals.push(`R/-W-${day + 1}T${startTime}/T${endTime}`);
            }
        }

        this.data.desiredIntervals = newDesiredIntervals;
    }

    private parsePeriods(intervals: Array<string>) {
        const periods: Array<Array<number>> = [[], [], [], [], [], [], []];

        if (intervals.includes(FULL_SCHEDULE_STRING)) {
            periods[0].length = 0;
            periods[0].push(MINUTES_DAY_START, MINUTES_PER_DAY);
            return periods;
        }

        intervals.forEach(interval => {
            const [startMinutes, endMinutes] = this.parsePeriod(interval);
            const periodDayIndex = parseInt(interval[SCHDULE_STRING_INDEX], 10) - 1;
            periods[periodDayIndex].length = 0;
            periods[periodDayIndex].push(startMinutes, endMinutes);
        });

        return periods;
    }

    private parsePeriod(period: string) {
        const regexTime = /(\d+:\d+)/g;
        const [starttime, endtime] = period.match(regexTime) || [];
        return [this.toMinutes(starttime), this.toMinutes(endtime)];
    }

    private toMinutes(time: string) {
        if (time) {
            const timeParts = time.split(':');
            const hour = parseInt(timeParts[0], 10);
            const minute = parseInt(timeParts[1], 10);
            return hour * MINUTES_PER_HOUR + minute;
        }
        return -1;
    }

    toDataObject(): ISchedule {
        return this.data;
    }
}
