import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getSelectedCylinder } from 'domain/cylinder/redux/selectors';
import { getSystemUtcOffset } from 'domain/authentication/redux/selectors';
import dateTimeUtils from 'common/utils/date_time';

import CylinderAccessLog from './cylinder_access_log';

const mapStateToProps = (state: IAppStoreState) => {
    return {
        cylinderData: getSelectedCylinder(state),
        showTimeZoneWarning: getSystemUtcOffset(state) !== dateTimeUtils.getTimeZoneUtcOffset()
    };
};

export default connect(mapStateToProps)(CylinderAccessLog);
