import Routes from './routes';

const getDefaultParamsMap = (route: Routes): { [x: string]: string } => {
    const items = route.match(/([:])\w+/g);
    let paramsMap = {};

    if (items) {
        paramsMap = items.reduce((result: { [x: string]: string }, item) => {
            const param = item.split(':')[1];
            result[param] = '';
            return result;
        }, {});
    }

    return paramsMap;
};

export const buildRoute = (route: Routes, paramsMap: { [x: string]: string } = {}) => {
    const defaultParamsMap = getDefaultParamsMap(route);

    let routeWithValues = route.toString().replace('?', '');

    const params = {
        ...defaultParamsMap,
        ...paramsMap
    };

    Object.keys(params).forEach(key => {
        routeWithValues = routeWithValues.replace(`:${key}`, params[key]);
    });

    return routeWithValues;
};
