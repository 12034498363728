import React from 'react';

import { Flex } from 'aa-react-ui';

import { DAYS_ENUMS, WEEK_DAYS } from 'common/constants';

import Day from 'domain/schedule/components/day';

function List() {
    return (
        <Flex flexDirection="column">
            {DAYS_ENUMS.map((day: string, index: number) => (
                <Day key={day} day={day} title={WEEK_DAYS[index]} />
            ))}
        </Flex>
    );
}

export default List;
