import React, { useEffect, useState } from 'react';
import { Flex, H5, Card, Button, Text, Spinner } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { CylinderCollectionItem } from 'domain/cylinder/components';
import CylinderModel from 'models/cylinder';
import Checkbox from 'lib/aa-react-ui/checkbox';
import commonUtils from 'common/utils/common';
import { IUnassignedTask } from 'domain/task/types';
import { ICylinder } from 'models/types';

interface IUnassignedTaskListProps {
    tasks: Array<IUnassignedTask>;
    onAssign?: (newData: Array<ICylinder>) => void;
    isLoading?: boolean;
}

const UnassignedTaskList: React.FunctionComponent<IUnassignedTaskListProps> = props => {
    const { tasks = [], onAssign, isLoading } = props;

    const { t } = useTranslation();
    const [dataState, setDataState] = useState(tasks);
    const [cylindersToAssign, setCylindersToAssign] = useState<Array<ICylinder>>([]);

    useEffect(() => {
        setDataState(tasks);
    }, [tasks]);

    useEffect(() => {
        setCylindersToAssign(dataState.filter(item => item.selected).map(item => item.cylinder));
    }, [dataState, setCylindersToAssign]);

    const handleCheckChange = (index: number, checked: boolean) => {
        const newData = commonUtils.cloneDeep(dataState);
        newData[index].selected = checked;
        setDataState(newData);
    };

    if (dataState.length === 0) {
        return null;
    }

    return (
        <Card p="full" mt="half">
            <Flex justifyContent="space-between">
                <H5 color="contrast.90" p="half">
                    {t(TKey.taskListUnassignedHeader)}
                </H5>
                {isLoading ? (
                    <Spinner size="16px" />
                ) : (
                    <Button
                        data-testid="assign-button"
                        disabled={cylindersToAssign.length === 0 || isLoading}
                        size="sm"
                        variant="primary"
                        onClick={() => {
                            onAssign && onAssign(cylindersToAssign);
                        }}>
                        <Text>{t(TKey.actionTaskAssignSelection)}</Text>
                    </Button>
                )}
            </Flex>

            <Flex m="half" flexDirection="column">
                {dataState.map((item, index) => {
                    return (
                        <CylinderCollectionItem
                            selected={item.selected}
                            key={`cylinder_${index}`}
                            hideStatus
                            onClick={() => {
                                handleCheckChange(index, !item.selected);
                            }}
                            dataModel={new CylinderModel(item.cylinder)}>
                            <Checkbox
                                data-testid="check-box-input"
                                checked={item.selected}
                                onChange={() => {
                                    handleCheckChange(index, !item.selected);
                                }}
                            />
                        </CylinderCollectionItem>
                    );
                })}
            </Flex>
        </Card>
    );
};

export default UnassignedTaskList;
