import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import Routes from './routes';
import DashboardLayout from 'views/layouts/dashboard_layout';
import AuthLayout from 'views/layouts/auth_layout';
import AboutLayout from 'views/layouts/about_layout';
import PrivateRoute from './private_route';

import {
    Home,
    Activate,
    Login,
    GoDashboard,
    ForgotPassword,
    Keys,
    Cylinders,
    TaskList,
    About,
    Logout,
    AccessLogRequest,
    License,
    ThirdPartyLicense,
    Policy,
    HandIn,
    HandOut,
    BlockKey,
    UnblockKey,
    CopyDetails
} from 'views';

interface IProps {
    isLoggedIn: boolean;
}

function AppRouter(props: IProps): JSX.Element {
    const { isLoggedIn } = props;

    return (
        <Switch>
            <Route exact path={Routes.ROOT} component={Home} />

            <Route
                path={Routes.AUTH}
                component={(componentProps: RouteComponentProps) => {
                    return (
                        <AuthLayout {...componentProps}>
                            <Route exact path={Routes.AUTH} component={Login} />
                            <Route path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />
                        </AuthLayout>
                    );
                }}
            />

            <Route path={Routes.ABOUT_ACTIVATE} component={Activate} />

            <PrivateRoute path={Routes.DASHBOARD} isAuthorized={isLoggedIn}>
                <DashboardLayout>
                    <Switch>
                        <Route exact path={Routes.DASHBOARD}>
                            <GoDashboard />
                        </Route>

                        <Route path={Routes.KEYS_KEY}>
                            <Keys />
                        </Route>
                        <Route path={Routes.CYLINDERS}>
                            <Cylinders />
                        </Route>
                        <Route path={Routes.ABOUT}>
                            <About />
                        </Route>
                        <Route path={Routes.TASK_LIST}>
                            <TaskList />
                        </Route>
                        <Route path={Routes.ACCESS_LOG_REQUEST}>
                            <AccessLogRequest />
                        </Route>
                        <Route path={Routes.HAND_IN}>
                            <HandIn />
                        </Route>
                        <Route path={Routes.BLOCK_KEY}>
                            <BlockKey />
                        </Route>
                        <Route path={Routes.HAND_OUT}>
                            <HandOut />
                        </Route>
                        <Route path={Routes.UNBLOCK_KEY}>
                            <UnblockKey />
                        </Route>
                        <Route path={Routes.COPY_DETAILS}>
                            <CopyDetails />
                        </Route>
                    </Switch>
                </DashboardLayout>
            </PrivateRoute>

            <Route path={Routes.LOGOUT} component={Logout} />

            <Route
                path={Routes.ABOUT}
                component={(componentProps: RouteComponentProps) => {
                    return (
                        <AboutLayout {...componentProps}>
                            <Route exact path={Routes.ABOUT} component={About} />
                            <Route path={Routes.ABOUT_LICENCE} component={License} />
                            <Route path={Routes.ABOUT_POLICY} component={Policy} />
                            <Route path={Routes.ABOUT_THIRD_PARTY_LICENSE} component={ThirdPartyLicense} />
                        </AboutLayout>
                    );
                }}
            />
        </Switch>
    );
}

export default AppRouter;
