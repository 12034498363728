import { KeyState } from 'models/types';
import { TKey } from 'locale';
import { KeyPendingStatus, KeyCollectionType } from './types';
import { CUSTOM_THEME_COLOR } from 'common/constants';

export const KEY_STATE_TRANSLATION_KEY_MAP = {
    [KeyState.HANDED_OUT]: TKey.genericHandedOut,
    [KeyState.BLOCKED]: TKey.genericBlocked,
    [KeyState.IN_STOCK]: TKey.genericInStock,
    [KeyState.UNKNOWN]: TKey.empty
};

export const KEY_STATUS_TRANSLATION_KEY_MAP = {
    [KeyPendingStatus.ACCESS]: TKey.genericAccess,
    [KeyPendingStatus.SCHEDULE]: TKey.genericSchedule,
    [KeyPendingStatus.TASKS]: TKey.genericTasks,
    [KeyPendingStatus.VALIDITY]: TKey.genericValidity
};

export const KEY_COLLECTION_NAME_TRANSLATION_KEY_MAP: { [x: string]: TKey } = {
    [KeyCollectionType.HARMFUL]: TKey.genericToBeBlockedInCylinder,
    [KeyCollectionType.TO_BE_BLOCKED_IN_CYLINDER]: TKey.genericToBeBlockedInCylinder,
    [KeyCollectionType.PENDING]: TKey.genericNeedUpdate,
    [KeyCollectionType.WITH_TASKS]: TKey.genericWithTasks,
    [KeyCollectionType.OTHER]: TKey.genericOther,
    [KeyCollectionType.TO_BE_ADDED]: TKey.genericToBeAdded,
    [KeyCollectionType.TO_BE_REMOVED]: TKey.genericToBeRemoved,
    [KeyCollectionType.BLOCKED_IN_CYLINDER]: TKey.genericBlockedInCylinder,
    [KeyCollectionType.UP_TO_DATE]: TKey.genericUpToDate
};

export const KEY_ICON_COLOR_MAP: { [x: string]: string } = {
    [KeyCollectionType.HARMFUL]: CUSTOM_THEME_COLOR.red,
    [KeyCollectionType.PENDING]: CUSTOM_THEME_COLOR.orange,
    [KeyCollectionType.OTHER]: CUSTOM_THEME_COLOR.darkBlue,
    [KeyCollectionType.WITH_TASKS]: CUSTOM_THEME_COLOR.blue,
    [KeyCollectionType.TO_BE_BLOCKED_IN_CYLINDER]: CUSTOM_THEME_COLOR.red,
    [KeyCollectionType.TO_BE_ADDED]: CUSTOM_THEME_COLOR.orange,
    [KeyCollectionType.TO_BE_REMOVED]: CUSTOM_THEME_COLOR.orange,
    [KeyCollectionType.BLOCKED_IN_CYLINDER]: CUSTOM_THEME_COLOR.gray
};
