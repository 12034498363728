import React from 'react';
import { Select } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { CylinderFilter } from 'domain/cylinder/types';

interface IFilterProps {
    value: CylinderFilter;
    onChange: (newFilter: CylinderFilter) => void;
}

const CylinderFilterSelect = (props: IFilterProps) => {
    const { value, onChange } = props;

    const { t } = useTranslation();

    return (
        <Select
            value={value}
            placeholder="Select filter"
            onChange={e => {
                onChange(e.target.value as CylinderFilter);
            }}>
            <option value={CylinderFilter.ALL}>{t(TKey.genericAll)}</option>
            <option value={CylinderFilter.INSTALLED}>{t(TKey.genericInstalled)}</option>
            <option value={CylinderFilter.IN_STOCK}>{t(TKey.genericInStock)}</option>
        </Select>
    );
};

export default CylinderFilterSelect;
