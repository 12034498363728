import React from 'react';
import SideBar from '../components/side_bar';

import { Surface, Flex, Text, Box } from 'aa-react-ui';
import { AssaAbloyLogo } from 'domain/authentication/components';
import Header from 'components/header';

const AboutLayout: React.FunctionComponent = props => {
    const { children } = props;
    return (
        <Surface height="100vh" width="100%" bg="contrast.20" overflow="hidden">
            <Header>
                <AssaAbloyLogo width="10rem" />
                <Text fontSize="18px" paddingLeft="0.2rem">
                    CLIQ™ Go
                </Text>
            </Header>
            <Flex height="inherit" paddingTop="4rem">
                <SideBar />
                <Flex padding="0.5rem" width="100%" height="100%" style={{ overflowY: 'auto' }} justifyContent="center">
                    <Box p="full" width={[1, 1, 0.5]} height="100%">
                        {children}
                    </Box>
                </Flex>
            </Flex>
        </Surface>
    );
};

export default AboutLayout;
