import CylinderModel from 'models/cylinder';
import KeyModel from 'models/key';
import {
    ICylinderCollectionMap,
    ICylinderCollection,
    CylinderFilter,
    CylinderCollectionType,
    IKeyAccessCylinderCollectionMap
} from 'domain/cylinder/types';
import {
    sortCylindersOnStateAndNameAndMarking,
    isCylinderAtRiskForLoal,
    isCylinderAtRisk
} from 'domain/cylinder/cylinder_helper';

class CylinderUtilsFactory {
    constructor(public cylinders: Array<CylinderModel>, public keys: Array<KeyModel>) {
        this.cylinders = cylinders;
        this.keys = keys;
    }

    getCylinderCollectionType(cylinder: CylinderModel) {
        if (isCylinderAtRisk(cylinder)) {
            return CylinderCollectionType.AT_RISK;
        } else if (cylinder.hasPendingJob()) {
            return CylinderCollectionType.PENDING_UPDATE;
        }

        return CylinderCollectionType.OTHER;
    }

    sortKeyCollections = (collections: Array<ICylinderCollection>) => {
        return collections.map((data: ICylinderCollection) => {
            const { type: name, items } = data;

            return {
                type: name,
                items: items.sort(sortCylindersOnStateAndNameAndMarking.bind(this))
            };
        });
    };

    getCylinderInfoById(cylinderId: string) {
        const cylinderData = this.cylinders.find(cylinder => cylinder.uuid === cylinderId);

        if (cylinderData) {
            const keyCollectionType = this.getCylinderCollectionType(cylinderData);
            return {
                cylinder: cylinderData,
                collectionType: keyCollectionType
            };
        }

        return {};
    }

    searchCylinder(cylinder: CylinderModel, withQuery: string) {
        return (
            cylinder.name.toUpperCase().indexOf(withQuery.toUpperCase()) !== -1 ||
            cylinder.marking.toUpperCase().indexOf(withQuery.toUpperCase()) !== -1
        );
    }

    filterCylinder(cylinder: CylinderModel, withFilter: CylinderFilter) {
        switch (withFilter) {
            case CylinderFilter.ALL:
                return true;
            case CylinderFilter.IN_STOCK:
                return cylinder.isInStock();
            case CylinderFilter.INSTALLED:
                return cylinder.isInstalled();
            default:
                return true;
        }
    }

    getKeyAccessCylinderCollectionType(key: KeyModel, cylinder: CylinderModel) {
        const loal = key.authorizations.find(loalItem => loalItem.cylinderUuid === cylinder.uuid);

        const louk = cylinder.unauthorizedKeys.find(loukItem => loukItem.keyUuid === key.uuid);

        if (loal) {
            if (isCylinderAtRiskForLoal(loal, cylinder, key)) {
                return CylinderCollectionType.AT_RISK;
            }

            if (loal.isAdded()) {
                return CylinderCollectionType.TO_BE_ADDED;
            }

            if (loal.isRemoved()) {
                return CylinderCollectionType.TO_BE_REMOVED;
            }

            if (loal.isCurrent()) {
                return CylinderCollectionType.OTHER;
            }
        }

        if (louk && !loal) {
            return CylinderCollectionType.BLOCKED_IN;
        }

        return CylinderCollectionType.UNKNOWN;
    }

    findById(cylinderId: string) {
        return this.cylinders.find(cylinder => cylinder.uuid === cylinderId);
    }

    getKeyAccessCylinderCollections(keyId: string, andSearchQuery: string) {
        if (!this.cylinders.length) {
            return [];
        }

        const selectedKey = this.keys.find(key => key.uuid === keyId);

        if (!selectedKey) {
            return [];
        }

        const collectionMap = this.cylinders.reduce(
            (result: IKeyAccessCylinderCollectionMap, item: CylinderModel) => {
                if (!item) {
                    return result;
                }

                if (this.searchCylinder(item, andSearchQuery)) {
                    const cylinderCollectionType = this.getKeyAccessCylinderCollectionType(selectedKey, item);

                    switch (cylinderCollectionType) {
                        case CylinderCollectionType.AT_RISK:
                            result.atRisk.items.push(item);
                            break;
                        case CylinderCollectionType.TO_BE_ADDED:
                            result.toBeAdded.items.push(item);
                            break;
                        case CylinderCollectionType.TO_BE_REMOVED:
                            result.toBeRemoved.items.push(item);
                            break;
                        case CylinderCollectionType.OTHER:
                            result.upToDate.items.push(item);
                            break;
                        case CylinderCollectionType.BLOCKED_IN:
                            result.blockedIn.items.push(item);
                            break;
                        default:
                            break;
                    }
                }

                return result;
            },
            {
                atRisk: {
                    type: CylinderCollectionType.AT_RISK,
                    items: []
                },
                toBeAdded: {
                    type: CylinderCollectionType.TO_BE_ADDED,
                    items: []
                },
                toBeRemoved: {
                    type: CylinderCollectionType.TO_BE_REMOVED,
                    items: []
                },
                upToDate: {
                    type: CylinderCollectionType.OTHER,
                    items: []
                },
                blockedIn: {
                    type: CylinderCollectionType.BLOCKED_IN,
                    items: []
                }
            }
        );

        return this.sortKeyCollections(Object.values(collectionMap));
    }

    getCylinderCollection(withFilter: CylinderFilter, andSearchQuery: string): Array<ICylinderCollection> {
        if (!this.cylinders.length) {
            return [];
        }

        const collectionMap = this.cylinders.reduce(
            (result: ICylinderCollectionMap, item: CylinderModel) => {
                if (this.filterCylinder(item, withFilter) && this.searchCylinder(item, andSearchQuery)) {
                    const cylinderCollectionType = this.getCylinderCollectionType(item);

                    switch (cylinderCollectionType) {
                        case CylinderCollectionType.AT_RISK:
                            result.atRisk.items.push(item);
                            break;
                        case CylinderCollectionType.PENDING_UPDATE:
                            result.pendingUpdate.items.push(item);
                            break;
                        case CylinderCollectionType.OTHER:
                            result.upToDate.items.push(item);
                            break;
                        default:
                            break;
                    }
                }

                return result;
            },
            {
                atRisk: {
                    type: CylinderCollectionType.AT_RISK,
                    items: []
                },
                pendingUpdate: {
                    type: CylinderCollectionType.PENDING_UPDATE,
                    items: []
                },
                upToDate: {
                    type: CylinderCollectionType.OTHER,
                    items: []
                }
            }
        );

        return this.sortKeyCollections(Object.values(collectionMap));
    }
}

export default CylinderUtilsFactory;
