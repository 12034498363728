import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box } from 'aa-react-ui';
import styled from 'styled-components';

interface ICustomLinkProps {
    disabled?: boolean;
}

const CustomLink = styled(Link)<ICustomLinkProps>`
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkButton: React.FunctionComponent<any> = props => {
    const { children, to, variant, size, disabled, ...rest } = props;

    return (
        <Box {...rest} as="span" display="inline-block">
            <CustomLink to={to} disabled={disabled}>
                <Button as="span" variant={variant} size={size} disabled={disabled}>
                    {children}
                </Button>
            </CustomLink>
        </Box>
    );
};

export default LinkButton;
