import { AuthorizationState, AuthorizationStateInt } from './types';

export default class AuthorizationEntryModel {
    public state: AuthorizationState;

    constructor(state: AuthorizationState) {
        this.state = state;
    }

    isCurrent() {
        return this.state === AuthorizationState.CURRENT;
    }

    isPlaceholder() {
        return this.state === AuthorizationState.PLACEHOLDER;
    }

    isAdded() {
        return this.state === AuthorizationState.ADDED;
    }

    isRemoved() {
        return this.state === AuthorizationState.REMOVED;
    }

    isPending() {
        return this.isAdded() || this.isRemoved();
    }

    setStateRemoved() {
        this.state = AuthorizationState.REMOVED;
    }

    stateIntRepresentation() {
        switch (this.state) {
            case AuthorizationState.ADDED:
                return AuthorizationStateInt.ADDED;
            case AuthorizationState.REMOVED:
                return AuthorizationStateInt.REMOVED;
            case AuthorizationState.CURRENT:
                return AuthorizationStateInt.CURRENT;
            case AuthorizationState.PLACEHOLDER:
                return AuthorizationStateInt.PLACEHOLDER;
            default:
                return AuthorizationStateInt.DEFAULT;
        }
    }
}
