import styled from 'styled-components';

import { themeGet } from '@styled-system/theme-get';
import {
    MarginProps,
    width,
    WidthProps,
    margin,
    height,
    overflow,
    HeightProps,
    OverflowProps,
    maxHeight,
    MaxHeightProps
} from 'styled-system';

const TRow = styled.tr``;

const TBody = styled.tbody``;

const TCell = styled.td<WidthProps>`
    ${width}
`;

const Thead = styled.thead`
    background-color: ${themeGet('colors.primary.10')};

    ${TCell} {
        position: sticky;
        top: 0;
        background-color: ${themeGet('colors.contrast.20')};
        font-weight: bold;
        padding: 9px;
    }
`;
interface TableProps extends MarginProps, HeightProps, OverflowProps, MaxHeightProps {}

const Table = styled('table')<TableProps>`
    border-radius: ${p => themeGet('radii.default')(p)};
    position: relative;

    ${TCell}, ${Thead} {
        border: 0.5px solid ${themeGet('colors.contrast.40')};
        padding-left: 9px;
    }
    
    ${margin}
    ${height}
    ${overflow}
    ${maxHeight}
`;

export { Thead, TRow, TCell, TBody };
export default Table;
