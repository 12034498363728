import React from 'react';
import { Button, Text, Spinner } from 'aa-react-ui';

import { useTranslation, TKey } from 'locale';

interface IEditorActionsProps {
    hideSave?: boolean;
    editorDirty?: boolean;
    loading?: boolean;
    onSave?: () => void;
    onBack?: () => void;
}

const EditorActions: React.FunctionComponent<IEditorActionsProps> = props => {
    const { loading, onSave, onBack, editorDirty, hideSave } = props;
    const { t } = useTranslation();

    return (
        <>
            <Button data-testid="editor-action-back" disabled={loading} mr="quarter" onClick={onBack}>
                <Text>{t(TKey.actionBack)}</Text>
            </Button>
            {!hideSave ? (
                <Button
                    data-testid="editor-action-save"
                    disabled={loading || !editorDirty}
                    onClick={onSave}
                    ml="quarter"
                    variant="primary">
                    {loading ? <Spinner data-testid="spinner" size="1rem" /> : <Text>{t(TKey.actionSave)}</Text>}
                </Button>
            ) : null}
        </>
    );
};

export default EditorActions;
