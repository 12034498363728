import { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { CUSTOM_THEME_COLOR } from 'common/constants';

const keyCardCSS = css`
    display: flex;
    flex-direction: row;
    padding: ${p => p.theme.space.half};
    margin-top: ${p => p.theme.space.quarter};

    transition: all 0.2s ease-in-out;
    cursor: pointer;

    border-radius: ${p => themeGet('radii.default')(p)};
    border: 1px solid ${p => themeGet('colors.contrast.30')(p)};

    outline: none;
    text-decoration: none;
    text-overflow: ellipsis;

    color: #000;

    &:hover {
        text-decoration: none;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    }

    &.selected {
        background: ${themeGet(`colors.${CUSTOM_THEME_COLOR.extraLightBlue}`)};
        border-color: ${themeGet(`colors.${CUSTOM_THEME_COLOR.lightBlue}`)};
    }

    &.disabled {
        background: ${themeGet(`colors.${CUSTOM_THEME_COLOR.lightGray}`)};
    }
`;

export default keyCardCSS;
