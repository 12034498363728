import React, { useRef, useEffect } from 'react';
import { History } from 'history';
import { SectionHeader, Section, SectionBody, H3, Box, Alert, Flex } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { CylinderCollectionItem } from 'domain/cylinder/components';
import { CylinderCollectionType } from 'domain/cylinder/types';
import { useUpdateCylinderRequest } from 'domain/cylinder/redux/hooks';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';
import { useNotification } from 'domain/notification/redux/hooks';

import CylinderModel from 'models/cylinder';
import commonUtils from 'common/utils/common';
import EditorActions from 'components/editor_actions';

interface ICylinderUninstallProps {
    history: History;
    cylinderData: { cylinder?: CylinderModel; collectionType?: CylinderCollectionType };
}

const CylinderUninstall = (props: ICylinderUninstallProps) => {
    const { history, cylinderData } = props;
    const { cylinder, collectionType } = cylinderData;

    const { t } = useTranslation();
    const pristineCylinderRef = useRef<CylinderModel | null>();
    const { updateCylinder, loading } = useUpdateCylinderRequest();
    const { showSuccess, showError } = useNotification();

    useEffect(() => {
        if (cylinder) {
            const cylinderClone = commonUtils.cloneDeep(cylinder);
            pristineCylinderRef.current = cylinderClone;
        }
    }, [cylinder]);

    if (!cylinder) {
        return null;
    }

    const goBack = () => {
        history.push(buildRoute(Routes.CYLINDERS_CYLINDER, { cylinderId: cylinder.uuid }));
    };

    const onSaveClick = async () => {
        const newCylinder = pristineCylinderRef.current;

        if (newCylinder) {
            newCylinder.setStateInStock();
            newCylinder.name = '';

            const updateSuccess = await updateCylinder(newCylinder.uuid, newCylinder.toDataTransferObject());
            if (updateSuccess) {
                showSuccess(TKey.cylinderUninstallSuccess);
                goBack();
            } else {
                showError(TKey.cylinderUninstallError);
            }
        }
    };

    const renderActions = () => <EditorActions loading={loading} onSave={onSaveClick} onBack={goBack} editorDirty />;

    return (
        <Section width="100%">
            <Flex flexDirection="column" height="100%">
                <SectionHeader title={<H3>{t(TKey.actionCylinderUninstall)}</H3>} actions={renderActions()} />
                <SectionBody style={{ overflowY: 'auto' }}>
                    <Flex height="inherit" flexDirection="column" width="100%">
                        <CylinderCollectionItem dataModel={cylinder} collectionType={collectionType} />
                        <Box mt="half">
                            <Alert title={t(TKey.genericWarning)} text={t(TKey.handInWarning)} variant="warning" />
                        </Box>
                    </Flex>
                </SectionBody>
            </Flex>
        </Section>
    );
};

export default CylinderUninstall;
