import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { ReactComponent as HomeIcon } from 'components/icon/svgs/home.svg';
import { ReactComponent as KeyIcon } from 'components/icon/svgs/key.svg';
import { ReactComponent as KeyBlockedIcon } from 'components/icon/svgs/key_blocked.svg';
import { ReactComponent as CylinderIcon } from 'components/icon/svgs/cylinder.svg';
import { ReactComponent as TaskListIcon } from 'components/icon/svgs/task_list.svg';
import { ReactComponent as AboutIcon } from 'components/icon/svgs/about.svg';
import { ReactComponent as LogoutIcon } from 'components/icon/svgs/logout.svg';
import { ReactComponent as ValidityIcon } from 'components/icon/svgs/validity.svg';
import { ReactComponent as ArrowRightIcon } from 'components/icon/svgs/arrow_right.svg';
import { ReactComponent as CheckCircle } from 'components/icon/svgs/check_circle.svg';
import { ReactComponent as ScheduleIcon } from 'components/icon/svgs/schedule.svg';
import { ReactComponent as Logs } from 'components/icon/svgs/logs.svg';
import { ReactComponent as DownRightArrow } from 'components/icon/svgs/down_right_arrow.svg';
import { ReactComponent as SettingsIcon } from 'components/icon/svgs/settings.svg';
import { THEME_COLOR_PREFIX } from 'common/constants';

interface IPlaceholderProps {
    fill?: string;
    stroke?: string;
    height?: string | number;
    width?: string | number;
}

const getThemeColorKey = (color: string) => `${THEME_COLOR_PREFIX}${color}`;

const IconPlaceholder = styled.span<IPlaceholderProps>`
    fill: ${props => {
        if (props.fill) {
            return themeGet(getThemeColorKey(props.fill))(props);
        } else {
            return themeGet('colors.transparent')(props);
        }
    }};
    stroke: ${props => {
        if (props.stroke) {
            return themeGet(getThemeColorKey(props.stroke))(props);
        } else {
            return themeGet('colors.transparent')(props);
        }
    }};
    height: ${props => props.height || '2rem'};
    width: ${props => props.width || '2.5rem'};
    stroke-width: 2.5px;
`;

interface IIconWrapperProps extends IPlaceholderProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
}

const IconWrapper = (props: IIconWrapperProps) => {
    const { icon, ...rest } = props;

    return <IconPlaceholder as={icon} {...rest} />;
};

export {
    HomeIcon,
    KeyIcon,
    CylinderIcon,
    TaskListIcon,
    AboutIcon,
    LogoutIcon,
    ValidityIcon,
    ArrowRightIcon,
    KeyBlockedIcon,
    CheckCircle,
    ScheduleIcon,
    Logs,
    DownRightArrow,
    IconWrapper,
    SettingsIcon
};
