import React, { useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';
import { Routes } from 'router';
import { useCylinderSelection } from 'domain/cylinder/redux/hooks';

import CylinderAccessLog from './cylinder_access_log';
import CylinderSummary from './cylinder_summary';
import CylinderUninstall from './cylinder_uninstall';
import CylinderNameEditor from './cylinder_name_editor';
import CylinderKeyAccessEditor from './cylinder_key_access_editor';

const CylinderDetails: React.FunctionComponent = () => {
    const { cylinderId } = useParams<{ cylinderId: string }>();
    const { setSelectedCylinderId } = useCylinderSelection();

    useEffect(() => {
        setSelectedCylinderId(cylinderId);
    }, [setSelectedCylinderId, cylinderId]);

    return (
        <>
            <Route exact path={Routes.CYLINDERS_CYLINDER} component={CylinderSummary} />
            <Route
                exact
                path={[Routes.CYLINDERS_CYLINDER_NAME, Routes.CYLINDERS_CYLINDER_INSTALL]}
                component={CylinderNameEditor}
            />
            <Route exact path={Routes.CYLINDERS_CYLINDER_UNINSTALL} component={CylinderUninstall} />
            <Route exact path={Routes.CYLINDERS_CYLINDER_ACCESS_LOG} component={CylinderAccessLog} />
            <Route exact path={Routes.CYLINDERS_CYLINDER_ACCESS} component={CylinderKeyAccessEditor} />
        </>
    );
};

export default CylinderDetails;
