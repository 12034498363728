import React from 'react';
import { useSelector } from 'react-redux';

import { Text, Flex } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';

import { getCylinderById } from 'domain/cylinder/redux/selectors';
import { IAppStoreState } from 'app_redux/types';

interface ITaskInfoProps {
    uuid: string;
}

const TaskInfo: React.FunctionComponent<ITaskInfoProps> = props => {
    const { uuid } = props;

    const cylinderSelector = getCylinderById();

    const selector = useSelector((state: IAppStoreState) => cylinderSelector(state, uuid));

    const { t } = useTranslation();

    const { cylinder } = selector || {};

    if (!cylinder) {
        return null;
    }

    const renderAssignStatus = () => {
        const taskCount = cylinder.countStartedTasks();
        if (taskCount > 0) {
            return <Text color="contrast.50">{t(TKey.taskListAssignedStatusStarted, { count: taskCount })}</Text>;
        }

        return null;
    };

    const renderPendingQueuedStatus = () => {
        const taskCount = cylinder.countAssignedButNotStartedTasks();

        if (taskCount > 0 && cylinder.hasStartedTasks()) {
            return <Text color="contrast.50">{t(TKey.taskListAssignedStatusPendingQueued, { count: taskCount })}</Text>;
        }

        return null;
    };

    const renderPendingAddedStatus = () => {
        const taskCount = cylinder.countAssignedButNotStartedTasks();

        if (taskCount > 0 && !cylinder.hasStartedTasks()) {
            return <Text color="contrast.50">{t(TKey.taskListAssignedStatusPendingAdded, { count: taskCount })}</Text>;
        }

        return null;
    };

    const renderPendingRemovedTaskStatus = () => {
        const taskCount = cylinder.countPendingUnassignedTasks();

        if (taskCount > 0) {
            return (
                <Text color="contrast.50">{t(TKey.taskListAssignedStatusPendingRemoved, { count: taskCount })}</Text>
            );
        }

        return null;
    };

    return (
        <Flex flexDirection="column" mt="quarter">
            {renderAssignStatus()}
            {renderPendingQueuedStatus()}
            {renderPendingAddedStatus()}
            {renderPendingRemovedTaskStatus()}
        </Flex>
    );
};

export default TaskInfo;
