import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Routes from './routes';

interface IProps {
    isAuthorized: boolean;
    path: string;
    children?: JSX.Element | JSX.Element[];
}

const PrivateRoute = (props: IProps): JSX.Element => {
    const { isAuthorized, path, children } = props;
    const location = useLocation();

    return isAuthorized ? (
        <Route to={path}>{children}</Route>
    ) : (
        <Redirect
            to={{
                pathname: Routes.AUTH,
                state: { from: location }
            }}
        />
    );
};

export default PrivateRoute;
