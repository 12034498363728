import { useSelector, useDispatch } from 'react-redux';
import { getAppLocale } from './selectors';
import { useCallback } from 'react';
import { setAppLocale } from './actions';

export const useAppLocale = () => {
    const dispatch = useDispatch();
    const locale = useSelector(getAppLocale);

    const setLocale = useCallback(
        (newLocale: string) => {
            setAppLocale(newLocale)(dispatch);
        },
        [dispatch]
    );

    return {
        locale,
        setLocale
    };
};
