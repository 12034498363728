import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useKey, useUpdateKeyRequest } from 'domain/key/redux/hooks';
import { HandInOption } from 'domain/key/types';
import { KeyState } from 'models/types';
import { Routes } from 'router';
import { IHandInSettings } from './types';
import { buildRoute } from 'router/helper';
import { useNotification } from 'domain/notification/redux/hooks';
import { TKey } from 'locale';
import KeyModel from 'models/key';
import commonUtils from 'common/utils/common';
import SelectAction from './select_action';
import SelectionSummary from './selection_summary';
import useWizard from 'components/wizard/useWizard';

import RouteGuard from 'components/route-guard';

const SelectSetting = () => {
    const history = useHistory();
    const location = useLocation<{ from: Location }>();
    const params = useParams<{ keyId: string }>();
    const [hasSaved, setHasSaved] = useState<boolean>(false);
    const key = useKey(params.keyId);
    const { updateKey, loading } = useUpdateKeyRequest();
    const { showSuccess, showError } = useNotification();

    const initialWizardData = {
        key,
        handInOption: HandInOption.KEEP_SETTINGS
    };

    const onFinish = async (finalData: IHandInSettings) => {
        const { handInOption } = finalData;
        if (key) {
            const keyToUpdate = commonUtils.cloneDeep(key);
            keyToUpdate.name = '';
            keyToUpdate.state = KeyState.IN_STOCK;
            const keyDataModel = new KeyModel(keyToUpdate);

            if (handInOption === HandInOption.RESET_SETTINGS) {
                keyDataModel.removeAllAuthorizations();
                keyDataModel.validity.setAlwaysValid();
                keyDataModel.schedule.setPendingStateNotUsed();
            }

            const updateSuccess = await updateKey(keyDataModel.uuid, keyDataModel.toDataTransferObject());
            if (updateSuccess) {
                const successMessage =
                    handInOption === HandInOption.RESET_SETTINGS ? TKey.handInSuccessErased : TKey.handInSuccessKept;

                setHasSaved(true);
                showSuccess(successMessage);
                history.push(buildRoute(Routes.KEYS_KEY, { keyId: keyDataModel.uuid }));
            } else {
                showError(TKey.handInError);
            }
        }
    };

    const onBack = () => {
        const { state } = location;
        if (state && state.from) {
            history.push(state.from.pathname);
        } else {
            history.push(Routes.HAND_IN);
        }
    };

    const { Wizard, goNext, goBack, data, setData } = useWizard<IHandInSettings>(initialWizardData, onFinish, onBack);

    useEffect(() => {
        setData({
            key,
            handInOption: HandInOption.KEEP_SETTINGS
        });
    }, [setData, key]);

    return (
        <>
            <RouteGuard when={hasSaved === false} />
            <Wizard>
                <SelectAction data={data} setData={setData} onNext={goNext} onBack={goBack} />
                <SelectionSummary data={data} setData={setData} isLoading={loading} onNext={goNext} onBack={goBack} />
            </Wizard>
        </>
    );
};

export default SelectSetting;
