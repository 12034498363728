import React from 'react';
import MarkdownView from 'components/markdown_view';
import { Card } from 'aa-react-ui';

const ThirdPartyLicense = () => {
    return (
        <Card width="100%" p="full" overflow="hidden">
            <MarkdownView path={require('./third_party_license.md')} />
        </Card>
    );
};

export default ThirdPartyLicense;
