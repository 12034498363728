import React, { useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';
import { Routes } from 'router';
import KeySummary from './key_summary';
import KeyValidityEditor from './key_validity_editor';
import KeyScheduleEditor from './key_schedule_editor';
import KeyNameEditor from './key_name_editor';
import KeyAccessEditor from './key_access_editor';
import { useKeySelection } from 'domain/key/redux/hooks';

const KeyDetails = () => {
    const { keyId } = useParams<{ keyId: string }>();
    const { setSelectedKeyId } = useKeySelection();

    useEffect(() => {
        setSelectedKeyId(keyId);
    }, [setSelectedKeyId, keyId]);

    return (
        <>
            <Route exact path={Routes.KEYS_KEY} component={KeySummary} />
            <Route exact path={Routes.KEYS_KEY_NAME} component={KeyNameEditor} />
            <Route exact path={Routes.KEYS_KEY_VALIDITY} component={KeyValidityEditor} />
            <Route exact path={Routes.KEYS_KEY_SCHEDULE} component={KeyScheduleEditor} />
            <Route exact path={Routes.KEYS_KEY_ACCESS} component={KeyAccessEditor} />
        </>
    );
};

export default KeyDetails;
