import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { transparentize } from 'polished';

import { Surface } from 'aa-react-ui';

const OPACITY = 0.5;

const DayWrapper = styled(Surface)`
    display: block;
    transition: all 0.2s ease-in-out;
    border-radius: ${p => themeGet('radii.default')(p)};

    margin-bottom: ${p => p.theme.space.full};
    padding: ${p => p.theme.space.half};

    border: ${p => p.theme.borders.default};

    :hover {
        border-color: ${p => transparentize(OPACITY, themeGet('colors.contrast.40')(p))};
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    }
`;

export default DayWrapper;
