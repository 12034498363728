import React, { useState } from 'react';
import { Alert, Box } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'router';
import { useKeys, useKey } from 'domain/key/redux/hooks';
import { useCylinders } from 'domain/cylinder/redux/hooks';
import { currentAuthorizationsForKey } from 'domain/key/key_helper';
import { buildRoute } from 'router/helper';
import StepView from 'components/wizard/step_view';
import TaskKeySelector from 'domain/task/components/task_key_selector';

const SelectTaskKey = () => {
    const history = useHistory();
    const keys = useKeys();
    const cylinders = useCylinders();
    const params = useParams<{ keyId: string }>();
    const keyToUnblock = useKey(params.keyId);
    const [selectedTaskKeyId, setSelectedTaskKeyId] = useState('');
    const { t } = useTranslation();

    if (!keyToUnblock) {
        return null;
    }

    const onNext = () => {
        const unblockSummaryRoute = buildRoute(Routes.UNBLOCK_KEY_SUMMARY, {
            keyId: keyToUnblock.uuid,
            taskKeyId: selectedTaskKeyId
        });

        history.push(unblockSummaryRoute, { from: history.location });
    };

    const onBack = () => {
        history.push(buildRoute(Routes.UNBLOCK_KEY_VERIFY, { keyId: keyToUnblock.uuid }));
    };

    const getCylindersToAssign = () => {
        const loalItems = currentAuthorizationsForKey(keyToUnblock);
        return cylinders.filter(item => loalItems.some(loalItem => loalItem.cylinderUuid === item.uuid));
    };

    return (
        <StepView
            title={t(TKey.blockKeyUnblockHeader)}
            subTitle={t(TKey.blockKeySelectProgrammingKeyHeader)}
            submitText={selectedTaskKeyId ? t(TKey.actionNext) : t(TKey.actionSkip)}
            onSubmit={onNext}
            onBack={onBack}>
            <Box m="quarter" mb="half">
                <Alert variant="warning" text={t(TKey.blockKeySelectProgrammingKeyBody)} />
            </Box>

            <TaskKeySelector
                keys={keys.filter(i => i.uuid !== keyToUnblock.uuid)}
                cylindersToAssign={getCylindersToAssign()}
                onSelect={taskKey => {
                    setSelectedTaskKeyId(taskKey.uuid);
                }}
            />
        </StepView>
    );
};

export default SelectTaskKey;
