import { ISchedule } from 'models/types';
import { DAYS_ENUMS } from 'common/constants';

import { FormattedInterval, FormattedWeek } from 'domain/schedule/types';

import { sortByDay, FULL_WEEK_TIME_PERIOD } from './utils';

/**
 *
 * @param {string} str A interval to format
 * @returns {object} {day: int, start: String, end: String}
 */
export function formatDateString(str: string): FormattedInterval {
    // NOTE: This is ES2018 syntax with the named capture groups. So we need to make sure Babel transforms it for us.
    const pattern = /(?<day>[1-7])T(?<start>[0|1|2][0-9]:[0-9][0-9])\/T(?<end>[0|1|2][0-9]:[0-9][0-9])/;

    const { groups = {} } = str.match(pattern) || {};

    const { day, start, end } = groups;

    const [startHour, startMinutes] = start.split(':');
    const [endHour, endMinutes] = end.split(':');

    // In the data received, weeks start at one. We want to transform this to start at zero.
    // This is used later to map to a weekend name (from a sorted array).
    const dayIndex: number = parseInt(day, 10) - 1;
    const weekDay: string = DAYS_ENUMS[dayIndex];

    return {
        day: weekDay,
        startHour,
        startMinutes,
        endHour,
        endMinutes
    };
}

/**
 *
 * Adds a day in to a existing week map.
 *
 * @param week Map to add day from interval to
 * @param interval Interval containing a day
 */
export function groupByDay(week: FormattedWeek, interval: FormattedInterval) {
    const { day } = interval || {};

    if (week === undefined) {
        throw Error('At least week is needed');
    }

    if (day === undefined) {
        return week;
    }

    const currentIntervals = week[day] || [];
    const intervals = [...currentIntervals, interval];

    return {
        ...week,
        [day]: intervals
    };
}

/**
 *
 * Given a schedule, return a object with formatted intervals
 *
 * @param schedule Schedule object from API
 * @returns A object with { [nameOfDay]: FormattedIntervals[] }
 */
export function formatSchedule(schedule: ISchedule): FormattedWeek {
    const { desiredIntervals = [] } = schedule || {};

    const emptyWeek = DAYS_ENUMS.reduce(
        (acc, day) => ({
            ...acc,
            [day]: []
        }),
        {}
    );

    const filterIntervals = (interval: string) => !!interval && interval.length && interval !== FULL_WEEK_TIME_PERIOD;

    return desiredIntervals
        .filter(filterIntervals)
        .map(formatDateString)
        .sort(sortByDay)
        .reduce(groupByDay, emptyWeek);
}

export default formatSchedule;
