import React, { useState } from 'react';
import { H5 } from 'aa-react-ui';
import { useFilteredKeys } from 'domain/key/redux/hooks';
import { KeyRadioList } from 'domain/key/components';
import { TKey, useTranslation } from 'locale';
import { useHistory } from 'react-router-dom';
import { Routes } from 'router';
import { KeyState } from 'models/types';
import { buildRoute } from 'router/helper';
import StepView from 'components/wizard/step_view';

const SelectKey = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const blockedKeys = useFilteredKeys(KeyState.BLOCKED);
    const [selectedKeyId, setSelectedKeyId] = useState('');

    const onBack = () => {
        history.push(Routes.DASHBOARD);
    };

    const onSubmit = () => {
        history.push(buildRoute(Routes.UNBLOCK_KEY_VERIFY, { keyId: selectedKeyId }));
    };

    return (
        <StepView
            title={t(TKey.blockKeyUnblockHeader)}
            onBack={onBack}
            submitText={t(TKey.actionNext)}
            disableSubmit={!selectedKeyId}
            onSubmit={onSubmit}>
            <H5 p="half">{t(TKey.genericAvailable)}</H5>

            <KeyRadioList
                items={blockedKeys}
                onSelect={key => {
                    setSelectedKeyId(key.uuid);
                }}
            />
        </StepView>
    );
};

export default SelectKey;
