import { SET_APP_LOCALE, SetAppLocaleActionType } from './action_types';
import storageService from 'common/services/storage_service';
import { SupportedLocale } from 'locale';

const APP_LOCALE = 'DATA_KEY:APP_LOCALE';

const defaultLocale = storageService.getItem(APP_LOCALE) || SupportedLocale.enGB;

const localeReducer = (state: string = defaultLocale, action: SetAppLocaleActionType) => {
    const { type, locale } = action;

    if (type === SET_APP_LOCALE) {
        storageService.setItem(APP_LOCALE, locale);
        return locale;
    }

    return state;
};

export { localeReducer };
