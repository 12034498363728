import { IValidity } from './types';
import { ValidityState } from 'domain/validity/types';

import moment from 'moment';
import { DEFAULT_DATE_FORMAT, FIRST_POSSIBLE_DATE, LAST_POSSIBLE_DATE } from 'common/configs';

export default class ValidityModel {
    readonly currentRangeBeginDate: string = '';
    readonly currentRangeEndDate: string = '';
    readonly currentState: ValidityState = ValidityState.ALWAYS;
    public newRangeBeginDate: string;
    public newRangeEndDate: string;
    public newState: ValidityState = ValidityState.ALWAYS;

    constructor(data: IValidity) {
        this.newRangeBeginDate = '';
        this.newRangeEndDate = '';

        if (data) {
            [this.currentRangeBeginDate, this.currentRangeEndDate] = data.programmedInterval.split('/');
            this.currentState = this.getState(this.currentRangeBeginDate, this.currentRangeEndDate);
            [this.newRangeBeginDate, this.newRangeEndDate] = data.desiredInterval.split('/');
            this.newState = this.getState(this.newRangeBeginDate, this.newRangeEndDate);
        }
    }

    getState(fromDate: string, toDate: string) {
        if (fromDate > toDate) {
            return ValidityState.NEVER;
        }
        if (fromDate === FIRST_POSSIBLE_DATE && toDate === LAST_POSSIBLE_DATE) {
            return ValidityState.ALWAYS;
        }
        return ValidityState.RANGE;
    }

    hasExpired() {
        return this.isCurrentlyUsingRange() && this.currentRangeEndDate < moment().format(DEFAULT_DATE_FORMAT);
    }

    isPending() {
        return (
            this.newState !== this.currentState ||
            (this.newState === ValidityState.RANGE &&
                (this.currentRangeBeginDate !== this.newRangeBeginDate ||
                    this.currentRangeEndDate !== this.newRangeEndDate))
        );
    }

    isCurrentlyValid() {
        return (
            this.isCurrentlyAlwaysValid() ||
            (this.isCurrentlyUsingRange() &&
                this.currentRangeBeginDate < moment().format(DEFAULT_DATE_FORMAT) &&
                this.currentRangeEndDate >= moment().format(DEFAULT_DATE_FORMAT))
        );
    }

    isCurrentlyNeverValid() {
        return this.currentState === ValidityState.NEVER;
    }

    isCurrentlyAlwaysValid() {
        return this.currentState === ValidityState.ALWAYS;
    }

    isCurrentlyUsingRange() {
        return this.currentState === ValidityState.RANGE;
    }

    setAlwaysValid() {
        this.newState = ValidityState.ALWAYS;
    }

    toDataObject() {
        const getInterval = function(state: ValidityState, startDate: string, endDate: string) {
            switch (state) {
                case ValidityState.ALWAYS:
                    return `${FIRST_POSSIBLE_DATE}/${LAST_POSSIBLE_DATE}`;
                case ValidityState.NEVER:
                    return `${LAST_POSSIBLE_DATE}/${FIRST_POSSIBLE_DATE}`;
                case ValidityState.RANGE:
                    endDate = moment(endDate)
                        .endOf('day')
                        .format('YYYY-MM-DDTHH:mm');
                    return `${startDate}/${endDate}`;
                default:
                    throw new Error('Invalid state');
            }
        };
        return {
            programmedInterval: getInterval(this.currentState, this.currentRangeBeginDate, this.currentRangeEndDate),
            desiredInterval: getInterval(this.newState, this.newRangeBeginDate, this.newRangeEndDate)
        };
    }
}
