import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';
import {
    IKeyCollection,
    IKeyCollectionMap,
    KeyStateFilter,
    KeyCollectionType,
    ICylinderAccessKeyCollectioMap
} from 'domain/key/types';
import { sortKeysOnStateAndNameAndMarking, isKeyHarmfulInCylinder } from 'domain/key/key_helper';
import { isKeyHarmful } from 'domain/key/key_harmful';

class KeyUtilsFactory {
    readonly keys: Array<KeyModel> = [];
    readonly cylinders: Array<CylinderModel> = [];

    constructor(keys: Array<KeyModel>, cylinders: Array<CylinderModel>) {
        this.cylinders = cylinders;
        this.keys = keys;
    }

    getSortedKeys() {
        return this.keys.sort(this.sortOnPendingAndStateAndNameAndMarking.bind(this));
    }

    searchKey(key: KeyModel, withQuery: string) {
        return (
            key.name.toUpperCase().indexOf(withQuery.toUpperCase()) !== -1 ||
            key.marking.toUpperCase().indexOf(withQuery.toUpperCase()) !== -1
        );
    }

    filterKey(key: KeyModel, withFilter: KeyStateFilter) {
        switch (withFilter) {
            case KeyStateFilter.ALL:
                return true;
            case KeyStateFilter.HANDED_OUT:
                return key.isHandedOut();
            case KeyStateFilter.IN_STOCK:
                return key.isInStock();
            case KeyStateFilter.BLOCKED:
                return key.isBlocked();
            default:
                return false;
        }
    }

    getKeyInfoById(keyId: string) {
        const keyData = this.keys.find(key => {
            return key.uuid === keyId;
        });

        if (keyData) {
            const keyCollectionType = this.getKeyCollectionType(keyData);
            return {
                key: keyData,
                collectionType: keyCollectionType
            };
        }

        return {};
    }

    getKeyCollectionType(key: KeyModel) {
        const harmful = {
            type: KeyCollectionType.HARMFUL,
            condition: isKeyHarmful(key, this.cylinders)
        };

        const pending = {
            type: KeyCollectionType.PENDING,
            condition: key.hasPendingJob() && key.isBlocked() === false
        };

        const withTasks = {
            type: KeyCollectionType.WITH_TASKS,
            condition: key.hasStartedTasks()
        };

        const fallbackType = {
            type: KeyCollectionType.OTHER,
            condition: true
        };

        const types = [harmful, pending, withTasks, fallbackType];

        const findByCondition = (type: { type: KeyCollectionType; condition: boolean | undefined }) => type.condition;

        const { type } = types.find(findByCondition) || {};

        return type;
    }

    getCylinderAccessKeyCollectionType = (key: KeyModel, cylinder: CylinderModel) => {
        const accessAuth = key.authorizations.find(auth => auth.cylinderUuid === cylinder.uuid);

        if (!accessAuth) {
            const unAuthKey = cylinder.unauthorizedKeys.find(auth => auth.keyUuid === key.uuid);

            if (unAuthKey) {
                return KeyCollectionType.BLOCKED_IN_CYLINDER;
            }

            return KeyCollectionType.UNKNOWN;
        }

        if (isKeyHarmfulInCylinder(key, cylinder) && accessAuth.isCurrent()) {
            return KeyCollectionType.TO_BE_BLOCKED_IN_CYLINDER;
        }

        if (accessAuth.isAdded()) {
            return KeyCollectionType.TO_BE_ADDED;
        }

        if (accessAuth.isRemoved()) {
            return KeyCollectionType.TO_BE_REMOVED;
        }

        return KeyCollectionType.OTHER;
    };

    sortKeyCollections = (collections: Array<IKeyCollection>) => {
        return collections.map((data: IKeyCollection) => {
            const { type: name, items } = data;

            return {
                type: name,
                items: items.sort(sortKeysOnStateAndNameAndMarking.bind(this))
            };
        });
    };

    getCylinderAccessKeyCollections(cylinderUuid: string, andSearchQuery: string): Array<IKeyCollection> {
        if (!this.keys.length) {
            return [];
        }

        const cylinder = this.cylinders.find(item => item.uuid === cylinderUuid);

        if (!cylinder) {
            return [];
        }

        const collections = this.keys.reduce(
            (result: ICylinderAccessKeyCollectioMap, item: KeyModel) => {
                if (this.searchKey(item, andSearchQuery)) {
                    const keyCollectionType = this.getCylinderAccessKeyCollectionType(item, cylinder);

                    switch (keyCollectionType) {
                        case KeyCollectionType.TO_BE_BLOCKED_IN_CYLINDER:
                            result.toBeBlockedInCylinder.items.push(item);
                            break;
                        case KeyCollectionType.TO_BE_ADDED:
                            result.toBeAdded.items.push(item);
                            break;
                        case KeyCollectionType.TO_BE_REMOVED:
                            result.toBeRemoved.items.push(item);
                            break;
                        case KeyCollectionType.OTHER:
                            result.upToDate.items.push(item);
                            break;
                        case KeyCollectionType.BLOCKED_IN_CYLINDER:
                            result.blockedInCylinder.items.push(item);
                            break;
                        default:
                            break;
                    }
                }

                return result;
            },
            {
                toBeBlockedInCylinder: {
                    type: KeyCollectionType.TO_BE_BLOCKED_IN_CYLINDER,
                    items: []
                },
                toBeAdded: {
                    type: KeyCollectionType.TO_BE_ADDED,
                    items: []
                },
                toBeRemoved: {
                    type: KeyCollectionType.TO_BE_REMOVED,
                    items: []
                },
                upToDate: {
                    type: KeyCollectionType.UP_TO_DATE,
                    items: []
                },
                blockedInCylinder: {
                    type: KeyCollectionType.BLOCKED_IN_CYLINDER,
                    items: []
                }
            }
        );
        return this.sortKeyCollections(Object.values(collections));
    }

    getKeyCollections(withFilter: KeyStateFilter, andSearchQuery: string): Array<IKeyCollection> {
        if (!this.keys.length) {
            return [];
        }

        const collectionMap = this.keys.reduce(
            (result: IKeyCollectionMap, item: KeyModel) => {
                if (this.filterKey(item, withFilter) && this.searchKey(item, andSearchQuery)) {
                    const keyCollectionType = this.getKeyCollectionType(item);

                    switch (keyCollectionType) {
                        case KeyCollectionType.HARMFUL:
                            result.harmful.items.push(item);
                            break;
                        case KeyCollectionType.PENDING:
                            result.pending.items.push(item);
                            break;
                        case KeyCollectionType.WITH_TASKS:
                            result.withTasks.items.push(item);
                            break;
                        case KeyCollectionType.OTHER:
                            result.updated.items.push(item);
                            break;
                        default:
                            result.updated.items.push(item);
                            break;
                    }
                }

                return result;
            },
            {
                harmful: {
                    type: KeyCollectionType.HARMFUL,
                    items: []
                },
                pending: {
                    type: KeyCollectionType.PENDING,
                    items: []
                },
                withTasks: {
                    type: KeyCollectionType.WITH_TASKS,
                    items: []
                },
                updated: {
                    type: KeyCollectionType.OTHER,
                    items: []
                }
            }
        );

        return this.sortKeyCollections(Object.values(collectionMap));
    }

    private sortOnPendingAndStateAndNameAndMarking(a: KeyModel, b: KeyModel) {
        if (a.hasPendingJob() !== b.hasPendingJob()) {
            if (a.hasPendingJob()) {
                return -1;
            } else {
                return 1;
            }
        }
        return sortKeysOnStateAndNameAndMarking(a, b);
    }
}

export default KeyUtilsFactory;
