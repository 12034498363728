import React, { useState } from 'react';
import { Label, Box, Alert } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';

import { KeyCollectionItem, KeyHandInOptions } from 'domain/key/components';
import { IHandInSettings } from '../types';
import KeyModel from 'models/key';
import StepView from 'components/wizard/step_view';

interface IProps {
    data: IHandInSettings;
    setData: (newData: IHandInSettings) => void;
    onBack?: () => void;
    onNext?: () => void;
}

const SelectAction = (props: IProps) => {
    const { onNext, onBack, data, setData } = props;

    const { key, handInOption } = data;

    const { t } = useTranslation();
    const [selectedHandInOption, setSelectedHandInOption] = useState(handInOption);

    const onSubmit = () => {
        setData({
            ...data,
            handInOption: selectedHandInOption
        });
        onNext && onNext();
    };

    return (
        <StepView title={t(TKey.handInHeader)} onBack={onBack} submitText={t(TKey.actionNext)} onSubmit={onSubmit}>
            {key && <KeyCollectionItem data={new KeyModel(key)} />}

            <Box m="quarter" mb="half" mt="half">
                <Alert text={t(TKey.handInOptionsBody)} variant="warning" />
            </Box>

            <Label pl="half">{t(TKey.handInOptionsHeader)}</Label>

            <KeyHandInOptions
                defaultValue={selectedHandInOption}
                onSelect={selectedValue => {
                    setSelectedHandInOption(selectedValue);
                }}
            />
        </StepView>
    );
};

export default SelectAction;
