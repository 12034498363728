import React from 'react';

import KeyInfoText from './key_info_text';
import KeyModel from 'models/key';
import { ArrowRightIcon } from 'components/icon';
import StyledLink from 'components/styled_link';
import { Routes } from 'router';
import KeyCollectionItem from 'domain/key/components/key_collection_item';
import { getKeyCollectionType } from 'domain/key/key_helper';
import { buildRoute } from 'router/helper';

interface IkeyInfoProps {
    disabled?: boolean;
    keyData?: KeyModel;
}

const KeyInfo = (props: IkeyInfoProps) => {
    const { keyData, disabled } = props;

    if (!keyData) {
        return null;
    }

    const keyCollectionType = getKeyCollectionType(keyData);
    const getNameEditRoute = () => buildRoute(Routes.KEYS_KEY_NAME, { keyId: keyData.uuid });

    return (
        <>
            <StyledLink data-testid={`key-name-edit-link-${keyData.uuid}`} disabled={disabled} to={getNameEditRoute()}>
                <KeyCollectionItem data={keyData} disabled={disabled} collectionType={keyCollectionType}>
                    {!disabled && <ArrowRightIcon height="2rem" width="2rem" />}
                </KeyCollectionItem>
            </StyledLink>
            <KeyInfoText collectionType={keyCollectionType} />
        </>
    );
};

export default KeyInfo;
