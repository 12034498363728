import React, { useState, useCallback, useMemo } from 'react';
import { Box, Alert } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { KeyAccessCheckList } from 'domain/key/components';
import { useCylinders } from 'domain/cylinder/redux/hooks';
import { getKeyAccessList, sanitizeKeyAccess } from 'domain/key/key_helper';
import { IKeyAccessInfo } from 'domain/key/types';
import { IKey } from 'models/types';
import StepView from 'components/wizard/step_view';
import CommonUtils from 'common/utils/common';

interface IProps {
    onNext?: () => void;
    onBack?: () => void;
    data: IKey;
    setData: (newData: IKey) => void;
}

const Access = (props: IProps) => {
    const { onNext, onBack, data, setData } = props;

    const cylinders = useCylinders();
    const { t } = useTranslation();
    const [currentKeyAccessData, setCurrentKeyAccessData] = useState<Array<IKeyAccessInfo>>([]);

    const accessListData = useMemo(() => getKeyAccessList(data, cylinders), [data, cylinders]);

    const onChange = useCallback((accessInfo: IKeyAccessInfo[]) => {
        setCurrentKeyAccessData(accessInfo);
    }, []);

    if (!data || !cylinders) {
        return null;
    }

    const onSubmit = () => {
        const keyRef = CommonUtils.cloneDeep(data);
        keyRef.authorizations = sanitizeKeyAccess(currentKeyAccessData, keyRef.authorizations);

        setData(keyRef);

        onNext && onNext();
    };

    return (
        <StepView title={t(TKey.handOutHeader)} submitText={t(TKey.actionNext)} onSubmit={onSubmit} onBack={onBack}>
            <Box mb="half" m="quarter">
                <Alert text={t(TKey.handOutAccessBody)} />
            </Box>
            <KeyAccessCheckList data={accessListData} onChange={onChange} />
        </StepView>
    );
};

export default Access;
