import axios from 'axios';

export const HTTP_STATUS_CODE = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PRECONDITION_FAILED: 412,
    IM_A_TEAPOT: 418,
    INTERNAL_SERVER_ERROR: 500
};

type HTTP_STATUS_CODE = typeof HTTP_STATUS_CODE[keyof typeof HTTP_STATUS_CODE];

const httpClientFactory = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let eventCallBackMap: { [x: string]: Array<(response: any) => void> } = {};
    const clearListeners = () => {
        eventCallBackMap = {};
    };

    const addListener = (eventName: HTTP_STATUS_CODE, callback: () => void) => {
        if (!eventCallBackMap[eventName]) {
            eventCallBackMap[eventName] = [];
        }

        eventCallBackMap[eventName].push(callback);
    };

    const removeListener = (eventName: HTTP_STATUS_CODE, callback: () => void) => {
        if (eventCallBackMap[eventName]) {
            eventCallBackMap[eventName] = eventCallBackMap[eventName].filter(item => item !== callback);
        }
    };

    axios.interceptors.request.use(requestConfig => {
        requestConfig.withCredentials = true;
        requestConfig.headers = {
            'Content-Type': 'application/json;charset=UTF-8'
        };
        return requestConfig;
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const publishEvent = (status: HTTP_STATUS_CODE, responseData?: any) => {
        const callbacks = eventCallBackMap[status];

        if (callbacks && callbacks.length) {
            try {
                callbacks.forEach(fn => {
                    fn(responseData);
                });
            } catch (e) {}
        }
    };

    axios.interceptors.response.use(
        response => {
            publishEvent(response.status);
            return response;
        },
        error => {
            const { response } = error;

            if (response) {
                publishEvent(response.status);
            }
            return Promise.reject(error);
        }
    );

    return {
        ...axios,
        addListener,
        removeListener,
        clearListeners
    };
};

const httpClient = httpClientFactory();

export default httpClient;
