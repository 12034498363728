import React from 'react';
import { useTranslation, TKey } from 'locale';
import { Text, Button } from 'aa-react-ui';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import { useHistory } from 'react-router-dom';
import { useNotification } from 'domain/notification/redux/hooks';
import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';

interface IProps {
    keyData?: KeyModel;
}

const UnblockKey = (props: IProps) => {
    const { keyData } = props;
    const { t } = useTranslation();
    const { showNotification } = useNotification();

    const history = useHistory();

    if (!keyData) {
        return null;
    }

    const getUnBlockKeyRoute = () => {
        const currentAuthorizations = keyData.authorizations.filter(item => item.isCurrent() || item.isRemoved());

        if (currentAuthorizations.length === 0) {
            return buildRoute(Routes.UNBLOCK_KEY_SUMMARY, {
                keyId: keyData.uuid
            });
        } else {
            return buildRoute(Routes.UNBLOCK_KEY_VERIFY, {
                keyId: keyData.uuid
            });
        }
    };

    const onClickHandler = () => {
        const cylindersWithKeyInLouk = keyData
            .getLoals()
            .filter(cylinder => cylinder.unauthorizedKeys.some(louk => louk.keyUuid === keyData.uuid));

        const hasStartedTasksOrPendingAccess = cylindersWithKeyInLouk.some(cylinder => {
            const cylinderModel = new CylinderModel(cylinder);
            return cylinderModel.hasStartedOrPendingUnassignedTask();
        });

        if (hasStartedTasksOrPendingAccess) {
            showNotification({
                type: 'error',
                message: TKey.blockKeyUnblockErrorNeedToFinishTasks
            });
        } else {
            history.push(getUnBlockKeyRoute());
        }
    };

    if (keyData.isBlocked()) {
        return (
            <Button data-testid="unblock-key-button" mr="quarter" onClick={onClickHandler}>
                <Text>{t(TKey.actionKeyUnblock)}</Text>
            </Button>
        );
    }

    return null;
};

export default UnblockKey;
