import React from 'react';
import { SectionHeader, SectionFooter, Button, Text, Flex, SectionBody, Section, H3 } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import Modal from 'components/modal';

const CLIQ_CONNECT_DOWNLON_LINK = 'https://cliqconnect.assaabloy.com/';

interface IProps {
    isOpen: boolean;
    onDone?: () => void;
}

const CliqConnectInstructionModal = (props: IProps) => {
    const { isOpen, onDone } = props;
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen}>
            <Section>
                <SectionHeader>
                    <H3>{t(TKey.activationCliqConnectPcInstructionsTitle)}</H3>
                </SectionHeader>

                <SectionBody maxWidth="500px">
                    <Text>{t(TKey.activationCliqConnectPcInstructionsText)}</Text>
                    <Flex marginTop="1rem">
                        <a href={CLIQ_CONNECT_DOWNLON_LINK} target="_blank" rel="noopener noreferrer">
                            <Text>{`${t(TKey.genericDownloadInfo)} ${t(TKey.genericDownloadLinkText)}`}</Text>
                        </a>
                    </Flex>
                </SectionBody>

                <SectionFooter>
                    <Flex justifyContent="flex-end">
                        <Button variant="info" onClick={onDone}>
                            {t(TKey.actionDone)}
                        </Button>
                    </Flex>
                </SectionFooter>
            </Section>
        </Modal>
    );
};

export default CliqConnectInstructionModal;
