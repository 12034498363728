import KeyModel from 'models/key';
import CylinderModel from 'models/cylinder';
import { ICylinder, IKey } from 'models/types';
import KeyUtils from './key_utils_factory';
import CylinderUtils from './cylinder_utils_factory';

class HardwareUtilsFactory {
    private keys: Array<KeyModel>;
    private cylinders: Array<CylinderModel>;

    constructor() {
        this.keys = [];
        this.cylinders = [];
    }

    populate(keys: Array<IKey>, cylinders: Array<ICylinder>) {
        this.setCylinders(this.parseCylinderData(cylinders));
        this.setKeys(this.parseKeyData(keys));

        return {
            keyUtils: () => new KeyUtils(this.keys, this.cylinders),
            cylinderUtils: () => new CylinderUtils(this.cylinders, this.keys)
        };
    }

    setKeys(list: Array<KeyModel>) {
        this.keys = list;
    }

    setCylinders(list: Array<CylinderModel>) {
        this.cylinders = list;
    }

    private parseKeyData(data: Array<IKey>) {
        const list = [];
        for (const hw of data) {
            list.push(new KeyModel(hw));
        }
        return list;
    }

    private parseCylinderData(data: Array<ICylinder>) {
        const list = [];
        for (const hw of data) {
            list.push(new CylinderModel(hw));
        }
        return list;
    }
}

const hardwareUtils = new HardwareUtilsFactory();

export default hardwareUtils;
