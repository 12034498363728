import AuthorizationEntryModel from './authorization_entry';
import { ICylinderAuthorization } from './types';

export default class LoalEntryModel extends AuthorizationEntryModel {
    public cylinderUuid: string;

    constructor(data: ICylinderAuthorization) {
        super(data.state);

        this.cylinderUuid = data.cylinderUuid;
    }

    toDataObject(): ICylinderAuthorization {
        return {
            cylinderUuid: this.cylinderUuid,
            state: this.state
        };
    }
}
