import React, { useState } from 'react';
import { useTranslation, TKey } from 'locale';
import { ICopySettings } from '../types';
import { Flex, Box, Alert } from 'aa-react-ui';
import StepView from 'components/wizard/step_view';
import CheckBoxRow from 'components/check_box_row';

interface IProps {
    onBack: () => void;
    onNext: () => void;
    data: ICopySettings;
    setData: (newData: ICopySettings) => void;
}

const SelectSettings = (props: IProps) => {
    const { onBack, onNext, data, setData } = props;
    const { t } = useTranslation();

    const [settings, setSettings] = useState(data.settings);

    const onSubmit = () => {
        setData({
            ...data,
            settings
        });
        onNext();
    };

    const handleChange = (setting: { [x: string]: boolean }) => {
        setSettings({
            ...settings,
            ...setting
        });
    };

    return (
        <StepView
            title={t(TKey.copyKeyHeader)}
            onBack={onBack}
            onSubmit={onSubmit}
            disableSubmit={!Object.values(settings).some(i => i)}
            submitText={t(TKey.actionNext)}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.copyKeyOptionsBody)} />
            </Box>

            <Flex flexDirection="column">
                <CheckBoxRow
                    text={t(TKey.genericValidity)}
                    name="validity"
                    checked={settings.validity}
                    onChange={handleChange}
                />
                <CheckBoxRow
                    text={t(TKey.genericSchedule)}
                    name="schedule"
                    checked={settings.schedule}
                    onChange={handleChange}
                />
                <CheckBoxRow
                    text={t(TKey.genericCylinderAccess)}
                    name="cylinderAccess"
                    checked={settings.cylinderAccess}
                    onChange={handleChange}
                />
            </Flex>
        </StepView>
    );
};

export default SelectSettings;
