import React from 'react';
import { useForm } from 'react-final-form';

import { AddTimeInterface } from './types';
import onSave from './onSave';
import TimeSlotForm from '../timeslot-form';

function AddTime(props: AddTimeInterface) {
    const { open, close } = props;
    const form = useForm();

    const onSaveCallback = onSave(form, close);

    return <TimeSlotForm open={open} onCancel={close} onSave={onSaveCallback} />;
}

export default AddTime;
