import HardwareModel from 'models/hardware';
import { IHardware, AuthorizationState } from 'models/types';

const getFirstMatchOn = (word: string, withExp: RegExp): string => {
    const groups = word.match(withExp);
    return groups ? groups[0] : '';
};

export const sortOnAlphaNumeric = (a: string, b: string): number => {
    if (a === b) {
        return 0;
    }

    if (!a) {
        return -1;
    }

    if (!b) {
        return 1;
    }

    const nonDigitRegex = /^\D+/;
    const anyDigitRegex = /\d+/;

    const aChar = getFirstMatchOn(a, nonDigitRegex);
    const bChar = getFirstMatchOn(b, nonDigitRegex);

    if (aChar === bChar) {
        const aNum = getFirstMatchOn(a, anyDigitRegex) || '-1';
        const bNum = getFirstMatchOn(b, anyDigitRegex) || '-1';

        if (aNum === bNum) {
            return sortOnAlphaNumeric(a.substr(aNum.length + aChar.length), b.substr(bNum.length + bChar.length));
        }

        return parseInt(aNum, 10) < parseInt(bNum, 10) ? -1 : 1;
    }

    return aChar < bChar ? -1 : 1;
};

export const sortOnNameAndMarking = (a: HardwareModel, b: HardwareModel) => {
    const result = sortOnAlphaNumeric(a.name ? a.name.toLowerCase() : '', b.name ? b.name.toLowerCase() : '');
    if (result === 0) {
        return sortOnAlphaNumeric(a.marking, b.marking);
    } else {
        return result;
    }
};

export const getHardwareMarking = (hardwareDataModel: HardwareModel) => {
    return hardwareDataModel.marking;
};

export const findHardware = (uuid: string, hardwareData: Array<IHardware>) => {
    return hardwareData.find(dataModel => {
        if (dataModel.uuid === uuid) {
            return true;
        }
        return false;
    });
};

export const getAuthorizationToggleState = (state: AuthorizationState) => {
    switch (state) {
        case AuthorizationState.CURRENT:
            return AuthorizationState.REMOVED;
        case AuthorizationState.REMOVED:
            return AuthorizationState.CURRENT;
        case AuthorizationState.ADDED:
            return AuthorizationState.PLACEHOLDER;
        case AuthorizationState.PLACEHOLDER:
            return AuthorizationState.ADDED;
        default:
            throw new Error('Unknown state');
    }
};
