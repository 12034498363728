import { connect } from 'react-redux';
import { IAppStoreState } from 'app_redux/types';
import { getActiveKeyFromState } from 'domain/key/redux/selectors';
import { IKey } from 'models/types';

import KeyScheduleEditor from './key_schedule_editor';

const mapStateToProps = (state: IAppStoreState) => {
    return {
        keyData: getActiveKeyFromState(state) as IKey
    };
};

export default connect(mapStateToProps)(KeyScheduleEditor);
