import { usePrompt } from 'lib/aa-prompt-service';
import { useTranslation, TKey } from 'locale';

export default function useErrorPrompt() {
    const showPrompt = usePrompt();
    const { t } = useTranslation();

    const showErrorPrompt = (error: TKey | string) => {
        return new Promise(resolve => {
            showPrompt({
                title: t(TKey.genericErrorHeader),
                message: t(error),
                actions: [
                    {
                        text: t(TKey.actionDone),
                        onClick: promptApi => {
                            promptApi.close();
                            resolve && resolve();
                        }
                    }
                ]
            });
        });
    };

    return {
        showErrorPrompt
    };
}
