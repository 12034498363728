import React from 'react';
import CylinderModel from 'models/cylinder';
import { Text } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';

interface ILoukInfoProps {
    cylinder: CylinderModel;
    keyId: string;
}

const LoukInfo: React.FunctionComponent<ILoukInfoProps> = props => {
    const { cylinder, keyId } = props;
    const { t } = useTranslation();

    if (!cylinder) {
        return null;
    }

    if (cylinder.hasRemovedKeyInLouk(keyId)) {
        return <Text color="error.50">{t(TKey.keyAccessBlockedRemoved)}</Text>;
    } else if (cylinder.hasAddedKeyInLouk(keyId)) {
        return <Text color="error.50">{t(TKey.keyAccessBlockedAdded)}</Text>;
    } else if (cylinder.isKeyBlocked(keyId)) {
        return <Text color="error.50">{t(TKey.keyAccessBlockedCurrent)}</Text>;
    } else {
        return null;
    }
};

export default LoukInfo;
