import React, { ReactElement, useState, useEffect } from 'react';
import { Router } from 'react-router-dom';

import { Flex, Spinner } from 'aa-react-ui';

import { initilizeLocaleSettings, SupportedLocale, changeLocale } from 'locale';
import { useAppSession } from 'domain/authentication/redux/hooks';
import { useAppLocale } from 'domain/locale/redux/hooks';

import CookieBanner from 'domain/legal/components/cookie_banner';
import AppRouter from './router';
import Notifications from 'views/notifications';
import socketService from 'common/services/socket_service';
import SessionWatcher from 'views/session_watcher';
import HttpErrorWatcher from 'views/http_error_watcher';

import history from 'router/history';

function AppRoot(): ReactElement {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { locale } = useAppLocale();
    const { session } = useAppSession();

    useEffect(() => {
        if (session.isLoggedIn && session.system) {
            socketService.open(session.system.aaCode, session.system.mksName);
        }

        return () => {
            socketService.close();
        };
    }, [session]);

    useEffect(() => {
        const intializeApp = async () => {
            await initilizeLocaleSettings(SupportedLocale.enGB);
            setIsLoading(false);
        };

        intializeApp();
    }, []);

    useEffect(() => {
        changeLocale(locale);
    }, [locale]);

    const { isLoggedIn } = session;

    if (isLoading) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="2rem" />
            </Flex>
        );
    }

    return (
        <Router history={history}>
            <AppRouter isLoggedIn={isLoggedIn} />
            <Notifications />
            <CookieBanner />
            <SessionWatcher />
            <HttpErrorWatcher />
        </Router>
    );
}

export default AppRoot;
