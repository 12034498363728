import React from 'react';

import { Flex, Label, Button } from 'aa-react-ui';

import { TKey, useTranslation } from 'locale';
import { FormattedInterval } from 'domain/schedule/types';
import { ScheduleIcon } from 'components/icon';

interface TimeSlotProps {
    data: FormattedInterval;
    edit: () => void;
    remove: () => void;
}

function TimeSlot(props: TimeSlotProps) {
    const { data, remove, edit } = props;
    const { startHour, startMinutes, endHour, endMinutes } = data || {};

    const { t } = useTranslation();

    const REMOVE = t(TKey.actionDelete);
    const EDIT = t(TKey.actionEdit);

    return (
        <>
            <Flex alignItems="center" mb="half">
                <ScheduleIcon stroke="black" strokeWidth="3px" height="2rem" width="2rem" />
                <Label data-testid="period-text" mb="0" ml="half">
                    {`${startHour}:${startMinutes} - ${endHour}:${endMinutes}`}
                </Label>
                <Flex ml="auto">
                    <Button size="sm" mr="half" onClick={remove} data-testid="timeslot-action-remove">
                        {REMOVE}
                    </Button>
                    <Button size="sm" onClick={edit} data-testid="timeslot-action-edit">
                        {EDIT}
                    </Button>
                </Flex>
            </Flex>
        </>
    );
}

export default TimeSlot;
