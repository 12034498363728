import styled from 'styled-components';
import { List } from 'aa-react-ui';

const ListWrapper = styled(List)`
    height: 100%;
    margin-top: 1rem;
    overflow-y: auto;
`;

export { ListWrapper };
