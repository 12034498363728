import React, { useState, useEffect } from 'react';
import { useTranslation, TKey } from 'locale';
import ValiditySelector from 'domain/validity/components/validity_selector';
import StepView from 'components/wizard/step_view';
import ValidityModel from 'models/validity';
import CommonUtils from 'common/utils/common';
import { IKey } from 'models/types';
import { Box, Alert } from 'aa-react-ui';

interface IProps {
    onNext?: () => void;
    onBack?: () => void;
    data: IKey;
    setData: (data: IKey) => void;
}

const Validity = (props: IProps) => {
    const { onNext, onBack, data, setData } = props;

    const { t } = useTranslation();
    const [currentValidity, setCurrentValidity] = useState<ValidityModel | undefined>();

    useEffect(() => {
        if (data) {
            setCurrentValidity(new ValidityModel(data.validity));
        }
    }, [data]);

    if (!currentValidity || !data) {
        return null;
    }

    const onSubmit = () => {
        const keyRef = CommonUtils.cloneDeep(data);
        keyRef.validity = currentValidity.toDataObject();
        setData(keyRef);
        onNext && onNext();
    };

    return (
        <StepView title={t(TKey.handOutHeader)} submitText={t(TKey.actionNext)} onSubmit={onSubmit} onBack={onBack}>
            <Box mb="half" m="quarter">
                <Alert text={t(TKey.handOutValidityBody)} />
            </Box>
            <ValiditySelector
                value={currentValidity}
                onSelect={newValidity => {
                    setCurrentValidity(newValidity);
                }}
            />
        </StepView>
    );
};

export default Validity;
