import React from 'react';
import { Field, AnyObject } from 'react-final-form';
import { History } from 'history';

import { SectionHeader, Section, SectionBody, H3, Label, Box, Input, Text, Alert } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { buildRoute } from 'router/helper';
import { Routes } from 'router';

import { KeyCollectionType } from 'domain/key/types';
import KeyModel from 'models/key';

import { useUpdateKeyRequest } from 'domain/key/redux/hooks';
import { useNotification } from 'domain/notification/redux/hooks';

import KeyCollectionItem from 'domain/key/components/key_collection_item';

import EditorActions from 'components/editor_actions';
import { Form } from 'components/form';
import RouteGuard from 'components/route-guard';

interface IKeyNameEditor {
    history: History;
    keyData: { key?: KeyModel; collectionType?: KeyCollectionType };
}

const validateName = (str: string) => (str && !!str.trim() ? undefined : 'Required');

const KeyNameEditor = (props: IKeyNameEditor) => {
    const { history, keyData } = props;
    const { key } = keyData;

    const { t } = useTranslation();
    const { updateKey } = useUpdateKeyRequest();
    const { showError, showSuccess } = useNotification();

    if (!key) {
        return null;
    }

    const goBack = async () => {
        const route = buildRoute(Routes.KEYS_KEY, { keyId: key.uuid });
        history.push(route);
    };

    const handleSubmit = async (values: AnyObject) => {
        const { name, uuid } = values;

        if (!name || !key) {
            return;
        }

        const dataToTransfer = key.toDataTransferObject();
        dataToTransfer.name = name;

        const updateSuccess = await updateKey(uuid, dataToTransfer);

        if (!updateSuccess) {
            showError(TKey.genericErrorSaving);
            return;
        }

        showSuccess(TKey.genericSuccessSaved);
        goBack();
    };

    return (
        <Form onSubmit={handleSubmit} initialValues={key}>
            {({ dirty, submitting, errors, handleSubmit }) => (
                <>
                    <RouteGuard when={dirty} />
                    <Section width="100%">
                        <SectionHeader
                            title={<H3>{t(TKey.genericName)}</H3>}
                            actions={
                                <EditorActions
                                    editorDirty={dirty && !(errors && errors.name)}
                                    loading={submitting}
                                    onSave={handleSubmit}
                                    onBack={goBack}
                                />
                            }
                        />
                        <SectionBody height="100%" overflow="hidden" width="100%">
                            <KeyCollectionItem data={key} />

                            <Box mb="full">
                                <Label>{t(TKey.genericName)}</Label>
                                <Field name="name" validate={validateName}>
                                    {({ input }) => (
                                        <Input
                                            disabled={submitting}
                                            data-testid="key-name-input"
                                            spellCheck={false}
                                            maxLength={100}
                                            placeholder={t(TKey.keyNamePlaceholder)}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                <Text mt="half">{t(TKey.keyNameHint)}</Text>
                            </Box>

                            {errors && errors.name && <Alert title={t(TKey.genericErrorEmptyFields)} variant="error" />}
                        </SectionBody>
                    </Section>
                </>
            )}
        </Form>
    );
};

export default KeyNameEditor;
