import React from 'react';
import { TKey, useTranslation } from 'locale';
import { IKey } from 'models/types';
import { Card, Box, Label } from 'aa-react-ui';
import LoalEntryModel from 'models/loal_entry';
import { CylinderIcon } from 'components/icon';

interface IProps {
    keyData: IKey;
}

const KeyAccessText = (props: IProps) => {
    const { keyData } = props;
    const { t } = useTranslation();

    const totalAccesses = keyData.authorizations.filter(loal => {
        const loalItem = new LoalEntryModel(loal);
        return loalItem.isAdded() || loalItem.isCurrent();
    }).length;

    return (
        <Card flexDirection="row" p="half" m="quarter">
            <Box mr="half">
                <CylinderIcon stroke="black" strokeWidth="3px" height="2rem" width="2rem" />
            </Box>
            <Label>{t(TKey.handOutSummaryNumCylinderAccesses, { count: totalAccesses })}</Label>
        </Card>
    );
};

export default KeyAccessText;
