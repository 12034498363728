import React, { useEffect, useState } from 'react';
import { Label, Box, Input, Text } from 'aa-react-ui';
import { useTranslation, TKey } from 'locale';
import { useFocus } from 'components/hooks';
import { IKey } from 'models/types';
import StepView from 'components/wizard/step_view';
import CommonUtils from 'common/utils/common';

interface IProps {
    onNext?: () => void;
    onBack?: () => void;
    data: IKey;
    setData: (newData: IKey) => void;
}

const Name = (props: IProps) => {
    const { onNext, onBack, data, setData } = props;

    const { t } = useTranslation();
    const [inputRef, setInputFocus] = useFocus();
    const [keyName, setKeyName] = useState('');

    useEffect(() => {
        setInputFocus();
    }, [setInputFocus]);

    useEffect(() => {
        if (data) {
            setKeyName(data.name);
        }
    }, [data]);

    if (!data) {
        return null;
    }

    const onSubmit = () => {
        const keyRef = CommonUtils.cloneDeep(data);
        keyRef.name = keyName;
        setData(keyRef);
        onNext && onNext();
    };

    return (
        <StepView
            title={t(TKey.handOutHeader)}
            onBack={onBack}
            submitText={t(TKey.actionNext)}
            onSubmit={onSubmit}
            disableSubmit={!keyName}>
            <Box>
                <Label>{t(TKey.handOutNameBody)}</Label>
                <Input
                    data-testid="key-name-input"
                    ref={inputRef}
                    spellCheck={false}
                    value={keyName}
                    maxLength={100}
                    placeholder={t(TKey.keyNamePlaceholder)}
                    onChange={e => {
                        setKeyName(e.target.value);
                    }}
                />
                <Text mt="half">{t(TKey.keyNameHint)}</Text>
            </Box>
        </StepView>
    );
};

export default Name;
