import React from 'react';
import { Alert, Box, Text } from 'aa-react-ui';
import { TKey, useTranslation } from 'locale';
import { KeyCollectionItem } from 'domain/key/components';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useKey } from 'domain/key/redux/hooks';
import { Routes } from 'router';
import { buildRoute } from 'router/helper';
import StepView from 'components/wizard/step_view';
import KeyModel from 'models/key';

const VerifySelection = () => {
    const params = useParams<{ keyId: string }>();
    const selectedKey = useKey(params.keyId);
    const location = useLocation<{ from: Location }>();
    const history = useHistory();

    const { t } = useTranslation();

    if (!selectedKey) {
        return null;
    }

    const onSubmit = () => {
        const selectTaskKeyRoute = buildRoute(Routes.BLOCK_KEY_TASK_ASSIGN_KEY, { keyId: selectedKey.uuid });
        history.push(selectTaskKeyRoute, { from: history.location });
    };

    const onBack = () => {
        const { state } = location;

        if (state && state.from) {
            history.push(state.from.pathname);
        } else {
            history.push(Routes.BLOCK_KEY);
        }
    };

    return (
        <StepView
            title={t(TKey.blockKeyHeader)}
            subTitle={t(TKey.blockKeyVerifyHeader)}
            onBack={onBack}
            disableSubmit={!selectedKey}
            submitText={t(TKey.actionNext)}
            onSubmit={onSubmit}>
            <Box m="quarter" mb="half">
                <Alert text={t(TKey.blockKeyVerifyBodyPart1)} variant="warning" />
            </Box>
            <KeyCollectionItem data={new KeyModel(selectedKey)} hideStatus />
            <Box m="quarter" mt="half" mb="half">
                <Alert
                    text={
                        <>
                            <Text mb="half">{t(TKey.blockKeyVerifyBodyPart2)}</Text>
                            <Text>{t(TKey.blockKeyVerifyBodyPart2b)}</Text>
                        </>
                    }
                    variant="info"
                />
            </Box>
        </StepView>
    );
};

export default VerifySelection;
